import React, { memo } from "react";
import classNames from "classnames";

function OrgInviteIr({ className, style, children, item = null, onClick }) {
    const clickHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (onClick) onClick(item);
    };

    return (
        <div className={classNames("org-invite-ir", className)} style={style}>
            <div className="c-black f-w-sb">{item.name}</div>
            <div className="m-t-10">
                <button className="btn is-primary ghost" title="Verify" onClick={clickHandler}>
                    Verify
                </button>
            </div>

            {children}
        </div>
    );
}

export default memo(OrgInviteIr);
/******************************
item
onClick
******************************/
