import React, {
    useContext,
    Fragment,
    useState,
    memo,
    useCallback,
    useEffect,
    useRef,
    forwardRef,
} from "react";
import classNames from "classnames";
import { objectHasMatch } from "../../app-base/fn";
import { OrgContext } from "../../context/OrgContext";
import PayerSearchIr from "../payers/PayerSearchIr";

const SearchPayer = forwardRef(
    (
        {
            className,
            style,
            children,
            visible = false,
            disabled = false,
            mode = "search",
            orgid = null,
            searchText = "",
            irClass = null,
            irStyle = null,
            toggleHandler,
        },
        ref,
    ) => {
        const orgState = useContext(OrgContext);
        const [state, setState] = useState();

        const isMounted = useRef(null);

        useEffect(() => {
            isMounted.current = true;

            return () => {
                isMounted.current = false;
            };
        }, []);

        const changeState = (newObj) => {
            if (isMounted.current) setState({ ...state, ...newObj });
        };

        useEffect(() => {
            if (isMounted.current) changeState();
        }, [orgid]);

        const sortFn = useCallback(
            (a, b) => {
                let val = 0;

                // sort by payer_name
                val = a.payer_name === b.payer_name ? 0 : a.payer_name < b.payer_name ? -1 : 1;
                return val;
            },
            [orgState.data.payers],
        );

        const filterFn = useCallback(
            (item) => {
                if (searchText === "") {
                    return true;
                } else {
                    // const regex = new RegExp(escapeRegexCharacters(searchText), "i");
                    // let found = false;

                    // found = regex.test(item.name);
                    // if (!found) found = regex.test(item.payer_type);
                    // if (!found) found = regex.test(item.master_claim_type);
                    // if (!found) found = regex.test(item.state);

                    // return found;

                    return objectHasMatch(item, searchText);
                }
            },
            [orgState.data.payers, searchText],
        );

        const clickHandler = (item) => {
            orgState.actions({
                type: "orgRecentListAdd",
                value: { view: "payers", item: item, itemKey: "id" },
            });

            toggleHandler();
        };

        if (!visible) {
            return null;
        } else {
            return (
                <div className={classNames("search-payer", className)} style={style}>
                    {children}

                    <div className={classNames({ "no-display": mode !== "search" })}>
                        <Fragment>
                            {orgState.data.payers
                                .sort(sortFn)
                                .filter(filterFn)
                                .map((item) => {
                                    return (
                                        <PayerSearchIr
                                            key={item.id}
                                            className={irClass}
                                            style={irStyle}
                                            disabled={disabled}
                                            item={item}
                                            onClick={clickHandler}
                                        />
                                    );
                                })}
                        </Fragment>
                    </div>

                    <div className={classNames({ "no-display": mode !== "recent" })}>
                        {orgState.data.recentList.payers.length === 0 && (
                            <div className="center-all f-w-sb c-grey m-t-50">No Recent</div>
                        )}

                        {orgState.data.recentList.payers.map((item) => {
                            return (
                                <PayerSearchIr
                                    key={item.id}
                                    className={irClass}
                                    style={irStyle}
                                    disabled={disabled}
                                    item={item}
                                    onClick={clickHandler}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        }
    },
);

export default memo(SearchPayer);
