import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import SearchBar from "./searchbar/SearchBar";
// import PatientSearchBar from "./contacts/PatientSearchBar";
// import SearchBar from "./SearchBar";
// import Stickyfill from "stickyfill";
// import { uniqueId } from "../app-base/fn";

function OrgNav({ className, style, children, nav = [], orgid = null, disabled = false }) {
    return (
        <div
            // id={this.uniqueId}
            className={classNames("org-nav", className)}
            // className={classNames("org-nav sticky", className)}
            style={style}
            // ref={this.navRef}
        >
            {children}

            <div className="fl middle p-l-20 p-r-20">
                <SearchBar
                    className="col-2"
                    style={{ maxWidth: "100%" }}
                    disabled={disabled}
                    orgid={orgid}
                />

                <div className="nav-area col-2 col-right p-t-5 p-b-5">
                    {nav.map((item, i) => {
                        return (
                            <div key={i} className="inline t-center">
                                <NavLink
                                    disabled={disabled}
                                    to={item.url}
                                    className={({ isActive }) =>
                                        "org-link" + (isActive ? " is-active" : "")
                                    }
                                    title={item.title}
                                >
                                    <div>
                                        <i
                                            className={classNames("material-icons f-s-xlg m-r-0", {
                                                "no-display": !item.icon,
                                            })}
                                            style={{ lineHeight: 1 }}
                                        >
                                            {item.icon}
                                        </i>

                                        <div
                                            className={classNames("letter f-s-xlg", {
                                                "no-display": item.icon,
                                            })}
                                        >
                                            {item.title.charAt(0).toUpperCase()}
                                        </div>

                                        <div className="f-s-xxsm" style={{ lineHeight: 0.6 }}>
                                            {item.title}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default memo(OrgNav);

// constructor(props) {
// super(props);
//     this.uniqueId = uniqueId();
//     // this.stickyfill = new Stickyfill();
//     // this.navRef = React.createRef();
// }

// check if org nav item should be set as active
// navActive = (match, location) => {

//     if (!match) {
//         const rootPaths = this.props.appstore.userStore.rootPaths;
//         let found = false;

//         for (let i = 0; i < rootPaths.length; i++) {
//             found = location.pathname.includes(rootPaths[i]);
//             if (found) break;
//         }

//         return found;
//     }
//     return true;
// };

// componentDidMount() {
//     // stickyfill.add(this.navRef.current);
//     // stickyfill.add(document.getElementById(this.uniqueId));
//     // this.stickyfill.add(this.navRef.current);
// }

// componentWillUnmount() {
//     // stickyfill.remove(document.getElementById(this.uniqueId));
//     // this.stickyfill.remove(this.navRef.current);
// }
