import React, { memo, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import TextInput from "../input/TextInput";

function SearchText({
    className,
    style,
    children,
    left,
    center,
    right,
    inputClass,
    inputStyle,
    btnClass = "sm",
    // btnStyle,
    type = "text",
    searchOnChange = true, // search when text input changes
    disabled = false,
    placeholder = "Search",
    icon = "search",
    iconClass = "",
    clearable = true,
    loading = false,
    value,
    required = false,
    onFocus,
    onBlur,
    onChange,
    onSearch,
}) {
    const hasMounted = useRef(null);
    const [state, setState] = useState({ viewText: value, searchText: "" });

    useEffect(() => {
        hasMounted.current = true;

        return () => {
            hasMounted.current = false;
        };
    }, []);

    const changeState = (newObj) => {
        if (hasMounted.current) {
            setState((state) => {
                return { ...state, ...newObj };
            });
        }
    };

    const changeHandler = (key, text) => {
        if (!disabled) {
            changeState({ viewText: text });
            if (onChange) onChange(text);
        }
    };

    const clearSearch = () => {
        changeState({ viewText: "", searchText: "" });
        if (onChange) onChange("");
        if (onSearch) onSearch("");
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const newText = state.viewText.trim();

        if (onSearch) onSearch(newText);
    };

    useEffect(() => {
        changeState({ viewText: value });
    }, [value]);

    useEffect(() => {
        const newText = state.viewText.trim();
        let filterTimer = null;

        if (newText !== state.searchText) {
            filterTimer = setTimeout(() => {
                const newText = state.viewText.trim();

                changeState({ searchText: newText });
                if (onSearch && searchOnChange) onSearch(newText);
            }, 300);
        }

        return () => {
            if (filterTimer) {
                clearTimeout(filterTimer);
                filterTimer = null;
            }
        };
    }, [state.viewText]);

    return (
        <div className={classNames("search-text", className)} style={style}>
            {children}

            <div className="fl p-rel middle" disabled={disabled}>
                {left}

                <TextInput
                    className={classNames("w-full", inputClass)}
                    inputClass={classNames({
                        "p-l-20": icon !== "",
                        "p-r-20": clearable,
                    })}
                    style={inputStyle}
                    disabled={disabled}
                    type={type}
                    name="value"
                    placeholder={placeholder}
                    required={required}
                    errorMsg="Please enter search value."
                    value={state.viewText}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={changeHandler}
                >
                    <span
                        className={classNames({ "no-display": icon === "" })}
                        style={{
                            position: "absolute",
                            left: "0",
                            top: "4px",
                        }}
                    >
                        <i className={classNames("material-icons", iconClass)}>{icon}</i>
                    </span>

                    <a
                        style={{
                            position: "absolute",
                            right: "0",
                            top: "4px",
                        }}
                        role="button"
                        title="Clear"
                        className={classNames("icon-only", { "no-display": !clearable })}
                        onClick={clearSearch}
                    >
                        <i className="material-icons f-w-b f-s-sm">close</i>
                    </a>
                </TextInput>

                {center}

                {!searchOnChange && (
                    <button
                        type="button"
                        className={classNames("btn m-l-8", btnClass, {
                            "is-loading": loading,
                            "no-display": !onSearch,
                        })}
                        disabled={disabled}
                        // style={{ minHeight: "25px", ...btnStyle }}
                        title="Search"
                        onClick={handleSubmit}
                    >
                        <i className="material-icons" style={{ lineHeight: 1.25 }}>
                            search
                        </i>
                    </button>
                )}

                {right}
            </div>
        </div>
    );
}

export default memo(SearchText);
