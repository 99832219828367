import React, { memo, useMemo, useEffect } from "react";
import classNames from "classnames";
import SigninPwdHdr from "./SigninPwdHdr";
import TextInput from "../../app-base/input/TextInput";
import { passwordRule, validate } from "../../validate";
import Form from "../../app-base/Form";

function SigninPwd({
    className,
    profile,
    email,
    pwd,
    loading = false,
    onChange,
    onSubmit,
    pwdReset,
    setStep,
}) {
    const form = useMemo(() => {
        return new Form();
    }, []);

    const submitHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!loading) {
            const formErr = form.validate(true);

            if (!formErr) {
                // no error on elements
                const error = validate({ pwd: pwd.trim() }, { pwd: passwordRule() });

                if (!error || error === "") {
                    onSubmit(); // commit data changes and check
                } else {
                    form.scrollToElement(error.name);
                }
            }
        }
    };

    const keyUpHandler = (e) => {
        if (e.keyCode === 13 && !loading) {
            // return pressed
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                if (e.target) e.target.blur();
            }

            submitHandler();
        }
    };

    const backHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setStep("email");
    };

    useEffect(() => {
        return () => {
            form.dispose();
        };
    }, []);

    return (
        <div>
            <SigninPwdHdr
                className={classNames("signin-pwd-hdr", className)}
                profile={profile}
                email={email}
            />

            <div onKeyUp={keyUpHandler}>
                <TextInput
                    className="t-center"
                    type="password"
                    name="pwd"
                    label="Password"
                    required={true}
                    // disabled={loading}
                    focus={true}
                    value={pwd}
                    onInputAdd={form.add}
                    onInputRemove={form.remove}
                    onChange={onChange}
                />

                <div className="signin-buttons">
                    <button
                        type="button"
                        className={classNames("btn is-primary w-full lg", {
                            "is-loading": loading,
                        })}
                        onClick={submitHandler}
                    >
                        <span>Sign In</span>
                    </button>
                </div>
            </div>

            <a
                role="button"
                className="sm m-b-15 m-t-5"
                title="Reset my password"
                onClick={pwdReset}
            >
                <i className="material-icons c-black m-t-1">https</i>
                <span className="m-l-5 f-w-sb">Reset Password</span>
            </a>

            <div className="m-t-15 t-center f-s-sm">
                <a style={{ cursor: "pointer" }} onClick={backHandler}>
                    <span>Sign in with different account</span>
                </a>
            </div>
        </div>
    );
}

export default memo(SigninPwd);
