import React from "react";
import classNames from "classnames";
import FormWrap from "./FormWrap";

function FormHoc(BaseCmp) {
    function Wrap({
        className,
        vertical = true,
        labelClass,
        labelStyle,
        labelWrapClass,
        inputWrapClass,
        style,
        label,
        help,
        dscr,
        showIndex,
        indexStr,
        required = false,
        ...other
    }) {
        return (
            <FormWrap
                className={classNames("form-input", className)}
                style={style}
                vertical={vertical}
                labelClass={labelClass}
                labelWrapClass={labelWrapClass}
                inputWrapClass={inputWrapClass}
                label={label}
                labelStyle={labelStyle}
                help={help}
                dscr={dscr}
                showIndex={showIndex}
                indexStr={indexStr}
                required={required}
            >
                <BaseCmp
                    {...other}
                    required={required}
                    className={other.innerClass}
                    style={other.inputStyle}
                />
            </FormWrap>
        );
    }

    return Wrap;
}

export default FormHoc;
