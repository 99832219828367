import React from "react";
import classNames from "classnames";

function Container({
    style,
    children,
    className = "bg-white",
    topBarClass = "bg-top-bar p-t-15 p-b-15 p-l-20 p-r-20",
    mainClass = "",
    title = "",
    showPadding = true, // show padding of 25px
    showShadow = true, // show shadow
    visible = true,
    topBarStyle,
    titleClass,
    titleStyle,
    titleRightElm,
    topElm,
    mainStyle,
}) {
    return (
        <div
            className={classNames("container", className, {
                "shadow-card": showShadow,
                "no-display": !visible,
            })}
            style={style}
        >
            {title !== "" && !topElm && (
                <div className={classNames("top-bar fl middle", topBarClass)} style={topBarStyle}>
                    <div
                        className={classNames("top-title col-3 m-r-5", titleClass)}
                        style={titleStyle}
                    >
                        {title}
                    </div>

                    {titleRightElm}
                </div>
            )}

            {topElm && <div className={classNames("top-bar", topBarClass)}>{topElm}</div>}

            <div
                className={classNames("main", mainClass, { "p-25": showPadding })}
                style={mainStyle}
            >
                {children}
            </div>
        </div>
    );
}

export default Container;
