import React from "react";
import Loading from "../app-base/elm/Loading";

const LoadingOrg = () => {
    return (
        <div className="loading-org t-center">
            <div style={{ marginTop: "25%" }}>
                <div className="lbl_grey f-w-b f-s-n l-s-1 blinking">LOADING ORGANIZATION</div>
                <Loading
                    className="p-t-2 p-l-10 p-r-10"
                    loader="bar"
                    loaderProps={{ height: 6, width: 250 }}
                />
            </div>
        </div>
    );
};

export default LoadingOrg;
