class IdleTimer {
    constructor({ timeout, onTimeout }) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;
        this.expiredTime = Date.now() + timeout * 1000;

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.continueTracking = this.startTracking.bind(this);
        this.startTracking();
    }

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            if (this.expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    startTracking() {
        this.tracker();
        this.startInterval();
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }

        this.timeoutTracker = setTimeout(() => {
            this.expiredTime = Date.now() + this.timeout * 1000;
        }, 300);
    }

    tracker() {
        // document.addEventListener("visibilitychange", this.eventHandler);
        window.addEventListener("mousemove", this.eventHandler);
        // window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        clearInterval(this.interval);

        // document.removeEventListener("visibilitychange", this.eventHandler);
        window.removeEventListener("mousemove", this.eventHandler);
        // window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}

export default IdleTimer;
