import React, { useMemo, memo } from "react";
import classNames from "classnames";
import { formatToHtml } from "../fn";

function FormWrap({
    className,
    style,
    children,
    help = "",
    dscr = "",
    required = false,
    vertical = true,
    inputWrapClass = "w-full",
    labelClass,
    labelStyle,
    labelWrapClass,
    showIndex,
    indexStr,
    label,
}) {
    const ir = useMemo(() => {
        return {
            label_html: formatToHtml(label),
            dscr_html: formatToHtml(dscr),
            help_html: formatToHtml(help),
            is_required: !required ? false : true,
        };
    }, [label, dscr, help, required]);

    return (
        <div
            className={classNames("form-wrap", className, {
                "has-label": ir.label_html !== "",
                fl: !vertical,
            })}
            style={style}
        >
            <div
                className={classNames("lbl-wrap", labelWrapClass, {
                    "no-display": ir.label_html === "",
                    "m-r-5": !vertical,
                })}
                style={labelStyle}
            >
                <div
                    className={classNames("line-index m-b-6", {
                        "no-display": !showIndex || !indexStr,
                    })}
                >
                    {indexStr}
                </div>

                <div className="title-wrap">
                    <span
                        className={classNames("lbl", labelClass)}
                        dangerouslySetInnerHTML={{ __html: ir.label_html }}
                    />
                    <span
                        className={classNames("line-required", { "no-display": !ir.is_required })}
                    >
                        *
                    </span>
                </div>
            </div>

            <div className={classNames("input-wrap", inputWrapClass)}>
                <div
                    className={classNames("dscr", { "no-display": ir.dscr_html === "" })}
                    dangerouslySetInnerHTML={{ __html: ir.dscr_html }}
                />

                {children}

                <div>
                    <small
                        className={classNames("help t-muted p-l-15 p-r-15", {
                            "no-display": ir.help_html === "",
                        })}
                        dangerouslySetInnerHTML={{ __html: ir.help_html }}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(FormWrap);
/******************************
 label
 dscr
 help
 required
 labelClasses
 children - elements
 showIndex - (Alpha numeric next to lable)
 indexStr - index String
 ******************************/
