import * as Sentry from "@sentry/react";
import "./styles/app.scss";
import "intersection-observer";
import NProgress from "nprogress";
import React from "react";
import { createRoot } from "react-dom/client";
import { configure } from "mobx";
import { AppContextProvider } from "./context/AppContext";
import AppContent from "./AppContent";

Sentry.init({
    dsn: "https://855f77c5f11c98f6d6844391f7e0c5b7@o4506974054187008.ingest.us.sentry.io/4507353914802176",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// remove app preloader
NProgress.configure({ showSpinner: false }); // hide spinner on progress bar

configure({
    useProxies: "never",
    enforceActions: "never",
});

const AppWithCallbackAfterRender = () => {
    return (
        <AppContextProvider data={null}>
            <AppContent />
        </AppContextProvider>
    );
};

const container = document.getElementById("app");
const root = createRoot(container);

root.render(<AppWithCallbackAfterRender />);

// if (module.hot) {
//     module.hot.accept("./app", () => {
//         const NextApp = require("./app").default; // Get the updated code
//         render(NextApp);
//     });
// }
