import React, { useContext, useRef, useState, memo } from "react";
import classNames from "classnames";
import { AppContext } from "../../context/AppContext";
import SearchText from "../../app-base/elm/SearchText";
import SearchBarPanel from "./SearchBarPanel";
import ButtonChoicesInput from "../../app-base/input/ButtonChoicesInput";

const SearchBar = ({ className, style, children, disabled = false, orgid = null }) => {
    const appState = useContext(AppContext);

    const defaultStyle = {
        // transition: `opacity 200ms ease-in-out`,
        overflow: "hidden",
        transform: "translate(0, 0)",
        // transform: "translate(0, -1000px)",
        // opacity: 0,
        height: 560,
        background: "#5b5b5b",
        top: 43,
        width: "100%",
        maxWidth: "900px",
        left: 25,
        right: 25,
        margin: "0 auto",
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
    };

    const searchViewList = [
        { label: "Patient", data: "patients", icon: "search" },
        { label: "Encounter", data: "encounters", icon: "search" },
        { label: "Payer", data: "payers", icon: "search" },
    ];

    const defaultState = {
        isOpen: false,
        mode: "search", // search or recent
        searchView: "patients",
        searchText: "", // search text
    };

    const searchHasFocus = useRef(false);

    const [state, setState] = useState(defaultState);

    const changeState = (newObj) => {
        setState((state) => {
            return { ...state, ...newObj };
        });
    };

    const toggleDropdown = (mode = "search", e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!disabled) {
            if (state.isOpen) {
                if (state.mode !== mode) {
                    changeState({ mode: mode });
                } else {
                    if (!searchHasFocus.current) changeState({ isOpen: !state.isOpen });
                }
            } else {
                // closed
                changeState({
                    mode: mode,
                    isOpen: !state.isOpen,
                });
            }
        }
    };

    const onChangeSearchView = (key, view) => {
        if (state.searchView !== view) {
            changeState({ searchView: view, searchText: "" });
        } else {
            if (!state.isOpen) {
                changeState({ isOpen: true });
            }
        }
    };

    const searchTextFocusHandler = () => {
        if (!disabled) {
            searchHasFocus.current = false;

            if (!disabled && !state.isOpen) {
                changeState({
                    isOpen: true,
                });
            }
        }
    };

    const searchTextBlurHandler = () => {
        searchHasFocus.current = false;
    };

    const searchHandler = (text) => {
        changeState({ searchText: text });

        if (text.trim() !== "") {
            if (!state.isOpen) {
                changeState({
                    mode: state.mode !== "search" ? "search" : state.mode,
                    isOpen: true,
                });
            }
        } else {
            // if (state.isOpen) {
            //     changeState({
            //         mode: "search",
            //         isOpen: false,
            //     });
            // }
        }
    };

    const addEncounterHandler = (contactId = null) => {
        if (state.isOpen) changeState({ isOpen: false });
        // let ndte = new Date();

        // ndte.setDate(ndte.getDate() + 1);

        appState.actions({
            type: "openWindow",
            value: {
                name: "encounter-add",
                title: "Schedule Encounter",
                props: {
                    isEzEntry: true,
                    showContact: true,
                    showPayers: true,
                    showPlan: false,
                    orgid: orgid,
                    encTime: null,
                    fromEncId: null,
                    providerId: null,
                    contactId: contactId,
                    // onSaved: encounterSaved,
                },
                pageType: "popup",
            },
        });
    };

    const addClickHandler = () => {
        if (!disabled) {
            if (state.isOpen) changeState({ isOpen: false });

            switch (state.searchView) {
                case "patients":
                    appState.actions({
                        type: "openWindow",
                        value: {
                            name: "patient",
                            title: "Add Patient",
                            props: {
                                orgid: orgid,
                            },
                            size: "md",
                            pageType: "popup",
                        },
                    });

                    break;
                case "encounters":
                    addEncounterHandler();
            }
        }
    };

    if (!orgid) return null;

    return (
        <div className={classNames("search-bar", className)} style={style}>
            <div className="fl middle">
                {children}

                {/* <span className="material-icons m-r-2 f-s-lg f-w-b">search</span> */}

                <ButtonChoicesInput
                    vGap={0}
                    className="m-t-2"
                    btnClass="is-light sm"
                    btnSelectedClass="is-primary sm"
                    // iconKey="icon"
                    // iconClass="f-s-lg m-r-2"
                    clearable={false}
                    required={true}
                    allowMultiple={false}
                    datalist={searchViewList}
                    dataKey="data"
                    labelKey="label"
                    value={state.searchView}
                    onCommit={onChangeSearchView}
                />

                {(state.searchView === "patients" || state.searchView === "encounters") && (
                    <button
                        title="Add"
                        className="btn icon-only is-dark m-l-3 p-l-5-p-r-5"
                        type="button"
                        disabled={disabled}
                        onClick={addClickHandler}
                    >
                        <i className="material-icons f-s-b f-s-lg" style={{ lineHeight: 1 }}>
                            add
                        </i>
                    </button>
                )}

                <SearchText
                    className="col-auto t-left m-l-10"
                    btnClass="is-light sm"
                    disabled={disabled}
                    name="searchText"
                    placeholder={state.searchView === "encounters" ? "Encounter #" : "Search"}
                    type="text"
                    value={state.searchText}
                    onFocus={searchTextFocusHandler}
                    onBlur={searchTextBlurHandler}
                    // onChange={searchValueChangeHandler}
                    onSearch={searchHandler}
                    right={
                        <>
                            {/* <button
                                        title="View search results"
                                        className="btn is-light m-l-5"
                                        type="button"
                                        onClick={(e) => toggleDropdown("search", e)}
                                    >
                                        <i
                                            className="material-icons f-s-xlg"
                                            style={{ lineHeight: 1.25 }}
                                        >
                                            view_headline
                                        </i>
                                    </button> */}

                            {/* <button
                                        title="View recent"
                                        className="btn sm is-light m-l-5"
                                        type="button"
                                        disabled={disabled}
                                        onClick={(e) => toggleDropdown("recent", e)}
                                    >
                                        <i className="material-icons" style={{ lineHeight: 1.25 }}>
                                            history
                                        </i>
                                    </button> */}
                        </>
                    }
                />

                <SearchBarPanel
                    visible={state.isOpen}
                    style={defaultStyle}
                    orgid={orgid}
                    mode={state.mode}
                    view={state.searchView}
                    searchText={state.searchText}
                    toggleDropdown={toggleDropdown}
                    onAddEncounter={addEncounterHandler}
                />
            </div>
        </div>
    );
};

export default memo(SearchBar);
