import React, { createContext, memo } from "react";
import appState from "../states/appState";

export const AppContext = createContext();

export const AppContextProvider = ({ children, data }) => {
    const val = appState(data);

    return <AppContext.Provider value={val}>{children}</AppContext.Provider>;
};

export default memo(AppContextProvider);
