import React, { lazy, Suspense, useEffect, useRef, useState, memo } from "react";
import classNames from "classnames";
import {
    // Await,
    createBrowserRouter,
    // createRoutesFromElements,
    // defer,
    // Form,
    // Link,
    Outlet,
    // Route,
    RouterProvider,
    // useLocation,
    // useNavigate,
    // useMatches,
    // useAsyncError,
    // useAsyncValue,
    // useFetcher,
    // useFetchers,
    // useLoaderData,
    // useNavigation,
    // useParams,
    // useRevalidator,
    // useRouteError,
    ScrollRestoration,
    // useMatch,
} from "react-router-dom";
import ContentContainer from "./ContentContainer";
import ErrorBoundary from "./ErrorBoundary";
import ModuleLoading from "./ui/ModuleLoading";
import NotFoundPage from "./ui/pages/NotFoundPage";
import Signin from "./ui/signin/Signin";

const EncounterListPage = lazy(() => import("./ui/pages/EncounterListPage"));
const TasksPage = lazy(() => import("./ui/pages/TasksPage"));
// const PatientDetailPage = lazy(() => import("./ui/pages/PatientDetailPage"));
const DocumentsPage = lazy(() => import("./ui/pages/DocumentsPage"));
// const EventsPage = lazy(() => import("./ui/pages/EventsPage"));
const OrgAdminPage = lazy(() => import("./ui/pages/OrgAdminPage"));
const VendorsPage = lazy(() => import("./ui/pages/VendorsPage"));
const ScheduleListPage = lazy(() => import("./ui/pages/ScheduleListPage"));
const BillingPage = lazy(() => import("./ui/pages/BillingPage"));

export function Fallback() {
    return <p>Performing initial data load</p>;
}

const Routes = ({
    className,
    style,
    root = null,
    isAuthenticated = false,
    orgid = null,
    hasOpenWindow = false,
}) => {
    const [state, setState] = useState({ router: null });
    const currentOrgRef = useRef(null);

    const changeState = (obj) => {
        if (obj) {
            setState((prevState) => ({ ...prevState, ...obj }));
        }
    };

    const createNavigation = () => {
        let router = null;
        let navs = [
            {
                element: (
                    <ContentContainer root={root}>
                        <Suspense fallback={<ModuleLoading />} errorElement={<ErrorBoundary />}>
                            <Outlet />
                        </Suspense>
                    </ContentContainer>
                ),
                children: [
                    {
                        name: "Sign In",
                        path: "/signin",
                        index: isAuthenticated,
                        element: <Signin title="Sign In" name="Sign In" />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Encounter",
                        path: "/encounters",
                        index: isAuthenticated,
                        element: (
                            <EncounterListPage title="Encounters" name="Encounters" orgid={orgid} />
                        ),
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Tasks",
                        path: "/tasks",
                        element: <TasksPage title="Tasks" name="Tasks" orgid={orgid} />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Documents",
                        path: "/docs",
                        element: <DocumentsPage title="Documents" name="Documents" orgid={orgid} />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Vendors",
                        path: "/vendors",
                        element: <VendorsPage title="Vendors" name="Vendors" orgid={orgid} />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Schedules",
                        path: "/schedules",
                        element: (
                            <ScheduleListPage title="Schedules" name="Schedules" orgid={orgid} />
                        ),
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Organization Detail",
                        path: "/organization",
                        element: (
                            <OrgAdminPage
                                title="Organization Detail"
                                name="Organization Detail"
                                orgid={orgid}
                            />
                        ),
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        name: "Billing",
                        path: "/billing",
                        element: <BillingPage title="Billing" name="Billing" orgid={orgid} />,
                        errorElement: <ErrorBoundary />,
                    },
                    // {
                    //     name: "Patient",
                    //     path: "/patients/:id",
                    //     element: <PatientDetailPage title="Patient" name="Patient" orgid={orgid} />,
                    //     errorElement: <ErrorBoundry />,
                    // },
                    // {
                    //     name: "Events",
                    //     path: "/events",
                    //     element: <EventsPage title="Events" name="Events" orgid={orgid} />,
                    //     errorElement: <ErrorBoundry />,
                    // },
                    {
                        path: "*",
                        element: <NotFoundPage />,
                    },
                ],
            },
        ];

        router = createBrowserRouter(navs);

        return router;
    };

    useEffect(() => {
        const navs = createNavigation();

        changeState({ router: navs });
    }, [isAuthenticated]);

    useEffect(() => {
        if (currentOrgRef.current !== orgid) {
            currentOrgRef.current = orgid;
            const navs = createNavigation();

            changeState({ router: navs });
        }
    }, [orgid]);

    useEffect(() => {
        const navs = createNavigation();

        changeState({ router: navs });
    }, []);

    if (import.meta.hot && state && state.router) {
        import.meta.hot.dispose(() => state.router.dispose());
    }

    if (!state || !state.router) return null;

    return (
        <div className={classNames(className)} style={style}>
            <RouterProvider router={state.router} fallbackElement={<Fallback />}>
                <ScrollRestoration
                    getKey={(location, matches) => {
                        return location.key;
                    }}
                />
            </RouterProvider>
        </div>
    );
};

export default memo(Routes);
