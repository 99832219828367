import { useReducer } from "react";
import axios from "axios";
import {
    isNullOrUndefined,
    findListItemIndex,
    updateObject,
    updateObjectKey,
    mergeExistingOnly,
    addToList, // add new line to a list
    updateList, // replace line in a list
    removeFromList, // remove line in a list
    getLocalStorage,
    setLocalStorage,
    uniqueId,
    hasObject,
    // deleteLocalStorageKey
} from "../app-base/fn";
import { fileAuthModel } from "../baseModels";
// import { isImage } from "../app-base/data/files";

// base data for app - accessible throught the app through context

export function userModel() {
    return {
        id: null,
        apploginct: 0,
        applstlogin: null,
        appusertype: "",
        firstname: "",
        lastname: "",
        mdlname: "",
        profileimgfile: "",
        sfx: "",
        status: "",
        stn: "",
    };
}

export function userOrgModel() {
    return {
        app_status: "active",
        entity: "",
        id: "", // contact id for organization
        isperson: true,
        name: "",
        orgid: "",
        profileimgfile: "",
        relationships: [],
        roles: [],
        usertype: "",
        email: "",
        email_id: null,
        email_verify_dte: null,
        primary_email: false,
    };
}

export function appModel() {
    return {
        theme: "light",
        preUrlPath: "", // initial user requested path before signin
        isAuthenticated: false,
        session: null,
        animatePage: true,
        orgid: null, // current user orgid
        initalLoad: true, // initial app load
        orgLoading: false,
        searchResultIsOpen: false,
        windows: [],
        user: null,
        orgs: null,
        defaultOrg: null,
        currentOrg: null,
        currentOrgKey: "_org",
        socket: null,
        socketConnected: false,
        socketSessionId: "",

        // socket: null,
        // socketConnected: false,
        fileUploadQue: [],
        fileErrorQue: [],
    };
}

export function appState(initData = null) {
    const data = initData ? { ...appModel(), ...initData } : appModel();

    axios.interceptors.response.use(
        (response) => {
            // NProgress.done();
            return response;
        },
        (error) => {
            // NProgress.done();
            return Promise.reject(error);
        }
    );

    const onLogout = (currentState) => {
        return updateObject(currentState, {
            isAuthenticated: false,
            session: null,
            user: null,
            orgs: null,
            orgid: null,
            windows: [],
            defaultOrg: null,
            currentOrg: null,
            currentOrgKey: null,
        });
    };

    const setUser = (userdata) => {
        if (isNullOrUndefined(userdata)) {
            return null;
        } else {
            return { ...userModel(), ...userdata };
        }
    };

    const setOrgs = (orglist) => {
        if (isNullOrUndefined(orglist) || orglist.length === 0) {
            return null;
        } else {
            return orglist.map((org) => {
                return { ...userOrgModel(), ...org };
            });
        }
    };

    const updateUserOrg = (currentState, { item, itemKey }) => {
        let newObj = {};
        let list = currentState.orgs && currentState.orgs ? currentState.orgs : [];

        const idx = findListItemIndex(list, itemKey, item[itemKey]);

        if (idx > -1) {
            list = updateList(list, mergeExistingOnly(list[idx], item), idx);
        } else {
            list = addToList(list, item);
        }

        newObj.orgs = list;

        if (currentState.currentOrg && currentState.currentOrg[itemKey] === item[itemKey]) {
            return {
                ...currentState,
                ...newObj,
                currentOrg: mergeExistingOnly(currentState.currentOrg, item),
            };
        } else {
            return { ...currentState, ...newObj };
        }
    };

    const removeUserOrg = (currentState, { key, item, itemKey }) => {
        let list = currentState.orgs && currentState.orgs ? currentState.orgs : [];

        const idx = findListItemIndex(list, "orgid", item.id);

        if (idx > -1) {
            let newObj = {};

            list = removeFromList(list, idx);
            newObj[key] = list;

            return { ...currentState, ...newObj };
        }

        return currentState;
    };

    // profile saved action
    const updateUser = (currentState, userData) => {
        if (currentState.user && userData && userData.id === currentState.user.id) {
            const currentUser = currentState.user;

            return { ...currentState, ...{ user: { ...currentUser, ...userData } } };
        } else {
            return currentState;
        }
    };

    const activeOrgs = (currentState) => {
        if (currentState && currentState.orgs) {
            return currentState.orgs.filter((org) => {
                return org.app_status === "active" && !isNullOrUndefined(org.email_verify_dte);
            });
        }

        return [];
    };

    // selected org changed
    const changeOrg = (currentState, orgid = null) => {
        if (
            currentState &&
            currentState.currentOrg &&
            orgid &&
            currentState.currentOrg.orgid !== orgid
        ) {
            const idx = findListItemIndex(currentState.orgs, "orgid", orgid);

            if (idx > -1) {
                const selectedOrg = currentState.orgs[idx];

                setLocalStorage(currentState.currentOrgKey, selectedOrg.orgid);

                return { ...currentState, ...{ currentOrg: selectedOrg } };
            } else {
                return currentState;
            }
        } else {
            return currentState;
        }
    };

    const setDefaultOrg = (currentState) => {
        let val = null;

        const actives = activeOrgs(currentState);

        if (actives && actives.length > 0) {
            const lastOrg = getLocalStorage(currentState.currentOrgKey);
            const lastOrgid = !lastOrg ? "" : lastOrg;

            if (lastOrgid !== "") {
                // set to last Organization selected
                const orgIdx = findListItemIndex(actives, "orgid", lastOrgid);

                if (orgIdx > -1) {
                    val = actives[orgIdx]; // org from last session is still active
                } else {
                    val = actives[0]; // load first active org
                }
            } else {
                val = actives[0]; // load first active org
            }
        }

        return val;
    };

    // set user data for using app
    const setSigninData = (currentState, signinData) => {
        if (signinData) {
            const session = !signinData.session_data ? null : signinData.session_data;
            const user = setUser(signinData.user);
            const orgs = setOrgs(signinData.orgs);
            let val = {
                isAuthenticated: true,
                session: session,
                user: user,
                orgs: orgs,
                // socket: null,
            };

            if (signinData.user) {
                val.currentOrgKey = signinData.user.id + "_org";
            }

            val.defaultOrg = val.currentOrg = setDefaultOrg(val);

            return val;
        } else {
            return null;
        }
    };

    const openWindow = (
        currentState,
        { name, title = "", props = null, pageType = "window", windowId = null }
    ) => {
        const winId =
            windowId && typeof windowId === "string" && windowId !== "" ? windowId : uniqueId();
        const windows = addToList(currentState.windows, {
            name: name,
            windowId: winId,
            title: title,
            props: props,
            pageType: pageType,
        });

        return updateObject(currentState, { windows: windows });
    };

    const closeWindow = (currentState, windowId) => {
        const idx = findListItemIndex(currentState.windows, "windowId", windowId);

        if (idx > -1) {
            const windows = removeFromList(currentState.windows, idx);
            return updateObject(currentState, { windows: windows });
        } else {
            return currentState;
        }
    };

    const closeAllWindows = (currentState) => {
        if (currentState.windows && currentState.windows.length > 0) {
            return updateObject(currentState, { windows: [] });
        } else {
            return currentState;
        }
    };

    const updateFileErrorQue = (currentState, fileErrorQue) => {
        if (isNullOrUndefined(fileErrorQue)) {
            return { ...currentState, fileErrorQue: [] };
        } else {
            return { ...currentState, fileErrorQue: fileErrorQue };
        }
    };

    const updateFileQue = (currentState, fileQue) => {
        if (isNullOrUndefined(fileQue)) {
            return { ...currentState, fileUploadQue: [] };
        } else {
            return { ...currentState, fileUploadQue: fileQue };
        }
    };

    // file selected for upload > cb is called after every file upload
    const processFiles = (
        currentState,
        { files, entity = "app", entityid = "", folder = "", cb, cbParams }
    ) => {
        if (hasObject(files)) {
            let newFileQue = [];

            Object.keys(files).map((key) => {
                const file = files[key];
                const authdata = fileAuthModel(
                    entity,
                    entityid,
                    "put",
                    folder,
                    file.name,
                    file.type
                );

                newFileQue.push({
                    authdata: authdata,
                    file: file,
                    onComplete: cb,
                    params: cbParams,
                });
            });

            const newList = currentState.fileUploadQue
                ? currentState.fileUploadQue.concat(newFileQue)
                : newFileQue;

            return { ...currentState, fileUploadQue: newList };
        } else {
            return currentState;
        }
    };

    const changeState = (currentState, newVals) => {
        return { ...currentState, ...newVals };
    };

    // will always return full state
    const reducer = (currentState, action) => {
        switch (action.type) {
            case "theme_change":
                // toggle theme > no params
                return updateObjectKey(
                    currentState,
                    "theme",
                    currentState.theme === "dark" ? "light" : "dark"
                );
            case "onLogin":
                // params - value:object > user profile data
                const r = setSigninData(currentState, action.value);

                if (isNullOrUndefined(r)) {
                    return currentState;
                } else {
                    return updateObject(currentState, r);
                }
            case "onLogout":
                // logout user > no params
                return onLogout(currentState);
            case "updateUser":
                return updateUser(currentState, action.value);
            case "changeOrg":
                return changeOrg(currentState, action.value);
            case "openWindow":
                return openWindow(currentState, action.value);
            case "closeWindow":
                return closeWindow(currentState, action.value);
            case "closeAllWindows":
                return closeAllWindows(currentState);
            case "processFiles":
                return processFiles(currentState, action.value);
            case "updateFileQue":
                return updateFileQue(currentState, action.value);
            case "updateFileErrorQue":
                return updateFileErrorQue(currentState, action.value);
            case "updateUserOrg":
                return updateUserOrg(currentState, action.value);
            case "changeState":
                return changeState(currentState, action.value);
            default:
                return currentState;
            // throw new Error("Action not found");
        }
    };

    const [state, dispatch] = useReducer(reducer, data);

    return { data: state, actions: dispatch };
}

export default appState;
