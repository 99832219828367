import React, { Fragment, useMemo, memo, useEffect, useRef, forwardRef } from "react";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars-2";
import SearchPatient from "./SearchPatient";
import SearchEncounter from "./SearchEncounter";
import SearchPayer from "./SearchPayer";
import { toTitleCase } from "../../app-base/fn";

const SearchBarPanel = forwardRef(
    (
        {
            className,
            style,
            children,
            visible = false,
            disabled = false,
            orgid = null,
            mode = "search", // search or recent
            view = null, // { label= "Patients", data= "patients" },
            searchText = "",
            toggleDropdown,
            onAddEncounter,
            pageType = "window",
        },
        ref,
    ) => {
        const isMounted = useRef(null);

        useEffect(() => {
            isMounted.current = true;

            return () => {
                isMounted.current = false;
            };
        }, []);

        const mouseLeaveHandler = (e) => {
            toggleHandler();
        };

        const toggleHandler = () => {
            if (toggleDropdown) toggleDropdown(mode);
        };

        const cmp = useMemo(() => {
            let viewLbl = "";
            let modeLbl = "";

            if (view) {
                viewLbl = toTitleCase(view);
                modeLbl = mode === "search" ? `Search ${viewLbl}` : `Recently Viewed ${viewLbl}`;
            }

            return {
                viewLbl: viewLbl,
                modeLbl: modeLbl,
            };
        }, [view, mode]);

        // if (ref) {
        //     useImperativeHandle(ref, () => ({
        //         load() {
        //             return load();
        //         },
        //     }));
        // }

        return (
            <div
                className={classNames(
                    "p-abs shadow-card animate__animated animate__zoomIn",
                    className,
                    { "no-display": !visible },
                )}
                style={{
                    ...style,
                    backgroundColor: "rgba(65, 66, 67, 1.00)",
                }}
                onMouseLeave={mouseLeaveHandler}
            >
                <Fragment>
                    <div
                        className="fl middle p-l-20 p-r-20 p-t-20"
                        style={{
                            width: "100%",
                            height: "60px",
                            backgroundColor: "rgba(37, 37, 37, 1.00)",
                        }}
                    >
                        <div
                            className="col-auto f-s-2x f-w-b"
                            style={{ color: "rgba(166, 166, 166, 1.00)" }}
                        >
                            {cmp.modeLbl}
                        </div>

                        <div className="col-fixed t-right" style={{ width: 50 }}>
                            {/* <a onClick={toggleHandler}>
                                <i className="material-icons f-w-b f-s-md c-silver">close</i>
                            </a> */}
                        </div>
                    </div>

                    <Scrollbars style={{ width: "100%", height: "500px" }}>
                        {children}

                        <div className="p-rel">
                            <SearchPatient
                                irClass="middle p-l-20 p-r-10 p-t-10 p-b-10 is-clickable c-pointer f-s-sm"
                                irStyle={{
                                    borderBottom: "solid 1px rgba(148, 155, 160, .2)",
                                }}
                                mode={mode}
                                visible={view === "patients" && visible}
                                disabled={disabled}
                                orgid={orgid}
                                searchText={searchText}
                                onAddEncounter={onAddEncounter}
                                toggleHandler={toggleHandler}
                            />

                            <SearchEncounter
                                irClass="middle p-l-20 p-r-10 p-t-10 p-b-10 is-clickable c-pointer f-s-sm"
                                irStyle={{
                                    borderBottom: "solid 1px rgba(148, 155, 160, .2)",
                                }}
                                mode={mode}
                                visible={view === "encounters" && visible}
                                disabled={disabled}
                                orgid={orgid}
                                searchText={searchText}
                                toggleHandler={toggleHandler}
                            />

                            <SearchPayer
                                irClass="middle p-l-20 p-r-10 p-t-10 p-b-10 is-clickable c-pointer f-s-sm"
                                irStyle={{
                                    borderBottom: "solid 1px rgba(148, 155, 160, .2)",
                                }}
                                mode={mode}
                                visible={view === "payers" && visible}
                                disabled={disabled}
                                orgid={orgid}
                                searchText={searchText}
                                toggleHandler={toggleHandler}
                            />
                        </div>
                    </Scrollbars>
                </Fragment>
            </div>
        );
    },
);

export default memo(SearchBarPanel);

// searchViewList = [
//     { label: "Patients", data: "patients" },
//     { label: "Encounters", data: "encounters" },
//     { label: "Payers", data: "payers" }
// ];
