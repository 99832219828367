import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { PAYER_TYPES } from "../../data/constants";
import { isNullOrUndefined, findListItemIndex } from "../../app-base/fn";
import SearchPhoneIr from "../ir/SearchPhoneIr";

function PayerSearchIr({ className, style, children, item = null, onClick }) {
    const cmp = useMemo(() => {
        let hasPhone = false;
        let payerTypeLbl = "";
        let actions = [];

        if (item) {
            hasPhone = !item.phones ? false : item.phones.length > 0;

            if (!isNullOrUndefined(item.payer_type)) {
                const pIdx = findListItemIndex(PAYER_TYPES, "data", item.payer_type);

                payerTypeLbl = pIdx === -1 ? "" : PAYER_TYPES[pIdx].label;
            }

            if (item.eligibility_status === "completed" || item.eligibility_status === "available")
                actions.push("Eligibility");

            if (item.claims_status === "completed" || item.claims_status === "available")
                actions.push("Claims");

            if (item.remittance_status === "completed" || item.remittance_status === "available")
                actions.push("Remittance");
        }

        return {
            hasPhone: hasPhone,
            payerTypeLbl: payerTypeLbl,
            actions: actions,
        };
    }, [item]);

    return (
        <div
            className={classNames("payer-search-ir", className)}
            // style={{ ...{ cursor: !onClick ? "auto" : "pointer" }, ...style }}
            onClick={() => onClick(item)}
            title="View"
            role="option"
        >
            <div className="fl">
                <div className="col-8">
                    <div className="c-white f-w-sb">{item.payer_name}</div>

                    {cmp.payerTypeLbl !== "" && (
                        <div className="c-grey-light">{cmp.payerTypeLbl}</div>
                    )}

                    {cmp.actions.length > 0 && (
                        <div className="fl-inline m-t-3 m-b-3">
                            {cmp.actions.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="f-s-n f-w-sb bg-grey-dark c-white p-t-1 p-b-1 p-l-8 p-r-8 m-r-5"
                                        style={{ borderRadius: "1.125rem" }}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="col-4 t-right">
                    <div className="f-w-b c-orange m-b-5">
                        {item.is_other_payer ? "Manual Payer" : item.master_claim_type}
                    </div>

                    {!cmp.hasPhone && (
                        <div className="fl-inline">
                            <i className="material-icons c-blue_sharp m-r-3">phone</i>
                            <span className="f-s-sm c-grey">No phone on record</span>
                        </div>
                    )}

                    {cmp.hasPhone &&
                        item.phones.map((phone, i) => {
                            return <SearchPhoneIr key={i} item={phone} idx={i} />;
                        })}
                </div>
            </div>

            {children}
        </div>
    );
}

export default memo(PayerSearchIr);
