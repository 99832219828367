import React, { memo, useCallback } from "react";
import classNames from "classnames";
import OrgChanger from "../ui/org/OrgChanger";
// import Avatar from "../app-base/elm/Avatar";
// import BubbleButton from '../app-base/elm/BubbleButton'

function Header({
    className,
    style,
    children,
    userId,
    userAvatar,
    orgid,
    orgList,
    socketConnected,
    onSettingClick,
    onProfileClick,
    onOrgVerifyClick,
    onOrgChange,
}) {
    const printSettingsHandler = useCallback((e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (onSettingClick) onSettingClick();
    }, []);

    return (
        <div
            id="app-hdr"
            className={classNames("header fl middle p-r-25", className)}
            style={style}
        >
            <div className="col-auto">
                {children}

                <div className="fl middle">
                    <a href="/">
                        <div
                            className="bg-lighter-70 p-l-20 p-r-20"
                            style={{
                                position: "relative",
                                height: "38px",
                            }}
                        >
                            <img
                                style={{ marginTop: "5px" }}
                                src="../images/pi_sm.png"
                                // srcSet="/images/logo-50.png 1x, /images/logo-50@2x.png 2x"
                                alt="PureInfo"
                            />
                        </div>
                    </a>

                    <OrgChanger
                        className="f-w-b f-s-n p-3 m-t-2"
                        // style={{ width: "350px" }}
                        selectedOrgid={orgid}
                        orgList={orgList}
                        onOrgVerifyClick={onOrgVerifyClick}
                        onOrgChange={onOrgChange}
                    />
                </div>
            </div>

            <div className="col-right m-t-2">
                <div className="fl p-rel">
                    {/* <a
                            className="btn icon-only is-dark m-t-2"
                            onClick={() =>
                                store.togglePanel({
                                    name: store.eventsPanelName
                                })
                            }
                        >
                            <i className="material-icons f-s-xlg">today</i>
                        </a> */}
                    {/* <a
                            className="btn icon-only is-dark m-t-2 m-l-15"
                            onClick={() =>
                                store.togglePanel({ name: store.msgPanelName })
                            }
                        >
                            <i className="material-icons f-s-xlg">comment</i>
                        </a> */}
                    {/* alertLabel={(store.unreadMsgCount === 0) ? null : store.unreadMsgCount} */}

                    {onSettingClick && (
                        <button
                            className="btn is-circle is-light sm m-l-10"
                            type="button"
                            onClick={printSettingsHandler}
                            title="Settings"
                        >
                            {/* <div className="circle lg bg-lighter-10"> */}
                            <i className="material-icons f-s-lg">settings</i>
                            {/* </div> */}
                        </button>
                    )}

                    {/* <div className="m-l-10" title="Edit Profile">
                        <Avatar
                            className="sm"
                            id={userId}
                            entity={userAvatar.entity}
                            imageUrl={userAvatar.imageurl}
                            text={userAvatar.text}
                            onClick={onProfileClick}
                        />
                    </div> */}

                    <div
                        style={{ position: "absolute", top: -3, right: 0, bottom: 10 }}
                        className={classNames("p-abs circle", {
                            "bg-red": !socketConnected,
                            "bg-aqua": socketConnected,
                        })}
                    />
                </div>
            </div>
        </div>
    );
}

Header.defualtProps = {
    userId: null,
    userAvatar: null,
    orgid: null,
    orgList: [],
    socketConnected: false,
    onSettingClick: undefined,
    onProfileClick: undefined,
    onOrgVerifyClick: undefined,
    onOrgChange: undefined,
};

export default memo(Header);
