// in phoneModel.js
import libphonenumber from "google-libphonenumber";

// in phoneModel.js
// check if string is valid phone #
export function isPhoneNumber(str, countryCode = "US") {
    // let americas = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    // let world = /^\+(?:[0-9]●?){6,14}[0-9]$/;
    let val = false;

    try {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        let phoneNumber = phoneUtil.parse(str, !countryCode ? "US" : countryCode);

        val = phoneUtil.isValidNumber(phoneNumber);
    } catch (ignore) {
        val = false;
    }

    return val;
}

export function isMail(link) {
    return /^((mailto:[^<>()/[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        link
    );
}

// check if string is valid url
export function isValidUrl(str) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
    // let re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    // return re.test(str);
}

// check if string is uuid
export function isObjectId(str) {
    // let checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$'); // mongodb _id format
    if (str) {
        const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return re.test(str);
    }

    return false;
}

// check if string is valid email address
export function isValidEmail(str) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(str);
}

// check if image file
export function isImageFile(file) {
    if (file.type) {
        return /^image\/\w+$/.test(file.type);
    } else {
        return /\.(jpg|jpeg|png|gif)$/.test(file);
    }
}

export function textInputValidator({ type, required, errorMsg, minLength, maxLength, value }) {
    const val = value ? value.trim() : "";
    let msg = "";

    if (required && (!val || val === "")) {
        if (type === "email") {
            msg = "Email address is required.";
        } else if (type === "password") {
            msg = "Password is required.";
        } else {
            msg = errorMsg ? errorMsg : "Value is required.";
        }
    }

    if (msg === "" && val !== "" && type === "email" && !isValidEmail(val))
        msg = "Please enter valid email.";
    if (msg === "" && val !== "" && type === "url" && !isValidUrl(val))
        msg = "Please enter valid URL.";
    if (msg === "" && val.length > maxLength)
        msg = "Value can not exceed " + maxLength + " characters.";
    if (msg === "" && val.length < minLength)
        msg = "Value must contain at least " + minLength + " characters.";

    return msg;
}

/******************************
// UI Utilities
******************************/
// set focus on specific input from list of inputs
// export function setFocus(inputs, name) {
//     inputs.map(function(input) {
//         if (input.name === name) {
//             input.focus();
//             return;
//         }
//     });
// }

// add input to inputs
// export function addInput(inputs, obj) {
//     const grp = obj.group;
//     const name = obj.name;

//     inputs.map((input, idx) => {
//         if (input.name === name && input.group === grp) {
//             inputs.splice(idx, 1);
//         }
//     });

//     if (obj) inputs.push(obj);
// }

// remove input from inputs
// export function removeInput(inputs, name) {
//     inputs.map(function(input, idx) {
//         if (input.name === name) {
//             inputs.splice(idx, 1);
//             return;
//         }
//     });
// }

// validate inputs
// export function isValid(inputs, group, focus = true) {
//     let valid = true;

//     if (group == undefined) group = "";

//     for (let i = 0; i < inputs.length; i++) {
//         const elm = inputs[i];

//         if (elm.group === group) {
//             const errorMsg = elm.validate(focus);
//             // console.log("isValid errorMsg " + elm.name + '  "' + errorMsg + '"');

//             if (errorMsg !== "") {
//                 valid = false;
//                 break;
//             } else {
//                 // no error
//                 valid = true;
//             }
//         }
//     }

//     return valid;
// }

// remove error from input > TODO > REMOVE AFTER USE IN HOC
// export function inputRemoveError(target) {
//     if (target.tt) {
//         if (target.tt !== null) {
//             target.tt.destroy();
//             target.tt = null;
//         }
//     }
// }
