import React, { Fragment, memo, useMemo } from "react";
import classNames from "classnames";
import { isMail, isPhoneNumber, isValidUrl } from "../validator";
import { formatPhone, addHttps, normalizeMailLink, formatToHtml } from "../fn";

/**
 *IfValueDiv
 *
 * @param {*} {
 *     className,
 *     style,
 *     children,
 *     vertical = false,
 *     lblClass = "f-s-sm",
 *     lblStyle,
 *     valueClass = "f-s-sm f-w-sb",
 *     valueStyle,
 *     convertToLink = true,
 *     isPhone = false,
 *     label = "",
 *     value = null,
 * }
 * @return {*}
 */
function IfValueDiv({
    className,
    style,
    children,
    vertical = false,
    lblClass = "f-s-sm",
    lblStyle,
    valueClass = "f-s-sm f-w-sb",
    valueStyle,
    convertToLink = true,
    isPhone = false,
    label = "",
    value = null,
}) {
    const cmp = useMemo(() => {
        let val = value;
        let link = "";
        let isUrlLink = true;

        if (convertToLink && value && typeof value === "string" && value !== "") {
            if (isValidUrl(val)) {
                link = addHttps(val);
                isUrlLink = true;
            } else if (isMail(val)) {
                link = normalizeMailLink(val);
                isUrlLink = false;
            } else if (isPhone && isPhoneNumber(val)) {
                link = `tel:${value}`;
                val = formatPhone(val);
                isUrlLink = false;
            }
        }

        return {
            val: val,
            link: link,
            isUrlLink: isUrlLink,
        };
    }, [convertToLink, label, value, isPhone]);

    if (value && value !== "") {
        return (
            <div className={classNames("i-div", className)} style={style}>
                <div className={classNames({ "fl-inline": !vertical })}>
                    <div
                        className={classNames("lbl", lblClass, {
                            "no-display": label === "",
                            "m-r-5": !vertical,
                        })}
                        style={lblStyle}
                    >
                        {label}
                    </div>

                    {!convertToLink ||
                        (cmp.link === "" && (
                            <div
                                className={classNames("val", valueClass)}
                                style={valueStyle}
                                dangerouslySetInnerHTML={{ __html: formatToHtml(cmp.val) }}
                            />
                        ))}

                    {convertToLink && cmp.link !== "" && (
                        <Fragment>
                            {cmp.isUrlLink && (
                                <a
                                    className={classNames("val", valueClass)}
                                    style={valueStyle}
                                    href={cmp.link}
                                    target="_blank"
                                    dangerouslySetInnerHTML={{ __html: cmp.val.toLowerCase() }}
                                />
                            )}

                            {!cmp.isUrlLink && (
                                <a
                                    className={classNames("val", valueClass)}
                                    style={valueStyle}
                                    href={cmp.link}
                                    dangerouslySetInnerHTML={{ __html: cmp.val }}
                                />
                            )}
                        </Fragment>
                    )}

                    {children}
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default memo(IfValueDiv);
