import humane from "humane-js"; // http://wavded.github.io/humane-js/
import swal from "sweetalert2";
import { useState, useRef, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";
// const Swal = require('sweetalert2')
// import "sweetalert2/dist/sweetalert2.css";
// import {serverErrorMsg} from '.fn'

/******************************
Display functions
******************************/
// small Growl like messages
export function showMessage(type, text) {
    const duration = type === "success" ? 2 : 5;
    // let duration = (errText === 'success') ? 1000 : 5000;
    // let msg = new Messenger({
    //   theme: 'flat',
    //   extraClasses: 'messenger-on-right messenger-on-top messenger-fixed'// messenger-fixed messenger-on-right messenger-on-bottom
    // }).post({
    //   message: errText,
    //   type: type, //info error success
    //   singleton: false,
    //   hideAfter: duration,
    //   showCloseButton: true
    // });

    const msg = humane.log(text, {
        // timeout: duration * 100000,
        timeout: duration * 1000,
        clickToClose: true,
        addnCls: "humane-" + type,
    });

    return msg;
}

// show alert box
/**
 *
 * @param {*} title
 * @param {*} type
 * @param {*} text
 * @param {*} onConfirm
 * @param {*} showCancelButton
 * @param {*} confirmButtonText
 * @param {*} confirmButtonColor
 * @param {*} onClose
 * @param {*} preConfirm
 */
export function showAlert(
    title,
    type,
    text,
    onConfirm,
    showCancelButton = false,
    confirmButtonText = "OK",
    confirmButtonColor = "#3085d6",
    onClose,
    preConfirm
) {
    // type : "warning", "error", "success" and "info", "input"
    let customClass = {
        confirmButton: "swal2-btn-confirm",
        cancelButton: "swal2-btn-cancel",
    };

    let options = {
        title: title,
        icon: type,
        html: text,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: customClass,
        showCancelButton: showCancelButton,
        cancelButtonText: "Cancel",
        confirmButtonColor: confirmButtonColor || "#C10000",
        confirmButtonText: confirmButtonText,
        preConfirm: preConfirm,
    };

    swal.fire(options).then((res) => {
        if (res.value) {
            if (onConfirm) onConfirm();
        } else if (res.dismiss === "cancel") {
            if (onClose) onClose();
        }
    });
}

// check if container lost focus (used in composit inputs - i.e. AddressInput.jsx)
// usage > onBlur = (e) => if (!this.focusInCurrentTarget(e)) {
export async function focusInContainer(evt) {
    if (evt) {
        const currentTarget = evt.currentTarget;

        if (currentTarget) {
            let n = false;

            await new Promise((resolve) => {
                setTimeout(() => {
                    // Check if the new activeElement is a child of the original container
                    if (!currentTarget.contains(document.activeElement)) {
                        n = true;
                    } else {
                        n = false;
                    }
                    resolve();
                }, 0);
            });

            return n;
        } else {
            return false;
        }
    } else {
        return false;
    }

    // if (relatedTarget === null) return false;

    // let node = relatedTarget.parentNode;

    // while (node !== null) {
    //     if (node === currentTarget) return true;
    //     node = node.parentNode;
    // }

    // return false;
}

// set focus on requested dom element
export function setInputFocus(reactDomElm) {
    try {
        if (isModernBrowser && reactDomElm) {
            reactDomElm.focus();
            reactDomElm.setSelectionRange(reactDomElm.value.length, reactDomElm.value.length);
        }
    } catch (ignore) {}
}

// set focus in first input with in requested dom
export function setInputFocusFirstInput(dom) {
    if (dom !== null && dom !== "") {
        try {
            let $input = $(dom)
                .find(
                    "input[type=text],input[type=number],input[type=email],input[type=password],textarea,select"
                )
                .filter(":visible:first")
                .focus();

            if ($input.length > 0 && isModernBrowser) {
                if ($input[0].hasOwnProperty("value")) {
                    let strLen = $input[0].value.length;
                    $input[0].setSelectionRange(strLen, strLen);
                }
            }
        } catch (ignore) {}
    }
}

export function scrollToY(elmId) {
    try {
        const elm = document.getElementById(elmId);
        elm.scrollIntoView(true);
        // elm.scrollTop -= 30;
    } catch (ignore) {}
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => void (ref.current = value), [value]);
    return ref.current;
}

export function useMeasure() {
    const ref = useRef();
    const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
    useEffect(() => {
        if (ref.current) ro.observe(ref.current);
        return () => ro.disconnect();
    }, []);
    return [{ ref }, bounds];
}
