import React, { useState, useMemo, useContext } from "react";
import classNames from "classnames";
import {
    Await,
    createBrowserRouter,
    createRoutesFromElements,
    defer,
    Form,
    Link,
    Outlet,
    Route,
    RouterProvider,
    useLocation,
    useNavigate,
    useAsyncError,
    useAsyncValue,
    useFetcher,
    useFetchers,
    useLoaderData,
    useNavigation,
    useParams,
    useRevalidator,
    useRouteError,
    ScrollRestoration,
} from "react-router-dom";
import {
    isNullOrUndefined,
    updateObject,
    getLocalStorage,
    setLocalStorage,
    deleteLocalStorageKey,
} from "../../app-base/fn";
import { AppContext } from "../../context/AppContext";
import { signinModel } from "../../baseModels";
import { apiPost } from "../../connection";
import { createVerificationCode } from "../../actions/userActions";
import { showAlert } from "../../app-base/displayUtils";
import Container from "../Container";
import SigninEmail from "./SigninEmail";
import SigninVerify from "./SigninVerify";
import SigninPwd from "./SigninPwd";
import SigninPwdChange from "./SigninPwdChange";
// import SigninInviteList from "./SigninInviteList";

function Signin({ className, style, initLoad = true }) {
    const newPwdMinLen = 6; // min chars for new password
    const appState = useContext(AppContext);

    const cmp = useMemo(() => {
        const localEmail = getLocalStorage(import.meta.env.VITE_PROFILE_KEY);

        return { localEmail: isNullOrUndefined(localEmail) ? "" : localEmail };
    }, [initLoad]);

    const [state, setState] = useState(signinModel(cmp.localEmail), []);

    const changeState = (newObj) => {
        setState((currentState) => {
            const newState = updateObject(currentState, newObj);
            return newState;
        });
    };

    const reset = () => {
        changeState(signinModel());
    };

    const changeStateValue = (key, val) => {
        let n = {};

        n[key] = val;
        changeState(n);
    };

    const onChange = (key, val) => {
        switch (key) {
            case "email":
                changeStateValue(key, val.trim().toLowerCase());
                break;
            case "pwd":
            case "newPwd1":
            case "newPwd2":
                changeStateValue(key, val);
                break;
            default:
                changeStateValue(key, val);
        }
    };

    // reset verification code
    const resetVerify = () => {
        changeStateValue("code", "");
    };

    // change view of signin page
    const setStep = (step) => {
        // email - pwd

        if (step === "email") {
            reset();
        } else {
            let title = "Sign in with your PureInfo account";

            switch (step) {
                case "pwd":
                    title = "Enter your password";
                    break;
                case "pwd-reset":
                    title = "Set your password";
                    break;
                case "verify":
                    title = "Enter verification code";
                    break;
                // case "invite-list":
                //     title = "You've been invited!";
                //     break;
            }

            changeState({
                email_disabled: step === "email" ? false : true,
                currentStep: step,
                title: title,
            });
        }
    };

    // go to password section
    const goToPass = () => {
        if (state.remember_me) {
            setLocalStorage(import.meta.env.VITE_PROFILE_KEY, state.email.trim());
        } else {
            deleteLocalStorageKey(import.meta.env.VITE_PROFILE_KEY);
        }

        // check if user needs to set new password
        if (state.getResetPwd) {
            setStep("pwd-reset");
        } else {
            setStep("pwd");
        }
    };

    const goToVerify = () => {
        setStep("verify");
    };

    // submit user login email
    const emailSubmit = async () => {
        if (!state.loading) {
            changeState({ loading: true, email_disabled: true });

            const res = await apiPost("signin-profile", { email: state.email.trim() });
            changeState({ loading: false });

            if (res) onSigninProfile(res.error, res.data);
        }
    };

    // Server response to user login email check
    const onSigninProfile = (err, ret) => {
        if (err) {
            changeState({ profile: null });

            showAlert("Warning", "warning", err, () => {
                changeState({ email_disabled: false });
            });
        } else {
            if (import.meta.env.VITE_DEBUG === "1") console.log("Debug onSigninProfile", ret);

            let changePwd = ret.isapppwdreset;
            let nextStep = "pwd";

            if (ret.verify_user) {
                // verfication code email was sent - show verify panel
                if (ret.appLoginCt > 0) changePwd = true;
                nextStep = "verify";
            } else {
                if (changePwd) {
                    nextStep = "pwd-reset";
                } else {
                    nextStep = "pwd";
                }
            }

            changeState({ profile: ret, getResetPwd: changePwd });

            if (nextStep === "verify") {
                goToVerify();
            } else if (nextStep === "pwd-reset") {
                pwdReset();
            } else {
                goToPass();
            }
        }
    };

    // submit user password
    const pwdSubmit = async () => {
        if (!state.loading) {
            changeState({ loading: true });

            const res = await apiPost("signin-web", { id: state.profile.id, pwd: state.pwd });
            changeState({ loading: false });

            if (res) onSignin(res.error, res.data);
        }
    };

    // Server response to user signin
    const onSignin = (err, data) => {
        if (err) {
            showAlert("Warning", "warning", err);
        } else {
            loadApp(data);
        }
    };

    // valid user > initiate user
    const loadApp = (data) => {
        const apiToken = data.session_data.token;
        const orgs = data.orgs;
        // const inviteds =
        //     orgs && orgs.length > 0
        //         ? orgs.filter((org) => {
        //               return org.app_status === "invited";
        //           })
        //         : null;

        setLocalStorage(import.meta.env.VITE_TOKEN_KEY, apiToken); // save token to local storage

        if (orgs.length > 0) {
            appState.actions({ type: "onLogin", value: data });
        } else {
            showAlert(
                "Information",
                "info",
                "You are not member of any organization at this time. Please login again at later time to check for available organizations",
                () => {
                    appState.actions({ type: "onLogout" });
                },
            );
        }
        // }
    };

    // reset password
    const pwdReset = async () => {
        changeState({ loading: true });

        const res = await apiPost("reset-pwd", {
            id: state.profile.id,
            user_email_id: state.profile.user_email_id,
        });

        changeState({ loading: false });
        if (res) onPwdReset(res.error, res.data);
    };

    const onPwdReset = (err, data) => {
        if (err) {
            showAlert("Warning", "warning", err);
        } else {
            showAlert(
                "Information",
                "info",
                "Code was sent to " + data.email,
                () => {
                    goToVerify();
                },
                true,
                "VERIFY",
            );
        }
    };

    // submit user password reset
    const pwdChange = async () => {
        if (state.newPwd1 === state.newPwd2) {
            changeState({ loading: true });

            const res = await apiPost("signin-invited", {
                id: state.profile.id,
                pwd: state.newPwd1,
                user_email_id: state.profile.user_email_id,
            });

            changeState({ loading: false });
            if (res) onPwdChange(res.error, res.data);
        } else {
            showAlert("Warning", "warning", "Confirmed password does not match your new password.");
        }
    };

    // Server response to password change
    const onPwdChange = (err, data) => {
        if (err) {
            showAlert("Warning", "warning", err);
        } else {
            loadApp(data);
        }
    };

    const sendVerificationCode = async () => {
        changeState({ loading: true });

        const res = await createVerificationCode(state.profile.user_email_id);

        changeState({ loading: false });

        if (res.error) {
            showAlert("Error", "error", "Verification could not be sent. Please try again.");
        } else {
            // res.data returns { status: "success", email: data.email }
            showAlert("Information", "info", `Verification was sent to ${res.data.email}.`);
        }
    };

    // verify code
    const verifyCode = async () => {
        changeState({ loading: true });

        const res = await apiPost("signin-verify", { id: state.profile.id, code: state.code });

        changeState({ loading: false });
        if (res) onVerifyCode(res.error, res.data);
    };

    const onVerifyCode = (err, res) => {
        // resetVerify();

        if (res && res.status === "success") {
            // valid verification
            // {
            //     status: "success",
            //     verified_email: data.verified_email,
            //     verifed_dte: data.verifed_dte,
            // }
            // goToPass();
            setStep("pwd-reset");
        } else {
            resetVerify();
            showAlert("Warning", "warning", "Please enter valid verification code.");
        }
    };

    // const invitationClick = (accepted, contact) => {};

    // const themeChangeHandler = () => {
    //     appState.actions({ type: "theme_change" });
    // };

    return (
        <div className={classNames("sign-in center-all", className)} style={style}>
            <div className="m-b-10 t-center">
                <div className="signin-logo">
                    <img
                        src="../../images/PureInfologo.png"
                        // srcSet="/images/logo-50.png 1x, /images/logo-50@2x.png 2x"
                        alt="PureInfo"
                    />
                </div>
            </div>

            {/* <button onClick={themeChangeHandler}>CHANGE</button> */}

            <Container titleClass="t-center" style={{ minWidth: "350px" }} title={state.title}>
                <div className="signin-content t-center">
                    <div
                        className={classNames("signin-step step-email", {
                            "no-display": state.currentStep !== "email",
                        })}
                    >
                        <div className="signin-main-content m-center">
                            <SigninEmail
                                email={state.email}
                                rememberMe={state.remember_me}
                                disabled={state.email_disabled}
                                loading={state.loading}
                                onChange={onChange}
                                onRememberChange={onChange}
                                onSubmit={emailSubmit}
                            />
                        </div>
                    </div>

                    {state.currentStep === "pwd" && (
                        <div className="signin-step step-pass">
                            <div className="signin-main-content m-center">
                                <SigninPwd
                                    profile={state.profile}
                                    email={state.email}
                                    pwd={state.pwd}
                                    loading={state.loading}
                                    onChange={onChange}
                                    onSubmit={pwdSubmit}
                                    pwdReset={pwdReset}
                                    setStep={setStep}
                                />
                            </div>
                        </div>
                    )}

                    {state.currentStep === "pwd-reset" && (
                        <div className="signin-step step-pass-reset">
                            <div className="signin-main-content m-center">
                                <SigninPwdChange
                                    profile={state.profile}
                                    email={state.email}
                                    newPwdMinLen={newPwdMinLen}
                                    newPwd1={state.newPwd1}
                                    newPwd2={state.newPwd2}
                                    loading={state.loading}
                                    onChange={onChange}
                                    onSubmit={pwdChange}
                                    setStep={setStep}
                                />
                            </div>
                        </div>
                    )}

                    {state.currentStep === "verify" && (
                        <div className="signin-step step-verify">
                            <div className="signin-main-content m-center">
                                <SigninVerify
                                    code={state.code}
                                    email={state.email}
                                    loading={state.loading}
                                    onChange={onChange}
                                    onSubmit={verifyCode}
                                    onResend={sendVerificationCode}
                                    setStep={setStep}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
}

export default Signin;

// {data.currentStep === "invite-list" && (
//     <div className="signin-step step-invite-list">
//         <div className="signin-main-content">
//             <SigninInviteList
//                 invitedOrgs={data.invitedOrgs}
//                 onItemClick={invitationClick}
//             >
//                 {orgs.activeOrgs.length > 0 && (
//                     <button
//                         type="button"
//                         className="btn is-primary w-full lg m-t-25"
//                         onClick={launchApp}
//                     >
//                         <span>SIGN IN</span>
//                     </button>
//                 )}

//                 {orgs.activeOrgs.length === 0 && (
//                     <div className="m-t-15 t-center f-s-sm">
//                         <a
//                             style={{ cursor: "pointer" }}
//                             onClick={appState.actions({ type: "onLogout" });}
//                         >
//                             <span>I'll decide later, Sign me out.</span>
//                         </a>
//                     </div>
//                 )}
//             </SigninInviteList>
//         </div>
//     </div>
// )}
