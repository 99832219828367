export const ADDRESS_LABELS = [
    { label: "Main", data: "main" },
    { label: "Mobile", data: "mobile" },
    { label: "Home", data: "home" },
    { label: "Work", data: "work" },
    { label: "Billing", data: "billing" },
    { label: "Contract", data: "contract" },
    { label: "Shipping", data: "shipping" },
];

export const ERR_CODES = {
    400: "Error. Invalid request.",
    401: "You are not authorized for this request.",
    402: "Sorry. Payment is required.",
    403: "Request is forbidden.",
    404: "Requested data could not be found.",
    500: "Sorry. Server is having issues. Please try again later.",
    501: "Requested function has not been implemented.",
};

export const GENDER_LIST = [
    { label: "Female", data: "female" },
    { label: "Male", data: "male" },
    { label: "Other", data: "other" },
    { label: "Decline to answer", data: "none" },
];

export const KEYS = {
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37,
    ENTER: 13,
    ESC: 27,
    PLUS: 43,
    A: 65,
    Z: 90,
    S: 83,
    SPACE: 32,
    TAB: 9,
};

export const PHONE_LABELS = [
    { label: "Mobile", data: "mobile" },
    { label: "Main", data: "main" },
    { label: "Home", data: "home" },
    { label: "Work", data: "work" },
    { label: "Fax", data: "fax" },
    { label: "Work Fax", data: "workfax" },
];

export const YES_OR_NO = [
    { label: "Yes", data: true },
    { label: "No", data: false },
];

export const U_OF_M = [
    { label: "Each", abbr: "ea", data: "each", format: "0,0.000", type: "quantities" },
    { label: "Dozen", abbr: "dz", data: "dozen", format: "0,0", type: "quantities" },
    { label: "Grams", abbr: "g", data: "grams", format: "0,0.000", type: "mass" },
    { label: "Pound", abbr: "lb", data: "pound", format: "0,0.000", type: "mass" },
    { label: "Gallon", abbr: "gal", data: "gallon", format: "0,0.000", type: "volume" },
    { label: "Liter", abbr: "l", data: "liter", format: "0,0.000", type: "volume" },
    { label: "Ounce", abbr: "oz", data: "ounce", format: "0,0.000", type: "volume" },
    { label: "Pint", abbr: "pt", data: "pint", format: "0,0.000", type: "volume" },
    { label: "Quart", abbr: "qt", data: "quart", format: "0,0.000", type: "volume" },
    {
        label: "Square Inches",
        abbr: "sqin",
        data: "square-inches",
        format: "0,0.000",
        type: "area",
    },
    { label: "Square Feet", abbr: "sqft", data: "square-feet", format: "0,0.000", type: "area" },
    { label: "Square Meter", abbr: "sqm", data: "square-meter", format: "0,0.000", type: "area" },
    { label: "Minutes", abbr: "min", data: "minutes", format: "0,0", type: "duration" },
    { label: "Hours", abbr: "hrs", data: "hours", format: "0,0.0", type: "duration" },
    { label: "Days", abbr: "days", data: "days", format: "0,0.0", type: "duration" },
];
