import { isUUID } from "../app-base/fn";
import { isObjectId } from "../app-base/validator";
import { apiGet, apiPost, apiPut, apiDelete } from "../connection";

// get list of contacts for organization
export async function getOrgContacts(orgid, status = "active", controller = null) {
    if (orgid !== "") {
        return await apiGet("contacts", { orgid: orgid, status: status }, controller);
    } else {
        throw "Organization is required.";
    }
}

// // get list of contacts for organization
export async function getOrgContactsSearch(
    orgid,
    relationship = "other",
    value = "",
    rows = 25,
    offset = 0, // from after row
    controller = null
) {
    if (isObjectId(orgid)) {
        const q = {
            orgid: orgid,
            value: value,
            relationship: relationship,
            rows: rows,
            offset: offset,
        };

        return await apiGet(`contacts-search`, q, controller);
    } else {
        throw "Organization is required.";
    }
}

// get contact detail
export async function getContact(id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`contacts/${id}`, null, controller);
    } else {
        throw "Contact is required.";
    }
}

// create contact
export async function createContact(savedata, controller = null) {
    return await apiPost(`contacts`, savedata, controller);
}

// update contact
export async function updateContact(id, savedata, controller = null) {
    if (isObjectId(id)) {
        return await apiPut(`contacts/${id}`, savedata, controller);
    } else {
        throw "Contact is required.";
    }
}

// delete contact
export async function deleteContact(id, controller = null) {
    if (isObjectId(id)) {
        return await apiDelete(`contacts/${id}`, controller);
    } else {
        throw "Contact is required.";
    }
}

// import list of contacts
export async function importContacts(orgid, importId, contacts, controller = null) {
    if (orgid !== "") {
        return await apiPost(
            `contacts-import`,
            { orgid: orgid, import_id: importId, contacts: contacts },
            controller
        );
    } else {
        throw "Organization is required.";
    }
}

// invite contact to organization in app
export async function inviteContact(id, controller = null) {
    if (isObjectId(id)) {
        return await apiPost(`contacts/${id}/access`, null, controller);
    } else {
        throw "Contact is required.";
    }
}

// resend invitation to contact
export async function resendInvitation(id, controller = null) {
    if (isObjectId(id)) {
        return await apiPost(`contacts/${id}/access-resend`, null, controller);
    } else {
        throw "Contact is required.";
    }
}

// remove contact app access for organization
export async function removeContactAccess(id, controller = null) {
    if (isObjectId(id)) {
        return await apiDelete(`contacts/${id}/access`, controller);
    } else {
        throw "Contact is required.";
    }
}

// get authorizations for contact insurance
export async function getContactAuthorizations(orgid, contactId, controller = null) {
    if (isObjectId(contactId) && orgid !== "") {
        return await apiGet(`contacts/${contactId}/authorizations`, { orgid: orgid }, controller);
    } else {
        throw "Organization and contact are required.";
    }
}

// get contact organizations
export async function getContactOrganizations(contactId, controller = null) {
    if (isObjectId(contactId)) {
        return await apiGet(`contacts/${contactId}/organizations`, null, controller);
    } else {
        throw "Contact is required.";
    }
}

// get contact data to create encounter
export async function getContactNewEncounterData(contactId, controller = null) {
    if (isObjectId(contactId)) {
        return await apiGet(`contacts/${contactId}/encounter-data`, null, controller);
    } else {
        throw "Contact is required.";
    }
}

// list of encounters for patient
export async function getPatientEncounters(
    orgid,
    contactId,
    futureOnly = false,
    rows = 10,
    offset = 0,
    controller = null
) {
    if (isObjectId(orgid) && isObjectId(contactId)) {
        return await apiGet(
            `contacts/${contactId}/encounters`,
            { orgid: orgid, futureOnly: futureOnly, rows: rows, offset: offset },
            controller
        );
    } else {
        throw "Invalid data.";
    }
}

// list of claims for patient
export async function getPatientClaims(orgid, contactId, status = "all", controller = null) {
    if (isObjectId(orgid) && isObjectId(contactId)) {
        return await apiGet(
            `contacts/${contactId}/claims`,
            { orgid: orgid, lineStatus: status },
            controller
        );
    } else {
        throw "Invalid data.";
    }
}

// get patient active diagnosis detail
export async function getPatientDiagnosis(contactId, id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`contacts/${contactId}/diagnoses/${id}`, null, controller);
    } else {
        throw "Invalid data";
    }
}

// create patient active diagnosis
export async function createPatientDiagnosis(contactId, savedata, controller = null) {
    if (isObjectId(contactId)) {
        return await apiPost(`contacts/${contactId}/diagnoses`, savedata, controller);
    } else {
        throw "Invalid data";
    }
}

// update patient diagnosis
export async function updatePatientDiagnosis(contactId, id, savedata, controller = null) {
    if (isObjectId(id)) {
        return await apiPut(`contacts/${contactId}/diagnoses/${id}`, savedata, controller);
    } else {
        throw "Patient is required.";
    }
}

// delete patient diagnosis
export async function deletePatientDiagnosis(contactId, id, controller = null) {
    if (isObjectId(contactId)) {
        return await apiDelete(`contacts/${contactId}/diagnoses/${id}`, controller);
    } else {
        throw "Patient is required.";
    }
}

// list of test results for patient
export async function getPatientTestResults(orgid, contactId, controller = null) {
    if (isObjectId(orgid) && isObjectId(contactId)) {
        return await apiGet(`contacts/${contactId}/test-results`, null, controller);
    } else {
        throw "Invalid data.";
    }
}

// get a patient test result
export async function getPatientTestResult(contactId, id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`contacts/${contactId}/test-results/${id}`, null, controller);
    } else {
        throw "Invalid data";
    }
}

// create a patient test result
export async function createPatientTestResult(contactId, savedata, controller = null) {
    if (isObjectId(contactId)) {
        const sData = { ...savedata, contact_id: contactId };

        return await apiPost(`contacts/${contactId}/test-results`, sData, controller);
    } else {
        throw "Invalid data";
    }
}

// update patient test result
export async function updatePatientTestResult(contactId, id, savedata, controller = null) {
    if (isObjectId(id)) {
        return await apiPut(`contacts/${contactId}/test-results/${id}`, savedata, controller);
    } else {
        throw "Patient is required.";
    }
}

// delete patient test result
export async function deletePatientTestResult(contactId, id, controller = null) {
    if (isObjectId(contactId)) {
        return await apiDelete(`contacts/${contactId}/test-results/${id}`, controller);
    } else {
        throw "Patient is required.";
    }
}

// get list of transactions for patient
export async function getPatientTransactions(
    orgid,
    contactId,
    from = "all", // 'all' || 'patient' || 'payer'
    entityId = null, // null || contact.id || payer.id
    rows = 1000,
    startRow = 0,
    controller = null
) {
    if (isObjectId(orgid) && isObjectId(contactId)) {
        if (from !== "all") {
            if (!isUUID(entityId)) {
                throw `Invalid ${from}.`;
            }
        }
        return await apiGet(
            `contacts/${contactId}/transactions`,
            {
                orgid: orgid,
                from: from,
                entityId: entityId,
                rows: rows,
                offset: startRow,
            },
            controller
        );
    } else {
        throw "Invalid data.";
    }
}

export async function getPatientTestPrintData(testId, controller = null) {
    if (isObjectId(testId)) {
        return await apiGet(`tests/${testId}/print`, null, controller);
    } else {
        throw "Invalid data";
    }
}

export async function getEmployeeOffHoursDetail(empId, lineId, controller = null) {
    return await apiGet(`contacts/${empId}/off-days/${lineId}`, null, controller);
}

/**
 *  Get list of off days for employee. Ordered  ascending
 * @param {guid} orgid
 * @param {guid} empId - contact_id
 * @param {boolean} futureOnly  @default true
 * @returns
 */
export async function getEmployeeOffHours(orgid, empId, futureOnly = true, controller = null) {
    return await apiGet(
        `contacts/${empId}/off-days`,
        { orgid: orgid, futureOnly: futureOnly },
        controller
    );
}

/**
 * Create off day for employee
 * @param {guid} empId - contact_id
 * @param {contactModels.employee_off_hours} data
 * @returns
 */
export async function createEmployeeOffHours(empId, data) {
    return await apiPost(`contacts/${empId}/off-days`, data);
}

/**
 *  update off day for employee
 * @param {guid} empId  - contact_id
 * @param {guid} id  - emp_off_hours.id
 * @param {contactModels.employee_off_hours} data  - any keys from model
 * @returns
 */
export async function updateEmployeeOffHours(empId, id, data) {
    return await apiPut(`contacts/${empId}/off-days/${id}`, data);
}

/**
 *  delete off day for employee
 * @param {guid} empId  - contact_id
 * @param {guid} id  - emp_off_hours.id
 * @returns  {error: null, data: {orgid: {guid}, id: {guid} }
 */
export async function deleteEmployeeOffHours(empId, id) {
    return await apiDelete(`contacts/${empId}/off-days/${id}`, null);
}
