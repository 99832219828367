export const LANGUAGE_LIST = [
    {
        iso639_1: "bh",
        iso639_3: "",
        name: "Bihari languages"
    },
    {
        iso639_1: "aa",
        iso639_3: "aar",
        name: "Afar"
    },
    {
        iso639_1: "ab",
        iso639_3: "abk",
        name: "Abkhazian"
    },
    {
        iso639_1: "af",
        iso639_3: "afr",
        name: "Afrikaans"
    },
    {
        iso639_1: "ak",
        iso639_3: "aka",
        name: "Akan"
    },
    {
        iso639_1: "am",
        iso639_3: "amh",
        name: "Amharic"
    },
    {
        iso639_1: "ar",
        iso639_3: "ara",
        name: "Arabic"
    },
    {
        iso639_1: "an",
        iso639_3: "arg",
        name: "Aragonese"
    },
    {
        iso639_1: "as",
        iso639_3: "asm",
        name: "Assamese"
    },
    {
        iso639_1: "av",
        iso639_3: "ava",
        name: "Avaric"
    },
    {
        iso639_1: "ae",
        iso639_3: "ave",
        name: "Avestan"
    },
    {
        iso639_1: "ay",
        iso639_3: "aym",
        name: "Aymara"
    },
    {
        iso639_1: "az",
        iso639_3: "aze",
        name: "Azerbaijani"
    },
    {
        iso639_1: "ba",
        iso639_3: "bak",
        name: "Bashkir"
    },
    {
        iso639_1: "bm",
        iso639_3: "bam",
        name: "Bambara"
    },
    {
        iso639_1: "be",
        iso639_3: "bel",
        name: "Belarusian"
    },
    {
        iso639_1: "bn",
        iso639_3: "ben",
        name: "Bengali"
    },
    {
        iso639_1: "bi",
        iso639_3: "bis",
        name: "Bislama"
    },
    {
        iso639_1: "bo",
        iso639_3: "bod",
        name: "Tibetan"
    },
    {
        iso639_1: "bs",
        iso639_3: "bos",
        name: "Bosnian"
    },
    {
        iso639_1: "br",
        iso639_3: "bre",
        name: "Breton"
    },
    {
        iso639_1: "bg",
        iso639_3: "bul",
        name: "Bulgarian"
    },
    {
        iso639_1: "ca",
        iso639_3: "cat",
        name: "Catalan"
    },
    {
        iso639_1: "cs",
        iso639_3: "ces",
        name: "Czech"
    },
    {
        iso639_1: "ch",
        iso639_3: "cha",
        name: "Chamorro"
    },
    {
        iso639_1: "ce",
        iso639_3: "che",
        name: "Chechen"
    },
    {
        iso639_1: "cu",
        iso639_3: "chu",
        name: "Church Slavic"
    },
    {
        iso639_1: "cv",
        iso639_3: "chv",
        name: "Chuvash"
    },
    {
        iso639_1: "kw",
        iso639_3: "cor",
        name: "Cornish"
    },
    {
        iso639_1: "co",
        iso639_3: "cos",
        name: "Corsican"
    },
    {
        iso639_1: "cr",
        iso639_3: "cre",
        name: "Cree"
    },
    {
        iso639_1: "cy",
        iso639_3: "cym",
        name: "Welsh"
    },
    {
        iso639_1: "da",
        iso639_3: "dan",
        name: "Danish"
    },
    {
        iso639_1: "de",
        iso639_3: "deu",
        name: "German"
    },
    {
        iso639_1: "dv",
        iso639_3: "div",
        name: "Dhivehi"
    },
    {
        iso639_1: "dz",
        iso639_3: "dzo",
        name: "Dzongkha"
    },
    {
        iso639_1: "el",
        iso639_3: "ell",
        name: "Greek"
    },
    {
        iso639_1: "en",
        iso639_3: "eng",
        name: "English"
    },
    {
        iso639_1: "eo",
        iso639_3: "epo",
        name: "Esperanto"
    },
    {
        iso639_1: "et",
        iso639_3: "est",
        name: "Estonian"
    },
    {
        iso639_1: "eu",
        iso639_3: "eus",
        name: "Basque"
    },
    {
        iso639_1: "ee",
        iso639_3: "ewe",
        name: "Ewe"
    },
    {
        iso639_1: "fo",
        iso639_3: "fao",
        name: "Faroese"
    },
    {
        iso639_1: "fa",
        iso639_3: "fas",
        name: "Persian"
    },
    {
        iso639_1: "fj",
        iso639_3: "fij",
        name: "Fijian"
    },
    {
        iso639_1: "fi",
        iso639_3: "fin",
        name: "Finnish"
    },
    {
        iso639_1: "fr",
        iso639_3: "fra",
        name: "French"
    },
    {
        iso639_1: "fy",
        iso639_3: "fry",
        name: "Western Frisian"
    },
    {
        iso639_1: "ff",
        iso639_3: "ful",
        name: "Fulah"
    },
    {
        iso639_1: "gd",
        iso639_3: "gla",
        name: "Scottish Gaelic"
    },
    {
        iso639_1: "ga",
        iso639_3: "gle",
        name: "Irish"
    },
    {
        iso639_1: "gl",
        iso639_3: "glg",
        name: "Galician"
    },
    {
        iso639_1: "gv",
        iso639_3: "glv",
        name: "Manx"
    },
    {
        iso639_1: "gn",
        iso639_3: "grn",
        name: "Guarani"
    },
    {
        iso639_1: "gu",
        iso639_3: "guj",
        name: "Gujarati"
    },
    {
        iso639_1: "ht",
        iso639_3: "hat",
        name: "Haitian"
    },
    {
        iso639_1: "ha",
        iso639_3: "hau",
        name: "Hausa"
    },
    {
        iso639_1: "he",
        iso639_3: "heb",
        name: "Hebrew"
    },
    {
        iso639_1: "hz",
        iso639_3: "her",
        name: "Herero"
    },
    {
        iso639_1: "hi",
        iso639_3: "hin",
        name: "Hindi"
    },
    {
        iso639_1: "ho",
        iso639_3: "hmo",
        name: "Hiri Motu"
    },
    {
        iso639_1: "hr",
        iso639_3: "hrv",
        name: "Croatian"
    },
    {
        iso639_1: "hu",
        iso639_3: "hun",
        name: "Hungarian"
    },
    {
        iso639_1: "hy",
        iso639_3: "hye",
        name: "Armenian"
    },
    {
        iso639_1: "ig",
        iso639_3: "ibo",
        name: "Igbo"
    },
    {
        iso639_1: "io",
        iso639_3: "ido",
        name: "Ido"
    },
    {
        iso639_1: "ii",
        iso639_3: "iii",
        name: "Sichuan Yi"
    },
    {
        iso639_1: "iu",
        iso639_3: "iku",
        name: "Inuktitut"
    },
    {
        iso639_1: "ie",
        iso639_3: "ile",
        name: "Interlingue"
    },
    {
        iso639_1: "ia",
        iso639_3: "ina",
        name: "Interlingua"
    },
    {
        iso639_1: "id",
        iso639_3: "ind",
        name: "Indonesian"
    },
    {
        iso639_1: "ik",
        iso639_3: "ipk",
        name: "Inupiaq"
    },
    {
        iso639_1: "is",
        iso639_3: "isl",
        name: "Icelandic"
    },
    {
        iso639_1: "it",
        iso639_3: "ita",
        name: "Italian"
    },
    {
        iso639_1: "jv",
        iso639_3: "jav",
        name: "Javanese"
    },
    {
        iso639_1: "ja",
        iso639_3: "jpn",
        name: "Japanese"
    },
    {
        iso639_1: "kl",
        iso639_3: "kal",
        name: "Kalaallisut"
    },
    {
        iso639_1: "kn",
        iso639_3: "kan",
        name: "Kannada"
    },
    {
        iso639_1: "ks",
        iso639_3: "kas",
        name: "Kashmiri"
    },
    {
        iso639_1: "ka",
        iso639_3: "kat",
        name: "Georgian"
    },
    {
        iso639_1: "kr",
        iso639_3: "kau",
        name: "Kanuri"
    },
    {
        iso639_1: "kk",
        iso639_3: "kaz",
        name: "Kazakh"
    },
    {
        iso639_1: "km",
        iso639_3: "khm",
        name: "Central Khmer"
    },
    {
        iso639_1: "ki",
        iso639_3: "kik",
        name: "Kikuyu"
    },
    {
        iso639_1: "rw",
        iso639_3: "kin",
        name: "Kinyarwanda"
    },
    {
        iso639_1: "ky",
        iso639_3: "kir",
        name: "Kirghiz"
    },
    {
        iso639_1: "kv",
        iso639_3: "kom",
        name: "Komi"
    },
    {
        iso639_1: "kg",
        iso639_3: "kon",
        name: "Kongo"
    },
    {
        iso639_1: "ko",
        iso639_3: "kor",
        name: "Korean"
    },
    {
        iso639_1: "kj",
        iso639_3: "kua",
        name: "Kuanyama"
    },
    {
        iso639_1: "ku",
        iso639_3: "kur",
        name: "Kurdish"
    },
    {
        iso639_1: "lo",
        iso639_3: "lao",
        name: "Lao"
    },
    {
        iso639_1: "la",
        iso639_3: "lat",
        name: "Latin"
    },
    {
        iso639_1: "lv",
        iso639_3: "lav",
        name: "Latvian"
    },
    {
        iso639_1: "li",
        iso639_3: "lim",
        name: "Limburgan"
    },
    {
        iso639_1: "ln",
        iso639_3: "lin",
        name: "Lingala"
    },
    {
        iso639_1: "lt",
        iso639_3: "lit",
        name: "Lithuanian"
    },
    {
        iso639_1: "lb",
        iso639_3: "ltz",
        name: "Luxembourgish"
    },
    {
        iso639_1: "lu",
        iso639_3: "lub",
        name: "Luba-Katanga"
    },
    {
        iso639_1: "lg",
        iso639_3: "lug",
        name: "Ganda"
    },
    {
        iso639_1: "mh",
        iso639_3: "mah",
        name: "Marshallese"
    },
    {
        iso639_1: "ml",
        iso639_3: "mal",
        name: "Malayalam"
    },
    {
        iso639_1: "mr",
        iso639_3: "mar",
        name: "Marathi"
    },
    {
        iso639_1: "mk",
        iso639_3: "mkd",
        name: "Macedonian"
    },
    {
        iso639_1: "mg",
        iso639_3: "mlg",
        name: "Malagasy"
    },
    {
        iso639_1: "mt",
        iso639_3: "mlt",
        name: "Maltese"
    },
    {
        iso639_1: "mn",
        iso639_3: "mon",
        name: "Mongolian"
    },
    {
        iso639_1: "mi",
        iso639_3: "mri",
        name: "Maori"
    },
    {
        iso639_1: "ms",
        iso639_3: "msa",
        name: "Malay"
    },
    {
        iso639_1: "my",
        iso639_3: "mya",
        name: "Burmese"
    },
    {
        iso639_1: "na",
        iso639_3: "nau",
        name: "Nauru"
    },
    {
        iso639_1: "nv",
        iso639_3: "nav",
        name: "Navajo"
    },
    {
        iso639_1: "nr",
        iso639_3: "nbl",
        name: "South Ndebele"
    },
    {
        iso639_1: "nd",
        iso639_3: "nde",
        name: "North Ndebele"
    },
    {
        iso639_1: "ng",
        iso639_3: "ndo",
        name: "Ndonga"
    },
    {
        iso639_1: "ne",
        iso639_3: "nep",
        name: "Nepali"
    },
    {
        iso639_1: "nl",
        iso639_3: "nld",
        name: "Dutch"
    },
    {
        iso639_1: "nn",
        iso639_3: "nno",
        name: "Norwegian Nynorsk"
    },
    {
        iso639_1: "nb",
        iso639_3: "nob",
        name: "Norwegian Bokmufffdl"
    },
    {
        iso639_1: "no",
        iso639_3: "nor",
        name: "Norwegian"
    },
    {
        iso639_1: "ny",
        iso639_3: "nya",
        name: "Nyanja"
    },
    {
        iso639_1: "oc",
        iso639_3: "oci",
        name: "Occitan"
    },
    {
        iso639_1: "oj",
        iso639_3: "oji",
        name: "Ojibwa"
    },
    {
        iso639_1: "or",
        iso639_3: "ori",
        name: "Oriya"
    },
    {
        iso639_1: "om",
        iso639_3: "orm",
        name: "Oromo"
    },
    {
        iso639_1: "os",
        iso639_3: "oss",
        name: "Ossetian"
    },
    {
        iso639_1: "pa",
        iso639_3: "pan",
        name: "Panjabi"
    },
    {
        iso639_1: "pi",
        iso639_3: "pli",
        name: "Pali"
    },
    {
        iso639_1: "pl",
        iso639_3: "pol",
        name: "Polish"
    },
    {
        iso639_1: "pt",
        iso639_3: "por",
        name: "Portuguese"
    },
    {
        iso639_1: "ps",
        iso639_3: "pus",
        name: "Pushto"
    },
    {
        iso639_1: "qu",
        iso639_3: "que",
        name: "Quechua"
    },
    {
        iso639_1: "rm",
        iso639_3: "roh",
        name: "Romansh"
    },
    {
        iso639_1: "ro",
        iso639_3: "ron",
        name: "Romanian"
    },
    {
        iso639_1: "rn",
        iso639_3: "run",
        name: "Rundi"
    },
    {
        iso639_1: "ru",
        iso639_3: "rus",
        name: "Russian"
    },
    {
        iso639_1: "sg",
        iso639_3: "sag",
        name: "Sango"
    },
    {
        iso639_1: "sa",
        iso639_3: "san",
        name: "Sanskrit"
    },
    {
        iso639_1: "si",
        iso639_3: "sin",
        name: "Sinhala"
    },
    {
        iso639_1: "sk",
        iso639_3: "slk",
        name: "Slovak"
    },
    {
        iso639_1: "sl",
        iso639_3: "slv",
        name: "Slovenian"
    },
    {
        iso639_1: "se",
        iso639_3: "sme",
        name: "Northern Sami"
    },
    {
        iso639_1: "sm",
        iso639_3: "smo",
        name: "Samoan"
    },
    {
        iso639_1: "sn",
        iso639_3: "sna",
        name: "Shona"
    },
    {
        iso639_1: "sd",
        iso639_3: "snd",
        name: "Sindhi"
    },
    {
        iso639_1: "so",
        iso639_3: "som",
        name: "Somali"
    },
    {
        iso639_1: "st",
        iso639_3: "sot",
        name: "Southern Sotho"
    },
    {
        iso639_1: "es",
        iso639_3: "spa",
        name: "Spanish"
    },
    {
        iso639_1: "sq",
        iso639_3: "sqi",
        name: "Albanian"
    },
    {
        iso639_1: "sc",
        iso639_3: "srd",
        name: "Sardinian"
    },
    {
        iso639_1: "sr",
        iso639_3: "srp",
        name: "Serbian"
    },
    {
        iso639_1: "ss",
        iso639_3: "ssw",
        name: "Swati"
    },
    {
        iso639_1: "su",
        iso639_3: "sun",
        name: "Sundanese"
    },
    {
        iso639_1: "sw",
        iso639_3: "swa",
        name: "Swahili"
    },
    {
        iso639_1: "sv",
        iso639_3: "swe",
        name: "Swedish"
    },
    {
        iso639_1: "ty",
        iso639_3: "tah",
        name: "Tahitian"
    },
    {
        iso639_1: "ta",
        iso639_3: "tam",
        name: "Tamil"
    },
    {
        iso639_1: "tt",
        iso639_3: "tat",
        name: "Tatar"
    },
    {
        iso639_1: "te",
        iso639_3: "tel",
        name: "Telugu"
    },
    {
        iso639_1: "tg",
        iso639_3: "tgk",
        name: "Tajik"
    },
    {
        iso639_1: "tl",
        iso639_3: "tgl",
        name: "Tagalog"
    },
    {
        iso639_1: "th",
        iso639_3: "tha",
        name: "Thai"
    },
    {
        iso639_1: "ti",
        iso639_3: "tir",
        name: "Tigrinya"
    },
    {
        iso639_1: "to",
        iso639_3: "ton",
        name: "Tonga"
    },
    {
        iso639_1: "tn",
        iso639_3: "tsn",
        name: "Tswana"
    },
    {
        iso639_1: "ts",
        iso639_3: "tso",
        name: "Tsonga"
    },
    {
        iso639_1: "tk",
        iso639_3: "tuk",
        name: "Turkmen"
    },
    {
        iso639_1: "tr",
        iso639_3: "tur",
        name: "Turkish"
    },
    {
        iso639_1: "tw",
        iso639_3: "twi",
        name: "Twi"
    },
    {
        iso639_1: "ug",
        iso639_3: "uig",
        name: "Uighur"
    },
    {
        iso639_1: "uk",
        iso639_3: "ukr",
        name: "Ukrainian"
    },
    {
        iso639_1: "ur",
        iso639_3: "urd",
        name: "Urdu"
    },
    {
        iso639_1: "uz",
        iso639_3: "uzb",
        name: "Uzbek"
    },
    {
        iso639_1: "ve",
        iso639_3: "ven",
        name: "Venda"
    },
    {
        iso639_1: "vi",
        iso639_3: "vie",
        name: "Vietnamese"
    },
    {
        iso639_1: "wa",
        iso639_3: "wln",
        name: "Walloon"
    },
    {
        iso639_1: "wo",
        iso639_3: "wol",
        name: "Wolof"
    },
    {
        iso639_1: "xh",
        iso639_3: "xho",
        name: "Xhosa"
    },
    {
        iso639_1: "yi",
        iso639_3: "yid",
        name: "Yiddish"
    },
    {
        iso639_1: "yo",
        iso639_3: "yor",
        name: "Yoruba"
    },
    {
        iso639_1: "za",
        iso639_3: "zha",
        name: "Zhuang"
    },
    {
        iso639_1: "zh",
        iso639_3: "zho",
        name: "Chinese"
    },
    {
        iso639_1: "zu",
        iso639_3: "zul",
        name: "Zulu"
    }
];
