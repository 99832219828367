import React, { useContext, useMemo, useEffect, useState, useRef, useCallback } from "react";
import classNames from "classnames";
import axios from "axios";
import { io } from "socket.io-client";
import { AppContext } from "./context/AppContext";
import Routes from "./Routes";
import Footer from "./ui/Footer";
import { addToList, removeFromList } from "./app-base/fn";
import { getFileAuth, S3FileUploader } from "./actions/appActions";
import IdleTimer from "./IdleTimer";
import { fileModel } from "./baseModels";
import { isImage } from "./app-base/data/files";

const root = document.getElementById("window-area");

const AppContent = () => {
    const appState = useContext(AppContext);
    const [state, setState] = useState(
        {
            uploadingFile: false,
            uploadPerc: 0,
            uploadingMsg: "",
            hasIdledOut: false,
        },
        [],
    );
    const currentAuthed = useRef(false);

    const hasActivity = () => {
        let val = false;

        if (appState && appState.data) {
            val = appState.data.fileUploadQue && appState.data.fileUploadQue.length > 0;
        }

        return val;
    };

    /*************File Uploading ***************/
    const onFileProgress = (authData, percent) => {
        setState({
            ...state,
            uploadingFile: true,
            uploadPerc: percent * 100,
            uploadingMsg: `${authData.orgfilename}.${authData.fileext}`,
        });
    };

    const fileUploadError = (currentState, { err, data }) => {
        if (appState.data.fileUploadQue && appState.data.fileUploadQue.length > 0) {
            let errorFile = { ...appState.data.fileUploadQue[0] };

            errorFile.err = err;

            if (errorFile.onComplete) errorFile.onComplete(err, data, errorFile.params);

            appState.actions({
                type: "updateFileErrorQue",
                value: addToList(appStore.data.fileErrorQue, errorFile),
            });

            appState.actions({
                type: "updateFileQue",
                value: removeFromList(appState.data.fileUploadQue, 0),
            });
        }
    };

    const fileUpladCompleted = (err, data) => {
        if (appState.data.fileUploadQue && appState.data.fileUploadQue.length > 0) {
            let successFile = { ...appState.data.fileUploadQue[0] };

            // set meta data for file
            let file = {
                ...fileModel(),
                ...{
                    bucket: data.bucket,
                    entity: data.entity,
                    entityid: data.entityid,
                    key: data.key,
                    filename: data.filename,
                    fileext: data.fileext,
                    filemime: data.filemime,
                    lastmodified: !successFile.file.lastModified
                        ? null
                        : successFile.file.lastModified,
                    lastmodifieddate: !successFile.file.lastModifiedDate
                        ? null
                        : successFile.file.lastModifiedDate,
                    orgfilename: !successFile.file.name ? null : successFile.file.name,
                    size: !successFile.file.size ? 0 : successFile.file.size,
                    crtdte: new Date().toISOString(),
                },
            };

            if (successFile.onComplete) {
                if (isImage(file.fileext)) {
                    // file is image > get image attributes
                    const url = URL.createObjectURL(successFile.file);
                    const img = new Image();

                    img.onload = () => {
                        file.width = img.width;
                        file.height = img.height;
                        successFile.onComplete(err, file, successFile.params);
                    };

                    img.onerror = () => {
                        successFile.onComplete(err, file, successFile.params);
                    };

                    img.src = url;
                } else {
                    // not image file
                    successFile.onComplete(err, file, successFile.params);
                }
            }

            appState.actions({
                type: "updateFileQue",
                value: removeFromList(appState.data.fileUploadQue, 0),
            });
        }
    };

    // upload file to aws upload url for file
    const onFileUploaded = (err, data) => {
        setState({ ...state, uploadingFile: false, uploadPerc: 0, uploadingMsg: "" });

        if (err) {
            fileUploadError(err, data);
        } else {
            fileUpladCompleted(err, data);
        }
    };

    // file auth to upload from api received
    const onGetFileAuth = (err, data) => {
        if (err) {
            appState.actions({ type: "fileUploadError", value: { err: err, data: data } });
        } else {
            const req = S3FileUploader(data, onFileProgress, onFileUploaded);
            req.send(appState.data.fileUploadQue[0].file);
        }
    };

    const uploadFileFromQue = async () => {
        if (appState.data.fileUploadQue && appState.data.fileUploadQue.length > 0) {
            const authdata = appState.data.fileUploadQue[0].authdata;
            const res = await getFileAuth(authdata);

            if (res) onGetFileAuth(res.error, res.data);
        }
    };

    /***********End **********/

    /********** Socket *********/
    // const onDisconnect = () => {
    //     if (appState.socket) {
    //         if (appState.socketConnected) {
    //             appState.socket.disconnect();
    //         } else {
    //             appState.socket.connect();
    //         }
    //     }
    // };

    // const connectionErrorHandler = useCallback((err) => {
    //     // console.log("org channel connectionErrorHandler", err);
    //     // revert to classic upgrade
    //     // setState({ ...state, socketConnected: false });
    // }, []);

    const handleUserMessages = useCallback((msg) => {
        // console.log("handleInviteAccepted", msg);
    });

    const disconnectHandler = useCallback((reason) => {
        // reasons
        // io server disconnect
        // io client disconnect
        // ping timeout
        // transport close
        // transport error

        appState.actions({
            type: "changeState",
            value: { socketConnected: false, socketSessionId: "", socket: null },
        });
    }, []);

    const socketInit = () => {
        if (import.meta.env.VITE_WS_URL !== "") {
            const newSocket = io(import.meta.env.VITE_WS_URL, {
                path: import.meta.env.VITE_WS_PATH,
                withCredentials: true,
                auth: { token: appState.data.user.id },
                transports: ["websocket", "polling"],
            });

            newSocket.on("connect", () => {
                appState.actions({
                    type: "changeState",
                    value: {
                        socketConnected: true,
                        socketSessionId: newSocket.id,
                        socket: newSocket,
                    },
                });
            });

            // newSocket.on("connect_error", connectionErrorHandler);
            newSocket.on("disconnect", disconnectHandler);
            newSocket.on("message", handleUserMessages);
        }
    };

    const socketClose = () => {
        if (appState.socket) {
            appState.socket.off("message", handleUserMessages);
            // appState.socket.off("connect_error", connectionErrorHandler);
            appState.socket.off("disconnect", disconnectHandler);
            appState.socket.disconnect();
        }
    };

    /***********End **********/

    const cmp = useMemo(() => {
        let bodyClasses = document.body.classList;
        let authorized = appState.data.isAuthenticated;
        let orgid = appState.data.currentOrg ? appState.data.currentOrg.orgid : null;
        let hasOpenWindow = appState.data.windows && appState.data.windows.length > 0;

        if (hasOpenWindow) {
            if (!bodyClasses.contains("no-scroll")) bodyClasses.add("no-scroll");
        } else {
            if (bodyClasses.contains("no-scroll")) bodyClasses.remove("no-scroll");
        }

        return {
            authorized: authorized,
            orgid: orgid,
            hasOpenWindow: hasOpenWindow,
        };
    }, [appState.data.isAuthenticated, appState.data.currentOrg, appState.data.windows]);

    // process files to upload
    useEffect(() => {
        uploadFileFromQue();
    }, [appState.data.fileUploadQue]);

    useEffect(() => {
        if (!currentAuthed.current) {
            // user not currently signed in

            currentAuthed.current = appState.data.isAuthenticated;

            if (appState.data.isAuthenticated) {
                // user signed in

                socketInit();
            }
        } else {
            // user currently signed in

            currentAuthed.current = appState.data.isAuthenticated;

            if (!appState.data.isAuthenticated) {
                // user signed out
                socketClose();
            }
        }
    }, [appState.data.isAuthenticated]);

    useEffect(() => {
        try {
            // initial user requested path before signin
            const wLoc = window.location;
            const queryString = wLoc.search;

            appState.actions({ type: "changeState", value: { preUrlPath: queryString } });
        } catch (ignore) {}

        axios.interceptors.response.use(
            (response) => {
                // NProgress.done();
                return response;
            },
            (error) => {
                // NProgress.done();

                return Promise.reject(error);
            },
        );

        let timer = null;

        // init idle timer - logout user if idle for 1 hour or more 3600

        if (import.meta.env.VITE_DEBUG === "0") {
            try {
                timer = new IdleTimer({
                    timeout: 3600, // expire after 1 hour
                    onTimeout: () => {
                        try {
                            const isActive = hasActivity();

                            if (!isActive) {
                                // no activity

                                setState({
                                    ...state,
                                    hasIdledOut: true,
                                });

                                window.location.replace("/?tme=" + new Date().getTime().toString());
                            } else {
                                timer.continueTracking();
                            }
                        } catch (ignore) {}
                    },
                });
            } catch (ignore) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("IdleTimer exception", ignore);
            }
        }

        return () => {
            if (import.meta.env.VITE_DEBUG === "0") timer.cleanUp();
            socketClose();
        };
    }, []);

    return (
        <div className={classNames("content", { "in-app": cmp.authorized })}>
            <div className={classNames("main-area", { "child-is-open": cmp.hasOpenWindow })}>
                <Routes
                    style={{
                        // overflow: "hidden",
                        filter: !cmp.hasOpenWindow ? "none" : "blur(1px)",
                        msFilter: !cmp.hasOpenWindow
                            ? "none"
                            : "progid:DXImageTransform.Microsoft.MotionBlur(strength=10)",
                    }}
                    root={root}
                    hasOpenWindow={cmp.hasOpenWindow}
                    orgid={cmp.orgid}
                    isAuthenticated={cmp.authorized}
                />
            </div>

            {cmp.authorized && (
                <div
                    className={classNames("file-msg", {
                        "animate__animated slow animate__slideInUp": state.uploadingFile,
                        "no-display": !state.uploadingFile,
                    })}
                >
                    <div className="inner p-rel">
                        <progress value={state.uploadPerc} max="100" />
                        <div className="lbl f-s-xsm f-w-sb t-center p-t-3">
                            {state.uploadingMsg}
                        </div>
                    </div>
                </div>
            )}

            {!cmp.authorized && (
                <div className="footer-wrap">
                    <Footer />
                </div>
            )}
        </div>
    );
};

export default AppContent;
