import React, { useState, useEffect, useMemo, memo, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import InputHoc from "./InputHoc";
import { isNullOrUndefined, linebreak, isUndefined } from "../fn";
import FormWrap from "../form/_FormHOC";

// commit on click

const CheckboxInput = forwardRef(
    (
        {
            className,
            style,
            children,
            inputClass,
            checkboxLabelClass,
            checkboxLabelStyle,
            name = "",
            itemType = "checkbox",
            checkboxLabel = "",
            required = false,
            disabled = false,
            value = false,
            errorMsg = "",
            onChange,
            onCommit,
            onBlur,
        },
        ref,
    ) => {
        const [state, setState] = useState({ data: value }, []);

        useEffect(() => {
            setState({ ...state, data: isNullOrUndefined(value) ? false : value });
        }, [value]);

        const cmp = useMemo(() => {
            return {
                label: linebreak(checkboxLabel),
            };
        }, [checkboxLabel]);

        const validator = (newVal) => {
            const val = isUndefined(newVal)
                ? !state.data
                    ? false
                    : state.data
                : !newVal
                  ? false
                  : newVal;
            let msg = "";

            if (required && !val) msg = errorMsg ? errorMsg : "Selection is required.";
            return msg;
        };

        const commitData = (newData) => {
            if (!disabled) {
                const err = validator(newData);

                if (onCommit) onCommit(name, newData, err);
            }
        };

        // commit event
        const changeHandler = (e) => {
            const newData = !state.data;

            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (!disabled) {
                const err = validator(newData);

                setState({ data: newData });
                if (onChange) onChange(name, newData, true, err);
            }
        };

        if (ref) {
            useImperativeHandle(ref, () => ({
                validate() {
                    return validator();
                },
                commitData() {
                    return commitData(state.data);
                },
            }));
        }

        return (
            <div className={classNames("checkbox-input", className)} style={style} onBlur={onBlur}>
                <div
                    className={classNames("wrap fl-inline", inputClass, {
                        "is-checked": state.data,
                        "c-pointer": !disabled,
                    })}
                    onClick={changeHandler}
                >
                    <i className="material-icons f-s-md c-primary" style={{ lineHeight: 1.1 }}>
                        {!state.data ? "check_box_outline_blank" : "check_box"}
                    </i>

                    <div
                        className={classNames("check-lbl", checkboxLabelClass, {
                            "no-display": checkboxLabel === "",
                        })}
                        style={checkboxLabelStyle}
                        dangerouslySetInnerHTML={{ __html: cmp.label }}
                    />
                </div>

                {children}
            </div>
        );
    },
);

export default FormWrap(InputHoc(memo(CheckboxInput), false));
