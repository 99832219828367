import { addressModel, phoneModel, priorityLevelModel } from "../baseModels";

export function userTypeModel(id = null, label = "") {
    return {
        id: id,
        label: label,
        status: 1,
        dscr: "",
        issystem: true,
        note: "",
        rights: null,
        crtdte: null,
        crtby: null,
        updte: null,
        upby: null,
    };
}

export function roleItemModel() {
    return {
        id: null,
        status: "active",
        label: "",
        dscr: "",
        tags: null,
    };
}

export function roleModel(orgid = null) {
    return {
        id: null,
        orgid: orgid,
        label: "",
        status: "active",
        dscr: "",
        note: "",
        is_system: false,
        tags: null, // [string]
        tasks: null, // [roleTaskModel]
        files: null, // [fileModel]
        documents: null, // [string] list of documents from org docs table
        crtdte: null,
        crtby: null,
        updte: null,
        upby: null,
    };
}

export function roleTaskItemModel() {
    return {
        id: null,
        orgid: null,
        role_id: null,
        idx: -1,
        title: "",
        priority: null,
        tags: null,
    };
}

export function employeeHoursModel() {
    return {
        start: null,
        end: null,
        areas: null,
    };
}

export function areaModel(orgid = null) {
    return {
        id: null,
        orgid: orgid,
        status: "active", // "active", "inactive",
        type: "", // not used yet
        name: "",
        dscr: "",
        ext: "", // phone extension
        location: null, //{ x: 0, y: 0,  z: 0}
        tags: null,
        notes: null,
        files: null,
        assets: [],
        equipments: [],
    };
}

export function orgHourModel(day = -1, start = null, end = null, note = "") {
    return {
        day: day,
        start: start,
        end: end,
        note: note,
    };
}

export function weekHoursModel() {
    return { d0: null, d1: null, d2: null, d3: null, d4: null, d5: null, d6: null };
}

export function defaultHours() {
    // m - f 9am to 5pm
    let val = weekHoursModel();

    val.d1 = [orgHourModel(1, 32400, 61200)];
    val.d2 = [orgHourModel(2, 32400, 61200)];
    val.d3 = [orgHourModel(3, 32400, 61200)];
    val.d4 = [orgHourModel(4, 32400, 61200)];
    val.d5 = [orgHourModel(5, 32400, 61200)];

    return val;
}

export function orgShortModel() {
    return {
        id: null,
        status: "active", // "active", "inactive", "archived"
        groupid: "",
        name: "",
        address: addressModel(),
        phone: phoneModel(),
        fax: phoneModel(),
        supportphone: phoneModel(),
        taxid: "",
        priority_levels: [], // priorityLevelModel
        user_types: [], // userTypeModel
        hours: null, //OrgHourModel
        org_sid: null,
        online: false,
    };
}

export function orgPayerItemModel() {
    return {
        id: null,
        orgid: null,
        master_payer_id: null,
        status: "active",
        eligibility_status: "open",
        claims_status: "open",
        remittance_status: "open",
        cs_status: "open",
        eligibility_master_payer_id: null,
        phones: null,
        addresses: null,
        tags: null,
        crtdte: null,
        name: "",
        payer_id: null,
        state: "",
        claim_type: "",
        payer_type: null, // constants.PAYER_TYPES.data
        claim_type: null, // constants.CLAIM_TYPES.data
        attachment: "no",
        urls: "",
        excluded_codes: null,
        in_network: false,
        is_supplemental: false,
        primary_for_covered_codes: false,
    };
}

export function procItemModel() {
    return {
        id: null,
        code: null,
        // orgid: null
        name: "",
        is_test: false,
        tags: null,
        providers: null, // array of contact ids
        duration: null, // in minutes
        charge_amt: null,
        allow_amt: null,
    };
}

export function modifierModel() {
    return {
        modifier: "",
        name: "",
        charge_amt: null,
        allow_amt: null,
    };
}

export function cptDiagnosisModel() {
    return {
        code: null,
        title: "",
        note: "",
    };
}

export function payerPriceModel() {
    return {
        ins_id: null,
        charge_pct: null,
        allow_pct: null,
        op_pct: null,
    };
}

export function qtyItemModel(uofm = "") {
    return {
        qty: null,
        uofm: uofm, // baseData.MED_UNITS.data
        label: "",
        stock: false,
        int_id: null,
        stock_id: null,
        area_id: null,
        contact_id: null, // employee id
        cost_ea: null,
        price_ea: null,
    };
}

export function medicationItemModel() {
    return {
        id: null,
        status: "active",
        category: "",
        title: "",
        mfg: "",
        ndc: "",
        tags: null,
        dosage: null, // numeric value 19,5
        uofm: null, // string from constants.MED_UNITS.data
        price: null,
        prescription: true,
    };
}

export function medPayerModel(insId, price = null) {
    return {
        ins_id: insId,
        price: price,
    };
}

export function medSubstituteModel(MedId, note = "") {
    return {
        med_id: MedId,
        note: note,
    };
}

export function medicationModel(orgid = null) {
    return {
        id: null,
        orgid: orgid,
        status: "active",
        category: "",
        title: "",
        dscr: "",
        mfg: "",
        ndc: "", // National Drug Code
        tags: null,
        files: null,
        uofm: null, // string from constants.MED_UNITS.data
        dosage: null, // numeric value 19,5
        substitutes: null, // [medSubstituteModel]
        procedures: null, // [procedures.id]
        payers: null, // [medPayerModel]
        providers: null, // [contact.id]
        cost: null,
        price: null,
        note: "",
        prescription: true,
        crtdte: null,
        crtby: null,
        updte: null,
        upby: null,
    };
}

export function orgProcedureModel(orgid, revenue_code) {
    return {
        id: null,
        code: null,
        orgid: orgid,
        starred: false,
        name: "",
        dscr: "",
        note: "",
        is_test: false,
        revenue_code: revenue_code,
        mod_required: false,
        duration: null, // in minutes
        charge_amt: null,
        allow_amt: null,
        diagnoses: null, // [cptDiagnosisModel] icd 10
        providers: null, // [string] array of contact ids
        supplies: null, // [qtyItemModel] supplies cost
        materials: null, // [qtyItemModel] material cost
        services: null, // [qtyItemModel] labor costs
        misc_costs: null, // [qtyItemModel] misc costs (rent, insurance, etc...)
        medication: null, // default medication for procedure
        units: 1, // procedure default units
        tests: null, // array of tests for procedure
        tags: null,
        files: null,
        crtdte: null,
        crtby: null,
        updte: null,
        upby: null,
    };
}

export function orgPayerModel(orgid = null) {
    return {
        id: null,
        orgid: orgid,
        payer_name: "",
        is_other_payer: false,
        master_payer_id: null,
        status: "active",
        payer_type: null, // PAYeR_TYPES.data
        claim_type: null, // constants.CLAIM_TYPES.data
        eligibility_status: "open", // completed, rejected, submitted, open, available, not-available
        claims_status: "open", //completed, rejected, submitted, open, available, not-available
        remittance_status: "open", // completed, rejected, submitted, open, available, not-available
        cs_status: "open", // Claim status : completed, rejected, submitted, open, available, not-available
        eligibility_master_payer_id: null,
        providers: null,
        phones: null,
        addresses: null,
        eligibility_open_date: null,
        eligibility_submit_date: null,
        eligibility_cmp_date: null,
        eligibility_reject_date: null,
        claims_open_date: null,
        claims_submit_date: null,
        claims_cmp_date: null,
        claims_reject_date: null,
        remittance_open_date: null,
        remittance_submit_date: null,
        remittance_cmp_date: null,
        remittance_reject_date: null,
        cs_open_date: null,
        cs_submit_date: null,
        cs_cmp_date: null,
        cs_reject_date: null,
        auth_required: false,
        crtby: null,
        crtdte: null,
        upby: null,
        updte: null,
        tags: null,
        email1: "",
        email2: "",
        email3: "",
        url: "",
        note: "",
        files: null,
        excluded_codes: null,
        covered_codes: null,
        in_network: false,
        default_copay_amt: null,
        default_coins_pct: null,
        is_supplemental: false,
        primary_for_covered_codes: false,
        cover_max_amt: null,
        payer_id: "",
        primary_ded_covered: false,
    };
}

export function transactionModel(orgid, isPayer, isDebit = false) {
    return {
        id: null,
        tran_num: "",
        orgid: orgid,
        is_payer: isPayer,
        status: "open", // constants.TRANSACTION_STATUS_LIST
        is_debit: isDebit,
        from_tran_id: null, // if is_debit and from EOB
        system_payment: false,
        imported: false,
        tran_type: null,
        payment_method: null,
        card_type: "",
        amount: null,
        claim_count: 0,
        billed_amt: null,
        allow_amt: null,
        deductible_amt: null,
        co_ins_amt: null,
        adj_amt: null,
        applied_amt: null,
        payment_ref: "",
        payment_ref_2: "",
        payment_ref_3: "",
        received_date: null,
        received_by: null,
        received_to: "",
        contact_id: null,
        org_payer_id: null,
        master_payer_id: null,
        tags: null,
        adj_reasons: null,
        files: null,
        verified: false,
        verified_by: null,
        verified_date: null,
        note: "",
        posted_total_amt: 0, // computed
        from_enc_id: null,
        balance: 0, // if is_debit (charge) how much is left to pay
        crtby: null,
        crtdte: null,
        upby: null,
        updte: null,
    };
}

export function transactionAppliesModel(
    id = null,
    orgid = null,
    contact_id = null,
    source = "encounter",
    entity_id = null,
) {
    return {
        id: id,
        orgid: orgid,
        status: "completed",
        source: source, // encounter, transaction
        actions: null,
        entity_id: entity_id,
        contact_id: contact_id,
        expected_amount: 0,
        amount: 0,
        is_payer: false,
        is_debit: false,
        primary_payer: false,
        org_payer_id: null,
        claim_id: null,
        claim_status: null,
        claim_dscr: "",
        claim_corrlation_id: "",
        claim_tracking_id: "",
        claim_total_amt: null,
        tags: null,
        files: null,
        tran_id: null,
        forwarded_to_org_payer_id: null,
        co_pay_apply_amt: 0,
        note: "",
        max_limit: null,
        previous_payment_amt: null, // if is debit  - amount paid previously - if patient payment
        completed_date: null,
        icn: "",
        crtby: null,
        crtdte: null,
        upby: null,
        updte: null,
    };
}

export function transactionApplyEncLinesModel(
    id = null,
    orgid = null,
    tran_id = null,
    tran_apply_id = null,
    enc_id = null,
    enc_payer_id = null,
    enc_proc_id = null,
    org_code_id = null,
    code = "",
    units = 1,
    amount = null,
    charge_amt = null,
    allow_amt = null,
    deductible_amt = null,
    co_ins_amt = null,
    copay_amt = null,
    adj_amt = null,
    adj_reasons = null,
    action_status = null,
    note = "",
) {
    return {
        id: id,
        orgid: orgid,
        tran_id: tran_id,
        tran_apply_id: tran_apply_id,
        claim_id: null,
        claim_line_id: null,
        enc_id: enc_id,
        is_copay: false,
        is_fee: false,
        is_discount: false,
        is_deposit: false,
        is_debit: false,
        enc_payer_id: enc_payer_id,
        enc_proc_id: enc_proc_id,
        org_code_id: org_code_id,
        code: code,
        units: units,
        amount: amount,
        billed_amt: null,
        charge_amt: charge_amt,
        allow_amt: allow_amt,
        deductible_amt: deductible_amt,
        co_ins_amt: co_ins_amt,
        copay_amt: copay_amt,
        adj_amt: adj_amt,
        adj_reasons: adj_reasons,
        action_status: action_status, // appeal, denied-to-patient,  denied-fix
        max_limit_applied_amt: null, // computed
        note: note,
        denial_codes: null,
        completed_date: null,
        previous_payment_amt: null, // if is debit  - amount paid previously
        crtby: null,
        crtdte: null,
        upby: null,
        updte: null,
    };
}

export function transactionAppealModel() {
    return {
        id: null,
        status: "open",
        orgid: null,
        tran_id: null,
        tran_apply_id: null,
        claim_id: null,
        enc_id: null,
        enc_proc_id: null,
        org_code_id: null,
        code: "",
        units: 1,
        amount_paid: null,
        amount_expected: null,
        allow_amt: null,
        allow_amt_expected: null,
        resolve_status: null,
        resolve_dte: null,
        resolve_by: null,
        resolve_allow_amt: null,
        resolve_amount: null,
        crtby: null,
        crtdte: null,
        note: "",
        upby: null,
        updte: null,
    };
}

export function claimModel(
    orgid = null,
    claim_status = "submitted",
    enc_id = null,
    enc_payer_line_id = null,
    partner_source = null,
    org_payer_id = null,
    correlation_id = null,
    provider_id = null,
    contact_id = null,
    control_num = "",
) {
    return {
        id: null,
        orgid: orgid,
        claim_status: claim_status,
        claim_dscr: "",
        enc_id: enc_id,
        enc_payer_line_id: enc_payer_line_id,
        partner_source: partner_source,
        org_payer_id: org_payer_id,
        correlation_id: correlation_id,
        contact_id: contact_id,
        patient_stn: "",
        patient_fname: "",
        patient_mname: "",
        patient_lname: "",
        patient_sfx: "",
        total_amt: null,
        crtby: null,
        crtdte: null,
        charge_amt: 0,
        allow_amt: 0,
        deductible_amt: 0,
        max_limit_applied_amt: 0,
        paid_amt: 0,
        completed: false,
        comp_dte: null,
        comp_by: null,
        last_action: "",
        last_action_dte: null,
        last_action_by: null,
        note: "",
        is_primary_payer: true,
        co_ins_balance: 0,
        expected_amt: 0,
        master_payer_id: null,
        payment_ref: "",
        control_num: control_num,
        claim_filing_code: null,
        claim_frq_code: null,
        payer_claim_status: "",
        payer_type: null,
        srv_location_code: null,
        service_location: null,
        billing_location: null,
        billing_name: "",
        billing_npi: "",
        billing_taxid: "",
        billing_ptan: "",
        provider_id: provider_id,
        att_provider_id: null,
        other_payer_payments: null,
        auth_code_1: "",
        auth_code_2: "",
        auth_code_3: "",
        rejected_reasons: null,
        rejected_date: null,
        resubmit_date: null,
        resubmit_by: null,
        voided_date: null,
        voided_by: null,
        denied_date: null,
        appeal_sent_date: null,
        payer_res: null,
        payer_data: null,
        error_note: "",
        files: null,
        has_denial: false,
        has_appeal: false,
        validated: false,
        validated_dte: null,
        validation_errors: null,
        write_off_amt: 0,
        to_patient_amt: 0,
        icn: "", // previous icn for claim resubmission
        file_id: "", // file id for claim resubmission
        crtby: null,
        crtdte: null,
        note: "",
        upby: null,
        updte: null,
    };
}

export function claimLineItemModel(
    claim_id = null,
    enc_proc_id = null,
    ord = 0,
    line_control_num = "",
    line_status = "submitted",
    orgid = null,
    srv_date = null,
    org_payer_id = null,
    provider_id = null,
    code = null,
    is_test = false,
    units = 1,
    charge_amt = 0,
    allow_amt = 0,
    modifiers = null,
    diagnoses = null,
    crtby = null,
) {
    return {
        id: null,
        claim_id: claim_id,
        enc_proc_id: enc_proc_id,
        ord: ord,
        line_control_num: line_control_num,
        line_status: line_status,
        orgid: orgid,
        srv_date: srv_date,
        org_payer_id: org_payer_id,
        provider_id: provider_id,
        code: code,
        proc_name: "",
        is_test: is_test,
        units: units,
        charge_amt: charge_amt,
        allow_amt: allow_amt,
        paid_amt: 0,
        modifiers: modifiers,
        diagnoses: diagnoses,
        revenue_code: "",
        medication: null, // encounterModels.procedureMedicationModel
        expected_amt: 0,
        deductible_applied_amt: 0,
        copay_applied_amt: 0,
        co_ins_amt: 0,
        max_limit_applied_amt: 0,
        not_covered_amt: 0,
        denial_codes: null, // text ['string']
        completed: false,
        denied_date: null,
        denied_by: null,
        appeal_outcome_status: "",
        appeal_sent: false,
        appeal_sent_date: null,
        appeal_sent_by: null,
        appeal_comp_date: null,
        appeal_comp_by: null,
        write_off_amt: 0,
        to_patient_amt: 0,
        crtby: crtby,
        crtdte: null,
        upby: null,
        updte: null,
    };
}

export function orgModel() {
    return {
        id: null,
        isperson: false,
        status: "active", // "active", "inactive", "archived"
        type: "", // practice or surgery-center
        groupid: "",
        name: "",
        time_zone: null,
        address: null,
        payment_address: null,
        taxonomy: null,
        phone: null,
        fax: null,
        supportphone: null,
        email_support: "",
        web_url: "",
        payment_phone: null,
        no_show_amt: null,
        priority_levels: [
            priorityLevelModel("critical", "Critical", 10, true, "#C10000"),
            priorityLevelModel("urgent", "Urgent", 20, true, "#ff5b57"),
            priorityLevelModel("high", "High", 30, true, "#f59c1a"),
            priorityLevelModel("medium", "Medium", 40, true, "#2a72b5"),
            priorityLevelModel("low", "Low", 50, true, "#0BAA50"),
        ],
        ownership: null,
        accounting_method: null,
        taxid: "",
        user_types: [
            userTypeModel("Associate", "Associate"),
            userTypeModel("Member", "Member"),
            userTypeModel("Admin", "Administrator"),
            userTypeModel("Owner", "Owner"),
        ],
        org_npi: "",
        npi_org_name: "",
        medicaid_num: "",
        ptan: "",
        dmerc_npi: "",
        dmerc_taxid: "",
        dmerc_ptan: "",
        clearinghouse_registered: false,
        clearing_partner: "claim-md", // claim-md or  change
        elg_srv_codes: "30",
        hours: null, //{ d0: [OrgHourModel], d1: null, d2: null, d3: null, d4: null, d5: null, d6: null };
    };
}

export function getPartnerName(partnerId) {
    if (!partnerId || partnerId === "") return "";
    return partnerId === "claim-md" ? "Claim MD" : "Change Healthcare";
}

export function getPayerActionStatus(val) {
    switch (val) {
        case "yes":
            return "available";
        case "enrollment":
            return "open";
        default:
            return "not-available";
    }
}

export function parsePayerInsuranceType(masterClaimType, insType, insName) {
    let claimType = "";
    let payerType = "";

    if (insType && insType.trim() !== "") {
        const lType = insType.toLowerCase();

        if (lType.indexOf("blue cross") > -1 || lType.indexOf("bcbs") > -1) {
            claimType = "BL";
        } else if (lType.indexOf("champus") > -1) {
            claimType = "CH";
        } else if (
            lType.indexOf("workers compensation") > -1 ||
            lType.indexOf("workerscomp") > -1
        ) {
            payerType = "WC";
            claimType = "WC";
            // } else if (lType.indexOf("hmo") > -1) {
            //     payerType = "HM";
            //     claimType = "HM";
        } else if (lType.indexOf("medicare") > -1) {
            if (masterClaimType.toLowerCase().indexOf("professional") > -1) {
                payerType = "MB";
                claimType = "MB";
            }
        } else if (lType.indexOf("medicaid") > -1) {
            payerType = "MC";
            claimType = "MC";
        } else if (lType.indexOf("commercial") > -1) {
            payerType = "C1";
            claimType = "CI";
        }
    }

    if (payerType === "") {
        if (insName && insName.trim() !== "") {
            const lName = insName.toLowerCase();

            if (lName.indexOf(" epo") > -1) {
                payerType = "EP";
                claimType = "14";
            } else if (lName.indexOf(" hmo") > -1) {
                payerType = "HM";
                claimType = "HM";
            } else if (lName.indexOf(" pos") > -1) {
                payerType = "PS";
            } else if (lName.indexOf(" PPO") > -1) {
                payerType = "PR";
                claimType = "12";
            }
        }
    }

    return { payer_type: payerType, claim_type: claimType };
}

export function parseMasterPayerToOrgPayer(payer, isNew = true) {
    if (!payer) return null;

    const { payer_type, claim_type } = parsePayerInsuranceType(
        payer.master_claim_type,
        payer.insurance_type,
        payer.name,
    );

    let updateValue = {
        master_payer_id: payer.id,
        payer_id: payer.payer_id,
        eligibility_partner_id: null,
        eligibility_status: getPayerActionStatus(payer.eligibility),
        claims_status: getPayerActionStatus(payer.pro_claims),
        remittance_status: getPayerActionStatus(payer.remittance),
        cs_status: getPayerActionStatus(payer.claim_status),
        eligibility_master_payer_id: null,
        payer_type: payer_type,
        claim_type: claim_type,
        avg_era_enroll_days: payer.avg_era_enroll_days,
        insurance_type: payer.insurance_type,
        master_claim_type: payer.master_claim_type,
        partner_source: payer.partner_source,
        pro_claims: payer.pro_claims,
        ub_claims: payer.ub_claims,
        payer_alt_names: payer.payer_alt_names,
        eligibility_open_date: null,
        eligibility_submit_date: null,
        eligibility_cmp_date: null,
        eligibility_reject_date: null,
        claims_open_date: null,
        claims_submit_date: null,
        claims_cmp_date: null,
        claims_reject_date: null,
        remittance_open_date: null,
        remittance_submit_date: null,
        remittance_cmp_date: null,
        remittance_reject_date: null,
        cs_open_date: null,
        cs_submit_date: null,
        cs_cmp_date: null,
        cs_reject_date: null,
    };

    if (isNew) updateValue.payer_name = payer.name;

    return updateValue;
}
