export const TIME_ZONES = [
    {
        data: "America/Adak",
        label: "America/Adak",
        zone: "HT",
        zone_name: "Hawaii-Aleutian Time",
        uses_dst: true,
    },
    {
        data: "America/Anchorage",
        label: "America/Anchorage",
        zone: "AKT",
        zone_name: "Alaska Time",
        uses_dst: true,
    },
    {
        data: "America/Chicago",
        label: "America/Chicago",
        zone: "CT",
        zone_name: "Central Time",
        uses_dst: true,
    },
    {
        data: "America/Denver",
        label: "America/Denver",
        zone: "MT",
        zone_name: "Mountain Time",
        uses_dst: true,
    },
    {
        data: "Pacific/Honolulu",
        label: "Pacific/Honolulu",
        zone: "HST",
        zone_name: "Hawaii Standard Time",
        uses_dst: false,
    },
    {
        data: "America/Los_Angeles",
        label: "America/Los Angeles",
        zone: "PT",
        zone_name: "Pacific Time",
        uses_dst: true,
    },
    {
        data: "America/New_York",
        label: "America/New York",
        zone: "ET",
        zone_name: "Eastern Time",
        uses_dst: true,
    },
    {
        data: "America/Phoenix",
        label: "America/Phoenix",
        zone: "MST",
        zone_name: "Mountain Standard Time",
        uses_dst: false,
    },
];

export const USER_TYPES = [
    { label: "Associate", data: "Associate" },
    { label: "Member", data: "Member" },
    { label: "Administrator", data: "Admin" },
    { label: "Owner", data: "owner" },
];

export const DOCUMENT_STATUS_LIST = [
    { label: "Active", data: false },
    { label: "Archived", data: true },
];

export const DOCUMENT_TYPES_LIST = [
    { label: "File", data: "file" },
    // { label: 'Folder', data: 'folder' },
    { label: "Form", data: "form" },
    { label: "Paper", data: "paper" },
];

export const ENCOUNTER_STATUS_LIST = [
    { label: "Expected", data: "expected", allowed: null },
    { label: "Checked In", data: "checked_in", allowed: ["expected"] },
    // { label: "Pre Exam", data: "pre_exam", allowed: ["checked_in"] },//replaced with 'admit'
    { label: "Admit", data: "admit", allowed: ["checked_in"] },
    { label: "Exam", data: "exam", allowed: ["checked_in", "admit"] },
    { label: "Check Out", data: "check_out", allowed: ["checked_in", "admit", "exam"] },
    { label: "Completed", data: "completed", allowed: null },
    { label: "Cancelled", data: "cancelled", allowed: null },
];

export const ENCOUNTER_CANCEL_REASONS = [
    { label: "No Show", data: "no-show" },
    { label: "Patient Request", data: "patient-request" },
    { label: "Mistake", data: "mistake" },
];

export const ENCOUNTER_TYPES = [
    { data: "follow-up", label: "Follow Up" },
    { data: "injection", label: "Injection" },
    { data: "unscheduled", label: "Unscheduled" },
    { data: "consult", label: "Consult" },
    { data: "emergency", label: "Emergency" },
    { data: "surgery", label: "Surgery" },
    { data: "nc", label: "N/C - Pre/post op." },
    { data: "new", label: "New" },
    { data: "work-in", label: "Work In" },
];

export const PATIENT_CONDITION_CODES = [
    { data: "A", label: "Acute Condition" },
    { data: "C", label: "Chronic Condition" },
    { data: "E", label: "Non-Life Threatening" },
    { data: "F", label: "Routine" },
    { data: "G", label: "Symptomatic" },
    { data: "M", label: "Acute Manifestation of a Chronic Condition" },
];

// export const OPTOMETRICS = [
//   {label: 'Refraction', data: 'refraction'},
//   {label: 'Post-Op Ref', data: 'post-op-ref'},
//   {label: 'Muscle Bal', data: 'muscle-bal'},
//   {label: 'CL Eval/Fit', data: 'cl-eval-fit'},
//   {label: 'CL F/up', data: 'cl-f-up'},
//   {label: 'CL Training', data: 'cl-training'},
//   {label: 'HCL Over Ref', data: 'hcl-over-ref'}
// ]

export const TRANSACTION_STATUS_LIST = [
    { label: "Open", data: "open" },
    { label: "Completed", data: "completed" },
    { label: "Voided", data: "voided" },
];

export const PREPAY_CREDIT_TYPES = [
    { data: "ora", label: "ORA" },
    { data: "restor", label: "Restor" },
    { data: "toric", label: "Toric" },
    { data: "crystalens", label: "Crystalens" },
    { data: "injections-meds", label: "Injections Medications" },
];

export const ORG_PAYER_CAT_STATUSES = [
    { label: "Open", data: "open" },
    { label: "Submitted", data: "submitted" },
    { label: "Completed", data: "completed" },
    { label: "Rejected", data: "rejected" },
];

// Type of Admission 2300 > CL101 > default "3"
export const ADMIT_TYPES = [
    {
        label: "1 : Emergency",
        dscr: "The patient requires immediate medical intervention as a result of severe, life threatening, or potentially disabling conditions.",
        data: "1",
    },
    {
        label: "2 : Urgent",
        dscr: "The patient requires immediate attention for the care and treatment of a physical or mental disorder. Generally the patient is admitted to the first available and suitable accommodation.",
        data: "2",
    },
    {
        label: "3 : Elective",
        dscr: "The patient's condition permits adequate time to schedule the admission based on the availability of a suitable accommodation.",
        data: "3",
    },
    {
        label: "5 : Trauma",
        dscr: "Visit to a trauma center/hospital as licensed or designated by the state or local government authority authorized to do so, or as verified by the American College of Surgeons and involving a trauma activation.",
        data: "5",
    },
    {
        label: "9 : Information not available",
        dscr: "The provider cannot classify the type of admission.",
        data: "9",
    },
];

// Point of Origin / Source of Admission > SRC. LOOP 2300 > CL102 > Data Element 1314 > default "1"
export const ADMIT_SOURCES = [
    {
        label: "1 : Order from Physician",
        dscr: "The patient presents to this facility with an order from a physician for services or seeks scheduled services for which an order is not required (e.g. mammography). Includes non-emergent self-referrals.",
        data: "1",
    },
    {
        label: "2 : Clinic",
        dscr: "The patient was referred to this facility for outpatient or referenced diagnostic services.",
        data: "2",
    },
    {
        label: "4 : Transfer From a Hospital (Different Facility)",
        dscr: "The patient was transferred to this facility as an outpatient from an acute care facility.",
        data: "4",
    },
    {
        label: "5 : Transfer From a Skilled Nursing Facility (SNF) or Intermediate Care Facility (ICF)",
        dscr: "The patient was referred to this facility for outpatient or referenced diagnostic services for a SNF or ICF where he or she was a resident. Note: NYS no longer uses ICF determination.",
        data: "5",
    },
    {
        label: "6 : Transfer From Another Health Care Facility",
        dscr: "The patient was referred to this facility for services by (a physician of) another health care facility not defined elsewhere in this code list where he or she was an inpatient or outpatient.",
        data: "6",
    },
    {
        label: "7 : Emergency Room",
        dscr: "The patient received unscheduled services in this facility's emergency department and discharged without an inpatient admission. Includes self-referrals in emergency situations that require immediate medical attention.",
        data: "7",
    },
    {
        label: "8 : Court/Law Enforcement",
        dscr: "The patient was referred to this facility upon the direction of a court of law, or upon the request of a law enforcement agency representative for outpatient or referenced diagnostic services.",
        data: "8",
    },
    {
        label: "9 : Information Not Available",
        dscr: "The means by which the patient was admitted to this hospital was not known.",
        data: "9",
    },
    {
        label: "D : Transfer from One Distinct Unit of the Hospital to another Distinct Unit of the Same Hospital Resulting in a Separate Claim to the Payer",
        dscr: "The patient received outpatient services in this facility as a transfer from within this hospital resulting in a separate claim to the payer.",
        data: "D",
    },
    // {
    //     label: "E : Transfer from Ambulatory Surgery Center",
    //     dscr: "The patient was referred to this facility for outpatient or referenced diagnostic services from an ambulatory surgery center.",
    //     data: "E",
    // },
    {
        label: "F : Transfer from Hospice and is Under a Hospice Plan of Care or Enrolled in a Hospice Program",
        dscr: "The patient was referred to this facility for outpatient or referenced diagnostic services from a hospice.",
        data: "F",
    },
];

// STAT > Patient Discharge Status  > 2300 > CL103 > default "01"
export const DISCHARGE_STATUSES = [
    { data: "01", label: "01 : Discharge to Home or Self Care (Routine Discharge)." },
    {
        data: "02",
        label: "02 : Discharged/transferred to a Short-Term General Hospital for Inpatient Care.",
    },
    {
        data: "03",
        label: "03 : Discharged/transferred to Skilled Nursing Facility (SNF) with Medicare Certification in Anticipation of Skilled Care.",
    },
    {
        data: "04",
        label: "04 : Discharged/transferred to a Facility that Provides Custodial or Supportive Care.",
    },
    {
        data: "05",
        label: "05 : Discharged/transferred to a Designated Cancer Center or Children's Hospital.",
    },
    {
        data: "06",
        label: "06 : Discharged/transferred to Home Under Care of Organized Home Health Service Organization in Anticipation of Covered Skilled Care.",
    },
    { data: "07", label: "07 : Left Against Medical Advice or Discontinued Care." },
    { data: "09", label: "09 : Admitted as an Inpatient to this Hospital." },
    { data: "20", label: "20 : Expired (or did not recover - Christian Science patient)." },
    { data: "21", label: "21 : Discharged/transferred to Court/Law Enforcement." },
    { data: "40", label: "40 : Expired at Home." },
    {
        data: "41",
        label: "41 : Expired in a Medical Facility (e.g. hospital, SNF, ICF, or free standing hospice).",
    },
    { data: "42", label: "42 : Expired - Place Unknown." },
    { data: "43", label: "43 : Discharged/transferred to a Federal Health Care Facility." },
    { data: "50", label: "50 : Hospice - Home." },
    {
        data: "51",
        label: "51 : Hospice - Medical Facility (Certified) Providing Hospice Level of Care.",
    },
    {
        data: "61",
        label: "61 : Discharged/transferred to Hospital-Based Medicare Approved Swing Bed.",
    },
    {
        data: "62",
        label: "62 : Discharged/transferred to an Inpatient Rehabilitation Facility (IRF) including Rehabilitation Distinct Part Units of a Hospital.",
    },
    {
        data: "63",
        label: "63 : Discharged/transferred to a Medicare Certified Long Term Care Hospital (LTCH).",
    },
    {
        data: "64",
        label: "64 : Discharged/transferred to a Nursing Facility Certified under Medicaid but not Certified under Medicare.",
    },
    {
        data: "65",
        label: "65 : Discharged/transferred to a Psychiatric Hospital or Psychiatric Distinct Part Unit of a Hospital.",
    },
    { data: "66", label: "66 : Discharged/transferred to a Critical Access Hospital (CAH)." },
    {
        data: "69",
        label: "69 : Discharged/transferred to a Designated Disaster Alternative Care Site.",
    },
    {
        data: "70",
        label: "70 : Discharged/transferred to another Type of Health Care Institution not Defined Elsewhere in this Code List (See Code 05).",
    },
    {
        data: "81",
        label: "81 : Discharged to Home or Self Care with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "82",
        label: "82 : Discharged/transferred to a Short-Term General Hospital for Inpatient Care with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "83",
        label: "83 : Discharged/transferred to Skilled Nursing Facility (SNF) with Medicare Certification with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "84",
        label: "84 : Discharged/transferred to a Facility that Provides Custodial or Supportive Care with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "85",
        label: "85 : Discharged/transferred to a Designated Cancer Center or Children's Hospital with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "86",
        label: "86 : Discharged/transferred to Home Under Care of Organized Home Health Service Organization with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "87",
        label: "87 : Discharged/transferred to Court/Law Enforcement with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "88",
        label: "88 : Discharged/transferred to a Federal Health Care Facility with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "89",
        label: "89 : Discharged/transferred to Hospital-Based Medicare Approved Swing Bed with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "90",
        label: "90 : Discharged/transferred to an Inpatient Rehabilitation Facility (IRF) including Rehabilitation Distinct Part Units of a Hospital with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "91",
        label: "91 : Discharged/transferred to a Medicare Certified Long Term Care Hospital (LTCH) with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "92",
        label: "92 : Discharged/transferred to a Nursing Facility Certified under Medicaid but not Certified under Medicare with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "93",
        label: "93 : Discharged/transferred to a Psychiatric Hospital or Psychiatric Distinct Part Unit of a Hospital with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "94",
        label: "94 : Discharged/transferred to a Critical Access Hospital (CAH) with a Planned Acute Care Hospital Inpatient Readmission.",
    },
    {
        data: "95",
        label: "95 : Discharged/transferred to another Type of Health Care Institution not Defined Elsewhere in this Code List with a Planned Acute Care Hospital Inpatient Readmission.",
    },
];

export const PAYER_TYPES = [
    { data: "AP", label: "Auto Insurance Policy" },
    { data: "C1", label: "Commercial" },
    {
        data: "CO",
        label: "Consolidated Omnibus Budget Reconciliation Act (COBRA)",
    },
    { data: "CP", label: "Medicare Conditionally Primary" },
    { data: "D", label: "Disability" },
    { data: "DB", label: "Disability Benefits" },
    { data: "EP", label: "Exclusive Provider Organization" },
    { data: "FF", label: "Family or Friends" },
    { data: "GP", label: "Group Policy" },
    { data: "HM", label: "Health Maintenance Organization (HMO)" },
    {
        data: "HN",
        label: "Health Maintenance Organization (HMO) - Medicare Risk",
    },
    { data: "HS", label: "Special Low Income Medicare Beneficiary" },
    { data: "IN", label: "Indemnity" },
    { data: "IP", label: "Individual Policy" },
    { data: "LC", label: "Long Term Care" },
    { data: "LD", label: "Long Term Policy" },
    { data: "LI", label: "Life Insurance" },
    { data: "LT", label: "Litigation" },
    { data: "MA", label: "Medicare Part A" },
    { data: "MB", label: "Medicare Part B" }, // medicare default
    { data: "MC", label: "Medicaid" },
    { data: "MH", label: "Medigap Part A" },
    { data: "MI", label: "Medigap Part B" },
    { data: "MP", label: "Medicare Primary" },
    { data: "OT", label: "Other" },
    { data: "PE", label: "Property Insurance - Personal" },
    { data: "PL", label: "Personal" },
    { data: "PP", label: "Personal Payment (Cash - No Insurance)" },
    { data: "PR", label: "Preferred Provider Organization (PPO)" },
    { data: "PS", label: "Point of Service (POS)" },
    { data: "QM", label: "Qualified Medicare Beneficiary" },
    { data: "RP", label: "Property Insurance - Real" },
    { data: "SP", label: "Supplemental Policy" },
    { data: "TF", label: "Tax Equity Fiscal Responsibility Act (TEFRA)" },
    { data: "WC", label: "Workers Compensation" },
    { data: "WU", label: "Wrap Up Policy" },
];

export const PAYER_TYPES_SECONDARY = [
    {
        data: "12",
        label: "Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan",
    },
    {
        data: "13",
        label: "Medicare Secondary End-Stage Renal Disease Beneficiary in the Mandated Coordination Period with an Employer's Group Health Plan",
    },
    {
        data: "14",
        label: "Medicare Secondary, No-fault Insurance including Auto is Primary",
    },
    { data: "15", label: "Medicare Secondary Worker's Compensation" },
    {
        data: "16",
        label: "Medicare Secondary Public Health Service (PHS)or Other Federal Agency",
    },
    { data: "41", label: "Medicare Secondary Black Lung" },
    { data: "42", label: "Medicare Secondary Veteran's Administration" },
    {
        data: "43",
        label: "Medicare Secondary Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)",
    },
    {
        data: "47",
        label: "Medicare Secondary, Other Liability Insurance is Primary",
    },
];

export const CLAIM_TYPES = [
    { data: "09", label: "Self-pay" },
    { data: "11", label: "Other Non-Federal Programs" },
    { data: "12", label: "Preferred Provider Organization (PPO)" },
    { data: "14", label: "Exclusive Provider Organization (EPO)" },
    { data: "15", label: "Indemnity Insurance" },
    { data: "16", label: "Health Maintenance Organization (HMO) Medicare Risk" },
    { data: "17", label: "Dental Maintenance Organization" },
    { data: "AM", label: "Automobile Medical" },
    { data: "BL", label: "Blue Cross/Blue Shield" },
    { data: "CH", label: "CHAMPUS" },
    { data: "CI", label: "Commercial Insurance Co." },
    { data: "DS", label: "Disability" },
    { data: "FI", label: "Federal Employees Program" },
    { data: "HM", label: "Health Maintenance Organization (HMO)" },
    { data: "LM", label: "Liability Medical" },
    { data: "MA", label: "Medicare Part A" },
    { data: "MB", label: "Medicare Part B" }, // default
    { data: "MC", label: "Medicaid" },
    { data: "OF", label: "Other Federal Program" },
    { data: "TV", label: "Title V" },
    { data: "VA", label: "Veterans Affairs Plan" },
    { data: "WC", label: "Workers' Compensation Health Claim" },
    { data: "ZZ", label: "Mutually Defined" },
];

export const CLAIM_FREQUENCY_CODES = [
    { data: "0", label: "Non-payment/Zero Claim" },
    { data: "1", label: "Admit thru discharge claim" }, // default
    { data: "2", label: "Interim - first claim" },
    { data: "3", label: "Interim - continuing claim" },
    { data: "4", label: "Interim - last claim" },
    { data: "5", label: "Late charges only(Institutional providers only)" },
    { data: "6", label: "Corrected claim" },
    { data: "7", label: "Replacement of prior claim" },
    // { data: "8", label: "Void/Cancel prior claim" },
];

export const RESPONSIBILITIES = [
    { data: "guarantor", label: "Guarantor" },
    { data: "poa", label: "Power of Atterney" },
    { data: "employer", label: "Employer" },
];

export const RELATION_TO_SUBSCRIBER_CODES = [
    { data: "01", label: "Spouse" },
    { data: "04", label: "Grandfather or Grandmother" },
    { data: "05", label: "Grandson or Grandaughter" },
    { data: "18", label: "Self" },
    { data: "19", label: "Child" },
    { data: "20", label: "Employee" },
    { data: "21", label: "Unknown" },
    { data: "29", label: "Significant Other" },
    { data: "32", label: "Mother" },
    { data: "33", label: "Father" },
    { data: "39", label: "Organ Donor" },
    { data: "40", label: "Cadaver Donor" },
    { data: "53", label: "Life Partner" },
    { data: "G8", label: "Other Relationship" },
];

export const PROVIDER_TYPES = [
    { data: "BillingProvider", label: "Billing Provider (2010AA)" },
    { data: "ReferringProvider", label: "Referring Provider (2310A)" },
    { data: "RenderingProvider", label: "Rendering Provider (2310B)" },
    { data: "OrderingProvider", label: "Ordering Provider (2420E)" },
    { data: "SupervisingProvider", label: "Supervising Provider (2310D)" },
];

export const PAY_RESPONSIBILITY_LEVEL_CODES = [
    { data: "P", label: "Primary" },
    { data: "S", label: "Secondary" },
    { data: "T", label: "Tertiary" },
    { data: "A", label: "Payer Responsibility Four" },
    { data: "B", label: "Payer Responsibility Five" },
    { data: "C", label: "Payer Responsibility Six" },
    { data: "D", label: "Payer Responsibility Seven" },
    { data: "E", label: "Payer Responsibility Eight" },
    { data: "F", label: "Payer Responsibility Nine" },
    { data: "G", label: "Payer Responsibility Ten" },
    { data: "H", label: "Payer Responsibility Eleven" },
    { data: "U", label: "Unknown" },
];

export const CLAIM_FILING_CODES = [
    { data: "09", label: "Self-pay" },
    { data: "11", label: "Other Non-Federal Programs" },
    { data: "12", label: "Preferred Provider Organization (PPO)" },
    { data: "14", label: "Exclusive Provider Organization (EPO)" },
    { data: "15", label: "Indemnity Insurance" },
    { data: "16", label: "Health Maintenance Organization (HMO) Medicare Risk" },
    { data: "17", label: "Dental Maintenance Organization" },
    { data: "AM", label: "Automobile Medical" },
    { data: "BL", label: "Blue Cross/Blue Shield" },
    { data: "CH", label: "CHAMPUS" },
    { data: "CI", label: "Commercial Insurance Co." },
    { data: "DS", label: "Disability" },
    { data: "FI", label: "Federal Employees Program" },
    { data: "HM", label: "Health Maintenance Organization (HMO)" },
    { data: "LM", label: "Liability Medical" },
    { data: "MA", label: "Medicare Part A" },
    { data: "MB", label: "Medicare Part B" }, // default
    { data: "MC", label: "Medicaid" },
    { data: "OF", label: "Other Federal Program" },
    { data: "TV", label: "Title V" },
    { data: "VA", label: "Veterans Affairs Plan" },
    { data: "WC", label: "Workers' Compensation Health Claim" },
    { data: "ZZ", label: "Mutually Defined" },
];

// List of elements for form building
export const FORM_ELS = [
    { id: 0, title: "Text", icon: "short_text", data: "text", ord: 0 },
    { id: 10, title: "Number", icon: "functions", data: "number", ord: 10 },
    { id: 20, title: "Text Area", icon: "wrap_text", data: "textarea", ord: 20 },
    { id: 30, title: "Email", icon: "email", data: "email", ord: 30 },
    { id: 40, title: "Phone", icon: "phone", data: "phone", ord: 40 },
    { id: 45, title: "Address", icon: "place", data: "address", ord: 45 },
    { id: 50, title: "Date", icon: "today", data: "date", ord: 50 },
    { id: 60, title: "Time", icon: "schedule", data: "time", ord: 60 },
    { id: 70, title: "Date Time", icon: "event_note", data: "datetime", ord: 70 },
    { id: 80, title: "Choice", icon: "format_list_bulleted", data: "choice", ord: 80 },
    { id: 95, title: "Switch", icon: "add", data: "switch", ord: 95 },
    { id: 100, title: "Scale", icon: "linear_scale", data: "scale", ord: 100 },
    { id: 105, title: "Tag", icon: "label_outline", data: "tag", ord: 105 },
    { id: 110, title: "Lookup", icon: "storage", data: "data-combobox", ord: 110 },
    {
        id: 120,
        title: "File Upload",
        icon: "file_upload",
        data: "file-upload",
        ord: 120,
    },
    { id: 1000, title: "Label", icon: "title", data: "label", ord: 1000 },
    { id: 1010, title: "File", icon: "insert_drive_file", data: "file", ord: 1010 },
];

// paper template field data
export const PAPER_DATA_FIELDS = {
    general: {
        current_date: {
            category: "general",
            label: "Current Date",
            type: "text",
            field: "date",
            option: false,
        },
        current_date_time: {
            category: "general",
            label: "Current Date Time",
            type: "text",
            field: "datetime",
            option: false,
        },
        current_time: {
            category: "general",
            label: "Current Time",
            type: "text",
            field: "time",
            option: false,
        },
    },
    organization: {
        name: {
            category: "organization",
            label: "Organization Name",
            type: "text",
            field: "name",
            option: false,
        },
        address: { label: "Organization Address", type: "text", field: "address", option: false },
        billing_address: {
            label: "Organization Billing Address",
            type: "text",
            field: "billing_address",
            option: false,
        },
        phone: {
            category: "organization",
            label: "Organization Phone",
            type: "text",
            field: "phone",
            option: false,
        },
        support_phone: {
            category: "organization",
            label: "Organization Support Phone",
            type: "text",
            field: "support_phone",
            option: false,
        },
        billing_phone: {
            category: "organization",
            label: "Organization Billing Phone",
            type: "text",
            field: "billing_phone",
            option: false,
        },
        fax: {
            category: "organization",
            label: "Organization Fax",
            type: "text",
            field: "fax",
            option: false,
        },
        taxid: {
            category: "organization",
            label: "Organization Tax ID",
            type: "text",
            field: "taxid",
            option: false,
        },
        npi: {
            category: "organization",
            label: "Organization NPI",
            type: "text",
            field: "npi",
            option: false,
        },
    },
    provider: {
        name: {
            category: "provider",
            label: "Provider Name",
            type: "text",
            field: "name",
            option: false,
        },
        address: {
            category: "provider",
            label: "Provider Address",
            type: "text",
            field: "address",
            option: true,
        },
        phone: {
            category: "provider",
            label: "Provider Phone",
            type: "text",
            field: "phone",
            option: true,
        },
        email: {
            category: "provider",
            label: "Provider Email",
            type: "text",
            field: "email",
            option: false,
        },
        dob: {
            category: "provider",
            label: "Provider Date of Birth",
            type: "text",
            field: "dob",
            option: false,
        },
        npi: {
            category: "provider",
            label: "Provider NPI #",
            type: "text",
            field: "npi",
            option: false,
        },
        upin: {
            category: "provider",
            label: "Provider UPIN",
            type: "text",
            field: "upin",
            option: false,
        },
        md_license: {
            category: "provider",
            label: "Provider Medical License #",
            type: "text",
            field: "md_license",
            option: false,
        },
        dea_license: {
            category: "provider",
            label: "Provider DEA License #",
            type: "text",
            field: "dea_license",
            option: false,
        },
        medicaid_num: {
            category: "provider",
            label: "Provider Medicaid #",
            type: "text",
            field: "medicaid_num",
            option: false,
        },
        employee_id: {
            category: "provider",
            label: "Provider Employee ID",
            type: "text",
            field: "employee_id",
            option: false,
        },
        taxonomy: {
            category: "provider",
            label: "Provider Taxonomy",
            type: "text",
            field: "taxonomy",
            option: false,
        },
    },
    patient: {
        name: {
            category: "patient",
            label: "Patient Name",
            type: "text",
            field: "name",
            option: false,
        },
        address: {
            category: "patient",
            label: "Patient Address",
            type: "text",
            field: "address",
            option: true,
        },
        phone: {
            category: "patient",
            label: "Patient Phone",
            type: "text",
            field: "phone",
            option: true,
        },
        insurance: {
            category: "patient",
            label: "Patient Insurance",
            type: "text",
            field: "insurance",
            option: true,
        },
        patient_id: {
            category: "patient",
            label: "Patient ID",
            type: "text",
            field: "patient_id",
            option: false,
        },
        email: {
            category: "patient",
            label: "Patient Email",
            type: "text",
            field: "time",
            option: false,
        },
        dob: {
            category: "patient",
            label: "Patient Date of Birth",
            type: "text",
            field: "dob",
            option: false,
        },
    },
};

export const ORG_SEARCH_MODE = [
    { label: "Patients", data: "patients" },
    { label: "Encounters", data: "encounters" },
    { label: "Payers", data: "payers" },
];

export const PRIVACY_LEVEL_LIST = [
    { label: "Only Me", data: "private" },
    { label: "Organization Administrators", data: "orgAdmin" },
    { label: "Organization Members", data: "orgMember" },
    { label: "Entire Organization", data: "org" },
    { label: "Anyone", data: "public" },
];

export const MEMBER_STATUS_LIST = [
    { label: "No Access", data: "no_access" },
    { label: "Invited", data: "invited" },
    { label: "Active", data: "active" },
    { label: "Inactive", data: "inactive" },
    { label: "Declined", data: "declined" },
];

export const STATUS_LIST = [
    { label: "Active", data: "active" },
    { label: "Inactive", data: "inactive" },
];

export const AUTH_STATUS_LIST = [
    // { label: "Open", data: "open" },
    { label: "Requested", data: "requested" },
    // { label: "Received", data: "received" },
    { label: "Revalidate", data: "revalidate" },
    { label: "Completed", data: "completed" },
];

export const EVENT_STATUS_LIST = [
    { label: "Open", data: "open" },
    { label: "Completed", data: "completed" },
];

export const ENTITY_TYPES = [
    { label: "Person", data: "person" },
    { label: "Company", data: "company" },
];

export const RELATIONSHIPS = [
    { label: "Customer", data: "customer", entities: ["company", "person"] },
    // { label: "Employee", data: "employee", entities: ["person"] },
    // { label: "Patient", data: "patient", entities: ["person"] },
    { label: "Vendor", data: "vendor", entities: ["company", "person"] },
    { label: "Other", data: "other", entities: ["company", "person"] },
];

export const CONTACT_STATUS_LIST = [
    { label: "Active", data: "active" },
    { label: "Archived", data: "archived" },
];

export const ORG_TYPES = [
    { label: "Practice", data: "practice" },
    { label: "Surgery Center", data: "surgery-center" },
];

// medication measure unit codes
export const MED_UNITS = [
    { data: "mg", label: "Milligram" },
    { data: "mL", label: "Milliliter" },
    { data: "g", label: "Gram" },
    { data: "kg", label: "Kilogram" },
    { data: "mcg", label: "Microgram" },
    { data: "ng", label: "Nanogram" },
    { data: "L", label: "Liter" },
    { data: "EA", label: "Each" },
];

export const MED_ROUTE = [
    { label: "Topical", data: "TP" },
    { label: "By mouth", data: "PO" },
    { label: "Transdermal", data: "TD" },
    { label: "Inhaled", data: "INH" },
    { label: "Intramuscular", data: "IM" },
    { label: "Intravenous", data: "IV" },
    { label: "Intravenous piggyback", data: "IVPB" },
    { label: "Intravenous push", data: "IVP" },
    { label: "Intradermal", data: "ID" },
    { label: "intraosseous", data: "IO" },
    { label: "Sublingual", data: "SL" },
    { label: "Buccal", data: "BUCC" },
    { label: "Intraperitoneal", data: "IP" },
    { label: "Rectally", data: "PR" },
];

export const PILL_KIND = [
    { data: "tablet", label: "Tablet" },
    { data: "capsule", label: "Capsule" },
];

export const CLAIM_MED_UNITS = [
    { data: "F2", label: "International Unit" },
    { data: "GR", label: "Gram" },
    { data: "ME", label: "Milligram" },
    { data: "ML", label: "Milliliter" },
    { data: "UN", label: "Unit" },
];

export const PLACES_OF_SERVICE = [
    {
        data: "24",
        label: "Ambulatory Surgical Center",
        desc: "A freestanding facility, other than a physician's office, where surgical and diagnostic services are provided on an ambulatory basis.",
    }, // default surgery center
    {
        data: "11",
        label: "Office",
        desc: "Location, other than a hospital, skilled nursing facility (SNF), military treatment facility, community health center, State or local public health clinic, or intermediate care facility (ICF), where the health professional routinely provides health examinations, diagnosis, and treatment of illness or injury on an ambulatory basis.",
    }, //default
    {
        data: "02",
        label: "Telehealth",
        desc: "The location where health services and health related services are provided or received, through a telecommunication system.",
    },
    {
        data: "12",
        label: "Home",
        desc: "Location, other than a hospital or other facility, where the patient receives care in a private residence.",
    },
    {
        data: "19",
        label: "Off Campus-Outpatient Hospital",
        desc: "A portion of an off-campus hospital provider based department which provides diagnostic, therapeutic (both surgical and nonsurgical), and rehabilitation services to sick or injured persons who do not require hospitalization or institutionalization.",
    },
    {
        data: "01",
        label: "Pharmacy",
        desc: "A facility or location where drugs and other medically related items and services are sold, dispensed, or otherwise provided directly to patients.",
    },
    // {
    //     data: "05",
    //     label: "Indian Health Service Free-standing Facility",
    //     desc:
    //         "A facility or location, owned and operated by the Indian Health Service, which provides diagnostic, therapeutic (surgical and non-surgical), and rehabilitation services to American Indians and Alaska Natives who do not require hospitalization.",
    // },
    // {
    //     data: "06",
    //     label: "Indian Health Service Provider-based Facility",
    //     desc:
    //         "A facility or location, owned and operated by the Indian Health Service, which provides diagnostic, therapeutic (surgical and non-surgical), and rehabilitation services rendered by, or under the supervision of, physicians to American Indians and Alaska Natives admitted as inpatients or outpatients.",
    // },
    // {
    //     data: "07",
    //     label: "Tribal 638 Free-standing Facility",
    //     desc:
    //         "A facility or location owned and operated by a federally recognized American Indian or Alaska Native tribe or tribal organization under a 638 agreement, which provides diagnostic, therapeutic (surgical and non-surgical), and rehabilitation services to tribal members who do not require hospitalization.",
    // },
    // {
    //     data: "08",
    //     label: "Tribal 638 Provider-based Facility",
    //     desc:
    //         "A facility or location owned and operated by a federally recognized American Indian or Alaska Native tribe or tribal organization under a 638 agreement, which provides diagnostic, therapeutic (surgical and non-surgical), and rehabilitation services to tribal members admitted as inpatients or outpatients.",
    // },

    {
        data: "13",
        label: "Assisted Living Facility",
        desc: "Congregate residential facility with self-contained living units providing assessment of each resident's needs and on-site support 24 hours a day, 7 days a week, with the capacity to deliver or arrange for services including some health care and other services.",
    },
    {
        data: "14",
        label: "Group Home ",
        desc: "A residence, with shared living areas, where clients receive supervision and other services such as social and/or behavioral services, custodial service, and minimal services (e.g., medication administration).",
    },
    {
        data: "15",
        label: "Mobile Unit",
        desc: "A facility/unit that moves from place-to-place equipped to provide preventive, screening, diagnostic, and/or treatment services.",
    },
    {
        data: "16",
        label: "Temporary Lodging",
        desc: "A short term accommodation such as a hotel, camp ground, hostel, cruise ship or resort where the patient receives care, and which is not identified by any other POS code.",
    },
    {
        data: "17",
        label: "Walk-in Retail Health Clinic",
        desc: "A walk-in health clinic, other than an office, urgent care facility, pharmacy or independent clinic and not described by any other Place of Service code, that is located within a retail operation and provides, on an ambulatory basis, preventive and primary care services.",
    },
    {
        data: "18",
        label: "Place of Employment-Worksite",
        desc: "A location, not described by any other POS code, owned or operated by a public or private entity where the patient is employed, and where a health professional provides on-going or episodic occupational medical, therapeutic or rehabilitative services to the individual.",
    },
    {
        data: "20",
        label: "Urgent Care Facility",
        desc: "Location, distinct from a hospital emergency room, an office, or a clinic, whose purpose is to diagnose and treat illness or injury for unscheduled, ambulatory patients seeking immediate medical attention.",
    },
    {
        data: "21",
        label: "Inpatient Hospital",
        desc: "A facility, other than psychiatric, which primarily provides diagnostic, therapeutic (both surgical and nonsurgical), and rehabilitation services by, or under, the supervision of physicians to patients admitted for a variety of medical conditions.",
    },
    {
        data: "22",
        label: "On Campus-Outpatient Hospital",
        desc: "A portion of a hospital’s main campus which provides diagnostic, therapeutic (both surgical and nonsurgical), and rehabilitation services to sick or injured persons who do not require hospitalization or institutionalization.",
    },
    {
        data: "23",
        label: "Emergency Room – Hospital",
        desc: "A portion of a hospital where emergency diagnosis and treatment of illness or injury is provided.",
    },
    {
        data: "25",
        label: "Birthing Center",
        desc: "A facility, other than a hospital's maternity facilities or a physician's office, which provides a setting for labor, delivery, and immediate post-partum care as well as immediate care of new born infants.",
    },
    {
        data: "26",
        label: "Military Treatment Facility",
        desc: "A medical facility operated by one or more of the Uniformed Services. Military Treatment Facility (MTF) also refers to certain former U.S. Public Health Service (USPHS) facilities now designated as Uniformed Service Treatment Facilities (USTF).",
    },
    {
        data: "31",
        label: "Skilled Nursing Facility",
        desc: "A facility which primarily provides inpatient skilled nursing care and related services to patients who require medical, nursing, or rehabilitative services but does not provide the level of care or treatment available in a hospital.",
    },
    {
        data: "32",
        label: "Nursing Facility",
        desc: "A facility which primarily provides to residents skilled nursing care and related services for the rehabilitation of injured, disabled, or sick persons, or, on a regular basis, health-related care services above the level of custodial care to other than individuals with intellectual disabilities.",
    },
    {
        data: "33",
        label: "Custodial Care Facility",
        desc: "A facility which provides room, board and other personal assistance services, generally on a long-term basis, and which does not include a medical component.",
    },
    {
        data: "34",
        label: "Hospice",
        desc: "A facility, other than a patient's home, in which palliative and supportive care for terminally ill patients and their families are provided.",
    },
    {
        data: "41",
        label: "Ambulance - Land",
        desc: "A land vehicle specifically designed, equipped and staffed for lifesaving and transporting the sick or injured.",
    },
    {
        data: "42",
        label: "Ambulance – Air or Water",
        desc: "An air or water vehicle specifically designed, equipped and staffed for lifesaving and transporting the sick or injured.",
    },
    {
        data: "49",
        label: "Independent Clinic",
        desc: "A location, not part of a hospital and not described by any other Place of Service code, that is organized and operated to provide preventive, diagnostic, therapeutic, rehabilitative, or palliative services to outpatients only.",
    },
    {
        data: "50",
        label: "Federally Qualified Health Center",
        desc: "A facility located in a medically underserved area that provides Medicare beneficiaries preventive primary medical care under the general direction of a physician.",
    },
    {
        data: "51",
        label: "Inpatient Psychiatric Facility",
        desc: "A facility that provides inpatient psychiatric services for the diagnosis and treatment of mental illness on a 24-hour basis, by or under the supervision of a physician.",
    },
    {
        data: "52",
        label: "Psychiatric Facility-Partial Hospitalization",
        desc: "A facility for the diagnosis and treatment of mental illness that provides a planned therapeutic program for patients who do not require full time hospitalization, but who need broader programs than are possible from outpatient visits to a hospital-based or hospital-affiliated facility.",
    },
    {
        data: "53",
        label: "Community Mental Health Center",
        desc: "A facility that provides the following services: outpatient services, including specialized outpatient services for children, the elderly, individuals who are chronically ill, and residents of the CMHC's mental health services area who have been discharged from inpatient treatment at a mental health facility; 24 hour a day emergency care services; day treatment, other partial hospitalization services, or psychosocial rehabilitation services; screening for patients being considered for admission to State mental health facilities to determine the appropriateness of such admission; and consultation and education services.",
    },
    {
        data: "54",
        label: "Intermediate Care Facility/ Individuals with Intellectual Disabilities",
        desc: "A facility which primarily provides health-related care and services above the level of custodial care to individuals but does not provide the level of care or treatment available in a hospital or SNF.",
    },
    {
        data: "55",
        label: "Residential Substance Abuse Treatment Facility",
        desc: "A facility which provides treatment for substance (alcohol and drug) abuse to live-in residents who do not require acute medical care. Services include individual and group therapy and counseling, family counseling, laboratory tests, drugs and supplies, psychological testing, and room and board.",
    },
    {
        data: "09",
        label: "09	Prison/Correctional Facility",
        desc: "A prison, jail, reformatory, work farm, detention center, or any other similar facility maintained by either Federal, State or local authorities for the purpose of confinement or rehabilitation of adult or juvenile criminal offenders.",
    },
    {
        data: "56",
        label: "Psychiatric Residential Treatment Center",
        desc: "A facility or distinct part of a facility for psychiatric care which provides a total 24-hour therapeutically planned and professionally staffed group living and learning environment.",
    },
    {
        data: "57",
        label: "Non-residential Substance Abuse Treatment Facility",
        desc: "A location which provides treatment for substance (alcohol and drug) abuse on an ambulatory basis.  Services include individual and group therapy and counseling, family counseling, laboratory tests, drugs and supplies, and psychological testing. ",
    },
    {
        data: "58",
        label: "Non-residential Opioid Treatment Facility",
        desc: "A location that provides treatment for opioid use disorder on an ambulatory basis. Services include methadone and other forms of Medication Assisted Treatment (MAT).",
    },
    {
        data: "60",
        label: "Mass Immunization Center",
        desc: "A location where providers administer pneumococcal pneumonia and influenza virus vaccinations and submit these services as electronic media claims, paper claims, or using the roster billing method. This generally takes place in a mass immunization setting, such as, a public health center, pharmacy, or mall but may include a physician office setting.",
    },
    {
        data: "61",
        label: "Comprehensive Inpatient Rehabilitation Facility",
        desc: "A facility that provides comprehensive rehabilitation services under the supervision of a physician to inpatients with physical disabilities. Services include physical therapy, occupational therapy, speech pathology, social or psychological services, and orthotics and prosthetics services.",
    },
    {
        data: "62",
        label: "Comprehensive Outpatient Rehabilitation Facility",
        desc: "A facility that provides comprehensive rehabilitation services under the supervision of a physician to outpatients with physical disabilities. Services include physical therapy, occupational therapy, and speech pathology services.",
    },
    {
        data: "65",
        label: "End-Stage Renal Disease Treatment Facility",
        desc: "A facility other than a hospital, which provides dialysis treatment, maintenance, and/or training to patients or caregivers on an ambulatory or home-care basis.",
    },
    {
        data: "71",
        label: "Public Health Clinic",
        desc: "A facility maintained by either State or local health departments that provides ambulatory primary medical care under the general direction of a physician.",
    },
    {
        data: "72",
        label: "Rural Health Clinic",
        desc: "A certified facility which is located in a rural medically underserved area that provides ambulatory primary medical care under the general direction of a physician.",
    },
    {
        data: "81",
        label: "Independent Laboratory",
        desc: "A laboratory certified to perform diagnostic and/or clinical tests independent of an institution or a physician's office.",
    },
    { data: "03", label: "School", desc: "A facility whose primary purpose is education." },
    {
        data: "04",
        label: "Homeless Shelter",
        desc: "A facility or location whose primary purpose is to provide temporary housing to homeless individuals (e.g., emergency shelters, individual or family shelters).",
    },
    {
        data: "99",
        label: "Other Place of Service",
        desc: "Other place of service not identified above.",
    },
];

export const CPT_MODIFIERS = [
    { data: "22", label: "Increased Procedural Services (surgical/procedures codes only)" },
    {
        data: "24",
        label: "Unrelated evaluation and management service by the same physician during a postoperative period",
    },
    {
        data: "25",
        label: "Significant, separately identifiable evaluation and management service by the same physician on the same day of the procedure or other service",
    },
    { data: "26", label: "Professional Component Only (separate from technical component)" },
    {
        data: "27",
        label: "Multiple E/M services performed by the same or different physicians in multiple outpatient hospital settings (e.g., emergency department, clinic, etc.)",
    },
    { data: "50", label: "Bilateral Procedure" },
    { data: "51", label: "Multiple procedures" },
    { data: "52", label: "Partially Reduced/Eliminated Services" },
    { data: "53", label: "Discontinued Procedure (professional services only)" },
    { data: "54", label: "Surgical Care Only" },
    { data: "55", label: "Postoperative Management Only" },
    {
        data: "57",
        label: "An evaluation and management (E/M) service that resulted in the initial decision to perform the surgery may be identified by adding modifier 57 to the appropriate level of E/M service.",
    },
    {
        data: "58",
        label: "Staged or Related Procedure or Service During Postoperative Period by Same Physician",
    },
    { data: "59", label: "Distinct Procedural Service" },
    { data: "62", label: "Co-Surgeons" },
    { data: "66", label: "Team Surgeons – Surgical Team" },
    {
        data: "73",
        label: "Prior Discontinued Ambulatory Surgical Center (ASC) or Outpatient Hospital",
    },
    {
        data: "74",
        label: "After Anesthesia Administration - Discontinued Ambulatory Surgical Center (ASC) or Outpatient Hospital",
    },
    { data: "76", label: "Repeat procedure by same physician" },
    { data: "77", label: "Repeat procedure by another physician" },
    { data: "78", label: "Return to Operating Room for related surgery during post op period" },
    {
        data: "79",
        label: "Unrelated procedure or service by same physician during postoperative period",
    },
    { data: "90", label: "Reference (Outside) Laboratory" },
    { data: "91", label: "Repeat Clinical Diagnostic Lab Test" },
    { data: "99", label: "Multiple Modifiers (same line, same code)" },
    { data: "AI", label: "Principal Physician of Record" },
    { data: "AY", label: "Item or service furnished to ESRD patient - not for ESRD treatment" },
    { data: "CR", label: "Catastrophe/Disaster" },
    {
        data: "CT",
        label: "Computed tomography services furnished using equipment that does not meet each of the attributes of the National Electrical Manufacturers Association (NEMA) XR-29-2013 standard",
    },
    { data: "FX", label: "X-ray taken using film" },
    { data: "FY", label: "X-ray taken using computed radiography" },
    {
        data: "GC",
        label: "Service has been performed in part by a resident under the direction of a teaching physician",
    },
    {
        data: "GJ",
        label: "Opt-out physicians billing on an emergency basis for non-contracted patients",
    },
    {
        data: "G0",
        label: "Used to identify telehealth services furnished for purposes of diagnosis, evaluation, or treatment of symptoms of an acute stroke",
    },
    {
        data: "GV",
        label: "Attending physician is not employed or paid under agreement by the patient Hospice provider",
    },
    { data: "GW", label: "Condition not related to the patient's terminal condition" },
    { data: "JW", label: "Drug amount discarded/not administered to any patient" },
    {
        data: "Q1",
        label: "Routine clinical service provided in a clinical research study that is in an approved clinical research study",
    },
    {
        data: "Q0",
        label: "Investigational clinical service provided in a clinical research study that is in an approved clinical research study",
    },
    {
        data: "GA",
        label: "Waiver of Liability Statement Issued as Required by Payer Policy. Used to report a required ABN was issued for a service and is on file. A copy of ABN does not have to be submitted by must be made available upon request",
    },
    {
        data: "GX",
        label: "Notice of Liability Issued, Voluntary Under Payer Policy. Used to report a voluntary ABN was issued for a service",
    },
    {
        data: "GY",
        label: "Notice of Liability Not Issued, Not Required Under Payer Policy. Used to report that an ABN was not issued because item or service is statutorily excluded or does not meet definition of any Medicare benefit",
    },
    {
        data: "GZ",
        label: "Item or Service Expected to Be Denied as Not Reasonable and Necessary. Used to report an ABN was not issued for a service",
    },
    { data: "LT", label: "Left side of body" },
    { data: "RT", label: "Right side of body" },
    { data: "E1", label: "Upper left, eyelid" },
    { data: "E2", label: "Lower left, eyelid" },
    { data: "E3", label: "Upper right, eyelid" },
    { data: "E4", label: "Lower right, eyelid" },
    { data: "AA", label: "Anesthesia services performed personally by an anesthesiologist" },
    {
        data: "AD",
        label: "Medical supervision by a physician; more than four concurrent anesthesia procedures",
    },
    {
        data: "G8",
        label: "Monitored anesthesia care (MAC) for deep complex, complicated or markedly invasive surgical procedure",
    },
    {
        data: "G9",
        label: "Monitored anesthesia care (MAC) for a patient who has a history of severe cardiopulmonary condition",
    },
    {
        data: "QK",
        label: "Medical direction of two, three or four concurrent anesthesia procedures involving qualified individuals",
    },
    { data: "QS", label: "Monitored anesthesia care service" },
    { data: "QX", label: "CRNA service; with medical direction by a physician" },
    {
        data: "QY",
        label: "Medical direction of one certified registered nurse anesthetist (CRNA) by an anesthesiologist",
    },
    { data: "QZ", label: "CRNA service; without medical direction by a physician" },
    {
        data: "23",
        label: "Unusual anesthesia - Used to report a procedure which usually requires either no anesthesia or local anesthesia; however, because of unusual circumstances must be done under general anesthesia. Coverage/payment will be determined on a 'by-report' basis.",
    },
    {
        data: "47",
        label: "Anesthesia by surgeon – Used to report regional or general anesthesia provided by the surgeon (Not covered by Medicare).",
    },
    { data: "80", label: "Assistant surgeon" },
    { data: "81", label: "Minimum assistant surgeon" },
    { data: "82", label: "Assistant surgeon – when qualified resident surgeon not available" },
    {
        data: "AS",
        label: "Physician Assistant (PA), Nurse Practitioner (NP) or Clinical Nurse Specialist (CNS) assistant at surgery services",
    },
];

export const PAYER_ACTIONS = {
    270: "Eligibility",
    276: "Claim Status",
    837: "Claims",
};

// export const MEDICATION_UM = [
//     { label: "%" },
//     { label: "AU" },
//     { label: "AU/mL" },
//     { label: "bar" },
//     { label: "BAU" },
//     { label: "BAU/mL" },
//     { label: "bead" },
//     { label: "BU" },
//     { label: "capsule" },
//     { label: "CCID 50" },
//     { label: "cellular sheet" },
//     { label: "Ci" },
//     { label: "cloth" },
//     { label: "cm^2" },
//     { label: "D'ag'U" },
//     { label: "disc" },
//     { label: "dL" },
//     { label: "drop" },
//     { label: "FFU" },
//     { label: "g" },
//     { label: "globule" },
//     { label: "granule" },
//     { label: "gum" },
//     { label: "hp C" },
//     { label: "hp M" },
//     { label: "hp Q" },
//     { label: "hp X" },
//     { label: "IU" },
//     { label: "IU/L" },
//     { label: "IU/mL" },
//     { label: "kp C" },
//     { label: "L" },
//     { label: "Lf" },
//     { label: "LfU/mL" },
//     { label: "lozenge" },
//     { label: "mCi" },
//     { label: "mCi/mL" },
//     { label: "mEq" },
//     { label: "mg" },
//     { label: "mg/actuat" },
//     { label: "mg/hr" },
//     { label: "mg/mg" },
//     { label: "mg/mL" },
//     { label: "mL" },
//     { label: "mmol" },
//     { label: "mol" },
//     { label: "mU" },
//     { label: "ng" },
//     { label: "nmol" },
//     { label: "organisms" },
//     { label: "pastille" },
//     { label: "patch" },
//     { label: "pellet" },
//     { label: "PFU" },
//     { label: "pill" },
//     { label: "PNU" },
//     { label: "PNU/mL" },
//     { label: "pouch" },
//     { label: "puff" },
//     { label: "ring" },
//     { label: "salve" },
//     { label: "stick" },
//     { label: "strip" },
//     { label: "suppository" },
//     { label: "swab" },
//     { label: "tablet" },
//     { label: "tampon" },
//     { label: "tape" },
//     { label: "tbsp" },
//     { label: "TCID 50" },
//     { label: "tsp" },
//     { label: "U" },
//     { label: "uCi" },
//     { label: "ug" },
//     { label: "ug/mL" },
//     { label: "uL" },
//     { label: "umol" },
//     { label: "unt" },
//     { label: "unt/mL" },
//     { label: "USP'U" },
//     { label: "vial" },
//     { label: "wafer" },
//     { label: "X" }
// ];

export const TRANSACTION_TYPES = [
    { label: "EOB", data: "eob", is_debit: false },
    { label: "Copay", data: "co-payment", is_debit: false },
    { label: "Payment", data: "payment", is_debit: false },
    { label: "Deposit", data: "deposit", is_debit: false },
    { label: "Credit", data: "credit", is_debit: false },
    { label: "Discount", data: "discount", is_debit: false },
    { label: "Write-off", data: "write-off", is_debit: false },
    { label: "Returned Payment", data: "returned-payment", is_debit: true },
    { label: "Refund", data: "refund", is_debit: true },
    { label: "Misc. Charge", data: "misc-charge", is_debit: true },
    { label: "Starting Balance", data: "starting-balance", is_debit: true },
];

export const PAY_STATUSES = [
    { label: "Open", data: "open" },
    { label: "Pending", data: "pending" },
    { label: "Submitted", data: "submitted" },
    { label: "Rejected", data: "rejected" },
    { label: "Completed", data: "completed" },
    { label: "Denied", data: "denied" },
    { label: "Voided", data: "voided" },
    { label: "Archived", data: "archived" },
];

export const PAYMENT_ACTIONS = [
    { label: "Mail to payer", data: "mail_to_payer" },
    { label: "Mailed to payer", data: "mailed_to_payer" },
    { label: "Fix", data: "fix" },
    { label: "Resubmit", data: "resubmit" },
    { label: "Appeal", data: "appeal" },
    { label: "Bill to patient", data: "bill_to_patient" },
    { label: "Write-off", data: "write-off" },
    { label: "Complete", data: "complete" },
    { label: "Void", data: "void" },
];

export const CLAIM_STATUS_LIST = [
    {
        pay_status: "submitted",
        actions: null,
        data: "submitting",
        dscr: "Claim is in the process of being submitted to payer.",
    },
    {
        pay_status: "submitted",
        actions: null,
        data: "waiting",
        dscr: "Claim is awaiting response from payer.",
    },
    {
        pay_status: "submitted",
        actions: null,
        data: "acknowledged",
        dscr: "Payer has acknowledged receipt of claim.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "paid",
        dscr: "Claim has been at least partially paid by payer.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "paid_in_full",
        dscr: "Total amount of claim has been paid by the payer",
    },
    {
        pay_status: "submitted",
        actions: ["appeal", "complete"],
        data: "adjudicated",
        dscr: "Payer has indicated that claim has been adjudicated, but the payment amount is $0.",
    },
    {
        pay_status: "denied",
        actions: ["appeal", "bill_to_patient", "write-off"],
        data: "denied",
        dscr: "Payer has indicated that the claim has been denied.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "paid_forwarded",
        dscr: "Claim has been at least partially paid by payer and forwarded to another entity.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "paid_in_full_forwarded",
        dscr: "Total amount of claim has been paid by payer and forwarded to another entity.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "adjudicated_forwarded",
        dscr: "Payer has indicated that claim has been adjudicated, but the payment amount is $0 and the claims has been forwarded to another entity.",
    },
    {
        pay_status: "denied",
        actions: ["appeal", "bill_to_patient", "write-off"],
        data: "reversal_of_previous",
        dscr: "Previous claim has been reversed.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "claim_forwarded",
        dscr: "The patient/subscriber is unknown and the claim is not adjudicated, but other payers are known and claim has been forwarded to them.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "predetermination_pricing",
        dscr: "ERA was only sent for predetermination pricing purposes, and no payment is forthcoming.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "acknowledged_forwarded",
        dscr: "Claim has been acknowledged and forwarded to another entity.",
    },
    {
        pay_status: "submitted",
        actions: null,
        data: "accepted_for_adjudication",
        dscr: "Claim has been accepted into adjudication system.",
    },
    {
        pay_status: "rejected",
        actions: ["appeal", "bill_to_patient", "write-off"],
        data: "rejected",
        dscr: "Claim has been rejected.",
    },
    {
        pay_status: "rejected",
        actions: ["appeal", "bill_to_patient", "write-off"],
        data: "rejected_reviewed",
        dscr: "Claims management app users have the ability to manually update the status of rejected claims in order to indicate that the claims have been reviewed and that no further action is required. This status was formerly used for all claims submitters, but now “rejected” is their terminal status.",
    },
    {
        pay_status: "rejected",
        actions: ["void"],
        data: "claim_not_found",
        dscr: "Claim cannot be found in payer’s adjudication system.",
    },
    {
        pay_status: "submitted",
        actions: null,
        data: "acknowledged_split",
        dscr: "Claim has been split upon acceptance into adjudication system.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "claim_pended",
        dscr: "No remittance has been issued, or only part of the claim has been paid.",
    },
    {
        pay_status: "submitted",
        actions: null,
        data: "pending_adjudication",
        dscr: "Claim is in payer’s system and is pending adjudication.",
    },
    {
        pay_status: "pending",
        actions: ["fix"],
        data: "pending_additional_information",
        dscr: "Claim is waiting for additional information from submitter.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "finalized",
        dscr: "The claim cycle has been completed and no additional action will be taken.",
    },
    {
        pay_status: "submitted",
        actions: null,
        data: "finalized_revised",
        dscr: "Adjudication info has been changed.",
    },
    {
        pay_status: "submitted",
        actions: ["complete"],
        data: "finalized_forwarded",
        dscr: "Claim processing is complete. Claim has been forwarded to a different entity.",
    },
    {
        pay_status: "submitted",
        actions: ["complete", "appeal", "bill_to_patient", "write-off"],
        data: "adjudicated_not_paid",
        dscr: "No payment is forthcoming.",
    },
    {
        pay_status: "pending",
        actions: ["fix"],
        data: "additional_information_requested",
        dscr: "Additional information has been requested by the payer.",
    },
    {
        pay_status: "pending",
        actions: ["resubmit"],
        data: "processing_error",
        dscr: "Error in the payer’s system.",
    },
    {
        actions: null,
        data: "response_received",
        dscr: "Default tracking description once response has been received for a claim, check result for more detailed information.",
    },
];

// Revenue code > LOOP 2300 > SV2
export const PROCEDURE_REVENUE_CODES = [
    {
        data: "0022",
        category: "Health Insurance Prospective Payment System (HIPPS)",
        label: "Skilled Nursing Facility (SNF) PPS",
    },
    {
        data: "0023",
        category: "Health Insurance Prospective Payment System (HIPPS)",
        label: "Home Health PPS",
    },
    {
        data: "0024",
        category: "Health Insurance Prospective Payment System (HIPPS)",
        label: "Inpatient Rehabilitation Facility (IRF) PPS",
    },
    {
        data: "0100",
        category: "All-inclusive Rate",
        label: "All inclusive room and board plus ancillary",
    },
    { data: "0101", category: "All-inclusive Rate", label: "All inclusive room and board" },
    { data: "0110", category: "Room and Board Private (one bed)", label: "General" },
    { data: "0111", category: "Room and Board Private (one bed)", label: "Medical/Surgical/GYN" },
    { data: "0112", category: "Room and Board Private (one bed)", label: "OB" },
    { data: "0113", category: "Room and Board Private (one bed)", label: "Pediatric" },
    { data: "0114", category: "Room and Board Private (one bed)", label: "Psychiatric" },
    { data: "0115", category: "Room and Board Private (one bed)", label: "Hospice" },
    { data: "0116", category: "Room and Board Private (one bed)", label: "Detoxification" },
    { data: "0117", category: "Room and Board Private (one bed)", label: "Oncology" },
    { data: "0118", category: "Room and Board Private (one bed)", label: "Rehabilitation" },
    { data: "0119", category: "Room and Board Private (one bed)", label: "Other" },
    { data: "0120", category: "Room and Board Semiprivate (two beds)", label: "General" },
    {
        data: "0121",
        category: "Room and Board Semiprivate (two beds)",
        label: "Medical/Surgical/GYN",
    },
    { data: "0122", category: "Room and Board Semiprivate (two beds)", label: "OB" },
    { data: "0123", category: "Room and Board Semiprivate (two beds)", label: "Pediatric" },
    { data: "0124", category: "Room and Board Semiprivate (two beds)", label: "Psychiatric" },
    { data: "0125", category: "Room and Board Semiprivate (two beds)", label: "Hospice" },
    { data: "0126", category: "Room and Board Semiprivate (two beds)", label: "Detoxification" },
    { data: "0127", category: "Room and Board Semiprivate (two beds)", label: "Oncology" },
    { data: "0128", category: "Room and Board Semiprivate (two beds)", label: "Rehabilitation" },
    { data: "0129", category: "Room and Board Semiprivate (two beds)", label: "Other" },
    { data: "0130", category: "Room and Board (3 and 4 beds)", label: "General" },
    { data: "0131", category: "Room and Board (3 and 4 beds)", label: "Medical/Surgical/GYN" },
    { data: "0132", category: "Room and Board (3 and 4 beds)", label: "OB" },
    { data: "0133", category: "Room and Board (3 and 4 beds)", label: "Pediatric" },
    { data: "0134", category: "Room and Board (3 and 4 beds)", label: "Psychiatric" },
    { data: "0135", category: "Room and Board (3 and 4 beds)", label: "Hospice" },
    { data: "0136", category: "Room and Board (3 and 4 beds)", label: "Detoxification" },
    { data: "0137", category: "Room and Board (3 and 4 beds)", label: "Oncology" },
    { data: "0138", category: "Room and Board (3 and 4 beds)", label: "Rehabilitation" },
    { data: "0139", category: "Room and Board (3 and 4 beds)", label: "Other" },
    { data: "0140", category: "Room and Board Deluxe Private", label: "General" },
    { data: "0141", category: "Room and Board Deluxe Private", label: "Medical/Surgical/GYN" },
    { data: "0142", category: "Room and Board Deluxe Private", label: "OB" },
    { data: "0143", category: "Room and Board Deluxe Private", label: "Pediatric" },
    { data: "0144", category: "Room and Board Deluxe Private", label: "Psychiatric" },
    { data: "0145", category: "Room and Board Deluxe Private", label: "Hospice" },
    { data: "0146", category: "Room and Board Deluxe Private", label: "Detoxification" },
    { data: "0147", category: "Room and Board Deluxe Private", label: "Oncology" },
    { data: "0148", category: "Room and Board Deluxe Private", label: "Rehabilitation" },
    { data: "0149", category: "Room and Board Deluxe Private", label: "Other" },
    { data: "0150", category: "Room and Board Ward", label: "General" },
    { data: "0151", category: "Room and Board Ward", label: "Medical/Surgical/GYN" },
    { data: "0152", category: "Room and Board Ward", label: "OB" },
    { data: "0153", category: "Room and Board Ward", label: "Pediatric" },
    { data: "0154", category: "Room and Board Ward", label: "Psychiatric" },
    { data: "0155", category: "Room and Board Ward", label: "Hospice" },
    { data: "0156", category: "Room and Board Ward", label: "Detoxification" },
    { data: "0157", category: "Room and Board Ward", label: "Oncology" },
    { data: "0158", category: "Room and Board Ward", label: "Rehabilitation" },
    { data: "0159", category: "Room and Board Ward", label: "Other" },
    { data: "0160", category: "Other Room and Board", label: "General" },
    { data: "0164", category: "Other Room and Board", label: "Sterile" },
    { data: "0167", category: "Other Room and Board", label: "Self-care" },
    { data: "0169", category: "Other Room and Board", label: "Other" },
    { data: "0170", category: "Nursery", label: "General" },
    { data: "0171", category: "Nursery", label: "Newborn Level I" },
    { data: "0172", category: "Nursery", label: "Newborn Level II" },
    { data: "0173", category: "Nursery", label: "Newborn Level III" },
    { data: "0174", category: "Nursery", label: "Newborn Level IV" },
    { data: "0179", category: "Nursery", label: "Other" },
    { data: "0180", category: "Leave of Absence", label: "General" },
    {
        data: "0182",
        category: "Leave of Absence",
        label: "Patience convenience - charges billable",
    },
    { data: "0183", category: "Leave of Absence", label: "Therapeutic leave" },
    { data: "0185", category: "Leave of Absence", label: "Nursing home (for hospitalization)" },
    { data: "0189", category: "Leave of Absence", label: "Other" },
    { data: "0190", category: "Subacute Care", label: "General" },
    { data: "0191", category: "Subacute Care", label: "Level I" },
    { data: "0192", category: "Subacute Care", label: "Level II" },
    { data: "0193", category: "Subacute Care", label: "Level III" },
    { data: "0194", category: "Subacute Care", label: "Level IV" },
    { data: "0199", category: "Subacute Care", label: "Other" },
    { data: "0200", category: "Intensive Care Unit", label: "General" },
    { data: "0201", category: "Intensive Care Unit", label: "Surgical" },
    { data: "0202", category: "Intensive Care Unit", label: "Medical" },
    { data: "0203", category: "Intensive Care Unit", label: "Pediatric" },
    { data: "0204", category: "Intensive Care Unit", label: "Psychiatric" },
    { data: "0206", category: "Intensive Care Unit", label: "Intermediate ICU" },
    { data: "0207", category: "Intensive Care Unit", label: "Burn Care" },
    { data: "0208", category: "Intensive Care Unit", label: "Trauma" },
    { data: "0209", category: "Intensive Care Unit", label: "Other" },
    { data: "0210", category: "Coronary Care Unit", label: "General" },
    { data: "0211", category: "Coronary Care Unit", label: "Myocardial Infarction" },
    { data: "0212", category: "Coronary Care Unit", label: "Pulmonary Care" },
    { data: "0213", category: "Coronary Care Unit", label: "Heart Transplant" },
    { data: "0214", category: "Coronary Care Unit", label: "Intermediate CCU" },
    { data: "0219", category: "Coronary Care Unit", label: "Other" },
    { data: "0220", category: "Special Charges", label: "General" },
    { data: "0221", category: "Special Charges", label: "Admission Charge" },
    { data: "0222", category: "Special Charges", label: "Technical Support Charge" },
    { data: "0223", category: "Special Charges", label: "UR Service Charge" },
    { data: "0224", category: "Special Charges", label: "Late Discharge - Medically Necessary" },
    { data: "0229", category: "Special Charges", label: "Other" },
    { data: "0230", category: "Incremental Nursing Charge", label: "General" },
    { data: "0231", category: "Incremental Nursing Charge", label: "Nursery" },
    { data: "0232", category: "Incremental Nursing Charge", label: "OB" },
    { data: "0233", category: "Incremental Nursing Charge", label: "ICU" },
    { data: "0234", category: "Incremental Nursing Charge", label: "CCU" },
    { data: "0235", category: "Incremental Nursing Charge", label: "Hospice" },
    { data: "0239", category: "Incremental Nursing Charge", label: "Other" },
    { data: "0240", category: "All-inclusive Ancillary", label: "General" },
    { data: "0241", category: "All-inclusive Ancillary", label: "Basic" },
    { data: "0242", category: "All-inclusive Ancillary", label: "Comprehensive" },
    { data: "0243", category: "All-inclusive Ancillary", label: "Specialty" },
    { data: "0249", category: "All-inclusive Ancillary", label: "Other" },
    { data: "0250", category: "Pharmacy", label: "General" },
    { data: "0251", category: "Pharmacy", label: "Generic drugs" },
    { data: "0252", category: "Pharmacy", label: "Nongeneric drugs" },
    { data: "0253", category: "Pharmacy", label: "Take-home drugs" },
    { data: "0254", category: "Pharmacy", label: "Drugs incident to Other diagnostic services" },
    { data: "0255", category: "Pharmacy", label: "Drugs incident to radiology" },
    { data: "0256", category: "Pharmacy", label: "Experimental drugs" },
    { data: "0257", category: "Pharmacy", label: "Nonprescription" },
    { data: "0258", category: "Pharmacy", label: "IV solutions" },
    { data: "0259", category: "Pharmacy", label: "Other" },
    { data: "0260", category: "IV Therapy", label: "General" },
    { data: "0261", category: "IV Therapy", label: "Infusion pump" },
    { data: "0262", category: "IV Therapy", label: "Pharmacy services" },
    { data: "0263", category: "IV Therapy", label: "Drug/supply delivery" },
    { data: "0264", category: "IV Therapy", label: "Supplies" },
    { data: "0269", category: "IV Therapy", label: "Other" },
    { data: "0270", category: "Medical/Surgical Supplies and Devices", label: "General" },
    { data: "0271", category: "Medical/Surgical Supplies and Devices", label: "Nonsterile" },
    { data: "0272", category: "Medical/Surgical Supplies and Devices", label: "Sterile" },
    {
        data: "0273",
        category: "Medical/Surgical Supplies and Devices",
        label: "Take-home supplies",
    },
    {
        data: "0274",
        category: "Medical/Surgical Supplies and Devices",
        label: "Prosthetic/orthotic devices",
    },
    { data: "0275", category: "Medical/Surgical Supplies and Devices", label: "Pacemaker" },
    { data: "0276", category: "Medical/Surgical Supplies and Devices", label: "Intracular lens" },
    { data: "0277", category: "Medical/Surgical Supplies and Devices", label: "Take-home oxygen" },
    { data: "0278", category: "Medical/Surgical Supplies and Devices", label: "Other implants" },
    { data: "0279", category: "Medical/Surgical Supplies and Devices", label: "Other" },
    { data: "0280", category: "Oncology", label: "General" },
    { data: "0289", category: "Oncology", label: "Other" },
    { data: "0290", category: "Durable Medical Equipment (Other than Renal)", label: "General" },
    { data: "0291", category: "Durable Medical Equipment (Other than Renal)", label: "Rental" },
    {
        data: "0292",
        category: "Durable Medical Equipment (Other than Renal)",
        label: "Purchase of new DME",
    },
    {
        data: "0293",
        category: "Durable Medical Equipment (Other than Renal)",
        label: "Purchase of used DME",
    },
    {
        data: "0294",
        category: "Durable Medical Equipment (Other than Renal)",
        label: "Supplies/Drugs for DME",
    },
    { data: "0299", category: "Durable Medical Equipment (Other than Renal)", label: "Other" },
    { data: "0300", category: "Laboratory", label: "General" },
    { data: "0301", category: "Laboratory", label: "Chemistry" },
    { data: "0302", category: "Laboratory", label: "Immunology" },
    { data: "0303", category: "Laboratory", label: "Renal patient (home)" },
    { data: "0304", category: "Laboratory", label: "Nonroutine dialysis" },
    { data: "0305", category: "Laboratory", label: "Hematology" },
    { data: "0306", category: "Laboratory", label: "Bacteriology and Microbiology" },
    { data: "0307", category: "Laboratory", label: "Urology" },
    { data: "0309", category: "Laboratory", label: "Other" },
    { data: "0310", category: "Laboratory Pathology", label: "General" },
    { data: "0311", category: "Laboratory Pathology", label: "Cytology" },
    { data: "0312", category: "Laboratory Pathology", label: "Histology" },
    { data: "0314", category: "Laboratory Pathology", label: "Biopsy" },
    { data: "0319", category: "Laboratory Pathology", label: "Other" },
    { data: "0320", category: "Radiology Diagnostic", label: "General" },
    { data: "0321", category: "Radiology Diagnostic", label: "Angiocardiography" },
    { data: "0322", category: "Radiology Diagnostic", label: "Arthrography" },
    { data: "0323", category: "Radiology Diagnostic", label: "Arteriography" },
    { data: "0324", category: "Radiology Diagnostic", label: "Chest X-ray" },
    { data: "0329", category: "Radiology Diagnostic", label: "Other" },
    {
        data: "0330",
        category: "Radiology Therapeutic and/of Chemotherapy Administration",
        label: "General",
    },
    {
        data: "0331",
        category: "Radiology Therapeutic and/of Chemotherapy Administration",
        label: "Chemotherapy administration - injection",
    },
    {
        data: "0332",
        category: "Radiology Therapeutic and/of Chemotherapy Administration",
        label: "Chemotherapy administration - oral",
    },
    {
        data: "0333",
        category: "Radiology Therapeutic and/of Chemotherapy Administration",
        label: "Radiation therapy",
    },
    {
        data: "0335",
        category: "Radiology Therapeutic and/of Chemotherapy Administration",
        label: "Chemotherapy administration - IV",
    },
    {
        data: "0339",
        category: "Radiology Therapeutic and/of Chemotherapy Administration",
        label: "Other",
    },
    { data: "0340", category: "Nuclear Medicine", label: "General" },
    { data: "0341", category: "Nuclear Medicine", label: "Diagnostic" },
    { data: "0342", category: "Nuclear Medicine", label: "Therapeutic" },
    { data: "0343", category: "Nuclear Medicine", label: "Diagnostic radiopharmaceuticals" },
    { data: "0344", category: "Nuclear Medicine", label: "Therapeutic radiopharmaceuticals" },
    { data: "0349", category: "Nuclear Medicine", label: "Other" },
    { data: "0350", category: "CT Scan", label: "General" },
    { data: "0351", category: "CT Scan", label: "Head scan" },
    { data: "0352", category: "CT Scan", label: "Body scan" },
    { data: "0359", category: "CT Scan", label: "Other" },
    { data: "0360", category: "Operating Room Services", label: "General" },
    { data: "0361", category: "Operating Room Services", label: "Minor surgery" },
    {
        data: "0362",
        category: "Operating Room Services",
        label: "Organ transplant - other than kidney",
    },
    { data: "0367", category: "Operating Room Services", label: "Kidney transplant" },
    { data: "0369", category: "Operating Room Services", label: "Other" },
    { data: "0370", category: "Anesthesia", label: "General" },
    { data: "0371", category: "Anesthesia", label: "Incident to radiology" },
    { data: "0372", category: "Anesthesia", label: "Incident to Other Diagnostic services" },
    { data: "0374", category: "Anesthesia", label: "Acupuncture" },
    { data: "0379", category: "Anesthesia", label: "Other" },
    { data: "0380", category: "Blood and Blood Products", label: "General" },
    { data: "0381", category: "Blood and Blood Products", label: "Packed red cells" },
    { data: "0382", category: "Blood and Blood Products", label: "Whole blood and blood products" },
    { data: "0383", category: "Blood and Blood Products", label: "Plasma" },
    { data: "0384", category: "Blood and Blood Products", label: "Platelets" },
    { data: "0385", category: "Blood and Blood Products", label: "Leukocytes" },
    { data: "0386", category: "Blood and Blood Products", label: "Other components" },
    {
        data: "0387",
        category: "Blood and Blood Products",
        label: "Other derivatives (cryoprecipitates)",
    },
    { data: "0389", category: "Blood and Blood Products", label: "Other" },
    {
        data: "0390",
        category: "Administration, Processing and Storage for Blood and Blood Components",
        label: "General",
    },
    {
        data: "0391",
        category: "Administration, Processing and Storage for Blood and Blood Components",
        label: "Administration (e.g., transfusions)",
    },
    {
        data: "0392",
        category: "Administration, Processing and Storage for Blood and Blood Components",
        label: "Processing and storage",
    },
    {
        data: "0399",
        category: "Administration, Processing and Storage for Blood and Blood Components",
        label: "Other processing and storage",
    },
    { data: "0400", category: "Other Imaging Services", label: "General" },
    { data: "0401", category: "Other Imaging Services", label: "Diagnostic mammography" },
    { data: "0402", category: "Other Imaging Services", label: "Ultrasound" },
    { data: "0403", category: "Other Imaging Services", label: "Screening mammography" },
    { data: "0404", category: "Other Imaging Services", label: "Positron Emission Tomography" },
    { data: "0409", category: "Other Imaging Services", label: "Other" },
    { data: "0410", category: "Respiratory Services", label: "General" },
    { data: "0412", category: "Respiratory Services", label: "Inhalation services" },
    { data: "0413", category: "Respiratory Services", label: "Hyperbaric oxygen therapy" },
    { data: "0419", category: "Respiratory Services", label: "Other" },
    { data: "0420", category: "Physical Therapy", label: "General" },
    { data: "0421", category: "Physical Therapy", label: "Visit charge" },
    { data: "0422", category: "Physical Therapy", label: "Hourly charge" },
    { data: "0423", category: "Physical Therapy", label: "Group rate" },
    { data: "0424", category: "Physical Therapy", label: "Evaluation or reevaluation" },
    { data: "0429", category: "Physical Therapy", label: "Other" },
    { data: "0430", category: "Occupational Therapy", label: "General" },
    { data: "0431", category: "Occupational Therapy", label: "Visit charge" },
    { data: "0432", category: "Occupational Therapy", label: "Hourly charge" },
    { data: "0433", category: "Occupational Therapy", label: "Group rate" },
    { data: "0434", category: "Occupational Therapy", label: "Evaluation or reevaluation" },
    { data: "0439", category: "Occupational Therapy", label: "Other" },
    { data: "0440", category: "Speech Therapy Language Pathology", label: "General" },
    { data: "0441", category: "Speech Therapy Language Pathology", label: "Visit charge" },
    { data: "0442", category: "Speech Therapy Language Pathology", label: "Hourly charge" },
    { data: "0443", category: "Speech Therapy Language Pathology", label: "Group rate" },
    {
        data: "0444",
        category: "Speech Therapy Language Pathology",
        label: "Evaluation or reevaluation",
    },
    { data: "0449", category: "Speech Therapy Language Pathology", label: "Other" },
    { data: "0450", category: "Emergency Room", label: "General" },
    {
        data: "0451",
        category: "Emergency Room",
        label: "EMTALA emergency medical screening services",
    },
    { data: "0452", category: "Emergency Room", label: "ER beyond EMTALA screening" },
    { data: "0456", category: "Emergency Room", label: "Urgent care" },
    { data: "0459", category: "Emergency Room", label: "Other" },
    { data: "0460", category: "Pulmonary Function", label: "General" },
    { data: "0469", category: "Pulmonary Function", label: "Other" },
    { data: "0470", category: "Audiology", label: "General" },
    { data: "0471", category: "Audiology", label: "Diagnostic" },
    { data: "0472", category: "Audiology", label: "Treatment" },
    { data: "0479", category: "Audiology", label: "Other" },
    { data: "0480", category: "Cardiology", label: "General" },
    { data: "0481", category: "Cardiology", label: "Cardiac cath lab" },
    { data: "0482", category: "Cardiology", label: "Stress test" },
    { data: "0483", category: "Cardiology", label: "Echocardiology" },
    { data: "0489", category: "Cardiology", label: "Other" },
    { data: "0490", category: "Ambulatory Surgical Care", label: "General" },
    { data: "0499", category: "Ambulatory Surgical Care", label: "Other" },
    { data: "0500", category: "Outpatient Services", label: "General" },
    { data: "0509", category: "Outpatient Services", label: "Other" },
    { data: "0510", category: "Clinic", label: "General" },
    { data: "0511", category: "Clinic", label: "Chronic pain center" },
    { data: "0512", category: "Clinic", label: "Dental clinic" },
    { data: "0513", category: "Clinic", label: "Psychiatric clinic" },
    { data: "0514", category: "Clinic", label: "OB/GYN clinic" },
    { data: "0515", category: "Clinic", label: "Pediatric clinic" },
    { data: "0516", category: "Clinic", label: "Urgent care clinic" },
    { data: "0517", category: "Clinic", label: "Family practice clinic" },
    { data: "0519", category: "Clinic", label: "Other" },
    { data: "0520", category: "Freestanding Clinic", label: "General" },
    { data: "0521", category: "Freestanding Clinic", label: "Clinic visit by member to RHC/FQHC" },
    { data: "0522", category: "Freestanding Clinic", label: "Home visit by RHC/FQHC practitioner" },
    { data: "0523", category: "Freestanding Clinic", label: "Family practice clinic" },
    {
        data: "0524",
        category: "Freestanding Clinic",
        label: "Visit by RHC/FQHC practitioner to member in a Part A covered stay in SNF",
    },
    {
        data: "0525",
        category: "Freestanding Clinic",
        label: "Visit by RHC/FQHC practitioner to member in a stay not covered by Part A in a SNF, NF or ICF MR or other residential facility",
    },
    { data: "0526", category: "Freestanding Clinic", label: "Urgent care clinic" },
    {
        data: "0527",
        category: "Freestanding Clinic",
        label: "Visiting nurse services to member's home in a home health shortage area",
    },
    {
        data: "0528",
        category: "Freestanding Clinic",
        label: "Visit by RHC/FQHC practitioner to other non-RHC/FQHC site (e.g., scene of accident)",
    },
    { data: "0529", category: "Freestanding Clinic", label: "Other" },
    { data: "0530", category: "Osteopathic Services", label: "General" },
    { data: "0531", category: "Osteopathic Services", label: "Osteopathic therapy" },
    { data: "0539", category: "Osteopathic Services", label: "Other" },
    { data: "0540", category: "Ambulance", label: "General" },
    { data: "0541", category: "Ambulance", label: "Supplies" },
    { data: "0542", category: "Ambulance", label: "Medical transport" },
    { data: "0543", category: "Ambulance", label: "Heart mobile" },
    { data: "0544", category: "Ambulance", label: "Oxygen" },
    { data: "0545", category: "Ambulance", label: "Air ambulance" },
    { data: "0546", category: "Ambulance", label: "Neonatal ambulance" },
    { data: "0547", category: "Ambulance", label: "Pharmacy" },
    { data: "0548", category: "Ambulance", label: "EKG transmission" },
    { data: "0549", category: "Ambulance", label: "Other" },
    { data: "0550", category: "Skilled Nursing", label: "General" },
    { data: "0551", category: "Skilled Nursing", label: "Visit charge" },
    { data: "0552", category: "Skilled Nursing", label: "Hourly charge" },
    { data: "0559", category: "Skilled Nursing", label: "Other" },
    { data: "0560", category: "Home Health Medical Social Services", label: "General" },
    { data: "0561", category: "Home Health Medical Social Services", label: "Visit charge" },
    { data: "0562", category: "Home Health Medical Social Services", label: "Hourly charge" },
    { data: "0569", category: "Home Health Medical Social Services", label: "Other" },
    { data: "0570", category: "Home Health Aide", label: "General" },
    { data: "0571", category: "Home Health Aide", label: "Visit charge" },
    { data: "0572", category: "Home Health Aide", label: "Hourly charge" },
    { data: "0579", category: "Home Health Aide", label: "Other" },
    { data: "0580", category: "Home Health Other Visits", label: "General" },
    { data: "0581", category: "Home Health Other Visits", label: "Visit charge" },
    { data: "0582", category: "Home Health Other Visits", label: "Hourly charge" },
    { data: "0583", category: "Home Health Other Visits", label: "Assessment" },
    { data: "0589", category: "Home Health Other Visits", label: "Other" },
    { data: "0590", category: "Home Health Units of Service", label: "General" },
    { data: "0600", category: "Home Health Oxygen", label: "General" },
    { data: "0601", category: "Home Health Oxygen", label: "Stat/Equip/Supply or contents" },
    { data: "0602", category: "Home Health Oxygen", label: "Stat/Equip/Supply Under 1 LPM" },
    { data: "0603", category: "Home Health Oxygen", label: "Stat/Equip Over 4 LPM" },
    { data: "0604", category: "Home Health Oxygen", label: "Portable Add-on" },
    { data: "0609", category: "Home Health Oxygen", label: "Other" },
    { data: "0610", category: "Magnetic Resonance Technology (MRT)", label: "General" },
    { data: "0611", category: "Magnetic Resonance Technology (MRT)", label: "Brain/brain stem" },
    { data: "0612", category: "Magnetic Resonance Technology (MRT)", label: "Spinal cord/spine" },
    { data: "0614", category: "Magnetic Resonance Technology (MRT)", label: "Other MRI" },
    { data: "0615", category: "Magnetic Resonance Technology (MRT)", label: "Head and neck" },
    { data: "0616", category: "Magnetic Resonance Technology (MRT)", label: "Lower extremities" },
    { data: "0618", category: "Magnetic Resonance Technology (MRT)", label: "Other MRA" },
    { data: "0619", category: "Magnetic Resonance Technology (MRT)", label: "Other MRT" },
    { data: "0621", category: "Medical/Surgical Supplies", label: "Incident to Radiology" },
    {
        data: "0622",
        category: "Medical/Surgical Supplies",
        label: "Incident to Other Diagnostic services",
    },
    { data: "0623", category: "Medical/Surgical Supplies", label: "Surgical Dressings" },
    { data: "0624", category: "Medical/Surgical Supplies", label: "FDA investigational devices" },
    { data: "0631", category: "Pharmacy", label: "Single source drug" },
    { data: "0632", category: "Pharmacy", label: "Multiple source drug" },
    { data: "0633", category: "Pharmacy", label: "Restrictive prescription" },
    { data: "0634", category: "Pharmacy", label: "Erythropoietin (EPO) less than 10,000 units" },
    { data: "0635", category: "Pharmacy", label: "Erythropoietin (EPO) 10,000 or more units" },
    { data: "0636", category: "Pharmacy", label: "Drugs requiring detailed coding" },
    { data: "0637", category: "Pharmacy", label: "Self-administerable drugs" },
    { data: "0640", category: "Home IV Therapy Services", label: "General" },
    {
        data: "0641",
        category: "Home IV Therapy Services",
        label: "Nonroutine nursing, central line",
    },
    { data: "0642", category: "Home IV Therapy Services", label: "IV site care, central line" },
    { data: "0643", category: "Home IV Therapy Services", label: "IV start/care, peripheral line" },
    {
        data: "0644",
        category: "Home IV Therapy Services",
        label: "Nonroutine nursing, peripheral line",
    },
    {
        data: "0645",
        category: "Home IV Therapy Services",
        label: "Training patient/caregiver, central line",
    },
    {
        data: "0646",
        category: "Home IV Therapy Services",
        label: "Training disabled patient, central line",
    },
    {
        data: "0647",
        category: "Home IV Therapy Services",
        label: "Training patient/caregiver, peripheral line",
    },
    {
        data: "0648",
        category: "Home IV Therapy Services",
        label: "Training disabled patient, peripheral line",
    },
    { data: "0649", category: "Home IV Therapy Services", label: "Other" },
    { data: "0650", category: "Hospice Service", label: "General" },
    { data: "0651", category: "Hospice Service", label: "Routine home care" },
    { data: "0652", category: "Hospice Service", label: "Continuous home care" },
    { data: "0655", category: "Hospice Service", label: "Inpatient respite care" },
    { data: "0656", category: "Hospice Service", label: "General inpatient care (nonrespite)" },
    { data: "0657", category: "Hospice Service", label: "Physician services" },
    {
        data: "0658",
        category: "Hospice Service",
        label: "Hospice room and board - nursing facility",
    },
    { data: "0659", category: "Hospice Service", label: "Other" },
    { data: "0660", category: "Respite Care", label: "General" },
    { data: "0661", category: "Respite Care", label: "Hourly charge/nursing" },
    { data: "0662", category: "Respite Care", label: "Hourly charge/aide/homemaker/companion" },
    { data: "0663", category: "Respite Care", label: "Daily respite charge" },
    { data: "0669", category: "Respite Care", label: "Other" },
    { data: "0670", category: "Outpatient Special Residence Charges", label: "General" },
    { data: "0671", category: "Outpatient Special Residence Charges", label: "Hospital owned" },
    { data: "0672", category: "Outpatient Special Residence Charges", label: "Contracted" },
    { data: "0679", category: "Outpatient Special Residence Charges", label: "Other" },
    { data: "0681", category: "Trauma Response", label: "Level I" },
    { data: "0682", category: "Trauma Response", label: "Level II" },
    { data: "0683", category: "Trauma Response", label: "Level III" },
    { data: "0684", category: "Trauma Response", label: "Level IV" },
    { data: "0689", category: "Trauma Response", label: "Other" },
    { data: "0700", category: "Cast Room", label: "General" },
    { data: "0710", category: "Recovery Room", label: "General" },
    { data: "0720", category: "Labor Room/Delivery", label: "General" },
    { data: "0721", category: "Labor Room/Delivery", label: "Labor" },
    { data: "0722", category: "Labor Room/Delivery", label: "Delivery" },
    { data: "0723", category: "Labor Room/Delivery", label: "Circumcision" },
    { data: "0724", category: "Labor Room/Delivery", label: "Birthing center" },
    { data: "0729", category: "Labor Room/Delivery", label: "Other" },
    { data: "0730", category: "EKG/ECG Electrocardiogram", label: "General" },
    { data: "0731", category: "EKG/ECG Electrocardiogram", label: "Holter monitor" },
    { data: "0732", category: "EKG/ECG Electrocardiogram", label: "Telemetry" },
    { data: "0739", category: "EKG/ECG Electrocardiogram", label: "Other" },
    { data: "0740", category: "EEG Electroencephalogram", label: "General" },
    { data: "0750", category: "Gastrointestinal Services", label: "General" },
    { data: "0760", category: "Specialty Services", label: "General" },
    { data: "0761", category: "Specialty Services", label: "Treatment room" },
    { data: "0762", category: "Specialty Services", label: "Observation hours" },
    { data: "0769", category: "Specialty Services", label: "Other" },
    { data: "0770", category: "Preventive Services", label: "General" },
    { data: "0771", category: "Preventive Services", label: "Vaccine administration" },
    { data: "0780", category: "Telemedicine", label: "General" },
    {
        data: "0790",
        category: "Extra-Corporeal Shock Wave Therapy (formerly Lithotripsy)",
        label: "General",
    },
    { data: "0800", category: "Inpatient Renal Dialysis", label: "General" },
    { data: "0801", category: "Inpatient Renal Dialysis", label: "Inpatient hemodialysis" },
    {
        data: "0802",
        category: "Inpatient Renal Dialysis",
        label: "Inpatient peritoneal (non-CAPD)",
    },
    {
        data: "0803",
        category: "Inpatient Renal Dialysis",
        label: "Inpatient Continuous Ambulatory Peritoneal Dialysis (CAPD)",
    },
    {
        data: "0804",
        category: "Inpatient Renal Dialysis",
        label: "Inpatient Continuous Cycling Peritoneal Dialysis (CCPD)",
    },
    { data: "0809", category: "Inpatient Renal Dialysis", label: "Other" },
    { data: "0810", category: "Acquisition of Body Components", label: "General" },
    { data: "0811", category: "Acquisition of Body Components", label: "Living donor" },
    { data: "0812", category: "Acquisition of Body Components", label: "Cadaver donor" },
    { data: "0813", category: "Acquisition of Body Components", label: "Unknown donor" },
    {
        data: "0814",
        category: "Acquisition of Body Components",
        label: "Unsuccessful organ search - donor bank charges",
    },
    { data: "0819", category: "Acquisition of Body Components", label: "Other" },
    { data: "0820", category: "Hemodialysis - Outpatient or Home", label: "General" },
    {
        data: "0821",
        category: "Hemodialysis - Outpatient or Home",
        label: "Composite or other rate",
    },
    { data: "0822", category: "Hemodialysis - Outpatient or Home", label: "Home supplies" },
    { data: "0823", category: "Hemodialysis - Outpatient or Home", label: "Home equipment" },
    { data: "0824", category: "Hemodialysis - Outpatient or Home", label: "Maintenance/100%" },
    { data: "0825", category: "Hemodialysis - Outpatient or Home", label: "Support Services" },
    { data: "0829", category: "Hemodialysis - Outpatient or Home", label: "Other" },
    { data: "0830", category: "Peritoneal Dialysis - Outpatient or Home", label: "General" },
    {
        data: "0831",
        category: "Peritoneal Dialysis - Outpatient or Home",
        label: "Composite or other rate",
    },
    { data: "0832", category: "Peritoneal Dialysis - Outpatient or Home", label: "Home supplies" },
    { data: "0833", category: "Peritoneal Dialysis - Outpatient or Home", label: "Home equipment" },
    {
        data: "0834",
        category: "Peritoneal Dialysis - Outpatient or Home",
        label: "Maintenance/100%",
    },
    {
        data: "0835",
        category: "Peritoneal Dialysis - Outpatient or Home",
        label: "Support Services",
    },
    { data: "0839", category: "Peritoneal Dialysis - Outpatient or Home", label: "Other" },
    {
        data: "0840",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "General",
    },
    {
        data: "0841",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "Composite or other rate",
    },
    {
        data: "0842",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "Home supplies",
    },
    {
        data: "0843",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "Home equipment",
    },
    {
        data: "0844",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "Maintenance/100%",
    },
    {
        data: "0845",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "Support Services",
    },
    {
        data: "0849",
        category: "Continuous Ambulatory Peritoneal Dialysis (CAPD) - Outpatient or Home",
        label: "Other",
    },
    {
        data: "0850",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "General",
    },
    {
        data: "0851",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "Composite or other rate",
    },
    {
        data: "0852",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "Home supplies",
    },
    {
        data: "0853",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "Home equipment",
    },
    {
        data: "0854",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "Maintenance/100%",
    },
    {
        data: "0855",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "Support Services",
    },
    {
        data: "0859",
        category: "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        label: "Other",
    },
    { data: "0860", category: "Magnetoencephalography", label: "General" },
    { data: "0861", category: "Magnetoencephalography", label: "MEG" },
    { data: "0880", category: "Miscellaneous Dialysis", label: "General" },
    { data: "0881", category: "Miscellaneous Dialysis", label: "Ultrafiltration" },
    { data: "0882", category: "Miscellaneous Dialysis", label: "Home dialysis aid visit" },
    { data: "0889", category: "Miscellaneous Dialysis", label: "Other" },
    { data: "0900", category: "Behavioral Health Treatments/Services", label: "General" },
    { data: "0901", category: "Behavioral Health Treatments/Services", label: "Electroshock" },
    { data: "0902", category: "Behavioral Health Treatments/Services", label: "Milieu therapy" },
    { data: "0903", category: "Behavioral Health Treatments/Services", label: "Play therapy" },
    { data: "0904", category: "Behavioral Health Treatments/Services", label: "Activity therapy" },
    {
        data: "0905",
        category: "Behavioral Health Treatments/Services",
        label: "Intensive outpatient services - psychiatric",
    },
    {
        data: "0906",
        category: "Behavioral Health Treatments/Services",
        label: "Chemical dependency",
    },
    {
        data: "0907",
        category: "Behavioral Health Treatments/Services",
        label: "Community behavioral health program - day treatment",
    },
    { data: "0911", category: "Behavioral Health Treatments/Services", label: "Rehabilitation" },
    {
        data: "0912",
        category: "Behavioral Health Treatments/Services",
        label: "Partial hospitalization - less intensive",
    },
    {
        data: "0913",
        category: "Behavioral Health Treatments/Services",
        label: "Partial hospitalization - intensive",
    },
    {
        data: "0914",
        category: "Behavioral Health Treatments/Services",
        label: "Individual therapy",
    },
    { data: "0915", category: "Behavioral Health Treatments/Services", label: "Group therapy" },
    { data: "0916", category: "Behavioral Health Treatments/Services", label: "Family therapy" },
    { data: "0917", category: "Behavioral Health Treatments/Services", label: "Biofeedback" },
    { data: "0918", category: "Behavioral Health Treatments/Services", label: "Testing" },
    {
        data: "0919",
        category: "Behavioral Health Treatments/Services",
        label: "Behavioral health treatments",
    },
    { data: "0920", category: "Other Diagnostic Services", label: "General" },
    { data: "0921", category: "Other Diagnostic Services", label: "Peripheral vascular lab" },
    { data: "0922", category: "Other Diagnostic Services", label: "Electromyelogram" },
    { data: "0923", category: "Other Diagnostic Services", label: "Pap smear" },
    { data: "0924", category: "Other Diagnostic Services", label: "Allergy test" },
    { data: "0925", category: "Other Diagnostic Services", label: "Pregnancy test" },
    { data: "0929", category: "Other Diagnostic Services", label: "Other" },
    { data: "0931", category: "Medical Rehabilitation Day Program", label: "Half day" },
    { data: "0932", category: "Medical Rehabilitation Day Program", label: "Full day" },
    { data: "0940", category: "Other Therapeutic Services", label: "General" },
    { data: "0941", category: "Other Therapeutic Services", label: "Recreational" },
    { data: "0942", category: "Other Therapeutic Services", label: "Education/training" },
    { data: "0943", category: "Other Therapeutic Services", label: "Cardiac rehabilitation" },
    { data: "0944", category: "Other Therapeutic Services", label: "Drug rehabilitation" },
    { data: "0945", category: "Other Therapeutic Services", label: "Alcohol rehabilitation" },
    {
        data: "0946",
        category: "Other Therapeutic Services",
        label: "Complex medical equipment - routine",
    },
    {
        data: "0947",
        category: "Other Therapeutic Services",
        label: "Complex medical equipment - ancillary",
    },
    { data: "0948", category: "Other Therapeutic Services", label: "Pulmonary rehabilitation" },
    { data: "0949", category: "Other Therapeutic Services", label: "Other" },
    { data: "0951", category: "Other Therapeutic Services", label: "Athletic training" },
    { data: "0952", category: "Other Therapeutic Services", label: "Kinesiotherapy" },
    { data: "0960", category: "Professional Fees", label: "General" },
    { data: "0961", category: "Professional Fees", label: "Psychiatric" },
    { data: "0962", category: "Professional Fees", label: "Ophthalmology" },
    { data: "0963", category: "Professional Fees", label: "Anesthesiologist (MD)" },
    { data: "0964", category: "Professional Fees", label: "Anesthesiologist (CRNA)" },
    { data: "0969", category: "Professional Fees", label: "Other" },
    { data: "0971", category: "Professional Fees", label: "Laboratory" },
    { data: "0972", category: "Professional Fees", label: "Radiology - diagnostic" },
    { data: "0973", category: "Professional Fees", label: "Radiology - therapeutic" },
    { data: "0974", category: "Professional Fees", label: "Nuclear medicine" },
    { data: "0975", category: "Professional Fees", label: "Operating room" },
    { data: "0976", category: "Professional Fees", label: "Respiratory therapy" },
    { data: "0977", category: "Professional Fees", label: "Physical therapy" },
    { data: "0978", category: "Professional Fees", label: "Occupational therapy" },
    { data: "0979", category: "Professional Fees", label: "Speech pathology" },
    { data: "0981", category: "Professional Fees", label: "Emergency room" },
    { data: "0982", category: "Professional Fees", label: "Outpatient services" },
    { data: "0983", category: "Professional Fees", label: "Clinic" },
    { data: "0984", category: "Professional Fees", label: "Medical social services" },
    { data: "0985", category: "Professional Fees", label: "EKG" },
    { data: "0986", category: "Professional Fees", label: "EEG" },
    { data: "0987", category: "Professional Fees", label: "Hospital visit" },
    { data: "0988", category: "Professional Fees", label: "Consultation" },
    { data: "0989", category: "Professional Fees", label: "Private-duty nurse" },
    { data: "0990", category: "Patient Convenience Items", label: "General" },
    { data: "0991", category: "Patient Convenience Items", label: "Cafeteria/guest tray" },
    { data: "0992", category: "Patient Convenience Items", label: "Private linen service" },
    { data: "0993", category: "Patient Convenience Items", label: "Telephone/telegraph" },
    { data: "0994", category: "Patient Convenience Items", label: "TV/radio" },
    { data: "0995", category: "Patient Convenience Items", label: "Nonpatient room rentals" },
    { data: "0996", category: "Patient Convenience Items", label: "Late discharge charge" },
    { data: "0997", category: "Patient Convenience Items", label: "Admission kits" },
    { data: "0998", category: "Patient Convenience Items", label: "Beauty shop/barber" },
    { data: "0999", category: "Patient Convenience Items", label: "Other" },
    { data: "1000", category: "Behavioral Health Accommodations", label: "General" },
    {
        data: "1001",
        category: "Behavioral Health Accommodations",
        label: "Residential treatment - psychiatric",
    },
    {
        data: "1002",
        category: "Behavioral Health Accommodations",
        label: "Residential treatment - chemical dependency",
    },
    { data: "1003", category: "Behavioral Health Accommodations", label: "Supervised living" },
    { data: "1004", category: "Behavioral Health Accommodations", label: "Halfway House" },
    { data: "1005", category: "Behavioral Health Accommodations", label: "Group Home" },
    { data: "2100", category: "Alternative Therapy Services", label: "General" },
    { data: "2101", category: "Alternative Therapy Services", label: "Acupuncture" },
    { data: "2102", category: "Alternative Therapy Services", label: "Acupressure" },
    { data: "2103", category: "Alternative Therapy Services", label: "Massage" },
    { data: "2104", category: "Alternative Therapy Services", label: "Reflexology" },
    { data: "2105", category: "Alternative Therapy Services", label: "Biofeedback" },
    { data: "2106", category: "Alternative Therapy Services", label: "Hypnosis" },
    { data: "2109", category: "Alternative Therapy Services", label: "Other" },
    { data: "3101", category: "Adult Care", label: "adult daycare, medical and social - hourly" },
    { data: "3102", category: "Adult Care", label: "Adult daycare, social - hourly" },
    { data: "3103", category: "Adult Care", label: "Adult daycare, medical and social - daily" },
    { data: "3104", category: "Adult Care", label: "Adult daycare, social - daily" },
    { data: "3105", category: "Adult Care", label: "Adult foster care - daily" },
    { data: "3109", category: "Adult Care", label: "Other" },
];
