import { isNullOrUndefined } from "../app-base/fn";
import { isObjectId } from "../app-base/validator";
import { apiGet, apiPut, apiPost, apiDelete } from "../connection";

// org summary with members, roles and areas
export async function getOrg(id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`orgs/${id}`, {}, controller);
    } else {
        throw "Organization is required.";
    }
}

// org detail > without  members, roles and areas
export async function getOrgDetail(id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`orgs/${id}/detail`, null, controller);
    } else {
        throw "Organization is required.";
    }
}

export async function createOrg(savedata, controller = null) {
    return await apiPost(`orgs`, savedata, controller);
}

export async function updateOrg(id, savedata, controller = null) {
    if (isObjectId(id)) {
        return await apiPut(`orgs/${id}`, savedata, controller);
    } else {
        throw "Organization is required.";
    }
}

export async function deleteOrg(id, controller = null) {
    if (isObjectId(id)) {
        return await apiDelete(`orgs/${id}`, controller);
    } else {
        throw "Organization is required.";
    }
}

export async function getOrgMembers(id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`orgs/${id}/members`, null, controller);
    } else {
        throw "Organization is required.";
    }
}

// let list of payers for organization
export async function getOrgPayerList(orgid, status = "active", controller = null) {
    if (isObjectId(orgid)) {
        return await apiGet(`orgs/${orgid}/payers`, { status: status }, controller);
    } else {
        throw "Organization is required.";
    }
}

// get org payer detail
export async function getOrgPayer(orgid, id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`orgs/${orgid}/payers/${id}`, null, controller);
    } else {
        throw "Payer is required.";
    }
}

// create org payer
export async function createOrgPayer(orgid, savedata, controller = null) {
    if (savedata.hasOwnProperty("id")) delete savedata.id;
    return await apiPost(`orgs/${orgid}/payers`, savedata, controller);
}

// update org payer
export async function updateOrgPayer(orgid, id, savedata, controller = null) {
    if (isObjectId(orgid) && isObjectId(id)) {
        return await apiPut(`orgs/${orgid}/payers/${id}`, savedata, controller);
    } else {
        throw "Insurance is required.";
    }
}

// delete insurance
export async function deleteOrgPayer(orgid, id, controller = null) {
    if (isObjectId(orgid) && isObjectId(id)) {
        return await apiDelete(`orgs/${orgid}/payers/${id}`, controller);
    } else {
        throw "Organization is required.";
    }
}

// list of visits for org by visit number
export async function getOrgEncounterSearch(
    orgid,
    encNum,
    rows = 25, // rows to fetch
    offset = 0, // from after row
    controller = null,
) {
    if (isObjectId(orgid) && encNum.trim() !== "") {
        return await apiGet(
            `orgs/${orgid}/encounter-search`,
            { enc_num: encNum.trim(), rows: rows, offset: offset },
            controller,
        );
    } else {
        throw "Invalid data.";
    }
}

// list of org visits by hour and status
export async function getOrgEncounterSummary(orgid, startdate, enddate, controller = null) {
    if (isObjectId(orgid)) {
        try {
            const r = await apiGet(
                `orgs/${orgid}/encounter-summary`,
                { start: startdate.toISOString(), end: enddate.toISOString() },
                controller,
            );

            return r;
        } catch (ignore) {
            throw "Valid dates are required.";
        }
    } else {
        throw "Organization is required.";
    }
}

// list of encounters for org for day
export async function getOrgEncounters(orgid, date = new Date(), controller = null) {
    let encounterDate = new Date();

    if (date && (typeof date === "string" || !date.hasOwnProperty("toISOString"))) {
        encounterDate = new Date(date);
    } else {
        if (!isNullOrUndefined(date)) encounterDate = date;
    }

    if (isObjectId(orgid)) {
        return await apiGet(
            `orgs/${orgid}/encounters`,
            { date: encounterDate.toISOString() },
            controller,
        );
    } else {
        throw "Organization is required.";
    }
}

// get list of data for encounter slips
export async function encounterSlipDataList(orgid, date = new Date(), controller = null) {
    if (isObjectId(orgid)) {
        return await apiGet(
            `orgs/${orgid}/encounter-slips`,
            { date: typeof date === "string" ? date : date.toISOString() },
            controller,
        );
    } else {
        throw "Invalid data.";
    }
}

export async function enrollForPayer(orgid, orgPayerId, type) {
    if (isObjectId(orgid, orgPayerId)) {
        // apiPost(`orgs/${orgid}/payers`, savedata, controller);

        return await apiPost(`orgs/${orgid}/payers/${orgPayerId}/enroll`, { type: type }, null);
    } else {
        throw "Invalid data.";
    }
}
