import React, { memo } from "react";
import classNames from "classnames";
import pack from "../../package.json";
// import { defineMessages, FormattedMessage } from 'react-intl'

// const messages = defineMessages({
//   builtWith: {
//     id: 'footer.builtWith',
//     description: 'Mention how the site is built with',
//     defaultMessage: 'This site is built with <3 using {link}'
//   }
// })

function Footer({ className }) {
    return (
        <footer
            className={classNames("footer p-l-20 p-r-20 p-t-10 p-b-10 bg-grey-dark", className)}
            style={{ position: "relative", borderTop: "solid 1px rgba(215, 215, 218, 0.7)" }}
        >
            <div className="fl">
                <nav className="col-auto">
                    <ul className="fl f-s-sm f-w-sb">
                        <li className="p-r-15">
                            <a className="c-silver" href="#" target="_blank">
                                About Us
                            </a>
                        </li>
                        <li className="p-r-15">
                            <a className="c-silver" href="#" target="_blank">
                                Mission
                            </a>
                        </li>
                        <li className="p-r-15">
                            <a className="c-silver" href="#" target="_blank">
                                Support
                            </a>
                        </li>
                        <li>
                            <a className="c-silver" href="#" target="_blank">
                                Blog
                            </a>
                        </li>
                    </ul>
                </nav>

                <p className="copyright col-right f-s-xsm c-lighter-50">
                    &copy;{new Date().getFullYear()} {pack.company} - v{pack.version}, made for a
                    better health care
                </p>
            </div>
        </footer>
    );
}

export default memo(Footer);
