import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { personName } from "../../utils";

function SigninPwdHdr({ className, style, profile = null, email = "" }) {
    const cmp = useMemo(() => {
        return {
            person_name: !profile ? "" : personName(profile, true),
        };
    }, [profile]);

    return (
        <div className={classNames("sign-in-user-info m-b-25 m-t-0", className)} style={style}>
            <div className="sign-in-info-wrap t-center">
                <div className="user-name f-w-sb f-s-sm">{cmp.person_name}</div>
                <div className="user-email f-w-sb">{email}</div>
            </div>
        </div>
    );
}

export default memo(SigninPwdHdr);
