import React, { useContext, useEffect, useMemo, useRef, memo } from "react";
import classNames from "classnames";
import { useLocation, useNavigate, useMatches } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import { OrgContextProvider } from "./context/OrgContext";
import { createVerificationCode } from "./actions/userActions";
import { getAvatarData } from "./utils";
import { isNullOrUndefined, copy } from "./app-base/fn";
import Header from "./ui/Header";
import Portal from "./ui/Portal";
import OrgContent from "./ui/OrgContent";
import { showAlert } from "./app-base/displayUtils";

const ContentContainer = ({ children, root }) => {
    const appState = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = useRef();
    const currentAuthed = useRef(false);
    const currentOrgid = useRef(null);

    const matches = useMatches();

    const profileClickHandler = () => {
        // TODO: Finish
    };

    const orgChangeHandler = (orgid) => {
        appState.actions({ type: "changeOrg", value: orgid });
    };

    const windowCloseHandler = (winId) => {
        appState.actions({
            type: "closeWindow",
            value: winId,
        });
    };

    const printSettingsHandler = () => {
        appState.actions({
            type: "openWindow",
            value: {
                name: "print-settings",
                title: "Print Settings",
                props: {
                    width: "100%",
                    size: "sm",
                    hasPadding: true,
                },
                pageType: "popup",
            },
        });
    };

    /*************Code Verifiation***************/
    const codeValidatedHandler = (emailId, email, verifyData) => {
        // return {
        //     status: "success",
        //     verified_email: data.verified_email,
        //     verifed_dte: data.verifed_dte,
        // };

        if (emailId) {
            const idx = findListItemIndex(appState.data.orgs, "email_id", emailId);

            if (idx > -1) {
                const updatedUserOrg = {
                    ...appState.data.orgs[idx],
                    email_verify_dte: verifyData.verifed_dte,
                };

                appState.actions({
                    type: "updateUserOrg",
                    value: { item: updatedUserOrg, itemKey: "id" },
                });
            }
        }
    };

    const orgVerifyClickHandler = async (org) => {
        if (org && org.email_id && isNullOrUndefined(org.email_verify_dte)) {
            const res = await createVerificationCode(org.email_id);

            if (res.error) {
                showAlert("Error", "error", "Verification could not be sent. Please try again.");
            } else {
                // res.data returns { status: "success", email: data.email }

                appState.actions({
                    type: "openWindow",
                    value: {
                        name: "verify-Code",
                        title: "Verify Code",
                        props: {
                            id: null,
                            emailId: org.email_id,
                            email: res.data.email,
                            onSaved: codeValidatedHandler,
                            size: "sm",
                        },
                        pageType: "popup",
                    },
                });
            }
        }
    };
    /*************End ***************/

    const cmp = useMemo(() => {
        let avatarData = null;

        if (appState.data.user) {
            avatarData = getAvatarData("person", appState.data.user);
        }

        return {
            avatarData: avatarData,
        };
    }, [appState.data.user]);

    const orgCmp = useMemo(() => {
        let hasOpenWindow = appState.data.windows && appState.data.windows.length > 0;

        return {
            hasOpenWindow: hasOpenWindow,
        };
    }, [appState.data.windows]);

    useEffect(() => {
        const path = location && location.pathname ? location.pathname.toLowerCase() : "";

        if (path !== currentPath.current) {
            currentPath.current = path;

            if (import.meta.env.VITE_DEBUG === "1") {
                console.log("path:", path);
                console.log("matches", matches);
            }

            if (!appState.data.isAuthenticated && path.indexOf("signin") === -1) {
                navigate(`/signin`);
            }

            //   TODO: Analytics here
        }
    }, [location]);

    useEffect(() => {
        if (!currentAuthed.current && appState.data.isAuthenticated) {
            currentAuthed.current = true;

            if (appState.data.currentOrg) {
                // has selected org

                currentOrgid.current = appState.data.currentOrg.orgid;

                if (appState.data.preUrlPath && appState.data.preUrlPath !== "") {
                    // user has requested path

                    navigate(appState.data.preUrlPath);
                } else {
                    if (currentPath.current !== "/encounters") {
                        navigate(`/encounters`);
                    }
                }
            } else {
                // no org

                // currentOrgid.current
                navigate(`/`);
            }
        } else {
            // auth status is same

            if (appState.data.isAuthenticated) {
                // was already authenticated

                if (currentOrgid.current !== appState.data.currentOrg.orgid) {
                    // organization changed

                    if (currentPath.current !== "/encounters") {
                        navigate(`/encounters`);
                    }
                }
            }
        }
    }, [appState.data.isAuthenticated, appState.data.currentOrg]);

    if (!appState.data.isAuthenticated) {
        return <>{children}</>;
    }

    // authenticated content
    return (
        <div className={classNames("auth-content")}>
            <div
                className="hdr-wrap"
                // style={{ borderBottom: "1px solid rgba(255,255,255,0.03)" }}
            >
                <Header
                    userId={appState.data.user.id}
                    userAvatar={cmp.avatarData}
                    orgid={appState.data.currentOrg.orgid}
                    orgList={appState.data.orgs}
                    socketConnected={appState.data.socketConnected}
                    onSettingClick={printSettingsHandler}
                    onProfileClick={profileClickHandler}
                    onOrgVerifyClick={orgVerifyClickHandler}
                    onOrgChange={orgChangeHandler}
                />
            </div>

            <div className="main-wrap">
                <OrgContextProvider data={null}>
                    <OrgContent
                        root={root}
                        hasOpenWindow={orgCmp.hasOpenWindow}
                        orgid={appState.data.currentOrg.orgid}
                    >
                        {children}
                    </OrgContent>

                    {orgCmp.hasOpenWindow &&
                        appState.data.windows.map((item) => {
                            return (
                                <Portal
                                    key={item.windowId}
                                    windowId={item.windowId}
                                    name={item.name}
                                    title={item.title}
                                    pageType={item.pageType}
                                    root={root}
                                    location={copy(location)}
                                    isRootPage={false}
                                    close={windowCloseHandler}
                                    {...item.props}
                                />
                            );
                        })}
                </OrgContextProvider>
            </div>
        </div>
    );
};

export default memo(ContentContainer);
