import React, { memo, useMemo, useEffect } from "react";
import classNames from "classnames";
import { emailRule, validate } from "../../validate";
import Form from "../../app-base/Form";
import TextInput from "../../app-base/input/TextInput";
import CheckboxInput from "../../app-base/input/CheckboxInput";

function SigninEmail({
    className,
    email,
    rememberMe,
    disabled,
    loading,
    onChange,
    onRememberChange,
    onSubmit,
}) {
    const form = useMemo(() => {
        return new Form();
    }, []);

    const submitHandler = (e) => {
        const formErr = form.validate(true);

        if (!formErr) {
            // no error on elements
            const error = validate({ email: email.trim() }, { email: emailRule() });

            if (!error || error === "") {
                onSubmit(); // commit data changes and check
            } else {
                form.scrollToElement(error.name);
            }
        }
    };

    const keyUpHandler = (e) => {
        if (e.keyCode === 13) {
            // return pressed
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                if (e.target) e.target.blur();
            }

            submitHandler();
        }
    };

    useEffect(() => {
        return () => {
            form.dispose();
        };
    }, []);

    return (
        <div className={classNames("signin-email", className)}>
            <div onKeyUp={keyUpHandler}>
                <TextInput
                    tabIndex={0}
                    className="t-center"
                    type="email"
                    name="email"
                    label="Email Address"
                    required={true}
                    focus={true}
                    disabled={disabled}
                    value={email}
                    onInputAdd={form.add}
                    onInputRemove={form.remove}
                    onChange={onChange}
                />
            </div>
            <div className="signin-buttons">
                <button
                    tabIndex={1}
                    type="button"
                    className={classNames("btn is-primary w-full lg", {
                        "is-loading": loading,
                    })}
                    onClick={submitHandler}
                >
                    <span>Next</span>
                </button>

                <CheckboxInput
                    checkboxLabel="Remember Me"
                    inputClass="t-center m-t-10 sm"
                    disabled={disabled}
                    name="remember_me"
                    value={rememberMe}
                    onCommit={onRememberChange}
                />
            </div>
            {/* </form> */}
        </div>
    );
}

export default memo(SigninEmail);
