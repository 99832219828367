import { fileRoot, formatDate, isNullOrUndefined } from "./app-base/fn";

// get system gender if entered as freetext (i.e. import data)
export function getGender(str) {
    if (str) {
        if (typeof str === "string") {
            const s = str.trim().toLowerCase();

            if (s !== "") {
                if (s.charAt(0) === "m") return "male";
                if (s.charAt(0) === "f") return "female";
                if (s === "women") return "female";
                if (s === "girl") return "female";
                if (s === "boy") return "male";
                if (s === "other") return "other";
                if (s === "none") return "none";
            }
        }
    }

    return null;
}

// get person avatar object from person object
export function getAvatarData(entity, data) {
    if (!data) {
        return { entity: entity, text: "", imageurl: "" };
    } else {
        // data exists
        let avatar = { entity: entity, text: "", imageurl: "" };

        if (!isNullOrUndefined(data.profileimgfile)) {
            if (data.profileimgfile.indexOf(";base64,") > -1) {
                // dataUrl - base64 path
                avatar.imageurl = data.profileimgfile;
            } else {
                if (data.profileimgfile.trim() !== "")
                    avatar.imageurl = fileRoot("app") + "avatars/" + data.profileimgfile.trim();
            }
        }

        if (avatar.imageUrl === "") {
            // no profile image - user letters
            if (entity === "person" && data.hasOwnProperty("firstname")) {
                try {
                    if (data.firstname !== "") avatar.text = data.firstname.charAt(0).toUpperCase();
                    if (data.lastname !== "") avatar.text += data.lastname.charAt(0).toUpperCase();
                } catch (ignore) {
                    avatar.text = "P";
                }
            } else {
                // company
                if (data.hasOwnProperty("co_name")) {
                    try {
                        if (data.co_name !== "") avatar.text = data.co_name.charAt(0).toUpperCase();
                    } catch (ignore) {
                        avatar.text = "C";
                    }
                }
            }
        }

        return avatar;
    }
}

export function getPersonName(
    showFull = true,
    stn = "",
    firstName = "",
    mdlName = "",
    lastName = "",
    sfx = ""
) {
    let val = "";

    if (showFull && stn && stn.trim() !== "") stn.trim() + " ";
    if (firstName && firstName.trim() !== "") val += firstName.trim() + " ";
    if (showFull && mdlName && mdlName.trim() !== "") val += mdlName.trim() + " ";
    if (lastName && lastName.trim() !== "") val += lastName.trim() + " ";
    if (sfx && sfx.trim() !== "") val += sfx.trim();

    return val;
}

// get person name from person object
export function personName(person, showFull = true) {
    let val = "";

    if (person)
        val = getPersonName(
            showFull,
            person.stn,
            person.firstname ? person.firstname : person.first_name,
            person.mdlname ? person.mdlname : person.middle_name,
            person.lastname ? person.lastname : person.last_name,
            person.sfx
        );
    return val.trim();
}

/**
 *
 * @param {string} add1 @default ""
 * @param {string} add2 @default ""
 * @param {string} city
 * @param {string} state
 * @param {string} zip
 * @param {string} formatToHtml
 * @returns
 */
export function getAddressString(
    add1 = "",
    add2 = "",
    city = "",
    state = "",
    zip = "",
    formatToHtml = true
) {
    let str = "";

    if (formatToHtml) {
        if (add1 && add1.trim() !== "") str += add1.trim() + " \n";
        if (add2 && add2.trim() !== "") str += add2.trim() + " \n";
        if (city && city.trim() !== "") str += city.trim() + " ";
        if (state && state.trim() !== "") str += ", " + state.trim();
        if (zip && zip.trim() !== "") str += " " + zip.trim();
    } else {
        if (add1 && add1.trim() !== "") str += add1.trim() + " ";
        if (add2 && add2.trim() !== "") str += add2.trim() + " ";
        if (city && city.trim() !== "") str += city.trim() + ", ";
        if (state && state.trim() !== "") str += state.trim();
        if (zip && zip.trim() !== "") str += " " + zip.trim();
    }

    return str;
}

// address data as string
export function addressString(addressData) {
    if (addressData) {
        return getAddressString(
            addressData.add1,
            addressData.add2,
            addressData.city,
            addressData.state,
            addressData.city.zip
        );
    } else {
        return "";
    }
}

// get if user has access
export function hasAccess(access, userType, owner_id = null, userid = null) {
    switch (access) {
        case "private":
            if (isNullOrUndefined(owner_id)) return true;
            if (!isNullOrUndefined(userid)) {
                if (userid.trim() !== "") return userid === owner_id;
            }
            return false;
        case "orgAdmin":
            return ["Owner", "Admin"].includes(userType);
        case "orgMember":
            return ["Owner", "Admin", "Member"].includes(userType);
        case "org":
            return ["Owner", "Admin", "Member", "Associate"].includes(userType);
        case "public":
            return true;
        default:
            return false;
    }
}

// get if user is org Admin
export function isOrgAdmin(userType) {
    if (userType === "Admin" || userType === "Owner") return true;
    return false;
}

// get if user is allowed to change access
export function allowAccessChange(userType, owner_id = null, userid = null) {
    if (isOrgAdmin(userType)) {
        return true;
    } else {
        if (isNullOrUndefined(owner_id)) return true;
        if (!isNullOrUndefined(userid)) {
            if (userid.trim() !== "") return userid === owner_id;
        }
    }

    return false;
}

// filter people by value
export function filterPeople(data, val) {
    let escapedValue = escapeRegexCharacters(val.trim());
    let regex = new RegExp("^" + escapedValue, "i");

    return regex.test(data.firstname) || regex.test(data.lastname);
}

// get event type for an event object
export function getEventType(event) {
    let type = "To Do";

    if (event.assigned_to.length > 1) type = "Task";

    return type;
}

// format date to event list date string
export function eventDateFormat(due_date) {
    let str = "";

    if (!isNullOrUndefined(due_date) && due_date !== "") {
        str = formatDate(due_date, "M/D/YY h:mm A");
        str = str.indexOf(" 12:00 AM") === -1 ? str : formatDate(due_date, "M/D/YY");
    }

    return str === "" ? "Anytime" : str;
}

export function getFilingCodeForPayer(payer) {
    let claimTypeCode = "";

    if (payer) {
        if (payer.claim_type && payer.claim_type !== "") return payer.claim_type;

        const sameCodesAsPayerType = ["HM", "MA", "MB", "MC", "WC"];

        if (payer.insurance_type && payer.insurance_type.trim() !== "") {
            // return based on provided insurance type from change healthcare
            if (payer.insurance_type.toLowerCase().indexOf("blue cross") > -1) return "BL";
            if (payer.insurance_type.toLowerCase().indexOf("champus") > -1) return "CH";
            if (payer.insurance_type.toLowerCase().indexOf("workers compensation") > -1)
                return "WC";
        }

        if (payer.payer_type && payer.payer_type.trim() !== "") {
            if (sameCodesAsPayerType.includes(payer.payer_type)) return payer.payer_type;

            if (payer.payer_type === "EP") {
                claimTypeCode = "14";
            } else if (payer.payer_type === "HN") {
                claimTypeCode = "16";
            } else if (payer.payer_type === "MH") {
                claimTypeCode = "MA";
            } else if (payer.payer_type === "MI") {
                claimTypeCode = "MB";
            } else if (payer.payer_type === "PR") {
                claimTypeCode = "12";
            }

            if (claimTypeCode !== "") return claimTypeCode;
        }
    }

    return claimTypeCode;
}
