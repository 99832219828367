import React, { Fragment, memo, useMemo } from "react";
import classNames from "classnames";
import { fullPhoneNumber } from "../../app-base/fn";

function SearchPhoneIr({ className, style, idx, item }) {
    const cmp = useMemo(() => {
        return {
            displayLabel: item.number === "" ? "" : fullPhoneNumber(item, true)
        };
    }, [item]);

    return (
        <div className={classNames("fl-inline", className, { "m-l-15": idx > 0 })} style={style}>
            {cmp.displayLabel === "" && idx === 0 && (
                <Fragment>
                    <i className="material-icons c-blue_sharp m-r-3">phone</i>
                    <span className="c-grey">No Phone on record.</span>
                </Fragment>
            )}

            {cmp.displayLabel !== "" && (
                <Fragment>
                    <i className="material-icons c-blue_sharp m-r-3">phone</i>
                    <span className="c-white">{cmp.displayLabel}</span>
                </Fragment>
            )}
        </div>
    );
}

export default memo(SearchPhoneIr);
