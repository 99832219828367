import React, { useMemo, memo, useEffect } from "react";
import classNames from "classnames";
import Form from "../../app-base/Form";
import TextInput from "../../app-base/input/TextInput";
import { requiredRule, validate } from "../../validate";

function SigninVerify({
    className,
    email,
    code,
    loading = false,
    onChange,
    onSubmit,
    onResend,
    setStep,
}) {
    const form = useMemo(() => {
        return new Form();
    }, []);

    const submitHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!loading) {
            const formErr = form.validate(true);

            if (!formErr) {
                // no error on elements
                const error = validate(
                    { code: code.trim() },
                    { code: requiredRule("Code is required.") },
                );

                if (!error || error === "") {
                    onSubmit(); // commit data changes and check
                } else {
                    form.scrollToElement(error.name);
                }
            }
        }
    };

    const keyUpHandler = (e) => {
        if (e.keyCode === 13) {
            // return pressed
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                if (e.target) e.target.blur();
            }

            submitHandler();
        }
    };

    const resendClickHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onResend) onResend();
    };

    useEffect(() => {
        return () => {
            form.dispose();
        };
    }, []);

    return (
        <div className={classNames("signin-verify", className)}>
            <div className="signin-verify" onKeyUp={keyUpHandler}>
                <div className="t-center m-b-20">
                    Verification code was sent to {email}.
                    <br />
                    Please check your email and enter the code.
                </div>

                <TextInput
                    className="t-center"
                    type="text"
                    name="code"
                    label="Verification Code"
                    required={true}
                    focus={true}
                    value={code}
                    onInputAdd={form.add}
                    onInputRemove={form.remove}
                    onCommit={onChange}
                />

                <div className="signin-buttons">
                    <button
                        type="button"
                        className={classNames("btn is-primary w-full lg", {
                            "is-loading": loading,
                        })}
                        onClick={submitHandler}
                    >
                        <span>Verify</span>
                    </button>
                </div>
            </div>

            <div className="m-t-15 t-center f-s-sm">
                <a style={{ cursor: "pointer" }} onClick={resendClickHandler}>
                    <span>Resend verification code</span>
                </a>
            </div>
        </div>
    );
}

export default memo(SigninVerify);
