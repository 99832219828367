export const IMG_FILES = [".png", ".jpeg", ".jpg", ".gif"];
export const VIDEO_FILES = [".mp4", ".m4v"];
export const DOC_FILES = [".pdf", ".doc", ".docx", ".txt", ".ppt", ".pptx"];
export const SPREAD_SHEET_FILES = [".csv", ".xls", ".xlsx"];
export const AUDIO_FILES = [".mp3", ".wav"];
export const MS_FILES = [".xls", ".xlsx", ".doc", ".docx", ".ppt", ".pptx"];

export function fileType(extension) {
    const ext = fileExt(extension);

    if (ext === "") {
        return "";
    } else if (isImage(ext)) {
        return "image";
    } else if (isVideo(ext)) {
        return "video";
    } else if (isAudio(ext)) {
        return "audio";
    } else if (isMsFile(ext)) {
        return "msfile";
    } else if (isPdf(ext)) {
        return "pdf";
    } else if (isCsv(ext)) {
        return "csv";
    } else if (isTxt(ext)) {
        return "txt";
    } else {
        return "other";
    }
}

export function getFileExt(filename) {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(filename)[1];
}

export function fileExt(fileext) {
    fileext = fileext.trim().toLowerCase();
    if (fileext !== "") return "." + fileext.split(".").pop();
    return fileext;
}

export function isImage(fileext) {
    return IMG_FILES.indexOf(fileExt(fileext)) === -1 ? false : true;
}

export function isVideo(fileext) {
    return VIDEO_FILES.indexOf(fileExt(fileext)) === -1 ? false : true;
}

export function isAudio(fileext) {
    return AUDIO_FILES.indexOf(fileExt(fileext)) === -1 ? false : true;
}

// is Pdf file
export function isPdf(fileext) {
    return fileExt(fileext) === ".pdf";
}

// is csv file
export function isCsv(fileext) {
    return fileExt(fileext) === ".csv";
}

// is txt file
export function isTxt(fileext) {
    return fileExt(fileext) === ".txt";
}

// is Microsoft file
export function isMsFile(fileext) {
    return MS_FILES.indexOf(fileExt(fileext)) === -1 ? false : true;
}
