import React, { useState, useMemo, useEffect, useRef } from "react";
import classNames from "classnames";
import { changeState, uniqueId, isNullOrUndefined } from "../fn";
import ErrorTooltip from "../elm/ErrorTooltip";

function InputHoc(BaseCmp, defaultValue = null) {
    function Wrap({
        name,
        className,
        style,
        onChange,
        onCommit,
        onTextFocusHandler,
        onInputAdd,
        onInputRemove,
        disabled = false,
        required = false,
        value,
        ...other
    }) {
        const childRef = useRef();
        const changedRef = useRef(false);

        const [state, setState] = useState(
            {
                error: "",
                validated: false,
                initData: isNullOrUndefined(value) ? defaultValue : value,
            },
            [],
        );

        const cmp = useMemo(() => {
            return {
                elmId: uniqueId(),
                nStyle: !style
                    ? { position: "relative" }
                    : { ...{ position: "relative" }, ...style },
            };
        }, []);

        const removeError = () => {
            changeState(setState, { error: "", validated: false });
        };

        const textFocusHandler = (e) => {
            removeError();
            if (onTextFocusHandler) onTextFocusHandler(e);
        };

        const changeHandler = (key, val = null, commitOnChange = false, error = "") => {
            if (!disabled) {
                // const newData = isNullOrUndefined(val) ? defaultValue : val;

                if (!commitOnChange) {
                    changedRef.current = true;
                    changeState(setState, {
                        error: "",
                        validated: false,
                    });
                }

                if (onChange) onChange(key, val);

                if (commitOnChange) {
                    if (!error || error === "") {
                        changedRef.current = false;
                        changeState(setState, { error: error, validated: true });
                        if (onCommit) onCommit(key, isNullOrUndefined(val) ? defaultValue : val);
                    } else {
                        changeState(setState, { validated: true, error: error });
                    }
                }
            }
        };

        // send final data to calling component for update if there is no error and there are changes
        const commitHandler = (key, data = null, error = "") => {
            if (!disabled) {
                if (changedRef.current && error === "") {
                    changedRef.current = false;
                    changeState(setState, { error: error, validated: true });
                    if (onCommit) onCommit(key, isNullOrUndefined(data) ? defaultValue : data);
                } else {
                    changeState(setState, { error: error, validated: true });
                }
            }
        };

        // OLD
        // const commitHandler = (key, error = "", data = null) => {
        //     if (!disabled) {
        //         changeState(setState, { error: error, validated: true, });
        //         if (onCommit) onCommit(key, error, isNullOrUndefined(data) ? defaultValue : data);
        //     }
        // };

        const showError = (error = "") => {
            changedRef.current = false;
            changeState(setState, { error: error, validated: true });
        };

        const validate = () => {
            let err = "";

            if (!disabled && childRef) {
                err = childRef.current.validate();
            }

            return { id: cmp.elmId, name: name, msg: err };
        };

        // useEffect(() => {
        //     validate();
        // }, [required]);

        useEffect(() => {
            changeState(setState, { initData: isNullOrUndefined(value) ? defaultValue : value });
        }, [value]);

        useEffect(() => {
            if (onInputAdd && !disabled) {
                onInputAdd(name, cmp.elmId, validate, showError);
            }

            return () => {
                if (onInputRemove && !disabled) onInputRemove(name);
            };
        }, []);

        return (
            <div id={cmp.elmId} className={classNames("cmp-wrap", className)} style={cmp.nStyle}>
                <BaseCmp
                    ref={childRef}
                    forwardedRef={childRef}
                    {...other}
                    name={name}
                    value={state.initData}
                    disabled={disabled}
                    required={required}
                    removeError={removeError}
                    // changed={changedRef.current}
                    // error={state.error}
                    // validated={state.validated}
                    onTextFocusHandler={textFocusHandler}
                    onChange={changeHandler}
                    onCommit={commitHandler}
                    // displayError={state.error}
                />

                <ErrorTooltip
                    isOpen={state.validated && state.error !== "" && state.error !== "inner"}
                    label={state.error}
                />
            </div>
        );
    }

    return Wrap;
}

export default InputHoc;
