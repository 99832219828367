import CryptoJS from "crypto-js";
import numeral from "numeral";
import moment from "moment";
// import S from 'string'
// import cookie from 'react-cookie'
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { customAlphabet } from "nanoid";
import pluralize from "pluralize";
import * as chrono from "chrono-node";
// import fuzzy from 'fuzzy'
import assign from "lodash.assign";
import clone from "lodash.clone";
import groupBy from "lodash.groupby";
import includes from "lodash.includes";
import keys from "lodash.keys";
import pick from "lodash.pick";
import range from "lodash.range";
import uniq from "lodash.uniq";
import isequal from "lodash.isequal";
import deepEqual from "deep-equal";
import { capitalCase, sentenceCase } from "change-case";
import { ERR_CODES } from "./data/baseData";
import libphonenumber from "google-libphonenumber";

const PNF = libphonenumber.PhoneNumberFormat;

/******************************
 // Browser checks
 ******************************/

/**
 * user browser is Apple safari
 * @returns {boolean}
 */
export function isSafari() {
    return navigator.vendor.indexOf("Apple") === 0 && /\sSafari\//.test(navigator.userAgent);
}

/**
 *
 * @param location
 * @returns {*|null}
 */
export function parsedLocation(location) {
    if (location) {
        if (location.hasOwnProperty("location")) return location.location;
        return location;
    }

    return null;
}

/*******************************************************
 // local storage functions
 *******************************************************/

/**
 * get key value for local storage
 * @param key
 * @returns {any|string|string}
 */
export function getLocalStorage(key) {
    let val = window.localStorage.getItem(key);
    return val ? decrypt(val) : val;
}

/**
 * set key value for local storage
 * @param key
 * @param value
 */
export function setLocalStorage(key, value) {
    return window.localStorage.setItem(key, encrypt(value));
}

/**
 * delete key for local storage
 * @param key
 */
export function deleteLocalStorageKey(key) {
    return window.localStorage.removeItem(key);
}

/*******************************************************
 // Data change marker list methods - used in api calls
 *******************************************************/

/**
 * register and track changed data for store
 * @param keys
 * @param key
 * @returns {Array}
 */
export function dataChanged(keys, key) {
    if (!includes(keys, key)) {
        return uniq(addToList(keys, key));
    } else {
        return uniq(keys);
    }
}

/**
 * clone list unique (no duplication) of changed props to currently saving marker list
 * @param keys
 * @returns {Array}
 */
export function dataKeysToSave(keys) {
    return uniq(keys);
}

/**
 * key and their values - ready to send to server
 * @param data
 * @param keys
 * @returns {*}
 */
export function getSaveData(data, keys) {
    return copy(pick(data, keys));
}

/**
 * add unsuccessful save keys back to change key marker list, so it can be saved next time
 * @param savedKeys
 * @param changedKeys
 * @returns {*}
 */
export function dataSaveError(savedKeys, changedKeys) {
    return changedKeys.concat(savedKeys);
}

/**
 * parse error object and return error string
 * @param res
 * @returns {string}
 */
export function serverErrorMsg(res) {
    let msg = "";
    let data = null;

    if (res) {
        if (res.data) data = res.data; // axios
        if (data === null && res.body) data = res.body; // superagent

        if (data) {
            if (data.message && data.message !== "") msg = data.message;
            if (msg === "" && data.statusCode) msg = ERR_CODES[data.statusCode];
        } else {
            if (res.message && res.message !== "") msg = res.message;
            if (msg === "" && res.status) msg = ERR_CODES[res.status];
        }
    }

    return msg === "" ? "There was error processing request" : msg;

    // return S(msg).humanize().s;
}

/*******************************************************
 // Data Utilities
 *******************************************************/

/**
 * encrypt value
 * @param val
 * @returns {string}
 */
export function encrypt(val) {
    let ret = CryptoJS.AES.encrypt(JSON.stringify(val), import.meta.env.VITE_SKEY);
    return ret.toString();
}

/**
 * decrypt value
 * @param val
 * @returns {any|string}
 */
export function decrypt(val) {
    try {
        let bytes = CryptoJS.AES.decrypt(val, import.meta.env.VITE_SKEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (ignore) {
        return "";
    }
}

/**
 * get unique time stamp string
 * @returns {string}
 */
export function getTimeId() {
    return new Date().getTime().toString();
}

/**
 * get new uuid version 4 value
 * @returns {*}
 */
export function uniqueId() {
    return uuidv4();
}

/**
 * takes an optional length parameter with a default value of 8. The second argument to customAlphabet specifies the characters to be used in generating the random string
 * @param length
 * @returns {string}
 */
export function stringNumGenerator(length = 8) {
    const id = customAlphabet("1234567890", length);
    return id(); //=> "4f90d13a42"
}

/**
 * validates if the input string is a valid UUID
 * @param str
 * @returns {false|*}
 */
export function isUUID(str) {
    return !isNullOrUndefined(str) && typeof str === "string" && uuidValidate(str);
}

/**
 * generates a random integer between 0 and 9999
 * @returns {string}
 */
export function randomId() {
    return Math.floor(Math.random() * 10000).toString();
}

/**
 * concatenates the given name parts into a full name with optional titles, first name, middle name, last name, and suffix
 * @param stn
 * @param first
 * @param mdl
 * @param last
 * @param sfx
 * @returns {string}
 */
export function fullName(stn, first, mdl, last, sfx) {
    let val = "";

    if (stn && stn.trim() !== "") val += stn + " ";
    if (first && first.trim() !== "") val += first + " ";
    if (mdl && mdl.trim() !== "") val += mdl + " ";
    if (last && last.trim() !== "") val += last + " ";
    if (sfx && sfx.trim() !== "") val += sfx;
    return val.trim();
}

/**
 * generates an array of numbers from a given range
 * @param min
 * @param max
 * @param step
 * @returns {Array}
 */
export function arrayFromRange(min, max, step) {
    let val = range(min, max, step);

    if (val.length > 0) {
        if (val[val.length - 1] < max) val.push(max);
    }

    return val;
}

/**
 * retrieves words from a collection of objects based on the given key or using a custom dataWordFunction.
 *
 * The function takes three arguments:
 *
 * collection (array of objects): the collection of objects to extract words from
 * key (string): the name of the property to extract words from
 * dataWordFunction (function): optional function that extracts words from each object in the collection. It takes an object as a parameter and returns an array of strings.
 * The function initializes an empty array arr, then iterates through the collection and extracts words based on the specified key or using the dataWordFunction.
 *
 * If the dataWordFunction is provided, it will be used to extract the array of strings for each item. If the result is falsy or an empty array, nothing is added to the output array.
 *
 * Otherwise, if the key is provided, it is used to access the value of the corresponding property. The function checks the type of the value and processes it accordingly:
 *
 * If the value is a string, non-empty strings are added directly to the output array.
 * If the value is a number, it is converted to a string and added to the output array.
 * If the value is an array of strings, all strings are added to the output array.
 * Otherwise, nothing is added to the output array.
 * Finally, the function sorts and returns the resulting array of words.
 * @param collection
 * @param key
 * @param dataWordFunction
 * @returns {*[]}
 */
export function getWords(collection, key, dataWordFunction = undefined) {
    let arr = [];

    if (!isNullOrUndefined(collection) && (key !== "" || dataWordFunction)) {
        try {
            collection.map((item) => {
                if (!isNullOrUndefined(dataWordFunction)) {
                    const str = dataWordFunction(item);

                    if (!isNullOrUndefined(str)) {
                        if (isArray(str)) {
                            if (str.length > 0) {
                                arr = arr.concat(str);
                            }
                        } else {
                            if (str) arr.push(str);
                        }
                    }
                } else {
                    if (item.hasOwnProperty(key)) {
                        if (!isNullOrUndefined(item[key])) {
                            const dataVal = item[key];

                            if (typeof dataVal === "string") {
                                if (dataVal.trim() !== "") arr.push(dataVal);
                            } else if (typeof dataVal === "number") {
                                if (!isNaN(dataVal)) arr.push(dataVal.toString());
                            } else {
                                if (dataVal.length > 0) {
                                    dataVal.map((subitem) => {
                                        arr.push(subitem);
                                    });
                                }
                            }
                        }
                    }
                }
            });
        } catch (ignore) {}
    }

    return arr.sort();
}

/**
 * This is a JavaScript function groupByWords that groups an array of words based on their frequency.
 *
 * The function takes a single argument:
 *
 * wordsList (array of strings): the list of words to group
 * The function checks if the given array is truthy and not empty. If so, it uses the reduce() method to iterate through the array and accumulate a count for each unique word.
 *
 * For each word encountered, a check is performed to see if it already exists as a property in the accumulator object (allWords). If it does, its count is incremented by 1. Otherwise, a new property with the word as key and value 1 is added to the object.
 *
 * Finally, the function returns the resulting object with keys being the words and values being their frequency.
 *
 * If the input array is falsy or empty, the function returns null.
 * @example const words = ['apple', 'banana', 'orange', 'apple', 'kiwi', 'banana', 'orange', 'orange'];
 * const groupedWords = groupByWords(words);
 * //=> { 'apple': 2, 'banana': 2, 'orange': 3, 'kiwi': 1 }
 *
 * const emptyList = [];
 * const emptyResult = groupByWords(emptyList); //=> null
 * @param wordsList
 * @returns {*|null}
 */
export function groupByWords(wordsList) {
    if (wordsList && wordsList.length > 0) {
        return wordsList.reduce(function (allWords, word) {
            if (word in allWords) {
                allWords[word]++;
            } else {
                allWords[word] = 1;
            }
            return allWords;
        }, {});
    } else {
        return null;
    }
}

/**
 * This is a JavaScript function groupByWordsList that converts an object of word frequencies into an array of objects with a format suitable for display, each containing the word, its frequency count, and a label.
 *
 * The function takes a single argument:
 *
 * obj (object): the object mapping words to their frequency count
 * The function initializes an empty array list, then iterates over the keys of the input object using Object.keys().
 *
 * For each key encountered, a new object is created with its properties set to data: key, label: key, and count: obj[key] (the frequency count for that word).
 *
 * The resulting object is pushed into the list array.
 *
 * Finally, the function sorts the array and returns it.
 * @example const wordFreq = { 'apple': 2, 'banana': 2, 'orange': 3, 'kiwi': 1 };
 * const list = groupByWordsList(wordFreq);
 *
 * [
 *   { data: 'apple', label: 'apple', count: 2 },
 *   { data: 'banana', label: 'banana', count: 2 },
 *   { data: 'kiwi', label: 'kiwi', count: 1 },
 *   { data: 'orange', label: 'orange', count: 3 }
 * ]
 *
 * @param obj
 * @returns {*[]}
 */
export function groupByWordsList(obj) {
    let list = [];

    Object.keys(obj).forEach((key) => {
        list.push({ data: key, label: key, count: obj[key] });
    });

    return list.sort();
}

/**
 * takes an email address as a parameter and returns a normalized version of the email's mailto link.
 *
 * If the email already starts with "mailto:", it will return the email as is. Otherwise, it will add "mailto:" before the email and return the new string.
 * @param email
 * @returns {*|string}
 */
export function normalizeMailLink(email) {
    if (email.toLowerCase().startsWith("mailto:")) {
        return email;
    }
    return `mailto:${email}`;
}

/**
 * Takes a URL string as a parameter and returns a normalized version of the URL.
 *
 * It first checks if the URL starts with "http://" or "https://". If it doesn't, it adds "https://" before the URL.
 *
 * Finally, it converts the entire URL to lowercase and returns it.
 * @param url
 * @returns {string}
 */
export function addHttps(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "https://" + url;
    }
    return url.toLowerCase();
}

/**
 * function that takes a value and a default value as parameters and returns "Yes" or "No" based on the input value.
 *
 * First, it checks if the input value is not null or undefined. If it is, it returns the default value.
 *
 * If the input value is a non-empty string, it converts it to lowercase and checks if it matches any of the accepted values: "y", "yes", "true", "1", "n", "no", "false", or "0". If it matches any of the "Yes" values, the function returns "Yes". If it matches any of the "No" values, the function returns "No".
 *
 * If the input value is a number, it checks if it's equal to 0 or -1 (which map to "No"), and if it's equal to 1 (which maps to "Yes").
 *
 * If the input value is a boolean, it returns "Yes" if the value is true and "No" if the value is false.
 *
 * Finally, if the input value doesn't match any of the accepted values, the function returns the default value.
 * @param val
 * @param defaultValue
 * @returns {string}
 */
export function yesOrNo(val, defaultValue = "") {
    let ret = defaultValue;

    if (!isNullOrUndefined(val)) {
        if (typeof val === "string" && val !== "") {
            const tV = val.toLowerCase();

            switch (tV) {
                case "y":
                case "yes":
                case "true":
                case "1":
                    ret = "Yes";
                    break;
                case "n":
                case "no":
                case "false":
                case "0":
                    ret = "No";
                    break;
            }
        } else {
            if (typeof val === "number") {
                if (val === 0 || val === -1) {
                    ret = "No";
                } else if (val === 1) {
                    ret = "Yes";
                }
            } else if (typeof val === "boolean") {
                ret = val === true ? "Yes" : "No";
            }
        }
    }

    return ret;
}

// get video thumbnail url
// export function videoClipUrl(fileData) {
//     let val = fileRoot(fileData.entity);

//     if (fileData.entityid !== "") val += fileData.entityid + "/";
//     if (fileData.key !== "") val += fileData.key + "/";
//     val += fileData.filename.replace("." + fileData.fileext, "-clip.png");
//     return val;
// }

// ----------------------------
// Object utilities
// ----------------------------
// mobx get value of map object (map)
export function getValue(obj, key) {
    return obj.get(key); // if mobx map
}

export function sortObject(obj) {
    return Object.keys(obj).sort();
}

export function copy(orgVal) {
    return clone(orgVal);
}

export function hasObject(obj) {
    return obj && Object.keys(obj).length > 0;
}

export function isArray(obj) {
    return Object.prototype.toString.call(obj) === "[object Array]";
}

// merge new object to exsiting object without adding new keys
export function mergeExistingOnly(updateIntoObj, updateObj) {
    return clone(assign(updateIntoObj, pick(updateObj, keys(updateIntoObj))));
}

// check for key in object
export function hasProperty(obj, property) {
    let result = false;
    if (obj) {
        for (const key in obj) {
            // eslint-disable-line no-restricted-syntax
            if ({}.hasOwnProperty.call(obj, key) && property === key) {
                result = true;
                break;
            }
        }
    }
    return result;
}

export function isUndefined(data) {
    return data === undefined || data === "undefined";
}

/**
 * Check if value is null or undefined
 * @param data
 * @returns {boolean}
 */
export function isNullOrUndefined(data) {
    if (data === null || data === undefined || data === "undefined") {
        return true;
    } else {
        return false;
    }
}

export function isBlank(data) {
    if (isNullOrUndefined(data)) return true;
    if (typeof data === "string" && data.trim() === "") return true;
}

export function formatPostalCode(postalCode) {
    if (!postalCode || postalCode.trim() === "") return "";
    return postalCode.replace(/(\d{5})(\d{4})?/, function (_, p1, p2) {
        return p2 ? `${p1}-${p2}` : p1;
    });
}

/**
 * that takes two objects as parameters and returns a boolean indicating whether they are equal or not.
 *
 * It's a wrapper function for the isequal function, which is likely a custom implementation of object equality comparison. The isequal function is called with the two objects passed as arguments, and its return value is returned as the result of the isSame function.
 *
 * Therefore, this function determines if the two objects are identical in terms of their properties and values, regardless of whether they are references to the same object or not.
 * @param oldObj
 * @param newObj
 * @returns {boolean}
 */
export function isSame(oldObj, newObj) {
    return isequal(oldObj, newObj);
}

/**
 * takes two objects as parameters and returns a boolean indicating whether they are equal or not.
 *
 * It's a wrapper function for the deepEqual function, which is likely a custom implementation of object equality comparison. The deepEqual function is called with the two objects passed as arguments, and its return value is returned as the result of the equal function.
 *
 * Therefore, this function determines if the two objects are equal in terms of their properties and values, including nested objects, arrays, and other complex data types. This function will return true only if the two objects are identical, i.e., have the same properties with the same values.
 * @param objA
 * @param objB
 * @returns {boolean|*|boolean}
 */
export function equal(objA, objB) {
    return deepEqual(objA, objB);
}

/**
 * takes an object and a property name as parameters, and returns a boolean indicating whether the property has a non-null/undefined/"undefined" value in the object or not.
 *
 * The function checks if the object has the specified property using the hasOwnProperty method. If the property exists, it checks if its value is null, undefined, or the string "undefined". If the value is any of these, it returns false indicating that the property does not have a valid value. Otherwise, it returns true indicating that the property has a valid value.
 *
 * If the object does not have the specified property, the function also returns false indicating that the property does not have a valid value.
 * @param dataObj
 * @param column
 * @returns {boolean}
 */
export function hasValue(dataObj, column) {
    if (dataObj.hasOwnProperty(column)) {
        if (
            dataObj[column] === null ||
            dataObj[column] === undefined ||
            dataObj[column] === "undefined"
        ) {
            return false;
        } else {
            return true;
        }
    }

    return false;
}

/**
 * takes an object as a parameter and returns a boolean indicating whether it's a new object or not.
 *
 * If the input object is null or undefined, the function considers it a new object and returns true.
 *
 * If the object is not null or undefined, the function checks if it has an "id" property using the hasOwnProperty method. If it has an "id" property, the function checks if its value is null, empty string, or undefined. If the "id" value is any of these, the function considers the object a new one and returns true. Otherwise, it assumes that the object is not new and returns false.
 *
 * If the object doesn't have an "id" property, the function considers it a new object and returns true.
 * @param dataObj
 * @returns {boolean}
 */
export function isNewObj(dataObj) {
    if (isNullOrUndefined(dataObj)) {
        return true;
    } else {
        if (dataObj.hasOwnProperty("id")) {
            return dataObj.id === null || dataObj.id === "" || dataObj.id === undefined;
        } else {
            return true;
        }
    }
}

/**
 * takes two objects as parameters and returns a new object that merges the properties of both input objects.
 *
 * It uses the Object.assign() method to create a new object and copy the properties of the oldObj parameter to it. Then, it copies the properties of the updateObj parameter to the new object, overwriting any properties with the same name.
 *
 * The returned object contains all properties from both input objects, with any overlapping properties in updateObj taking precedence over those in oldObj.
 * @param oldObj
 * @param updateObj
 * @returns {any}
 */
export function updateObject(oldObj, updateObj) {
    return Object.assign({}, oldObj, updateObj);
}

/**
 * takes an object, a key and a value as parameters and returns a new object with the specified key updated to the specified value.
 *
 * It creates a new object named newObj and assigns the provided key property with the provided value. Then it uses Object.assign() method to merge the properties of the oldObj parameter into newObj, with the updated property.
 *
 * The returned object contains all properties from the oldObj parameter, with the updated value for the specified key property. If the key does not exist in the original object, it will be added with the specified value.
 * @param oldObj
 * @param key
 * @param value
 * @returns {any}
 */
export function updateObjectKey(oldObj, key, value) {
    let newObj = {};

    newObj[key] = value;

    return Object.assign({}, oldObj, newObj);
}

// set object data in a object - used in import data
/**
 * takes five parameters: an object data, two string values objectKey and detailKey, an optional value (default is null), and an optional objModel object (default is an empty object).
 *
 * It creates an empty object named tmpData and applies the following operations:
 *
 * If the data object has a property with the name objectKey, it retrieves its value and assigns it to the tgtData variable. Otherwise, the function uses the objModel object (or an empty object if no objModel was provided) as the target data.
 * The function then assigns the value parameter to the detailKey property of the tmpData object.
 * Finally, it returns a new object that merges the tmpData object with the tgtData object using the updateObject() function.
 * The returned object is a copy of the tgtData object, but with the detailKey property updated to the value parameter. If data doesn't have the specified objectKey, the returned object is a copy of objModel object, but with the detailKey property updated to the value parameter
 * @param data
 * @param objectKey
 * @param detailKey
 * @param value
 * @param objModel
 * @returns {*}
 */
export function getUpdateObject(data, objectKey, detailKey, value = null, objModel = {}) {
    let tmpData = {};
    let tgtData = data.hasOwnProperty(objectKey) ? data[objectKey] : objModel;

    tmpData[detailKey] = value;
    return updateObject(tgtData, tmpData);
}

/**
 * takes an object obj and a string value str as parameters, and returns a boolean indicating whether any property of the object matches the given string.
 *
 * The function first escapes any special characters in the string using the escapeRegexCharacters() function. Then, it creates a regular expression using the escaped value and the "i" flag to make the search case-insensitive.
 *
 * Next, the function iterates over all properties of the object using a for-in loop. For each property, it checks the type of the value using the typeof operator. If it's a string, the function checks if the regular expression matches the value of the property. If it's a number, the function converts the number to a string and checks if the regular expression matches the string representation of the number.
 *
 * If a match is found, the function returns true. Otherwise, it continues iterating over the remaining properties. If no match is found, the function returns false.
 * @param obj
 * @param str
 * @returns {boolean}
 */
export function objectHasMatch(obj, str) {
    const escapedValue = escapeRegexCharacters(str);
    const regex = new RegExp(escapedValue, "i");

    for (let key in obj) {
        // iterate all keys
        const dataType = typeof obj[key];

        if (dataType === "string") {
            // match only if key value is string
            if (regex.test(obj[key])) return true;
        } else if (dataType === "number") {
            if (regex.test(obj[key].toString())) return true;
        }
    }

    return false;
}

/*********************************************
 // Array utilities
 *********************************************/

// get index of value
/**
 * takes an array and a value as input parameters. It then iterates over each element of the array using the forEach() method. For each element, it checks if the element is equal to the given value. If it is, it sets the idx variable to the index of the element and returns. If no match is found, the function returns -1.
 * @param {*} arr
 * @param {*} val
 * @returns
 */
export function findArrayIndex(arr, val) {
    let idx = -1;

    arr.forEach(function (item, index) {
        if (item === val) {
            idx = index;
            return;
        }
    });

    return idx;
}

/**
 *  takes an array as a parameter and returns a new array with only the unique elements from the input array.
 *
 * The function uses the uniq() function to remove any duplicate elements in the input array. If the input array is falsy (e.g., null, undefined, or an empty string), the function returns the same value as is, without removing any duplicates.
 *
 * The returned array contains all elements from the input array, but with duplicates removed. The order of the elements is preserved in the output array.
 * @param array
 * @returns {*|Array}
 */
export function uniqueArray(array) {
    if (array) return uniq(array);
    return array;
}

/**
 * takes an array and an optional replacement string as parameters, and returns a string with the values of the elements in the array concatenated together using the replacement string as a separator.
 *
 * The function checks if the input list parameter is not null or undefined. If it's not, it uses the join() method to concatenate all elements of the array into a string using the replacement parameter as the separator. If the replacement parameter is not provided, a newline character (\n) is used as the default separator.
 *
 * If the list parameter is null or undefined, the function returns an empty string. Otherwise, it returns the concatenated string with a replacement string separating each element.
 * @param list
 * @param replacement
 * @returns {*|string}
 */
export function arrayToString(list, replacement = "\n") {
    if (!isNullOrUndefined(list)) {
        return list.join(replacement);
    }

    return "";
}

/**
 * takes three parameters: an array list, a new item to add to the list newItem, and an optional index idx.
 * @param list
 * @param newItem
 * @param idx
 * @returns {unknown[]|*[]}
 */
export function addToList(list, newItem, idx) {
    const tList = !list ? [] : list;

    if (!isNullOrUndefined(idx)) {
        return [...tList.slice(0, idx + 1), newItem, ...tList.slice(idx + 1)];
    } else {
        return [...tList, newItem];
    }
}

/**
 * update list without mutation
 * @param list
 * @param newItem
 * @param idx
 * @returns {*[]}
 */
export function updateList(list, newItem, idx) {
    const oldItem = list[idx];

    if (isNullOrUndefined(oldItem)) {
        return [...list.slice(0, idx), newItem, ...list.slice(idx + 1)];
    } else {
        if (typeof oldItem !== "object") {
            return [...list.slice(0, idx), newItem, ...list.slice(idx + 1)];
        } else {
            const newObj = { ...oldItem, ...newItem };

            return [...list.slice(0, idx), newObj, ...list.slice(idx + 1)];
        }
    }
}

/**
 * remove from list without mutation
 * @param list
 * @param idx
 * @returns {*[]}
 */
export function removeFromList(list, idx) {
    return [...list.slice(0, idx), ...list.slice(idx + 1)];
}

/**
 * takes three parameters: an object data, a string value key, and an optional default value defaultVal (default is null).
 *
 * The function checks if the input data object has a property with the name key using the hasOwnProperty() method. If it does, the function checks if the property is falsy (e.g., null, undefined, an empty string, or 0). If it's falsy, the function returns the defaultVal. Otherwise, it returns the value of the key property from the data object.
 *
 * If the data object does not have the specified key, the function also returns the defaultVal.
 * @param data
 * @param key
 * @param defaultVal
 * @returns {null|*}
 */
export function findArrData(data, key, defaultVal = null) {
    return data.hasOwnProperty(key) ? (!data[key] ? defaultVal : data[key]) : defaultVal;
}

/**
 * takes an array arr, another array of values values, and an optional string parameter key as inputs, and returns a boolean indicating whether any value in the values array is found in the arr array.
 *
 * The function first checks if the arr input is not null or undefined. If it's not, the function iterates over each value in the values array using a for loop. For each value, it looks for a match in the arr array by either checking the index of the findVal in arr using the indexOf() method (if key is an empty string), or by calling the findListItemIndex() function to look for an object in arr that has a property with the specified key and the same findVal value. If a match is found, the function returns true.
 *
 * If no match is found after all values in the values array are checked, or if the arr input is null or undefined, the function returns false.
 * @param arr
 * @param values
 * @param key
 * @returns {boolean}
 */
export function arrayHasArrayValues(arr, values, key = "") {
    if (arr) {
        for (let i = 0; i < values.length; i++) {
            const findVal = values[i];

            if (key === "") {
                if (arr.indexOf(findVal) > -1) return true;
            } else {
                const idx = findListItemIndex(arr, key, findVal);
                if (idx > -1) return true;
            }
        }
    }

    return false;
}

/**
 * set array data in object - used in import data
 * takes multiple parameters to update an object within an array of objects.
 *
 * The data parameter is the original array of objects, whereas objectKey is the property name of the object in the array that needs to be updated. The arrayKey parameter identifies the sub-property name of the target object to be updated.
 *
 * If detailKey property is provided, it further denotes a nested object within the trgtData object specified by arrayKey.
 * @param data
 * @param objectKey
 * @param arrayKey
 * @param value
 * @param rowIdx
 * @param rowModel
 * @param detailKey
 * @param detailModel
 * @returns {*[]}
 */
export function getUpdateArray(
    data,
    objectKey,
    arrayKey,
    value = null,
    rowIdx = 0,
    rowModel = {},
    detailKey = "",
    detailModel = null,
) {
    let ret = findArrData(data, objectKey, []);
    let tgtData = ret.length > rowIdx ? updateObject(rowModel, ret[rowIdx]) : rowModel;

    if (detailKey === "") {
        tgtData[arrayKey] = value;
    } else {
        const nestedObj = tgtData.hasOwnProperty(detailKey) ? tgtData[detailKey] : detailModel;
        let tmpData = {};

        tmpData[detailKey] = value;
        tgtData[arrayKey] = updateObject(nestedObj, tmpData);
    }

    if (ret.length > rowIdx) {
        ret = updateList(ret, tgtData, rowIdx);
    } else {
        ret = addToList(ret, tgtData);
    }

    return ret;
}

/**
 * takes an array of words listOfWords as a parameter and returns a new array of objects with three properties: "label", "data", and "count".
 * listOfWords: ['the', 'here', 'there', 'the']
 * @param listOfWords
 * @returns {*[]}
 */
export function wordsToCollection(listOfWords) {
    let data = [];
    const obj = groupByWords(listOfWords);

    Object.keys(obj).forEach(function (key) {
        data.push({ label: key, data: key, count: obj[key] });
    });

    return data;
}

/**
 * takes an array arr as input and returns a new array with only the unique elements of the original array.
 * @param arr ([string], [int])
 * @returns {null}
 */
export function arrayUniqueList(arr) {
    let ret = null;

    if (arr && arr.length > 0) {
        ret = [];

        arr.map((item) => {
            if (ret.indexOf(item) === -1) ret.push(item);
        });
    }

    return ret;
}

/**
 * Takes array and returns array of duplicate items
 * @param arr
 * @param key
 * @returns {null|*[]}
 */
export function arrayDuplicates(arr, key) {
    if (!arr || arr.length === 0) {
        return null;
    } else {
        let dupes = [];

        arr.map((itm, idx) => {
            if (key && key !== "") {
                arr.map((innerItm, innerIdx) => {
                    if (innerItm[key] === itm[key] && innerIdx !== idx) dupes.push(innerItm);
                });
            } else {
                arr.map((innerItm, innerIdx) => {
                    if (innerItm === itm && innerIdx !== idx) dupes.push(innerItm);
                });
            }
        });

        return dupes.length === 0 ? null : dupes;
    }
}

/************************************************
 // Collection utilities
 ************************************************/
/**
 * It returns the index of the first element in the array that matches the specified column value or the specified value, depending on whether the column parameter is provided.
 * @param arr
 * @param column
 * @param val
 * @returns {number}
 */
export function findListItemIndex(arr, column, val) {
    let idx = -1;

    if (arr) {
        if (column && column !== "") {
            arr.forEach((item, index) => {
                if (item[column] === val) {
                    idx = index;
                    return;
                }
            });
        } else {
            arr.forEach((item, index) => {
                if (item === val) {
                    idx = index;
                    return;
                }
            });
        }
    }

    return idx;
}

/**
 * takes an array of objects list, a string key, and a value value. The function returns the first element in the input array that has a property with the specified key and the specified value
 * @param list
 * @param key
 * @param value
 * @returns {null}
 */
export function findListItem(list, key = "", value) {
    let val = null;

    if (list && list.hasOwnProperty("length") && value) {
        for (let i = 0; i < list.length; i++) {
            const itm = list[i];

            if (itm && itm.hasOwnProperty(key) && itm[key] === value) {
                val = itm;
                break;
            }
        }
    }

    return val;
}

/**
 *
 * @param list
 * @param key
 * @param asc
 * @returns {*}
 */
export function sortCollection(list, key, asc = true) {
    if (list) {
        return list.slice().sort((a, b) => {
            let val = 0;

            // sort by key
            if (hasProperty(a, key) && hasProperty(b, key)) {
                val = a[key] === b[key] ? 0 : a[key] < b[key] ? (asc ? -1 : 1) : asc ? 1 : -1;
                return val;
            }

            return val;
        });
    }

    return list;
}

/**
 * takes three parameters: an array of objects list, a string key, and a value value. The function returns the first element in the input array that has a property with the specified key and the specified value.
 * @param list
 * @param key
 * @returns {Object}
 */
export function groupByKey(list, key) {
    return groupBy(list, (item) => {
        return item[key];
    });
}

/**
 * group collection by date
 * @param list
 * @param key
 * @param format
 * @param toUtc
 * @returns {Object}
 */
export function groupByDate(list, key, format, toUtc = true) {
    return groupBy(list, (item) => {
        if (!toUtc) {
            return moment(item[key]).startOf("day").format(format);
        } else {
            return moment(item[key]).utc().startOf("day").format(format);
        }
    });
}

//
// export function getMatchingValues(list, val, column) {
//   let escapedValue = escapeRegexCharacters(val.trim());
//   let regex = new RegExp('^' + escapedValue, 'i');
//
//   return list.filter(list => regex.test(list[column]));
// }

/**
 *  get array list current filter for key > i.e. 'tags'
 * takes two parameters: an object filter and a string key, and returns the "data" property of the object found at the specified key in the filter object. If no such object or key exists, the function returns null.
 * @param filter
 * @param key
 * @returns {*|null}
 */
export function listFilterSelections(filter, key) {
    if (!isNullOrUndefined(filter) && filter.hasOwnProperty(key)) return filter[key].data;

    return null;
}

// filter object - no mutation
// key ="tags"
// val = string, bool, int
// fn = custom filter function
/**
 * takes four parameters: an object filter, a string key, an optional value val, and an optional function fn. The function returns a new filter object that corresponds to the updated list of filters.
 * @param filter object
 * @param key
 * @param val string, bool, int
 * @param fn custom filter function
 * @returns {{}|*}  no mutation
 */
export function listFilterChange(filter, key, val = null, fn = undefined) {
    if (key) {
        let retFilter = {};

        if (isNullOrUndefined(val)) {
            retFilter = { ...filter };

            delete retFilter[filter];
        } else {
            let filterItem = { data: val, fn: fn };

            if (isNullOrUndefined(filter)) {
                // no filters yet
                retFilter[key] = filterItem;
            } else {
                // list already has filters
                retFilter = { ...filter };
                let selections = listFilterSelections(filter, key);

                if (isNullOrUndefined(selections)) {
                    // filter does not exist
                    selections = [val];
                } else {
                    // key already has selections
                    if (selections.includes(val)) {
                        // filter value needs to be removed / toggled
                        const idx = selections.indexOf(val);

                        selections.splice(idx, 1);
                    } else {
                        // filter needs to be added to existing type
                        selections.push(val);
                    }
                }

                if (!selections || selections.length === 0) {
                    // no selections for key
                    delete retFilter[key];
                } else {
                    filterItem.data = selections;
                    retFilter[key] = filterItem;
                }
            }
        }

        return retFilter;
    } else {
        return filter;
    }
}

/**
 * takes two parameters: an object filters and an item. The function returns a boolean indicating whether the item passes all of the filters in the filters object.
 * The function checks if the filters object is null or undefined, or if it doesn't contain any keys. If either of these conditions are true, the function returns true (i.e., the item is considered to have passed all filters).
 * If the filters object contains at least one filter, the function sets an initial value of show to true, which will only be set to false if the item fails to pass any of the filters.
 * @param filters [...{providers: {data: ['active'], fn: undefined}}]
 * @param item
 * @returns {boolean}
 */
export function listFilter(filters, item) {
    if (isNullOrUndefined(filters) || Object.keys(filters).length === 0) {
        return true;
    } else {
        let show = true;

        Object.keys(filters).map((filterKey) => {
            const filter = filters[filterKey];

            if (show) {
                if (
                    filter.hasOwnProperty("data") &&
                    !isNullOrUndefined(filter.data) &&
                    filter.data.length > 0
                ) {
                    if (!isNullOrUndefined(filter.fn)) {
                        // has custom filter

                        show = filter.fn(filter, item);
                    } else {
                        const isArray =
                            filterKey.indexOf(".") > -1 ||
                            filterKey.indexOf("tags") > -1 ||
                            filterKey === "keywords" ||
                            filterKey === "providers" ||
                            filterKey === "members";
                        const selections = filter.data;
                        const keys = filterKey.split(".");
                        const baseKey = keys && keys.length > 1 ? keys[0] : filterKey;

                        if (selections.length > 0 && item.hasOwnProperty(baseKey)) {
                            if (isArray) {
                                show = arrayHasArrayValues(
                                    item[baseKey],
                                    selections,
                                    filterKey && filterKey.length > 1 ? keys[1] : "",
                                );
                            } else {
                                show = selections.includes(item[filterKey]);
                            }

                            if (!show) return false;
                        }
                    }
                }
            }
        });

        return show;
    }
}

export function filterCollectionByList(collection, key, filterList) {
    if (filterList) {
        if (filterList.length > 0) {
            return collection.filter((item) => {
                if (item.hasOwnProperty(key)) {
                    return filterList.includes(item[key]);
                }

                return false;
            });
        }
    }

    return collection;
}

/**
 * Returns new filtered array of list
 * @param str
 * @param list
 * @returns {*}
 */
export function filterList(str, list) {
    if (list === null || str === "") {
        return list;
    } else {
        // not remote data and not blank search - filter local data list with regex
        // returned filtered list
        return list.filter((item) => {
            if (str === "") {
                return true;
            } else {
                return objectHasMatch(item, str);
            }
        });
    }
}

/**
 * find index of any row that matches value in a collection
 * @param collection
 * @param val
 * @returns {number}
 */
export function findIndexInCollection(collection, val) {
    const searchVal = typeof val === "string" ? val.trim().toLowerCase() : val;
    let idx = -1;

    for (let i = 0; i < collection.length; i++) {
        const item = collection[i];
        let found = false;

        Object.keys(item).map((key) => {
            if (!found) {
                const keyVal = item[key];
                const matchVal = typeof keyVal === "string" ? keyVal.trim().toLowerCase() : keyVal;
                found = matchVal === searchVal;
            }
        });

        if (found) {
            idx = i;
            break;
        }
    }

    return idx;
}

/**
 * update collection without mutation
 * @param list
 * @param key
 * @param item
 * @param itemKey
 * @param addNotFound
 * @returns {*[]|*[]|*}
 */
export function collectionUpdate(list, key, item, itemKey, addNotFound = true) {
    const tList = !list ? [] : list;
    const idx = findListItemIndex(tList, key, item[itemKey]);

    if (idx > -1) {
        const nItem = { ...tList[idx], ...item };
        return updateList(tList, nItem, idx);
    } else {
        if (addNotFound) return addToList(tList, item);
        return list;
    }
}

/**
 * update collection without mutation
 * @param list
 * @param key
 * @param item
 * @param itemKey
 * @returns {*|*[]}
 */
export function collectionRemove(list, key, item, itemKey) {
    const tList = !list ? [] : list;
    const idx = findListItemIndex(tList, key, item[itemKey]);

    if (idx > -1) {
        return removeFromList(tList, idx);
    } else {
        return list;
    }
}

/**
 * takes three parameters: an array array of objects, a parent object parent, and a tree object tree. The function returns a new array that represents a hierarchical structure of objects.
 * @param array
 * @param parent
 * @param tree
 * @returns {*|*[]}
 */
export function unflatten(array, parent, tree) {
    tree = typeof tree !== "undefined" ? tree : [];
    parent = typeof parent !== "undefined" ? parent : { id: null };

    let children = array.filter((child) => {
        return child.parent_id === parent.id;
    });

    if (children) {
        if (parent.id === null) {
            tree = children.sort((a, b) => a.name > b.name);
        } else {
            parent["children"] = children;
        }

        children.map((child) => {
            unflatten(array, child);
        });
    }

    return tree.sort((a, b) => a.name > b.name);
}

/******************************
 // String functions
 ******************************/
/**
 * Pluralize word
 * @param word
 * @param count
 * @returns {*}
 */
export function getPlural(word, count) {
    return pluralize(word, count);
}

/**
 * title case string
 * @param str
 * @returns {string}
 */
export function toTitleCase(str = "") {
    let ret = "";

    if (!isNullOrUndefined(str)) ret = capitalCase(str);
    return ret;
}

// export function toTitleCase(str) {
//     if (str) {
//         return str.replace(/\w\S*/g, function(txt) {
//             return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//         });
//     }

//     return "";
// }

/**
 * sentence case string
 * @param str
 * @returns {string}
 */
export function toSentenceCase(str = "") {
    let ret = "";
    if (!isNullOrUndefined(str)) ret = sentenceCase(str);
    return ret;
}

/**
 * linebreak string to html
 * @param str
 * @returns {string}
 */
export function linebreak(str) {
    if (str && typeof str === "string" && str.trim() !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    }
    return str;
}

/**
 * format linebreak to html
 * @param str
 * @returns {string}
 */
export function formatToHtml(str) {
    if (str && typeof str === "string" && str.trim() !== "") return linebreak(str.toString());
    return "";
}

/**
 * returns a new string with all regular expression special characters escaped by appending a backslash before them
 * @param str
 * @returns {*}
 */
export function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * check if string starts with value
 * @param str
 * @param searchString
 * @param position
 * @returns {boolean}
 */
export function startsWith(str, searchString, position = 0) {
    if (str && searchString) {
        return str.substr(position || 0, searchString.length) === searchString;
    } else {
        return false;
    }
}

/********************************************
 // Number functions
 ********************************************/
/**
 * returns a floating-point value with a maximum of 12 significant digits. If the input is not a number, it simply returns the input value.
 * @param num
 * @returns {*|number}
 */
export function getFloat(num) {
    try {
        return parseFloat(parseFloat(num).toPrecision(12));
    } catch (ignore) {
        return num;
    }
}

// convert string to number
/**
 *
 * @param str
 * @returns {null|number}
 */
export function stringToNumber(str) {
    try {
        const tmp = numeral(str);

        return isNaN(tmp.value()) ? null : parseFloat(tmp.value());
    } catch (ignore) {
        return null;
    }
}

// convert number to string - 0.[000]
/**
 *
 * @param num
 * @param format
 * @returns {*|string}
 */
export function numberToString(num, format) {
    let val = typeof num === "string" ? stringToNumber(num) : isNullOrUndefined(num) ? "" : num;

    if (isNullOrUndefined(val)) return "";
    if (val === "") return "";
    return numeral(num).format(format);
}

/**
 *
 * @param str
 * @param currency
 * @returns {*|null}
 */
export function stringToCurrency(str, currency = "USD") {
    try {
        const num = numeral(str);
        return currency === "USD" ? num.format("$0,0.00") : num.format("0,0");
    } catch (ignore) {
        return null;
    }
}

/********************************************
 // date utilities
 ********************************************/
/**
 * format date object to string
 * https://momentjs.com/docs/#/displaying/format/
 * @param date
 * @param format - M/D/YY h:mm A
 * @param ignoreTimezone
 * @returns {string}
 */
export function formatDate(date, format = "M/D/YYYY", ignoreTimezone = false) {
    if (!date) {
        return "";
    } else {
        try {
            if (ignoreTimezone === false) {
                return new moment(new Date(date)).format(format);
            } else {
                return new moment(new Date(date)).utc().format(format);
            }
        } catch (ignore) {
            return "";
        }
    }
}

/**
 * returns a string representing the date in the format "YYYY-MM-DD", without including the timezone information.
 * @param date
 * @param attachCurrentTimeZone
 * @returns {*|null}
 */
export function dateStrWithoutTimezone(date, attachCurrentTimeZone = false) {
    if (date) {
        if (isNaN(date)) return null;

        let nStr = date.toISOString();
        nStr = nStr.substring(0, nStr.indexOf("T"));

        // if (attachCurrentTimeZone) {
        //     const clientDate = new Date();
        //     const cD = clientDate.toISOString();

        //     console.log('clientDate.getTimezoneOffset()', clientDate.getTimezoneOffset());

        //     // nStr += "T00:00:00.000Z";
        //     nStr += cD.substring(cD.indexOf("T"), cD.length);
        // }

        return nStr;

        // const n = new Date(nStr);
        // const isoStr = n.toISOString();
        // return isoStr.substring(0, isoStr.indexOf("T"));
    } else {
        return null;
    }
}

/**
 * d1 and d2, which are both date/time values. The function returns a string that represents the time difference between the two dates, using a format such as "5 minutes ago" or "3 hours ago".
 * @param d1
 * @param d2
 * @returns {string}
 */
export function timeAgo(d1, d2) {
    if (!d1 || !d2) {
        return "";
    } else {
        return moment(d1).from(d2, true);
    }
}

/**
 * d1 and d2, which are both date/time values, and an optional string unit that represents the duration unit (default is "seconds"). The function returns the duration between the two dates in the specified unit.
 * @param d1
 * @param d2
 * @param unit
 * @returns {number|null}
 */
export function timeDuration(d1, d2, unit = "seconds") {
    if (!d1 || !d2) {
        return null;
    } else {
        return moment(d1).diff(d2, unit, true);
    }
}

/**
 * d1 and d2, which are both date/time values. The function returns a string that represents the duration between the two dates as a human-readable value.
 * @param d1
 * @param d2
 * @returns {string}
 */
export function timeDurationString(d1, d2) {
    if (!d1 || !d2) {
        return "";
    } else {
        const diff = moment(d1).diff(d2, "seconds", true);

        if (diff > 86400) return (Math.round((diff / 86400) * 100) / 100).toString() + " Days";
        if (diff > 3600) return (Math.round((diff / 3600) * 100) / 100).toString() + " Hours";
        return (Math.round((diff / 60) * 100) / 100).toString() + " Minutes";
    }
}

/**
 * returns a string that represents the current date and time in the specified format.
 * @param format
 * @returns {string}
 */
export function currentDateTimeString(format) {
    if (format === undefined) format = "MM/DD/YYYY";

    try {
        return formatDate(Date.now(), format);
    } catch (ignore) {
        return "";
    }
}

/**
 * takes a string parameter jsonDate, which represents a date and time in JSON format. The function returns a string that represents the relative time difference between the input date/time and the current date/time, using a format such as "5 minutes ago" or "3 hours ago".
 * @param jsonDate
 * @returns {string}
 */
export function relativeDatetime(jsonDate) {
    return moment(jsonDate).toNow();
}

/**
 * takes two parameters: a numerical value ms representing a duration in milliseconds, and a string format representing the desired time unit of the output. The function returns the duration converted to the specified time unit.
 * @param ms
 * @param format - seconds, minutes, hours, days
 * @returns {number}
 */
export function millisecondsToFormat(ms, format) {
    let diff = new moment.duration(ms);

    switch (format) {
        case "seconds":
            return diff.asSeconds();
        case "minutes":
            return diff.asMinutes();
        case "hours":
            return diff.asHours();
        case "days":
            return diff.asDays();
        default:
            return 0;
    }
}

// format time duration to milliseconds
/**
 * takes two parameters: a numerical value val representing a duration, and a string format representing the time unit of the input value. The function returns the duration converted to milliseconds.
 * @param val
 * @param format
 * @returns {number}
 */
export function formatToMilliseconds(val, format) {
    return moment.duration(val, format).asMilliseconds();
}

/**
 * takes a string text as input and returns an object containing information about any dates found in the input string. The function uses the chrono.parse() method to parse the input string for any date/time values and returns them in an object.
 * @param text
 * @returns {{duration: null, start_time: null, end_time: null, text}|null}
 */
export function datesFromString(text) {
    if (text !== null) {
        if (text.length > 2) {
            let vals = {
                start_time: null,
                end_time: null,
                duration: null,
                text: text,
            };

            let obj = chrono.parse(text);

            // date already adjusts for DST based on dates entered
            // https://www.npmjs.com/package/chrono-node

            if (obj.length > 0) {
                if (obj[0].hasOwnProperty("start")) {
                    vals.start_time = obj[0].start.date().toISOString();
                }

                if (!isNullOrUndefined(obj[0].end)) {
                    // has end time

                    if (obj[0].end.date() >= obj[0].start.date()) {
                        // end time is greater than start time
                        vals.end_time = obj[0].end.date().toISOString();
                        vals.duration = millisecondsToFormat(
                            obj[0].end.date() - obj[0].start.date(),
                            "minutes",
                        );
                    }
                }

                if (obj[0].hasOwnProperty("text")) {
                    // replace timed string
                    vals.text = vals.text.replace(obj[0].text, "").trim();
                    vals.text = vals.text.replace("  ", " ");
                }
            }

            return vals;
        }
    }

    return null;
}

/**
 * check 2 dates are from same date
 * @param d1
 * @param d2
 * @returns {boolean}
 */
export function isSameDay(d1, d2) {
    if (isNullOrUndefined(d1) || isNullOrUndefined(d2)) {
        if (!isNullOrUndefined(d1) || !isNullOrUndefined(d2)) {
            return false;
        } else {
            return true;
        }
    } else {
        const d2time = d2.getTime();
        const sofd = moment(d1).startOf("day").valueOf();
        const eofd = moment(d1).endOf("day").valueOf();

        return d2time >= sofd && d2time <= eofd;
    }
}

export function getWeekNumber(date) {
    // Copy date so don't modify original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    let yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    let weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return weekNo;
}

/**
 * string to moment shorthand string
 * @param dateScaleData
 * @returns {string}
 */
export function getMomentDuration(dateScaleData) {
    switch (dateScaleData) {
        case "milliseconds":
            return "ms";
        case "seconds":
            return "s";
        case "minutes":
            return "m";
        case "hours":
            return "h";
        case "days":
            return "d";
        case "weeks":
            return "w";
        case "months":
            return "M";
        case "quarters":
            return "Q";
        case "years":
            return "y";
        default:
            return "ms";
    }
}

/**
 * takes three parameters: sdate, interval (default is "days"), duration (default is 1), and fromStartOfDay (default is true). The function returns a new date object that represents the result of adding the specified duration to the input date.
 * @param sdate
 * @param interval
 * @param duration
 * @param fromStartOfDay
 * @returns {null|Date}
 */
export function dateAdd(sdate, interval = "days", duration = 1, fromStartOfDay = true) {
    if (sdate) {
        try {
            let dte = fromStartOfDay ? moment(sdate).startOf("day").toDate() : sdate;

            switch (interval) {
                case "d":
                case "days":
                    return fromStartOfDay
                        ? moment(dte).add(duration, "days").endOf("day").toDate()
                        : moment(dte).add(duration, "days").toDate();
                case "w":
                case "weeks":
                    return fromStartOfDay
                        ? moment(dte).add(duration, "weeks").endOf("day").toDate()
                        : moment(dte).add(duration, "weeks").toDate();
                case "M":
                case "months":
                    const l = fromStartOfDay
                        ? moment(dte).add(duration, "months").endOf("day").toDate()
                        : moment(dte).add(duration, "months").toDate();

                    return l;
                case "y":
                case "years":
                    return fromStartOfDay
                        ? moment(dte).add(duration, "years").endOf("day").toDate()
                        : moment(dte).add(duration, "years").toDate();
                default:
                    return null;
            }
        } catch (ignore) {
            return null;
        }
    }

    return null;
}

// Function to get the start of the day for a provided date
export function getStartOfDay(date) {
    if (isNullOrUndefined(date)) return null;

    const startOfDay = new Date(date);

    startOfDay.setHours(0);
    startOfDay.setMinutes(0);
    startOfDay.setSeconds(0);
    startOfDay.setMilliseconds(0);

    return startOfDay;
}

export function getEndOfDay(date) {
    if (isNullOrUndefined(date)) return null;

    const endOfDay = new Date(date);

    endOfDay.setHours(23);
    endOfDay.setMinutes(59);
    endOfDay.setSeconds(59);
    endOfDay.setMilliseconds(999);

    return endOfDay;
}

/*********************************************
 // Phone utilities
 *********************************************/

/**
 * takes three parameters: num, countryCode (default is "US"), and format (default is PNF.INTERNATIONAL). The function uses the Google's libphonenumber library to parse and format phone numbers.
 * @param num
 * @param countryCode
 * @param format
 * @returns {*}
 */
export function formatPhone(num, countryCode = "US", format = PNF.INTERNATIONAL) {
    let val = "";

    try {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

        val = phoneUtil.format(phoneUtil.parse(num, countryCode, format), format);
    } catch (ignore) {
        val = num;
    }

    return val;
}

/**
 *
 * @param {string} num
 * @param {string} countryCode  @default "US"
 * @returns string @default ""
 */
export function formatPhoneForDisplay(num, countryCode = "US") {
    let val = "";

    if (!num || num.trim() === "") return "";

    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    // number = phoneUtil.formatInOriginalFormat(num, countryCode);

    const number = phoneUtil.parse(num, countryCode);
    val = phoneUtil.format(number, PNF.NATIONAL);
    // val = number.getNationalNumber();

    return val;
}

/**
 * takes two parameters: phone, which is an object that contains phone number information such as the phone number itself and the country code, and showLabel (default is false), which is a boolean value that determines whether or not to show the label of the phone number.
 * formats the phone number with the country code.
 * @param phone
 * @param showLabel
 * @returns {string}
 */
export function fullPhoneNumber(phone, showLabel = false) {
    if (phone) {
        if (phone.number && phone.number.trim() !== "") {
            let str = formatPhoneForDisplay(phone.number, phone.country);

            if (phone.ext !== "") str += " ext. " + phone.ext;
            if (
                showLabel &&
                phone.label &&
                typeof phone.label === "string" &&
                phone.label.trim() !== ""
            )
                str = toTitleCase(phone.label.trim()) + " " + str;
            return str;
        }
    }

    return "";
}

/*********************************************
 // Hook functions
 *********************************************/

// change state
/**
 *
 * @param setState
 * @param newObj
 */
export function changeState(setState, newObj) {
    setState((prevState) => {
        return Object.assign({}, prevState, newObj);
    });
}

/*********************************************
 // File functions
 *********************************************/

/**
 * get root application file path
 * @param entity
 * @returns string
 */
export function fileRoot(entity) {
    let path = import.meta.env.VITE_FILE_PATHS_APP;

    switch (entity) {
        case "org":
            path = import.meta.env.VITE_FILE_PATHS_ORG;
            break;
        case "person":
            path = import.meta.env.VITE_FILE_PATHS_PERSON;
            break;
    }

    return path;
}

/**
 * get file url string from file data(object)
 * @param fileData
 * @returns {string}
 */
export function fileUrl(fileData) {
    let val = fileRoot(fileData.entity);

    if (fileData.entityid && fileData.entityid !== "") val += fileData.entityid + "/";
    if (fileData.key && fileData.key !== "") val += fileData.key + "/";
    val += fileData.filename;

    return val;
}

/**
 * format bytes(number) to string
 * @param val
 * @param decimals
 * @returns {string}
 */
export function formatToFileSize(val, decimals = 1) {
    if (typeof val === "number") {
        if (val === 0) return "0 Byte";
        let k = 1000;
        let dm = decimals + 1 || 3;
        let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let i = Math.floor(Math.log(val) / Math.log(k));
        return parseFloat((val / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    } else {
        return "";
    }
}

/**
 *  takes a string dataURI as input, which represents a data URI (Uniform Resource Identifier) for an image. The function returns a Blob object that represents the binary data of the input image.
 *  convert base64 dataURI to file > data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAADwCAYAAAA+VemSAAA...
 * @param dataURI
 * @returns {module:buffer.Blob} - file
 */
export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;

    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}
