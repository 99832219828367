import React, { useMemo } from "react";
import loadable from "@loadable/component";

import IFrameViewer from "./IFrameViewer";
// import FileViewer from "../app-base/elm/FileViewer";
// import TextareaView from "./TextareaView";
const TextareaView = loadable(() => import("./TextareaView"));
const VerifyCode = loadable(() => import("./VerifyCode"));
const PatientDetail = loadable(() => import("./contacts/PatientDetail"));
const EncounterPayerDetail = loadable(() => import("./encounters/EncounterPayerDetail"));
const EncounterAdd = loadable(() => import("./encounters/EncounterAdd"));
const Encounter = loadable(() => import("./encounters/Encounter"));
const EncounterProcedure = loadable(() => import("./encounters/EncounterProcedure"));
const EncounterDiagnosisSelect = loadable(() => import("./encounters/EncounterDiagnosisSelect"));
const EncounterCancel = loadable(() => import("./encounters/EncounterCancel"));
const EncounterDateChange = loadable(() => import("./encounters/EncounterDateChange"));
const FileViewer = loadable(() => import("../app-base/elm/FileViewer"));
const MemberDetail = loadable(() => import("./contacts/MemberDetail"));
const EligibilityView = loadable(() => import("./eligibilities/EligibilityView"));
const AreaDetail = loadable(() => import("./areas/AreaDetail"));
const OrgPayer = loadable(() => import("./payers/OrgPayer"));
const MedicationDetail = loadable(() => import("./medications/MedicationDetail"));
const ProcedureDetail = loadable(() => import("./procedures/ProcedureDetail"));
const RoleDetail = loadable(() => import("./roles/RoleDetail"));
const DocumentDetail = loadable(() => import("./docs/DocumentDetail"));
// const FormDetail = lazy(() => import("./forms/FormDetail"));
// const FormBuildItem = lazy(() => import("./forms/FormBuildItem"));
// const EventAdd = lazy(() => import("./events/EventAdd"));
// const EventDetail = lazy(() => import("./events/EventDetail"));
// const ImageCropper = lazy(() => import("../app-base/elm/file/ImageCropper"));
// const RoleTask = lazy(() => import("./roles/RoleTask"));
const Scheduler = loadable(() => import("./schedules/Scheduler"));
// const AuthorizationDetail = lazy(() => import("./authorizations/AuthorizationDetail"));

const AuthorizationDetail = loadable(() => import("./authorizations/AuthorizationDetail"));
const PaperBuildDataField = loadable(() => import("./papers/PaperBuildDataField"));
const PaperBuildInputField = loadable(() => import("./papers/PaperBuildInputField"));
const MedicationSelect = loadable(() => import("./medications/MedicationSelect"));
const VendorDetail = loadable(() => import("./contacts/VendorDetail"));
const TransactionDetail = loadable(() => import("./ar/TransactionDetail"));
const DiagnosesAddSelect = loadable(() => import("./procedures/DiagnosesAddSelect"));
const ProcedureSelectView = loadable(() => import("./procedures/ProcedureSelectView"));
const OrgProcedureSelect = loadable(() => import("./procedures/OrgProcedureSelect"));
// const EncSlipPrint = lazy(() => import("./encounters/EncSlipPrint"));
const ClaimDetail = loadable(() => import("./claims/ClaimDetail"));
const ClaimProcedure = loadable(() => import("./claims/ClaimProcedure"));
const ClaimErrorPopup = loadable(() => import("./claims/ClaimErrorPopup"));
const PrintSettings = loadable(() => import("./profile/PrintSettings"));
const OrgPayerContactTransactions = loadable(() => import("./payers/OrgPayerContactTransactions"));
const PatientDiagnosis = loadable(() => import("./contacts/PatientDiagnosis"));
const PatientApplyPayment = loadable(() => import("./ar/PatientApplyPayment"));
const OffDaysDetail = loadable(() => import("./contacts/OffDaysDetail"));
const TestSelect = loadable(() => import("./tests/TestSelect"));

function WindowContent({
    className,
    cr,
    name,
    title,
    pageType,
    windowId,
    size,
    passed,
    onCmpMounted,
    onClose,
}) {
    const allProps = useMemo(() => {
        return {
            ref: cr,
            name: name,
            title: title,
            pageType: pageType,
            size: size,
            windowId: windowId,
            ...passed,
            onCmpMounted,
            onClose,
        };
    }, []);

    switch (name) {
        case "iframe-viewer":
            return <IFrameViewer className={className} {...allProps} />;
        case "textarea-view":
            return <TextareaView className={className} {...allProps} />;
        case "verify-code":
            return <VerifyCode className={className} {...allProps} />;
        case "medication-select":
            return <MedicationSelect className={className} {...allProps} />;
        case "paper-build-data-field":
            return <PaperBuildDataField className={className} {...allProps} />;
        case "paper-build-input-field":
            return <PaperBuildInputField className={className} {...allProps} />;
        case "patient":
            return <PatientDetail className={className} {...allProps} />;
        case "authorization":
            return <AuthorizationDetail className={className} {...allProps} />;
        case "encounter-add":
            return <EncounterAdd className={className} {...allProps} />;
        case "encounter":
            return <Encounter className={className} {...allProps} />;
        // case "encounter-fee-ticket":
        //     return <EncSlipPrint className={className} {...allProps} />;
        case "encounter-payer":
            return <EncounterPayerDetail className={className} {...allProps} />;
        case "encounter-diagnosis":
            return <EncounterDiagnosisSelect className={className} {...allProps} />;
        case "encounter-procedure":
            return <EncounterProcedure className={className} {...allProps} />;
        case "encounter-cancel":
            return <EncounterCancel className={className} {...allProps} />;
        case "encounter-date-change":
            return <EncounterDateChange className={className} {...allProps} />;
        case "file-viewer":
            return <FileViewer className={className} {...allProps} />;
        case "image-cropper":
            return <ImageCropper className={className} {...allProps} />;
        case "member":
            return <MemberDetail className={className} {...allProps} />;
        case "eligibility-view":
            return <EligibilityView className={className} {...allProps} />;
        case "area":
            return <AreaDetail className={className} {...allProps} />;
        case "org-payer":
            return <OrgPayer className={className} {...allProps} />;
        case "medication":
            return <MedicationDetail className={className} {...allProps} />;
        // case "procedure-add":
        //     return <ProcedureAdd className={className} {...allProps} />;
        case "procedure":
            return <ProcedureDetail className={className} {...allProps} />;
        case "patient-diagnosis":
            return <PatientDiagnosis className={className} {...allProps} />;
        case "role":
            return <RoleDetail className={className} {...allProps} />;
        case "role-task":
            return <RoleTask className={className} {...allProps} />;
        case "document":
            return <DocumentDetail className={className} {...allProps} />;
        case "form":
            return <FormDetail className={className} {...allProps} />;
        case "form-build-item":
            return <FormBuildItem className={className} {...allProps} />;
        case "task-add":
            return <EventAdd className={className} {...allProps} />;
        case "task":
            return <EventDetail className={className} {...allProps} />;
        case "scheduler":
            return <Scheduler className={className} {...allProps} />;
        case "vendor":
            return <VendorDetail className={className} {...allProps} />;
        case "transaction":
            return <TransactionDetail className={className} {...allProps} />;
        case "diagnosis-add-lookup":
            return <DiagnosesAddSelect className={className} {...allProps} />;
        case "org-procedure-select-view":
            return <ProcedureSelectView className={className} {...allProps} />;
        case "org-procedure-add-lookup":
            return <OrgProcedureSelect className={className} {...allProps} />;
        case "claim":
            return <ClaimDetail className={className} {...allProps} />;
        case "claim-procedure":
            return <ClaimProcedure className={className} {...allProps} />;
        case "claim-error-popup":
            return <ClaimErrorPopup className={className} {...allProps} />;
        case "print-settings":
            return <PrintSettings className={className} {...allProps} />;
        case "org-payer-contact-transactions":
            return <OrgPayerContactTransactions className={className} {...allProps} />;
        case "patient-apply-payment":
            return <PatientApplyPayment className={className} {...allProps} />;
        case "employee-off-days-detail":
            return <OffDaysDetail className={className} {...allProps} />;
        case "test-select":
            return <TestSelect className={className} {...allProps} />;
    }
}

export default WindowContent;
