import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { ENCOUNTER_STATUS_LIST } from "../../data/constants";
// import Avatar from "../../app-base/elm/Avatar";
import {
    // isSameDay,
    timeAgo,
    timeDurationString,
    formatDate,
    fullPhoneNumber,
    findListItemIndex,
    // toTitleCase,
} from "../../app-base/fn";
import { getAvatarData, personName, getPersonName } from "../../utils";
import IfValueDiv from "../../app-base/elm/IfValueDiv";

function EncounterSearchIr({
    className,
    style,
    children,
    // statuses,
    item = null,
    onClick,
}) {
    const cmp = useMemo(() => {
        const cDate = new Date();
        const encTime = formatDate(item.enc_time, "M/D/YY h:mm A");
        const phone1 = !item.phones
            ? "None"
            : item.phones.length > 0
              ? fullPhoneNumber(item.phones[0])
              : "None";
        let duration = "";

        const status =
            !item.status || item.status === ""
                ? null
                : ENCOUNTER_STATUS_LIST[
                      findListItemIndex(ENCOUNTER_STATUS_LIST, "data", item.status)
                  ];

        switch (item.status) {
            case "expected":
            case "cancelled":
                duration = "";
                break;
            case "completed":
                duration = timeDurationString(item.complete_time, item.enc_time);
                break;
            default:
                duration = "Checked in " + timeAgo(item.enc_time, cDate);
        }

        return {
            statusLbl: status ? (status.data === "" ? "" : status.label) : "",
            person_name: personName(item, true),
            provider_name: getPersonName(
                true,
                item.provider_stn,
                item.provider_firstname,
                item.provider_mdlname,
                item.provider_lastname,
                item.provider_sfx,
            ),
            avatar_data: getAvatarData(item),
            phone1: phone1,
            phone_lbl: phone1 !== "" ? phone1 : "No phone on record.",
            email_lbl: item.email !== "" ? item.email : "No email on record.",
            duration: duration,
            time_display:
                item.status === "expected"
                    ? encTime
                    : formatDate(item.check_in_time, "M/D/YY h:mm A"),
        };
    }, [item]);

    const onLineClick = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (onClick) onClick(item);
    };

    if (item.id) {
        return (
            <div
                className={classNames(
                    "encounter-search-ir",
                    { "c-pointer": onLineClick },
                    className,
                )}
                style={style}
                title="View Encounter"
                role="option"
                onClick={onLineClick}
            >
                <div className="fl p-l-10 p-r-10">
                    <div className="col-3">
                        <IfValueDiv
                            lblClass="c-orange f-w-b"
                            label={item.enc_num}
                            valueClass="c-white f-w-b"
                            value={cmp.time_display}
                        />

                        <div className="fl-inline m-t-5">
                            {/* <Avatar
                                id={item.id}
                                className="col-fixed md m-r-20"
                                entity={cmp.avatar_data.entity}
                                imageUrl={cmp.avatar_data.imageUrl}
                                text={cmp.avatar_data.text}
                            /> */}

                            <div className="f-w-sb m-t-3">
                                <label className="f-w-sb t-right c-white">{cmp.person_name}</label>
                                <div
                                    className={classNames("f-s-sm c-white", {
                                        "no-display": item.patient_id === "",
                                    })}
                                >
                                    <span className="c-white">{item.patient_id}</span>
                                </div>

                                {/* <div
                                    className={classNames("m-t-5", {
                                        "no-display": cmp.phone1 === "",
                                    })}
                                >
                                    <div className="fl-inline middle">
                                        <i className="material-icons c-blue_sharp m-r-3">phone</i>
                                        <span className="c-white">{cmp.phone_lbl}</span>
                                    </div>
                                </div>

                                <div className="m-t-0">
                                    <div className="fl-inline middle">
                                        <i className="material-icons c-blue_sharp m-r-3">email</i>
                                        <span className="c-white">{cmp.email_lbl}</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-3 col-right">
                        <div className="c-orange f-w-b m-b-5">{cmp.statusLbl}</div>

                        <IfValueDiv
                            label="Alternate ID :"
                            lblClass="f-w-n c-grey-dark"
                            valueClass="c-grey f-w-sb"
                            value={item.enc_num_2}
                        />

                        <div
                            className={classNames("f-w-sb c-white", {
                                "no-display": cmp.provider_name === "",
                            })}
                        >
                            <span className="f-w-sb">{cmp.provider_name}</span>
                        </div>
                    </div>
                </div>

                {children}
            </div>
        );
    } else {
        return false;
    }
}

export default memo(EncounterSearchIr);
