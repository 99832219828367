import React, { memo, useMemo } from "react";
import classNames from "classnames";
import {
    BarLoader,
    ScaleLoader,
    GridLoader,
    PropagateLoader,
    ClockLoader,
    PulseLoader,
} from "react-spinners";

// Loader ["bar", "scale", "grid", "propagate", "clock", "pulse"]
function Loading({
    className = "p-15",
    style,
    children,
    loader = "pulse",
    show = true,
    color = "rgba(48, 83, 234, 1)",
    loaderProps = null,
}) {
    const cmp = useMemo(() => {
        let l = null;

        switch (loader) {
            case "bar":
                l = <BarLoader color={color} {...{ height: 4, width: 100, ...loaderProps }} />;
                break;
            case "scale":
                l = (
                    <ScaleLoader
                        color={color}
                        {...{ height: 35, width: 4, radius: 2, margin: 2, ...loaderProps }}
                    />
                );
                break;
            case "grid":
                l = <GridLoader color={color} {...{ size: 15, ...loaderProps }} />;
                break;
            case "propagate":
                l = <PropagateLoader color={color} {...{ size: 15, ...loaderProps }} />;
                break;
            case "clock":
                l = <ClockLoader color={color} {...{ size: 50, ...loaderProps }} />;
                break;
            case "pulse":
                l = <PulseLoader color={color} {...{ size: 15, margin: 2, ...loaderProps }} />;
                break;
        }

        return {
            ld: l,
        };
    }, [loader, show, color, loaderProps]);

    if (!show || !cmp.ld) {
        return null;
    } else {
        return (
            <div className={classNames("loading fl middle center", className)} style={style}>
                {children}
                {cmp.ld}
            </div>
        );
    }
}

export default memo(Loading);
