import React, { useMemo, memo } from "react";
import ContentLoader from "react-content-loader";

function PlListLine({ idx, withCircle }) {
    const rowStart = 100 * idx;

    return (
        <>
            {withCircle && <circle cx="18" cy={rowStart + 18} r="18" />}
            <rect x={withCircle ? "60" : 0} y={rowStart} rx="3" ry="3" width="650" height="20" />
            <rect
                x={withCircle ? "60" : 0}
                y={rowStart + 25}
                rx="3"
                ry="3"
                width="380"
                height="20"
            />
            <rect
                x={withCircle ? "60" : 0}
                y={rowStart + 50}
                rx="3"
                ry="3"
                width="500"
                height="20"
            />
        </>
    );
}

function PlaceholderList({
    className,
    style,
    children,
    loading = false,
    width = 650,
    rows = 5,
    withCircle = true,
    theme = "default", // light or dark or default
    loaderStyle = { width: "100%", height: "auto", maxWidth: "600px" },
}) {
    const cmp = useMemo(() => {
        let idxList = [];
        let height = 70; // total height of each row
        let vbox = "0 0 650 600";
        let foreground = "#eee";
        let background = "#f5f6f7";

        switch (theme) {
            case "dark":
                foreground = "rgba(148, 155, 160, .8)";
                background = "rgba(148, 155, 160, .7)";
                break;
            case "light":
                foreground = "rgba(148, 155, 160, .2)";
                background = "rgba(148, 155, 160, .1)";
                break;
        }

        for (let i = 0; i < rows; i++) {
            idxList.push(i);
        }

        height = rows === 1 ? height : (height + 30) * rows - 30;
        vbox = `0 0 ${width.toString()} ${height.toString()}`;

        return {
            idxList: idxList,
            height: height,
            vbox: vbox,
            foreground: foreground,
            background: background,
        };
    }, [rows, theme]);

    if (loading) {
        return (
            <div className={className} style={style}>
                {children}

                <ContentLoader
                    width={width}
                    height={cmp.height}
                    viewBox={cmp.vbox}
                    style={loaderStyle}
                    backgroundColor={cmp.background}
                    foregroundColor={cmp.foreground}
                >
                    {cmp.idxList.map((idx) => (
                        <PlListLine key={idx} idx={idx} withCircle={withCircle} />
                    ))}
                </ContentLoader>
            </div>
        );
    } else {
        return null;
    }
}

export default memo(PlaceholderList);
