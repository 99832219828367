import React, { createContext, memo } from "react";
import orgState from "../states/orgState";

export const OrgContext = createContext();

export const OrgContextProvider = ({ children, data }) => {
    return <OrgContext.Provider value={orgState(data)}>{children}</OrgContext.Provider>;
};

export default memo(OrgContextProvider);
