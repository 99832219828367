import React, { memo, useMemo, useEffect } from "react";
import classNames from "classnames";
import SigninPwdHdr from "./SigninPwdHdr";
import Form from "../../app-base/Form";
import TextInput from "../../app-base/input/TextInput";
import { passwordRule, validate } from "../../validate";
import { showAlert } from "../../app-base/displayUtils";

function SigninPwdChange({
    className,
    profile,
    newPwdMinLen,
    email,
    newPwd1,
    newPwd2,
    loading = false,
    disabled = false,
    onChange,
    onSubmit,
    setStep,
}) {
    const form = useMemo(() => {
        return new Form();
    }, []);

    const submitHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!loading) {
            const formErr = form.validate(true);

            if (!formErr) {
                // no error on elements
                const error = validate(
                    { newPwd1: newPwd1.trim(), newPwd2: newPwd2.trim() },
                    {
                        newPwd1: passwordRule(),
                        newPwd2: passwordRule("Please confirm new password."),
                    },
                );

                if (!error || error === "") {
                    if (newPwd1 !== newPwd2) {
                        form.scrollToElement("newPwd2");
                        showAlert("Error", "error", "Please confirm new password.");
                    } else {
                        onSubmit(); // commit data changes and check
                    }
                } else {
                    form.scrollToElement(error.name);
                }
            }
        }
    };

    const keyUpHandler = (e) => {
        if (e.keyCode === 13) {
            // return pressed
            if (e) {
                e.preventDefault();
                e.stopPropagation();
                if (e.target) e.target.blur();
            }

            submitHandler();
        }
    };

    const backHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setStep("email");
    };

    useEffect(() => {
        return () => {
            form.dispose();
        };
    }, []);

    return (
        <div className={classNames("sigin-pwd-change w-full", className)}>
            <SigninPwdHdr
                className={classNames("signin-pwd-hdr", className)}
                profile={profile}
                email={email}
            />

            <div onKeyUp={keyUpHandler}>
                <TextInput
                    className="t-center"
                    type="password"
                    name="newPwd1"
                    label="New Password"
                    autoComplete="new-password"
                    required={true}
                    focus={true}
                    minLength={newPwdMinLen}
                    value={newPwd1}
                    onInputAdd={form.add}
                    onInputRemove={form.remove}
                    onCommit={onChange}
                />

                <TextInput
                    className="t-center"
                    type="password"
                    name="newPwd2"
                    label="Confirm Password"
                    required={true}
                    autoComplete="password-confirm"
                    errorMsg="Please confirm password."
                    value={newPwd2}
                    onInputAdd={form.add}
                    onInputRemove={form.remove}
                    onCommit={onChange}
                />

                <div className="signin-buttons">
                    <button
                        type="button"
                        className={classNames("btn is-primary w-full lg", {
                            "is-loading": loading,
                        })}
                        onClick={submitHandler}
                    >
                        <span>Sign In</span>
                    </button>
                </div>
            </div>

            <div className="m-t-15 t-center w-full f-s-sm">
                <a style={{ cursor: "pointer" }} onClick={backHandler}>
                    <span>Sign in with different account</span>
                </a>
            </div>
        </div>
    );
}

export default memo(SigninPwdChange);
