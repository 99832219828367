import { createElement, useMemo, memo } from "react";
import { createPortal } from "react-dom";
import WindowUI from "./WindowUI";

function Portal({ root, ...otherProps }) {
    const cmp = useMemo(() => {
        return {
            elm: createElement(WindowUI, otherProps),
        };
    }, []);

    return createPortal(cmp.elm, root);
}

export default memo(Portal);
