import React, { memo, useContext, useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { AppContext } from "../context/AppContext";
import { OrgContext } from "../context/OrgContext";
import { SocketContext } from "../context/SocketContext";
import { mergeExistingOnly, isNullOrUndefined } from "../app-base/fn";
import OrgNav from "./OrgNav";
import LoadingOrg from "./LoadingOrg";
import { getOrg } from "../actions/orgActions";
import { showAlert } from "../app-base/displayUtils";

// data holder and root for organization content

function OrgContent({ className, style, children, orgid }) {
    const appState = useContext(AppContext);
    const orgState = useContext(OrgContext);
    const [socketState, setSocketState] = useState(null);
    const [state, setState] = useState({ loading: false, initLoad: true }, []);
    const location = useLocation();
    const navigate = useNavigate();
    const currentOrgid = useRef(null);

    const goToInitPage = () => {
        let nextpage = orgState.data.defaultPage;

        if (location) {
            if (location.pathname) {
                if (location.pathname !== "/") nextpage = location.pathname;
                if (location.search) nextpage += location.search;
            }
        }

        if (nextpage.indexOf("signin") > -1) nextpage = orgState.data.defaultPage;

        navigate(nextpage, { replace: true });
    };

    // const connectionErrorHandler = useCallback((err) => {
    //     // revert to classic upgrade
    //     // setState({ ...state, socketConnected: false });
    // }, []);

    // real time other org member online status
    const rtMOrgMemberActivity = useCallback((data) => {
        orgState.actions({
            type: "updateOrgData",
            value: { key: "members", item: data, itemKey: "id", addNotFound: false },
        });
    }, []);

    const rtOrgChanged = useCallback((data) => {
        if (data.hasOwnProperty("name")) {
            appState.actions({
                type: "updateUserOrg",
                value: { item: { orgid: data.id, name: data.name }, itemKey: "orgid" },
            });
        }

        orgState.actions({
            type: "changeState",
            value: { data: mergeExistingOnly(orgState.data.data, data) },
        });
    }, []);

    const rtMemberChanged = useCallback((data) => {
        orgState.actions({
            type: "updateOrgData",
            value: { key: "members", item: data, itemKey: "id" },
        });
    }, []);

    const rtMemberRemoved = useCallback((data) => {
        orgState.actions({
            type: "removeOrgData",
            value: { key: "members", item: data, itemKey: "id" },
        });
    }, []);

    const rtOrgPayerChanged = useCallback((data) => {
        orgState.actions({
            type: "updateOrgData",
            value: { key: "payers", item: data, itemKey: "id" },
        });
    }, []);

    const rtOrgPayerRemoved = useCallback((data) => {
        orgState.actions({
            type: "removeOrgData",
            value: { key: "payers", item: data, itemKey: "id" },
        });
    }, []);

    const rtOrgProcChanged = useCallback((data) => {
        orgState.actions({
            type: "updateOrgData",
            value: { key: "procedures", item: data, itemKey: "id" },
        });
    }, []);

    const rtOrgProcRemoved = useCallback((data) => {
        orgState.actions({
            type: "removeOrgData",
            value: { key: "procedures", item: data, itemKey: "id" },
        });
    }, []);

    const rtAreaChanged = useCallback((data) => {
        orgState.actions({
            type: "updateOrgData",
            value: { key: "areas", item: data, itemKey: "id" },
        });
    }, []);

    const rtAreaRemoved = useCallback((data) => {
        orgState.actions({
            type: "removeOrgData",
            value: { key: "areas", item: data, itemKey: "id" },
        });
    }, []);

    const rtOrgConnect = (orgid) => {
        if (import.meta.env.VITE_WS_URL !== "") {
            if (socketState) {
                socketState.disconnect();
            }

            if (appState.socketSessionId !== "") {
                const newSocket = io(
                    import.meta.env.VITE_WS_URL + import.meta.env.VITE_WS_PATH + "orgs/" + orgid,
                    {
                        // path: import.meta.env.VITE_WS_PATH + "orgs/" + orgid + "/",
                        path: import.meta.env.VITE_WS_PATH + "orgs/" + orgid,
                        transports: ["websocket", "polling"],
                        withCredentials: true,
                        auth: { token: appState.socketSessionId },
                    }
                );

                newSocket.on("connect", () => {
                    // newSocket.emit("user_online", { orgid: orgid, uid: appState.data.data.id });
                });
                // newSocket.on("connect_error", connectionErrorHandler);

                // newSocket.on("disconnect", (reason) => {
                //     // reasons
                //     // io server disconnect
                //     // io client disconnect
                //     // ping timeout
                //     // transport close
                //     // transport error
                //     // setState({ ...state, socketConnected: false });
                // });

                newSocket.on("member_activity", rtMOrgMemberActivity);
                newSocket.on("org_change", rtOrgChanged);
                newSocket.on("member_change", rtMemberChanged);
                newSocket.on("member_remove", rtMemberRemoved);
                newSocket.on("org_payer_change", rtOrgPayerChanged);
                newSocket.on("org_payer_remove", rtOrgPayerRemoved);
                newSocket.on("org_proc_change", rtOrgProcChanged);
                newSocket.on("org_proc_remove", rtOrgProcRemoved);
                newSocket.on("area_change", rtAreaChanged);
                newSocket.on("area_remove", rtAreaRemoved);

                setSocketState(newSocket);
            }
        }
    };

    const onOrgLoaded = (res) => {
        if (res.error || isNullOrUndefined(res.data)) {
            showAlert("Error", "error", res.error ? res.error : "Organization is unavailable");
        } else {
            orgState.actions({
                type: "setOrgData",
                value: {
                    data: res.data,
                    orgUserId: appState.data.currentOrg.id,
                    userType: appState.data.currentOrg.usertype,
                },
            });

            if (state.initLoad) {
                setState({ ...state, initLoad: false });

                setTimeout(() => {
                    goToInitPage();
                }, 1000);
            } else {
                navigate(orgState.data.defaultPage);
            }
        }
    };

    const loadOrg = async (oid) => {
        if (oid) {
            setState({ ...state, loading: true });

            const res = await getOrg(oid);

            setState({ ...state, loading: false });

            onOrgLoaded(res);
        } else {
            orgState.actions({ type: "resetOrg" });
        }
    };

    useEffect(() => {
        async function initOrg() {
            currentOrgid.current = orgid;

            if (!orgState.data.data || (orgState.data.data && orgState.data.data.id !== orgid)) {
                // org changed or not loaded
                await loadOrg(orgid);
                await rtOrgConnect(orgid);
            }
        }

        if (orgid !== currentOrgid.current) initOrg();
    }, [orgid, appState.socketSessionId]);

    useEffect(() => {
        return () => {
            if (socketState) {
                socketState.off("member_activity", rtMOrgMemberActivity);
                socketState.off("org_change", rtOrgChanged);
                socketState.off("member_change", rtMemberChanged);
                socketState.off("member_remove", rtMemberRemoved);
                socketState.off("org_payer_change", rtOrgPayerChanged);
                socketState.off("org_payer_remove", rtOrgPayerRemoved);
                socketState.off("org_proc_change", rtOrgProcChanged);
                socketState.off("org_proc_remove", rtOrgProcRemoved);
                socketState.off("area_change", rtAreaChanged);
                socketState.off("area_remove", rtAreaRemoved);

                socketState.off("connect_error", connectionErrorHandler);
                socketState.disconnect();
            }
        };
    }, []);

    return (
        <div className={classNames("org-content", className)} style={style}>
            <SocketContext.Provider value={socketState}>
                {!state.loading && orgState.data.nav && orgState.data.nav.length > 0 && (
                    <div className="org-nav-wrap shadow-card animate__animated animate__fadeIn">
                        <OrgNav orgid={orgid} nav={orgState.data.nav} disabled={state.loading} />
                    </div>
                )}

                <div className="main-area">
                    {(state.loading || state.initLoad) && <LoadingOrg />}

                    {!state.loading && !state.initLoad && (
                        <div className="content-pad">{children}</div>
                    )}
                </div>
            </SocketContext.Provider>
        </div>
    );
}

OrgContent.defualtProps = {
    orgid: null, // selected orgid
    root: null, // dom element to attach windows to
    hasOpenWindow: false, // app has open window
};

export default memo(OrgContent);
