import validator from "validate.js";
import { formatDate, getPlural } from "./app-base/fn";
import moment from "moment";

validator.extend(validator.validators.datetime, {
    parse: function (value, options) {
        // const n = moment.utc(value);
        // console.log("parse n ", n);
        // return n;

        const n = new Date(value).getTime();

        return n;
    },

    format: function (value, options) {
        const format = options.dateOnly ? "M/D/YYYY" : "M/D/YYYY h:mm A";
        const n = moment.utc(value).format(format);
        // console.log("format n", n);
        return n;
    },
});

export const requiredRule = (msg = null) => {
    if (!msg) msg = "^Value is required.";

    return {
        presence: {
            allowEmpty: false,
            message: msg,
        },
    };
};

export const emailRule = (msg = null) => {
    return {
        presence: {
            allowEmpty: false,
            message: !msg ? "^Please enter an email address." : msg,
        },
        email: {
            message: "^Please enter a valid email address.",
        },
    };
};

export const passwordRule = (msg = null) => {
    return {
        presence: {
            allowEmpty: false,
            message: !msg ? "^Please enter valid password." : msg,
        },
        length: {
            minimum: 4,
            message: "^Please enter valid password.",
        },
    };
};

export const arraryLengthRule = (min = 1, max = null, msg = null) => {
    if (!msg) {
        if (!max) {
            msg = `^There must be at least ${min.toString()} ${getPlural("entry", min)}.`;
        } else {
            msg = `^There must be between ${min.toString()} to ${max.toString()} ${getPlural(
                "entry",
                max
            )}.`;
        }
    }

    let length = {
        minimum: min,
    };

    if (max && max > min) length.maximum = max;

    return {
        presence: {
            allowEmpty: false,
            message: msg,
        },
        length: length,
    };
};

export const intLessEqualRule = (max, msg) => {
    if (!msg) msg = "^Value must be equal or less than " + max.toString() + ".";

    return {
        numericality: {
            noStrings: false,
            onlyInteger: true,
            lessThanOrEqualTo: max,
            notLessThanOrEqualTo: msg,
            notInteger: msg,
            message: msg,
        },
    };
};

export const intGreaterEqualRule = (min, msg) => {
    if (!msg) msg = "^Value must be equal or greater than " + min.toString() + ".";
    return {
        numericality: {
            noStrings: false,
            onlyInteger: true,
            greaterThanOrEqualTo: min,
            notGreaterThanOrEqualTo: msg,
            notInteger: msg,
            message: msg,
        },
    };
};

export const intBetweenRule = (min, max, msg) => {
    if (!msg) msg = "^Value must be between " + min.toString() + " to " + max.toString() + ".";
    return {
        numericality: {
            noStrings: false,
            onlyInteger: true,
            greaterThanOrEqualTo: min,
            notGreaterThanOrEqualTo: msg,
            lessThanOrEqualTo: max,
            notLessThanOrEqualTo: msg,
            notInteger: msg,
            message: msg,
        },
    };
};

export const dateRule = (
    dateOnly = false,
    msg = null,
    required = false,
    minDate = null,
    maxDate = null
) => {
    const minStr = !minDate ? "" : formatDate(minDate, "M/D/YYYY");
    const maxStr = !maxDate ? "" : formatDate(maxDate, "M/D/YYYY");

    let v = {};
    let dateV = {};

    dateV.dateOnly = dateOnly;

    if (minDate) {
        dateV.earliest = minDate;
        msg = `^Date can not be earlier than ${minStr}.`;
    }

    if (maxDate) {
        dateV.latest = minDate;
        msg = `^Date can not be later than ${maxStr}.`;
    }

    if (minDate && maxDate) {
        msg = `^Date must be between ${minStr} to ${maxStr}.`;
    }

    if (required) {
        v.presence = {
            allowEmpty: false,
            message: !msg ? "^Please enter date." : msg,
        };
    }

    v.datetime = dateV;

    return v;
};

export function validate(dataset, rules, firstErrorOnly = true) {
    const errors = validator.validate(dataset, rules);

    // console.log("errors", errors);

    if (errors) {
        if (!firstErrorOnly) {
            return errors; // object list of all errors
        } else {
            return { name: Object.keys(errors)[0], errors: errors[Object.keys(errors)[0]][0] };
        }
    }

    return null;
}

export function validateSingle(value, constraints) {
    const errors = validator.single(value, constraints);
    return errors;
}

// export function validate(fieldName, constraint, value) {
//     let formValues = {};
//     formValues[fieldName] = value;

//     let formFields = {};
//     formFields[fieldName] = constraint;

//     const result = validation(formValues, formFields);

//     // console.log("validate result", result);

//     if (result) {
//         // Return only the field error message if there are multiple
//         return result[fieldName][0];
//     }

//     return null;
// }

export default validate;
