import { isNullOrUndefined } from "../app-base/fn";
import { isObjectId } from "../app-base/validator";
import { apiGet, apiPut, apiPost } from "../connection";

// get user
export async function getUserDetail(id, controller = null) {
    if (isObjectId(id)) {
        return await apiGet(`users/${id}`, null, controller);
    } else {
        throw "User ID is required.";
    }
}

// save user data
export async function updateUser(id, savedata, controller = null) {
    if (isObjectId(id)) {
        return await apiPut(`users/${id}`, savedata, controller);
    } else {
        throw "User is required.";
    }
}

// create verification to email for user
export async function createVerificationCode(emailId, controller = null) {
    if (isObjectId(emailId)) {
        return await apiPost(`verify/send`, { email_id: emailId }, controller);
    } else {
        throw "Invalid request.";
    }
}

// validate verification code from email for user
export async function verifyCode(uid, code, controller = null) {
    if (isObjectId(uid) || !isNullOrUndefined(code) || code.trim() !== "") {
        return await apiPost(`verify/check`, { id: uid, code: code }, controller);
    } else {
        throw "Invalid request.";
    }
}
