import { useReducer } from "react";
import numeral from "numeral";
import {
    isNullOrUndefined,
    findListItemIndex,
    addToList,
    updateList,
    removeFromList,
    listFilterChange,
    isUndefined,
    updateObject,
} from "../app-base/fn";

export function listStateData() {
    return {
        rows: 25, // rows to load per api call - if pagination is used
        loading: false,
        loaded: false,
        totalRows: 0, // total number of rows available in API
        loadedRows: 0,
        list: [],
        filter: {}, // filterItemModel { key: type, items: [] }
        filterText: "",
        viewRows: 25000, //  local rows per display more
        viewLastRow: 25000, // currently displayed last row
        params: null, // get api get params
    };
}

export function listState(data, listKey = "id") {
    const initData = { ...listStateData(), ...data };

    const reset = (currentState) => {
        return { ...listStateData(), ...data };
    };

    const changeState = (currentState, newState) => {
        return { ...currentState, ...newState };
    };

    const setParams = (currentState, { params = {} }) => {
        return { ...currentState, params: params };
    };

    const setParamKey = (currentState, { key, val, valKey = null }) => {
        let newObj = {};

        if (isNullOrUndefined(currentState.params[key])) {
            newObj[key] = !valKey ? val : val[valKey];

            return { ...currentState, params: updateObject(currentState.params, newObj) };
        } else {
            if (isNullOrUndefined(valKey) || valKey === "") {
                // val is not object
                if (currentState.params[key] !== val) {
                    newObj[key] = val;

                    return { ...currentState, params: updateObject(currentState.params, newObj) };
                }
            } else {
                // val is object
                if (currentState.params[key] !== val[valKey]) {
                    newObj[key] = val[valKey];
                    return { ...currentState, params: updateObject(currentState.params, newObj) };
                }
            }
        }

        return currentState;
    };

    // add or remove filter item - was changeFilter
    const filterChange = (currentState, { key, value, fn = undefined }) => {
        const filter = listFilterChange(currentState.filter, key, value, fn);

        return { ...currentState, filter: filter, viewLastRow: currentState.viewRows };
    };

    // clear all list filter
    const clearFilter = (currentState) => {
        return { ...currentState, filter: {}, filterText: "", viewLastRow: currentState.viewRows };
    };

    const stopCall = (currentState, { controller = null, loading = false }) => {
        if (controller) {
            controller.abort();
            controller = null;
        }

        return { ...currentState, loading: loading };
    };

    const startCall = (currentState, controller = null) => {
        return { ...currentState, loading: true };
    };

    const setList = (currentState, { list = [], append = false }) => {
        let totalRows = 0;

        if (list && list.length > 0) {
            totalRows = list.length;

            if (list[0].hasOwnProperty("full_count")) {
                totalRows = numeral(list[0].full_count).value();

                list.forEach((v) => {
                    delete v.full_count;
                });
            }
        }

        const allRows = append ? currentState.list.concat(list) : list;

        return {
            ...currentState,
            loaded: true,
            loadedRows: allRows.length,
            list: allRows,
            totalRows: totalRows,
        };
    };

    // private function
    const remove = (currentState, index) => {
        if (!isUndefined(index) && index > -1) {
            const newList = removeFromList(currentState.list, index);

            return {
                ...currentState,
                list: newList,
                totalRows: currentState.totalRows - 1,
                loadedRows: currentState.loadedRows - 1,
            };
        }

        return currentState;
    };

    const addItem = (currentState, { item, index }) => {
        if (item) {
            return {
                ...currentState,
                list: addToList(currentState.list, item, index),
                totalRows: currentState.totalRows + 1,
                loadedRows: currentState.loadedRows + 1,
            };
        }

        return currentState;
    };

    const removeItem = (currentState, { item, key = listKey }) => {
        if (item && key) {
            if (item.hasOwnProperty(key) && currentState.list) {
                const idx = findListItemIndex(currentState.list, key, item[key]);
                return remove(currentState, idx);
            }
        }

        return currentState;
    };

    // update list item or add to list if not found if addToList is specified
    const updateItem = (currentState, { item, key = listKey, addNotFound = true }) => {
        if (item && key) {
            if (item.hasOwnProperty(key) && currentState.list) {
                const idx = findListItemIndex(currentState.list, key, item[key]);

                if (idx === -1) {
                    // not found in list > add to list
                    if (addNotFound) return addItem(currentState, { item: item, key: key });
                } else {
                    return { ...currentState, list: updateList(currentState.list, item, idx) };
                }
            }
        }

        return currentState;
    };

    const reducer = (currentState, action) => {
        switch (action.type) {
            case "reset":
                return reset(currentState);
            case "changeState":
                return changeState(currentState, action.value);
            case "setParams":
                return setParams(currentState, action.value);
            case "setParamKey":
                return setParamKey(currentState, action.value);
            case "filterChange":
                return filterChange(currentState, action.value);
            case "clearFilter":
                return clearFilter(currentState);
            case "startCall":
                return startCall(currentState, action.value);
            case "stopCall":
                return stopCall(currentState, action.value);
            case "setList":
                return setList(currentState, action.value);
            case "addItem":
                return addItem(currentState, action.value);
            case "updateItem":
                return updateItem(currentState, action.value);
            case "removeItem":
                return removeItem(currentState, action.value);

            default:
                return currentState;
        }
    };

    const [state, dispatch] = useReducer(reducer, initData);

    return { state: state, actions: dispatch };
}

export default listState;
