import React, { useMemo, memo } from "react";
import classNames from "classnames";
import { toTitleCase, formatToHtml } from "../../app-base/fn";

function ListObjectLine({
    className,
    style,
    idx = -1,
    displayKey = "label",
    vertical = true,
    value = null,
    toTitle = false,
    valueItemBreak = "",
}) {
    const ir = useMemo(() => {
        let lblVal = "";

        if (!displayKey || displayKey === "") {
            lblVal = value;
        } else {
            lblVal = value[displayKey];
        }

        lblVal = toTitle ? toTitleCase(lblVal) : lblVal;

        if (idx > 0 && valueItemBreak && valueItemBreak !== "") lblVal = valueItemBreak + lblVal;

        return {
            label: lblVal,
        };
    }, [value, idx]);

    return (
        <div
            className={classNames(className)}
            style={style}
            dangerouslySetInnerHTML={{ __html: formatToHtml(ir.label) }}
        />
    );
}

/**
 *ListObjectDisplay
 *
 * @param {*} {
 *     className,
 *     style,
 *     children,
 *     vertical = true,
 *     lblClass = "f-w-sb f-s-sm",
 *     lblStyle,
 *     valueWrapClass,
 *     valueClass = "f-s-sm",
 *     valueStyle,
 *     label = '',
 *     displayKey ="label",
 *     value = null,
 *     toTitle = false,
 *     valueItemBreak = '',
 * }
 * @return {*}
 */
function ListObjectDisplay({
    className,
    style,
    children,
    vertical = true,
    lblClass = "f-w-sb f-s-sm",
    lblStyle,
    valueWrapClass,
    valueClass = "f-s-sm",
    valueStyle,
    label = "",
    displayKey = "label",
    value = null,
    toTitle = false,
    valueItemBreak = "",
}) {
    if (!value) {
        return null;
    } else {
        return (
            <div className={classNames("list-o-display", className)} style={style}>
                <div className={classNames({ "fl-inline": !vertical })}>
                    <div
                        className={classNames("lbl", lblClass, {
                            "no-display": label === "",
                            "m-r-5": !vertical,
                        })}
                        style={lblStyle}
                    >
                        {label}
                    </div>

                    <div className={classNames(valueWrapClass)}>
                        {value.map((line, idx) => {
                            return (
                                <ListObjectLine
                                    key={idx}
                                    idx={idx}
                                    vertical={vertical}
                                    className={valueClass}
                                    style={valueStyle}
                                    displayKey={displayKey}
                                    value={line}
                                    toTitle={toTitle}
                                    valueItemBreak={valueItemBreak}
                                />
                            );
                        })}
                    </div>

                    {children}
                </div>
            </div>
        );
    }
}

export default memo(ListObjectDisplay);
