import React, { memo } from "react";
import classNames from "classnames";

function PageHeader({
    className,
    style,
    children,
    childClass,
    childStyle,
    pageType = "page", // page - window - popup
    title = "",
    titleClass,
    titleStyle,
    // icon= '',
    showIcon = true,
    showClose = true,
    onClose,
}) {
    return (
        <div className={classNames(`page-hdr is-${pageType}`, className)} style={style}>
            <div className="fl middle">
                {/* {showIcon && <i className="material-icons f-w-b">keyboard_arrow_right</i>} */}

                {/* {icon === '' &&
            <i className="material-icons f-w-b" >keyboard_arrow_right</i>
            }

            {icon !== '' &&
            <i className="material-icons m-r-2" >{icon}</i>
            } */}

                <h2 className={classNames("page-title col-auto", titleClass)} style={titleStyle}>
                    {title}
                </h2>

                <div
                    className={classNames("page-hdr-right ", childClass, {
                        "no-display": !children,
                    })}
                    style={childStyle}
                >
                    {children}
                </div>

                <div
                    className={classNames("close-wrap", { "no-display": !onClose || !showClose })}
                    style={{ zIndex: 10, paddingLeft: "8px" }}
                >
                    <a
                        role="button"
                        title="Close"
                        className={classNames("btn", {
                            "is-primary icon-only": pageType === "page",
                            "icon-only": pageType === "window",
                            "is-dark icon-only": pageType === "popup",
                        })}
                        onClick={onClose}
                    >
                        <i className="material-icons f-w-b f-s-xlg m-r-0">close</i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default memo(PageHeader);
