import React, { useState, useEffect, memo, useMemo, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import InputHoc from "./InputHoc";
import { focusInContainer } from "../displayUtils";
import FormWrap from "../form/_FormHOC";
import { copy, isNullOrUndefined, isUndefined } from "../fn";

// commit on blur

function ButtonChoice({
    // className,
    style,
    btnClass,
    btnSelectedClass,
    iconClass,
    disabled,
    clearable,
    value,
    dataKey,
    labelKey,
    iconKey,
    labelFunction,
    allowMultiple,
    selection,
    onChange,
}) {
    const cmp = useMemo(() => {
        let label = "";
        let isSelected = false;
        let cls = "";
        let icon = "";

        try {
            if (value) {
                isSelected =
                    selection === null
                        ? false
                        : allowMultiple
                          ? selection.includes(value[dataKey])
                          : selection === value[dataKey];

                if (!isNullOrUndefined(labelFunction)) {
                    label = labelFunction(value);
                } else {
                    if (labelKey && labelKey !== "") {
                        label = value[labelKey];
                    }
                }

                if (
                    iconKey &&
                    iconKey !== "" &&
                    value.hasOwnProperty(iconKey) &&
                    value[iconKey] &&
                    value[iconKey] !== ""
                ) {
                    icon = value[iconKey];
                }
            }
        } catch (ignore) {
            label = "";
        }

        if (!isSelected) {
            cls = btnClass;
        } else {
            cls = !btnSelectedClass ? `${btnClass} is-active` : btnSelectedClass;

            if (!clearable) cls = cls + " not-clickable";
        }

        return {
            label: label,
            is_selected: isSelected,
            cls: cls,
            icon: icon,
        };
    }, [selection, value, allowMultiple, btnClass, btnSelectedClass, iconKey, clearable]);

    const changeHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (onChange) onChange(value[dataKey]);
    };

    if (cmp.label === "") {
        return null;
    } else {
        return (
            <a
                disabled={disabled}
                role="button"
                className={classNames("btn", cmp.cls)}
                style={style}
                onClick={changeHandler}
            >
                {cmp.icon && <i className={classNames("material-icons", iconClass)}>{cmp.icon}</i>}
                <span>{cmp.label}</span>
            </a>
        );
    }
}

const ButtonChoicesInput = forwardRef(
    (
        {
            className,
            style,
            children,
            name,
            disabled = false,
            required = false,
            itemType = "button-choices",
            btnClass = null,
            btnSelectedClass = null,
            inputClass,
            iconClass = null,
            isVertical = true,
            clearable = true,
            hGap = 5,
            vGap = 5,
            datalist = [],
            labelKey = "label",
            labelFunction = undefined,
            dataKey = "data",
            iconKey = "",
            value = null,
            allowMultiple = false,
            errorMsg = "",
            onChange,
            onBlur,
            onCommit,
        },
        ref,
    ) => {
        const [state, setState] = useState({ data: value }, []);

        useEffect(() => {
            setState({ ...state, data: value });
        }, [value]);

        const cmp = useMemo(() => {
            let listStyle = null;
            let itemStyle = null;

            if (!isVertical) {
                listStyle = {
                    display: "inline-flex",
                    flexWrap: "wrap",
                    margin: `-${vGap}px 0 0 -${hGap}px`,
                    width: `calc(100% + ${hGap}px)`,
                };

                itemStyle = {
                    margin: `${vGap}px 0 0 ${hGap}px`,
                };
            } else {
                // vertical stack

                listStyle = {
                    display: "block",
                    marginTop: `-${vGap}px`,
                };

                itemStyle = {
                    margin: `${vGap}px 0 0 ${hGap}px`,
                };
            }

            return {
                selection: !state.data ? null : state.data,
                listStyle: listStyle,
                itemStyle: itemStyle,
            };
        }, [state.data, isVertical, hGap, vGap]);

        const validator = (newVal) => {
            let msg = "";
            const val = isUndefined(newVal) ? (!state.data ? null : state.data) : newVal;

            if (required && (!val || val.length === 0))
                msg = errorMsg ? errorMsg : "Selection is required.";
            return msg;
        };

        // click of selection > commit
        const changeHandler = (val) => {
            if (!disabled) {
                let data = null;

                if (allowMultiple) {
                    // multiple selection allowed
                    if (!state.data) {
                        // no exisiting value
                        data = [val];
                    } else {
                        // has existing values
                        let removeIdx = -1;

                        if (state.data) {
                            data = copy(state.data);

                            for (let i = 0; i < data.length; i++) {
                                const item = data[i];

                                if (item === val) {
                                    removeIdx = i; // already selected > remove from selections
                                    break;
                                }
                            }

                            if (removeIdx > -1 && clearable) {
                                data.splice(removeIdx, 1);
                            } else {
                                data.push(val);
                            }
                        } else {
                            data = [val];
                        }
                    }
                } else {
                    // single value only;
                    data = state.data !== val ? val : clearable ? null : state.data;
                }

                if (data && data.length === 0 && clearable) data = null;

                const err = validator(data);

                setState({ data: data });
                if (onChange) onChange(name, data, true, err);
            }
        };

        const commitData = (val) => {
            if (!disabled) {
                const err = validator(val);
                if (onCommit) onCommit(name, val, err);
            }
        };

        // commit event
        const blurHandler = async (e) => {
            const blurred = await focusInContainer(e);

            if (blurred) {
                if (!disabled) commitData(state.data); // commit data on blur
                if (onBlur) onBlur(e);
            }
        };

        if (ref) {
            useImperativeHandle(ref, () => ({
                validate() {
                    return validator();
                },
                commitData() {
                    return commitData(state.data);
                },
            }));
        }

        return (
            <div className={classNames("btn-choices-input", className)} style={style}>
                <div
                    disabled={disabled}
                    name={name}
                    className={classNames("fl middle wrap", inputClass)}
                    style={cmp.listStyle}
                    onBlur={blurHandler}
                >
                    {datalist.map((item, idx) => {
                        return (
                            <ButtonChoice
                                key={idx}
                                style={cmp.itemStyle}
                                btnClass={btnClass}
                                btnSelectedClass={btnSelectedClass}
                                clearable={clearable}
                                iconClass={iconClass}
                                iconKey={iconKey}
                                disabled={disabled}
                                value={item}
                                dataKey={dataKey}
                                labelKey={labelKey}
                                labelFunction={labelFunction}
                                allowMultiple={allowMultiple}
                                selection={cmp.selection}
                                onChange={changeHandler}
                            />
                        );
                    })}
                </div>

                {children}
            </div>
        );
    },
);

export default FormWrap(InputHoc(memo(ButtonChoicesInput), null));
/******************************
id
name
group
buttonsPerRow = 7 // rows to show per row
datalist = [{label: 'label', data: 1}, ...]
labelKey: 'label',
dataKey: 'data',
value = ['data value', ...] // value of selected data key

required: bool
allowMultiple: bool

onChange
onFocus
onBlur
onKeyUp

inputClass
******************************/
