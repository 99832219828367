import axios from "axios";
import { stringify, parse } from "qs";
import { getLocalStorage, serverErrorMsg, hasObject } from "./app-base/fn";
import { ERR_CODES } from "./app-base/data/baseData";

const hasInternet = () => {
    let hasConnection = true;

    try {
        hasConnection = navigator.onLine;
    } catch (ignore) {}

    return hasConnection;
};

const getToken = () => {
    let token = "";

    if (getLocalStorage(import.meta.env.VITE_TOKEN_KEY) !== null) {
        token = getLocalStorage(import.meta.env.VITE_TOKEN_KEY);
    }

    return token;
};

const setHeader = () => {
    const cToken = getToken();
    return { headers: { authorization: cToken ? cToken : "" } };
};

const dispatchSuccessRespose = (response) => {
    return { error: undefined, data: response.data };
};

const dispatchErrorResponse = (err) => {
    let msg = "Error occurred";

    if (import.meta.env.VITE_DEBUG === "1" && err) {
        console.log("err:", err);
        console.log("dispatchErrorResponse err", JSON.stringify(err));
        console.trace();
    }

    if (!err) return { error: msg };

    if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        msg = serverErrorMsg(err.response);
    } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest

        if (err.request.response) {
            const reqRes =
                typeof err.request.response === "string"
                    ? JSON.parse(err.request.response)
                    : err.request.response;

            if (reqRes.message && reqRes.message !== "") {
                msg = reqRes.message;
            } else {
                if (reqRes.error && reqRes.error !== "") {
                    msg = reqRes.error;
                }
            }
        }
    } else {
        let statMsg = "";

        if (err.status) statMsg = ERR_CODES[err.status];
        if (statMsg === "" && err.message && err.message !== "") statMsg = err.message;
        msg = statMsg;
    }

    return { error: msg === "" ? "Error occurred" : msg };
};

/******************************
Methods
******************************/
export async function getTextFile(url, controller = null) {
    if (hasInternet()) {
        let res = { error: null, data: null };

        try {
            const req = {
                responseType: "text",
            };

            if (controller) req.signal = controller.signal;

            const n = await axios.get(url, req);

            res.data = n.data;
        } catch (exp) {
            if (["AbortError", "CanceledError"].includes(exp.name)) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("request aborted");
            } else {
                const msg = exp.response
                    ? serverErrorMsg(exp.response)
                    : "Unable to connect to network.";
                res.error = msg;
            }
        }

        return res;
    } else {
        return {
            error: "Please check your network connectivity",
        };
    }
}

export async function externalGet(url, query, controller = null) {
    if (hasInternet()) {
        const req = {
            params: query,
            paramsSerializer: {
                indexes: null,
            },
            // paramsSerializer: {
            //     encode: parse,
            //     serialize: stringify,
            // },
            // paramsSerializer: (query) => {
            //     return stringify(query, { arrayFormat: "repeat" });
            // },
        };

        if (controller) req.signal = controller.signal;

        try {
            const res = await axios.get(url, req);
            return dispatchSuccessRespose(res);
        } catch (exp) {
            if (["AbortError", "CanceledError"].includes(exp.name)) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("request aborted");
            } else {
                return dispatchErrorResponse(exp);
            }

            return false;
        }
    } else {
        return {
            error: "Please check your network connectivity",
        };
    }
}

export async function apiGet(path, query, controller) {
    const p = hasObject(query) ? query : null;

    if (hasInternet()) {
        let req = {
            params: p,
            paramsSerializer: {
                // indexes: null,
                // arrayFormat: "repeat",
                serialize: (params) => {
                    // return stringify(params, { arrayFormat: "repeat" });
                    return stringify(params, { arrayFormat: "repeat" });
                },
                // encode: (params) => {
                //     return parse(params, { comma: true });
                // },
                // encode: parse,
            },

            // paramsSerializer: (params) => {
            //     const c = stringify(params, { arrayFormat: "brackets" });

            //     return c === "" ? null : c;
            // },
            // paramsSerializer: {
            //     indexes: null,
            // },
            // paramsSerializer: (params) => {
            //     const c = stringify(params);

            //     return c;
            // },
            // paramsSerializer: {
            //     encode: parse,
            //     serialize: stringify,
            // },
            headers: { authorization: getToken() },
        };

        if (controller) req.signal = controller.signal;

        try {
            const res = await axios.get(import.meta.env.VITE_API_URL + path, req);
            return dispatchSuccessRespose(res);
        } catch (exp) {
            if (["AbortError", "CanceledError"].includes(exp.name)) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("request aborted");
            } else {
                return dispatchErrorResponse(exp);
            }

            return false;
        }
    } else {
        return {
            error: "Please check your network connectivity",
        };
    }
}

export async function apiPost(path, body, controller = null) {
    // try {
    //     const token = getToken();
    //     let request = {
    //         method: "POST",
    //         body: JSON.stringify(body)
    //     };

    //     if (token && token !== "") request.headers = { Authorization: "Bearer " + token };

    //     let response = await fetch(import.meta.env.VITE_API_URL + path, request);

    //     if (response.ok) {
    //         // status 200-299
    //         let responseJson = await response.json();

    //         return { error: undefined, data: responseJson };
    //     }

    //     return dispatchErrorResponse(response);
    // } catch (error) {
    //     return { error: "Unable to connect to server." };
    // }

    if (hasInternet()) {
        let req = setHeader();

        if (controller) req.signal = controller.signal;

        try {
            const res = await axios.post(import.meta.env.VITE_API_URL + path, body, req);
            return dispatchSuccessRespose(res);
        } catch (exp) {
            if (["AbortError", "CanceledError"].includes(exp.name)) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("request aborted");
            } else {
                return dispatchErrorResponse(exp);
            }

            return false;
        }
    } else {
        return {
            error: "Please check your network connectivity",
        };
    }
}

export async function apiPut(path, body, controller = null) {
    if (hasInternet()) {
        let req = setHeader();

        if (controller) req.signal = controller.signal;

        try {
            const res = await axios.put(import.meta.env.VITE_API_URL + path, body, req);
            return dispatchSuccessRespose(res);
        } catch (exp) {
            if (["AbortError", "CanceledError"].includes(exp.name)) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("request aborted");
            } else {
                return dispatchErrorResponse(exp);
            }

            return false;
        }
    } else {
        return {
            error: "Please check your network connectivity",
        };
    }
}

export async function apiDelete(path, controller = null) {
    if (hasInternet()) {
        let req = setHeader();

        if (controller) req.signal = controller.signal;

        try {
            const res = await axios.delete(import.meta.env.VITE_API_URL + path, req);
            return dispatchSuccessRespose(res);
        } catch (exp) {
            if (["AbortError", "CanceledError"].includes(exp.name)) {
                if (import.meta.env.VITE_DEBUG === "1") console.log("request aborted");
            } else {
                return dispatchErrorResponse(exp);
            }
            return false;
        }
    } else {
        return {
            error: "Please check your network connectivity",
        };
    }
}
