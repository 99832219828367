import React, { memo } from "react";
import classNames from "classnames";

function OrgListIr({ className, style, children, item = null, onClick }) {
    return (
        <a className={classNames("org-list-ir", className)} style={style} onClick={onClick}>
            <div className="f-w-sb l-s-1 " style={{ lineHeight: "30px" }}>
                {item.name}
            </div>

            {children}
        </a>
    );
}

export default memo(OrgListIr);
