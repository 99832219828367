import { scrollToY } from "./displayUtils";

export default class Form {
    constructor(onSubmit = null) {
        this.elements = [];
        this.onSubmit = onSubmit;
    }

    dispose = () => {
        this.elements = [];
    };

    add = (name, id, validate, showError) => {
        this.remove(name);

        this.elements.push({
            name: name,
            id: id,
            validate: validate,
            showError: showError
        });
    };

    // remove input
    remove = (name) => {
        if (this.elements) {
            this.elements.map((input, idx) => {
                if (input.name === name) this.elements.splice(idx, 1); // remove if already in elements
            });
        }
    };

    element = (name) => {
        if (name) {
            for (let i = 0; i < this.elements.length; i++) {
                const elm = this.elements[i];
                if (elm.name === name) return elm;
            }
        }

        return null;
    };

    // focus in on input
    focus = (name) => {
        const elm = this.element(name);
        if (elm && elm.focus) elm.focus();
    };

    // scroll to element
    show = (name, focus = false) => {
        const elm = this.element(name);

        if (elm) {
            try {
                scrollToY(elm.id);
                if (focus && elm.focus) elm.focus();
            } catch (ignore) {}
        }
    };

    scrollToElement = (name) => {
        let idx = -1;

        for (let i = 0; i < this.elements.length; i++) {
            if (this.elements[i].name === name) {
                idx = i;
                break;
            }
        }

        if (idx > -1) scrollToY(this.elements[idx].id);
    };

    validate = (scrollToError = false) => {
        let err = null;
        let showErrorHandler = null;

        for (let i = 0; i < this.elements.length; i++) {
            const elm = this.elements[i];

            if (elm && elm.hasOwnProperty("validate")) {
                const elmErr = elm.validate();

                // if (elm.validate) elm.validate();

                if (elmErr && elmErr.msg && elmErr.msg !== "") {
                    err = elmErr;
                    if (elm.hasOwnProperty("showError")) showErrorHandler = elm.showError;
                    break;
                }
            }
        }

        if (err && scrollToError) {
            if (showErrorHandler) showErrorHandler(err.msg);
            scrollToY(err.id);
        }

        return err;
    };
}
