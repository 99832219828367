import React, { useMemo, memo, forwardRef, useImperativeHandle, useState, useEffect } from "react";
import classNames from "classnames";
import { isUndefined, randomId } from "../fn";
import { isValidEmail, isValidUrl } from "../validator";
import InputHoc from "./InputHoc";
import FormHoc from "../form/_FormHOC";

// commit on blur

const TextInput = forwardRef(
    (
        {
            className,
            style,
            children,
            name = "",
            itemType = "text",
            type = "text", // text, number, email, tel, url
            value = "",
            disabled = false,
            required = false,
            focus = false,
            placeholder = "",
            autoCapitalize = "off", // sentences - words - characters
            autoComplete = "off",
            errorMsg = "",
            inputClass,
            // id,
            // elmId,
            minLength,
            maxLength,
            onFocus,
            onKeyDown,
            onKeyUp,
            onChange,
            onBlur,
            onCommit,
        },
        ref,
    ) => {
        const [state, setState] = useState({ data: value }, []);

        const cmp = useMemo(() => {
            const rand = randomId();

            return {
                rand: rand,
                ph: state.data === "" ? (!placeholder ? "" : placeholder) : "",
                ac: autoComplete && autoComplete === "on" ? autoComplete : "text_" + rand,
            };
        }, [value, autoComplete, placeholder]);

        const validator = (newVal) => {
            const val = isUndefined(newVal)
                ? !state.data
                    ? ""
                    : state.data.trim()
                : !newVal
                  ? ""
                  : newVal.trim();
            let msg = "";

            if (required && (!val || val === "")) {
                if (type === "email") {
                    msg = "Email address is required.";
                } else if (type === "password") {
                    msg = "Password is required.";
                } else {
                    msg = errorMsg ? errorMsg : "Value is required.";
                }
            }

            if (msg === "" && val !== "" && type === "email" && !isValidEmail(val))
                msg = "Please enter valid email.";
            if (msg === "" && val !== "" && type === "url" && !isValidUrl(val))
                msg = "Please enter valid URL.";
            if (msg === "" && val.length > maxLength)
                msg = "Value can not exceed " + maxLength + " characters.";
            if (msg === "" && val.length < minLength)
                msg = "Value must contain at least " + minLength + " characters.";

            return msg;
        };

        useEffect(() => {
            setState({ ...state, data: value });
        }, [value]);

        const commitData = (val) => {
            if (!disabled) {
                const err = validator(val);
                if (onCommit) onCommit(name, val, err);
            }
        };

        const changeHandler = (e) => {
            if (!disabled) {
                const txt = e.target.value;

                setState({ data: txt });
                if (onChange) onChange(name, txt);
            }
        };

        // commit event
        const blurHandler = (e) => {
            if (!disabled) commitData(state.data); // commit data on blur
            if (onBlur) onBlur(e);
        };

        if (ref) {
            useImperativeHandle(ref, () => ({
                validate() {
                    return validator();
                },
                commitData() {
                    return commitData(state.data);
                },
            }));
        }

        return (
            <div className={classNames("input-base", className)} style={style}>
                <input
                    disabled={disabled}
                    type={type}
                    className={classNames("input", inputClass)}
                    // id={elmId}
                    name={cmp.rand}
                    placeholder={cmp.ph}
                    required={required}
                    minLength={minLength}
                    maxLength={maxLength}
                    autoFocus={focus}
                    autoCapitalize={autoCapitalize}
                    autoComplete={cmp.ac}
                    value={state.data}
                    onFocus={onFocus}
                    onChange={changeHandler}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onBlur={blurHandler}
                />

                {children}
            </div>
        );
    },
);

export default FormHoc(InputHoc(memo(TextInput), ""));
/******************************
id
name
type
focus
placeholder
value
required
maxLength
maxLength
autoCapitalize
autoComplete
group
onInputAdd
onInputRemove(name) {}
onChange
onFocus
onBlur
onKeyUp

className
inputClass
******************************/
