import React, { useMemo, memo } from "react";
import classNames from "classnames";
import { LANGUAGE_LIST } from "../../app-base/data/langs";
import { findListItemIndex, formatDate, timeDuration, toTitleCase } from "../../app-base/fn";
import { getAvatarData, personName } from "../../utils";
// import Avatar from "../../app-base/elm/Avatar";
import ListObjectDisplay from "../../app-base/elm/ListObjectDisplay";

function PatientSearchIr({ className, style, children, item = null, onClick, onAddEncounter }) {
    const addEncounterHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        onAddEncounter(item.id);
    };

    const cmp = useMemo(() => {
        let avatar_data = null;
        let pre_patient = false;
        let age_str = "";
        let langs = null;

        if (item) {
            avatar_data = getAvatarData(item.entity, item);
            pre_patient = item.status === "pending" ? "Pre-Patient" : "";
            age_str = !item.dob ? "" : Math.floor(timeDuration(new Date(), item.dob, "years"));

            if (item.lang && item.lang.length > 0) {
                langs = item.lang.map((lang) => {
                    if (typeof lang === "string") {
                        const idx = findListItemIndex(LANGUAGE_LIST, "iso639_1", lang);

                        if (idx > -1) return LANGUAGE_LIST[idx].name;
                    } else {
                        if (lang && lang.name) return lang.name;
                    }
                });
            }
        }

        return {
            avatar_data: avatar_data,
            pre_patient: pre_patient,
            age_str: age_str,
            langs: langs,
        };
    }, [item]);

    return (
        <div
            className={classNames("patient-search-ir fl", className)}
            style={{ ...{ cursor: !onClick ? "auto" : "pointer" }, ...style }}
            onClick={() => onClick(item)}
            title="View"
            role="option"
        >
            <div className="col-fixed" style={{ width: "36px" }}>
                {/* {cmp.pre_patient === "" && (
                    <Avatar
                        id={item.id}
                        className="sm"
                        entity={cmp.avatar_data.entity}
                        imageUrl={cmp.avatar_data.imageUrl}
                        text={cmp.avatar_data.text}
                    />
                )} */}

                {cmp.pre_patient !== "" && (
                    <i
                        className="material-icons c-yellow f-s-xlg m-l-2"
                        style={{ lineHeight: 1.3 }}
                    >
                        schedule
                    </i>
                )}
            </div>

            <div className="col-auto">
                <div className="c-white f-w-sb">{personName(item, true)}</div>

                <div className="fl-inline">
                    <div className="c-grey-light">
                        {item.gender && item.gender !== "" && toTitleCase(item.gender)}
                    </div>

                    <div className="c-grey-light m-l-10">
                        {item.dob && <span>{formatDate(item.dob, "M/D/YYYY", true)}</span>}
                    </div>

                    <div className=" c-grey-light m-l-10">
                        {item.dob && (
                            <>
                                <span className="c-grey f-s-sm">Age:</span>
                                <span className="f-s-sb m-l-3">{cmp.age_str}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="col-fixed col-center" style={{ width: "100px" }}>
                <ListObjectDisplay
                    vertical={true}
                    lblClass="c-grey-md f-w-sb f-s-sm"
                    valueClass="f-s-sm c-grey-light"
                    label="Language"
                    value={cmp.langs}
                    displayKey=""
                />
            </div>

            <div className="col-fixed col-right" style={{ width: "100px" }}>
                <div className="c-orange">{item.patient_id}</div>

                <button className="btn sm is-light m-t-5" onClick={addEncounterHandler}>
                    <span className="material-icons">add</span>
                    <span className="">Encounter</span>
                </button>
            </div>

            {children}
        </div>
    );
}

export default memo(PatientSearchIr);

// export default memo(
//     PatientSearchIr,
//     (prevProps, nextProps) =>
//         !isSame(prevProps.className, nextProps.className) || !isSame(prevProps.item, nextProps.item)
// );
