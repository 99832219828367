import React, { memo } from "react";
import classNames from "classnames";

function ErrorTooltip({ className, style, children, isOpen = false, label = "" }) {
    return (
        <div
            className={classNames("error-tooltip", className, {
                "is-open": label !== "" && isOpen,
            })}
            style={style}
        >
            <div className="error-content">
                {label}
                {children}
            </div>
        </div>
    );
}

export default memo(ErrorTooltip);
