import React, { useMemo, memo } from "react";
import classNames from "classnames";
import { isNullOrUndefined } from "../../app-base/fn";
import Dropdown from "../../app-base/input/Dropdown";
import OrgListIr from "./OrgListIr";
import OrgInviteIr from "./OrgInviteIr";

function OrgChanger({
    className,
    style,
    disabled = false,
    selectedOrgid = null,
    orgList = [],
    onOrgVerifyClick,
    onOrgChange,
}) {
    const cmp = useMemo(() => {
        let invitedOrgList = [];
        let activeOrgList = [];

        if (orgList) {
            invitedOrgList = orgList.filter((org) => {
                return org.app_status === "active" && isNullOrUndefined(org.email_verify_dte);
            });

            activeOrgList = orgList.filter((org) => {
                return org.app_status === "active" && !isNullOrUndefined(org.email_verify_dte);
            });
        }

        return {
            invitedOrgList: invitedOrgList,
            activeOrgList: activeOrgList,
        };
    }, [orgList]);

    const orgChangeHandler = (name, val) => {
        if (onOrgChange) onOrgChange(val.orgid);
    };

    return (
        <div className={classNames("org-changer p-rel", className)} style={style}>
            <Dropdown
                buttonClass="w-full ghost is-black"
                // listClass="f-w-sb"
                placeholder=" "
                disabled={disabled}
                name="id"
                // iconPosition="left"
                datalist={cmp.activeOrgList}
                value={selectedOrgid}
                labelKey="name"
                valueKey="orgid"
                required={true}
                listIr={OrgListIr}
                onChange={orgChangeHandler}
            >
                <div
                    className={classNames("l-s-1 bg-grey c-white p-t-5 p-b-5 t-center", {
                        "no-display": cmp.invitedOrgList.length === 0,
                    })}
                >
                    Pending Organizations
                </div>

                {cmp.invitedOrgList.map((item) => {
                    return (
                        <OrgInviteIr
                            key={item.id}
                            className="p-20 t-center"
                            item={item}
                            onClick={onOrgVerifyClick}
                        />
                    );
                })}

                {/* <li className="footer t-center p-t-20 p-b-20 bg-white">
                    <a
                        role="button"
                        className="bg-none"
                        onClick={onAdd}
                        title="Create Organization"
                    >
                        + Create Organization
                    </a>
                </li> */}
            </Dropdown>

            <div
                className={classNames("circle bg-red", {
                    "no-display": cmp.invitedOrgList.length === 0,
                })}
                style={{ position: "absolute", top: "0px", right: "0px" }}
            />
        </div>
    );
}

export default memo(OrgChanger);
