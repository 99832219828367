import React from "react";
import classNames from "classnames";
import { parsedLocation } from "../../app-base/fn";
import Loading from "../../app-base/elm/Loading";

const NotFoundPage = ({ className, style, children, location, open }) => {
    const loc = parsedLocation(location);
    const path = !loc ? "" : loc.pathname.toLowerCase();
    const transitionPage = path === "" || path === "/" || path === "/signin";

    return (
        <div
            className={classNames(
                {
                    "container-open": open,
                    "not-found-page": !transitionPage,
                    "transition-page": transitionPage,
                },
                className
            )}
            style={style}
        >
            {children}
            <div className="p-rel t-center h-full w-full">
                {!transitionPage && (
                    <section className="not-found-page content-pad">Page Not Found</section>
                )}

                {transitionPage && (
                    <div style={{ paddingTop: "25%" }}>
                        <Loading show={true} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotFoundPage;
