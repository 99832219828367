import React, {
    useContext,
    Fragment,
    memo,
    useCallback,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import classNames from "classnames";
import { AppContext } from "../../context/AppContext";
import { OrgContext } from "../../context/OrgContext";
import listState from "../../states/listState";
import { getOrgEncounterSearch } from "../../actions/orgActions";
import PlaceholderList from "../PlaceholderList";
import EncounterSearchIr from "../encounters/EncounterSearchIr";

const SearchEncounter = forwardRef(
    (
        {
            className,
            style,
            children,
            visible = false,
            disabled = false,
            mode = "search",
            orgid = null,
            searchText = "",
            irClass = null,
            irStyle = null,
            toggleHandler,
        },
        ref,
    ) => {
        const appState = useContext(AppContext);
        const orgState = useContext(OrgContext);
        const { state, actions } = listState();

        const isMounted = useRef(null);
        const controller = useRef(null);
        const reload = useRef(false);

        useEffect(() => {
            isMounted.current = true;

            return () => {
                isMounted.current = false;
            };
        }, []);

        useEffect(() => {
            if (visible) {
                reload.current = false;
                load(orgid, searchText, false);
            } else {
                reload.current = true;
            }
        }, [orgid, searchText]);

        useEffect(() => {
            if (visible && reload.current) {
                reload.current = false;
                load(orgid, searchText, false);
            }
        }, [visible]);

        const sortFn = useCallback(
            (a, b) => {
                let val = 0;

                // sort by title
                val = a.lastName === b.lastName ? 0 : a.lastName < b.lastName ? -1 : 1;
                return val;
            },
            [state.list],
        );

        const load = async (orgid, searchText, append = false) => {
            if (controller.current) {
                controller.current.abort();
                controller.current = null;
            }

            if (searchText === "") {
                if (isMounted.current) actions({ type: "setList", value: { list: [] } });
            } else {
                if (isMounted.current && !append) actions({ type: "setList", value: { list: [] } });

                controller.current = new AbortController();
                if (isMounted.current) actions({ type: "changeState", value: { loading: true } });

                const res = await getOrgEncounterSearch(
                    orgid,
                    searchText,
                    10,
                    append ? state.loadedRows : 0,
                    controller.current,
                );

                if (isMounted.current) actions({ type: "changeState", value: { loading: false } });

                if (!res.error && res.data) {
                    if (isMounted.current)
                        actions({ type: "setList", value: { list: res.data, append: append } });
                }
            }

            if (isMounted.current) actions({ type: "changeState", value: { loading: false } });
        };

        const loadMoreHandler = () => {
            load(orgid, searchText, true);
        };

        const savedHandler = (data, itemKey = "id") => {
            if (isMounted.current) {
                actions({
                    type: "updateItem",
                    value: { item: data, key: itemKey, addNotFound: true },
                });

                // update recent list
                orgState.actions({
                    type: "orgRecentListItemUpdate",
                    value: { view: "encounters", item: data, itemKey: itemKey },
                });
            }
        };

        const clickHandler = (item) => {
            orgState.actions({
                type: "orgRecentListAdd",
                value: { view: "encounters", item: item, itemKey: "id" },
            });

            appState.actions({
                type: "openWindow",
                value: {
                    name: "encounter",
                    title: "Encounter",
                    props: {
                        orgid: orgid,
                        id: item.id,
                        onSaved: savedHandler,
                    },
                },
            });

            toggleHandler();
        };

        if (ref) {
            useImperativeHandle(ref, () => ({
                load() {
                    return load(orgid, searchText, false);
                },
            }));
        }

        if (!visible) {
            return null;
        } else {
            return (
                <div className={classNames("search-encounter", className)} style={style}>
                    {children}

                    {mode === "search" && (
                        <Fragment>
                            {state.list.length === 0 && !state.loading && (
                                <div className="center-all f-w-sb c-grey m-t-50">No Result</div>
                            )}

                            {state.list.sort(sortFn).map((item) => {
                                return (
                                    <EncounterSearchIr
                                        key={item.id}
                                        className={irClass}
                                        style={irStyle}
                                        disabled={disabled}
                                        item={item}
                                        onClick={clickHandler}
                                    />
                                );
                            })}

                            <PlaceholderList
                                className="p-25"
                                rows={6}
                                loading={state.loading}
                                theme="dark"
                                loaderStyle={{ width: "100%", height: "auto", maxWidth: "600px" }}
                            />

                            {state.totalRows > state.loadedRows && (
                                <div className="m-t-15 m-b-30 t-center">
                                    <button
                                        type="button"
                                        className={classNames("btn", {
                                            "is-loading": state.loading,
                                        })}
                                        disabled={state.loading}
                                        onClick={loadMoreHandler}
                                    >
                                        Load More
                                    </button>
                                </div>
                            )}
                        </Fragment>
                    )}

                    {mode === "recent" && (
                        <Fragment>
                            {orgState.data.recentList.encounters.length === 0 && (
                                <div className="center-all f-w-sb c-grey m-t-50">No Recent</div>
                            )}

                            {orgState.data.recentList.encounters.map((item) => {
                                return (
                                    <EncounterSearchIr
                                        key={item.id}
                                        className={irClass}
                                        style={irStyle}
                                        disabled={disabled}
                                        item={item}
                                        onClick={clickHandler}
                                    />
                                );
                            })}
                        </Fragment>
                    )}
                </div>
            );
        }
    },
);

export default memo(SearchEncounter);
