import React, {
    useState,
    useEffect,
    useMemo,
    memo,
    useRef,
    forwardRef,
    useImperativeHandle,
    createElement,
} from "react";
import classNames from "classnames";
import InputHoc from "./InputHoc";
import { focusInContainer } from "../displayUtils";
import { findListItemIndex, isUndefined } from "../fn";
import FormWrap from "../form/_FormHOC";

// commit on click of selection

function DropItemIr({
    className,
    style,
    item,
    disabled,
    valueKey,
    labelKey,
    selected,
    listIr,
    onClick,
}) {
    const clickHandler = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (onClick) onClick(item);
    };

    const cmp = useMemo(() => {
        let classes = classNames("list-item", className, {
            "is-active": selected,
        });

        let ir;

        if (!listIr) {
            ir = <a onClick={clickHandler}>{item[labelKey]}</a>;
        } else {
            ir = createElement(listIr, {
                item: item,
                onClick: clickHandler,
            });
        }

        return {
            itemCls: classes,
            ir: ir,
        };
    }, [item, valueKey, selected, disabled]);

    return (
        <li className={cmp.itemCls} style={style}>
            {cmp.ir}
        </li>
    );
}

function DropPanel({
    className,
    style,
    children,
    disabled,
    listPosition,
    listWidth,
    isOpen,
    datalist,
    listHeaderLabel,
    valueKey,
    labelKey,
    selectedIdx,
    value,
    listIr,
    listFooterRenderer,
    close,
    onClick,
}) {
    // DropPanel.handleClickOutside = () => {
    //     if (close) close();
    // };

    style = { ...style, width: listWidth };

    const mouseLeaveHandler = (e) => {
        close();
    };

    return (
        <ul
            className={classNames("drop-list w-full animate__animated", listPosition, className, {
                "is-open animate__zoomIn": isOpen,
                "is-closed": !isOpen,
                "no-display": !isOpen || !datalist || datalist.length === 0,
            })}
            style={style}
            role="menu"
            onMouseLeave={mouseLeaveHandler}
        >
            <li
                className={classNames("hdr", {
                    "no-display": !listHeaderLabel || listheaderLabel === "",
                })}
            >
                {listHeaderLabel}
            </li>

            {datalist.map((item, idx) => {
                return (
                    <DropItemIr
                        key={idx}
                        item={item}
                        disabled={disabled}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        selected={selectedIdx === idx}
                        value={value}
                        listIr={listIr}
                        onClick={onClick}
                    />
                );
            })}

            {children}

            <li
                className={classNames("footer t-center p-t-30 p-b-30", {
                    "no-display": !listFooterRenderer,
                })}
            >
                {listFooterRenderer}
            </li>
        </ul>
    );
}

// const DropPanelWithClickOut = onClickOutside(DropPanel, {
//     handleClickOutside: () => DropPanel.handleClickOutside,
// });

const Dropdown = forwardRef(
    (
        {
            className,
            style,
            children,
            itemType = "dropdown",
            datalist = [],
            value = null,
            labelKey = "label",
            valueKey = "data",
            disabled = false,
            required = false,
            placeholder = "Select...",
            listPosition = "left", // left | center | right
            iconPosition = "right", // left | right
            errorMsg = "",
            dropdownStyle = null,
            listWidth = "100%",
            listClass,
            listHeaderLabel,
            listIr,
            listFooterRenderer,
            name,
            onFocus,
            onChange,
            onBlur,
            onCommit,
        },
        ref,
    ) => {
        const isMounted = useRef(null);
        const [state, setState] = useState(
            {
                data: value,
                is_open: false,
            },
            [],
        );

        useEffect(() => {
            isMounted.current = true;

            return () => {
                isMounted.current = false;
            };
        }, []);

        const changeState = (newObj) => {
            if (isMounted.current)
                setState((state) => {
                    return { ...state, ...newObj };
                });
        };

        const cmp = useMemo(() => {
            let label = placeholder ? placeholder : "Select...";
            let selectedIdx = -1;

            if (datalist && state.data)
                selectedIdx = state.data.hasOwnProperty(valueKey)
                    ? findListItemIndex(datalist, valueKey, state.data[valueKey])
                    : findListItemIndex(datalist, valueKey, state.data);

            if (selectedIdx > -1) {
                const itm = datalist[selectedIdx];
                label = itm[labelKey];
            }

            return {
                selectedIdx: selectedIdx,
                value_label: label,
            };
        }, [placeholder, datalist, value, valueKey, labelKey, disabled]);

        const toggleDropdown = () => {
            if (!disabled) {
                changeState({ is_open: !state.is_open });
            }
        };

        const validator = (newVal) => {
            let msg = "";
            const val = isUndefined(newVal) ? (!state.data ? null : state.data) : newVal;

            if (required && !val) msg = errorMsg ? errorMsg : "Selection is required.";
            return msg;
        };

        const commitData = (newVal) => {
            if (!disabled) {
                const err = validator(newVal);

                if (onCommit) onCommit(name, newVal, err);
            }
        };

        const changeHandler = (item) => {
            if (!disabled) {
                const err = validator(item);

                changeState({ data: item, is_open: false });
                if (onChange) onChange(name, item, true, err);
            }
        };

        const closeHandler = () => {
            changeState({ is_open: false });
        };

        const focusHandler = (e) => {
            if (onFocus) onFocus(e);
        };

        const blurHandler = async (e) => {
            const blurred = await focusInContainer(e);

            if (blurred) {
                if (!disabled) commitData(state.data);
                if (onBlur) onBlur(e);
            }
        };

        useEffect(() => {
            changeState({ data: value });
        }, [value]);

        if (ref) {
            useImperativeHandle(ref, () => ({
                validate() {
                    return validator();
                },
                commitData() {
                    return commitData(state.data);
                },
            }));
        }

        return (
            <div
                className={classNames("dropdown", className, { "is-disabled": disabled })}
                style={style}
                disabled={disabled}
                onFocus={focusHandler}
                onBlur={blurHandler}
            >
                <a
                    role="button"
                    tabIndex="-1"
                    name={name}
                    disabled={disabled}
                    onClick={toggleDropdown}
                >
                    <div
                        className={classNames("dropdown-inner fl middle", {
                            "is-active": state.is_open,
                        })}
                    >
                        <i
                            className={classNames("material-icons col-fixed m-l-0 f-s-lg", {
                                "is-open": state.is_open,
                                "no-display": iconPosition !== "left",
                            })}
                        >
                            arrow_drop_down
                        </i>

                        <div
                            className={classNames("lbl t-ellipsis col-auto", {
                                "t-placeholder": placeholder === cmp.value_label,
                            })}
                        >
                            {cmp.value_label}
                        </div>

                        <i
                            className={classNames(
                                "material-icons col-fixed t-right m-r-0 m-l-5 f-s-lg",
                                {
                                    "is-open": state.is_open,
                                    "no-display": iconPosition !== "right",
                                },
                            )}
                        >
                            arrow_drop_down
                        </i>
                    </div>
                </a>

                <DropPanel
                    className={listClass}
                    style={dropdownStyle}
                    listPosition={listPosition}
                    listWidth={listWidth}
                    disabled={disabled}
                    isOpen={state.is_open}
                    datalist={datalist}
                    listHeaderLabel={listHeaderLabel}
                    valueKey={valueKey}
                    labelKey={labelKey}
                    selectedIdx={cmp.selectedIdx}
                    value={state.data}
                    listIr={listIr}
                    listFooterRenderer={listFooterRenderer}
                    close={closeHandler}
                    onClick={changeHandler}
                    children={children}
                />
            </div>
        );
    },
);

export default FormWrap(InputHoc(memo(Dropdown), null));

// export default FormWrap(
//     InputHoc(
//         memo(onClickOutside(Dropdown, { handleClickOutside: () => Dropdown.handleClickOutside })),
//         null
//     )
// );
/******************************
name
placeholder
group
value
required
datalist
listIr
listHeaderLabel
listFooterRenderer
valueKey
labelKey
onChange
onInputAdd
onInputRemove(name) {}
onValidationRemove(id) {}
className - i.e. col-10
buttonClass
listClass
******************************/
