import React, { Suspense, memo, forwardRef, useRef, useState, useEffect } from "react";
import classNames from "classnames";
import WindowContent from "./WindowContent";
import { randomId } from "../app-base/fn";
// import { AppContext } from "../context/AppContext";
// import { Scrollbars } from "react-custom-scrollbars-2";
// import ModuleLoading from "./ModuleLoading";
import PageHeader from "./PageHeader";

const WindowUI = forwardRef(
    (
        {
            style,
            className,
            name,
            title = "",
            pageType = "window", // page - window - popup
            size = "md", // xsm - sm - md - lg
            hasPadding = true,
            close,
            windowId,
            ...other
        },
        ref,
    ) => {
        const elm = useRef();
        const containerId = useRef(randomId());
        const [state, setState] = useState({ scrollYPos: 0, cmpLoaded: false });

        const changeState = (obj) => {
            if (obj) {
                setState((prevState) => ({ ...prevState, ...obj }));
            }
        };

        const scrollHandler = (e) => {
            try {
                const elmScroll = e.currentTarget.scrollTop;

                changeState({
                    scrollYPos: elmScroll,
                });
            } catch (ignore) {}
        };

        const scrollClickHandler = (e) => {
            try {
                if (e) {
                    e.preventDefault();
                }

                const elm = document.getElementById(containerId.current);

                elm.scrollTo(0, 0);
            } catch (ignore) {}
        };

        const componentLoadedHandler = (mounted) => {
            changeState({ cmpLoaded: mounted });
        };

        const closeHandler = () => {
            if (elm.current && elm.current.closeValidate) {
                const promptData = elm.current.closeValidate();

                if (promptData && promptData.show) {
                    const c = confirm(promptData.msg);
                    if (c === true) if (close) close(windowId);
                } else {
                    changeState({
                        cmpLoaded: false,
                    });

                    if (close) {
                        setTimeout(() => {
                            close(windowId);
                        }, 200);
                    }
                }
            } else {
                changeState({
                    cmpLoaded: false,
                });

                if (close) {
                    setTimeout(() => {
                        close(windowId);
                    }, 200);
                }
            }
        };

        useEffect(() => {
            setTimeout(() => {
                // fallback just in case componentLoadedHandler was not called - make loaded component visible
                try {
                    if (!state.cmpLoaded) componentLoadedHandler(true);
                } catch (ignore) {}
            }, 500);
        }, []);

        return (
            <div
                className={classNames(
                    `window-ui content-float is-${pageType} is-${size}`,
                    className,
                )}
                id={containerId}
                style={style}
                onScroll={scrollHandler}
            >
                {/* <div className="bg" onClick={closeHandler} /> */}
                <div className="bg" onClick={closeHandler} />

                <div
                    className="wrap"
                    style={{
                        transitionDelay: state.cmpLoaded ? "100ms" : "0",
                        transition: state.cmpLoaded ? "all 0.2s ease-in" : "all 0.2s ease-out",
                        transform: state.cmpLoaded
                            ? "translate3D(0,0,0)"
                            : "translate3D(0,-40px,0)",
                        opacity: state.cmpLoaded ? 1 : 0,
                    }}
                >
                    {/* <div className="wrap"> */}
                    <PageHeader
                        title={title}
                        pageType={pageType}
                        isRootPath={false}
                        onClose={closeHandler}
                    />

                    {/* <Scrollbars autoHeight autoHeightMin={10} autoHeightMax={10000}> */}
                    <div className={classNames("window-inner", { "has-padding": hasPadding })}>
                        {/* {contentHeight && (
                        <Scrollbars style={{ width: contentWidth, height: contentHeight }}>
                            <Suspense fallback={<div />}>
                                <WindowContent
                                    className={classNames({ "p-25": pageType === "popup" })}
                                    cr={elm}
                                    name={name}
                                    title={title}
                                    pageType={pageType}
                                    size={size}
                                    passed={other}
                                    onClose={closeHandler}
                                />
                            </Suspense>
                        </Scrollbars>
                    )} */}

                        {/* {!contentHeight && ( */}
                        <Suspense fallback={<div />}>
                            <WindowContent
                                cr={elm}
                                name={name}
                                title={title}
                                pageType={pageType}
                                size={size}
                                windowId={windowId}
                                passed={other}
                                onCmpMounted={componentLoadedHandler}
                                onClose={closeHandler}
                            />
                        </Suspense>
                        {/* )} */}
                    </div>

                    {/* </Scrollbars> */}
                    {/* </div> */}
                </div>

                {state.scrollYPos > 50 && (
                    <>
                        {pageType === "window" && (
                            <div
                                className={classNames(
                                    "p-fixed animate__animated animate__backInDown shadow-2",
                                )}
                                style={{
                                    bottom: "40px",
                                    right: "90px",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                }}
                            >
                                <a
                                    className="t-center btn is-circle is-black"
                                    title="Close"
                                    style={{ width: "40px", height: "40px" }}
                                    onClick={closeHandler}
                                >
                                    <i className="material-icons f-s-lg">close</i>
                                </a>
                            </div>
                        )}

                        <div
                            className={classNames(
                                "p-fixed animate__animated animate__backInDown shadow-2",
                            )}
                            style={{
                                bottom: "40px",
                                right: "40px",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                            }}
                        >
                            <a
                                className="t-center btn is-circle is-blue"
                                title="Go to top"
                                style={{ width: "40px", height: "40px" }}
                                onClick={scrollClickHandler}
                            >
                                <i className="material-icons f-s-2x">arrow_upward</i>
                            </a>
                        </div>
                    </>
                )}
            </div>
        );
    },
);

export default memo(WindowUI);
