import { isNullOrUndefined, stringNumGenerator } from "./app-base/fn";

export function signinModel(email = "") {
    return {
        loading: false,
        currentStep: "email", // email, pwd, pwd-reset, verify, invite-list
        title: "Sign in with your PureInfo account",
        email: isNullOrUndefined(email) ? "" : email,
        email_disabled: false,
        remember_me: true,
        profile: null,
        pwd: "", // enter password
        code: "", // verification code
        newPwd1: "",
        newPwd2: "",
        getResetPwd: false,
        invitedOrgs: null,
    };
}

export function fileAuthModel(
    entity = "app",
    entityid = "",
    action = "put",
    folder = "",
    filename = "",
    contenttype = ""
) {
    return {
        entity: entity, // app - org - person : application or organization or person (application by default)
        entityid: entityid, // id of person or organization
        action: action, // get put delete
        folder: folder, // folder to insert into (blank is base of entity folder)
        filename: filename, // file name
        contenttype: contenttype,
    };
}

export function labelDataModel() {
    return {
        label: "",
        id: null,
        data: null,
    };
}

export function fileModel() {
    return {
        bucket: "",
        entity: "",
        entityid: "",
        key: "",
        filename: "",
        fileext: "",
        filemime: "",
        height: null,
        lastmodified: null,
        lastmodifieddate: null,
        orgfilename: "",
        size: null,
        width: null,
        updte: null,
        crtdte: null,
        title: "",
        tip: "",
        dscr: "",
    };
}

export function wordData(value = null, label = "", count = 0) {
    return {
        id: stringNumGenerator(8),
        value: value,
        label: label,
        count: count,
    };
}

// export function filterItemModel(
//     title = "",
//     filterKey = "",
//     isDynamic = true,
//     options = null,
//     optionsLabel = "label",
//     optionsKey = "data"
// ) {
//     return {
//         title: title, // title of filter
//         filterKey: filterKey, // key to filter
//         isDynamic: isDynamic,
//         options: options,
//         optionsLabel: optionsLabel,
//         optionsKey: optionsKey
//     };
// }

export function emitterEvent(action, data) {
    return {
        action: action,
        data: data,
    };
}

export function checkItemModel() {
    return {
        completed: false,
        title: "",
    };
}

export function phoneModel(label = "mobile", country = "US", number = "", ext = "", note = "") {
    return {
        label: label,
        country: country, // uppercase
        number: number,
        ext: ext,
        note: note,
    };
}

export function addressModel(
    label = "",
    title = "",
    add1 = "",
    add2 = "",
    city = "",
    country = "US",
    state = "",
    zip = ""
) {
    return {
        label: label,
        title: title,
        add1: add1,
        add2: add2,
        city: city,
        country: country,
        state: state,
        zip: zip,
    };
}

export function priorityLevelModel(data = "", label = "", ord = 0, is_system = true, clr = "") {
    return {
        data: data,
        label: label,
        ord: ord,
        is_system: is_system,
        clr: clr,
    };
}

export function taxonomyModel() {
    return {
        code: "",
        classification: "",
        group: "",
        specialization: "",
    };
}

export function userDetailModel(firstname = "", lastname = "") {
    return {
        id: "",
        stn: "",
        firstname: firstname,
        mdlname: "",
        lastname: lastname,
        sfx: "",
        gender: null,
        profileimgfile: "",
        phones: null,
        addresses: null,
        dob: null,
        dob_privacy: null,
        gender: null,
        gender_privacy: null,
    };
}

export function scheduleModel() {
    return {
        frequency: "none", // "","none", "daily", "weekly", "monthly", "yearly", "hourly"// recurring frequency
        time: null, // due time
        interval: 1, // interval frequency int in 'daily', 'week', 'month', 'year', 'hour'
        month_week: 1, // constants.MONTHLY_WEEKS_LIST ( 1st ~ last week of month) > only if yearly or monthly
        month_days: null,
        week_days: null, // days of the week for schedule (sun ~ mon) > only if yearly or monthly
        day_of_week: 1, // day of the week (0 ~ 6) > only if monthly | yearly
        months: null, // list of months > only if yearly
        reminder_at: 15, // reminder int in reminder_interval prior to event
        reminder_interval: "minutes", // "", "minutes", "hours", "days" > eminder time interval > minutes  hours  days
        end_date: null, // date when recurrence will stop
    };
}

export function contactInfoModel() {
    return {
        is_primary: false,
        name: "",
        email: "",
        phone: phoneModel(),
        title: "", // relation or job title
        note: "",
    };
}
export function payDataModel() {
    return {
        method: "",
        type: null,
        number: "",
        note: "",
    };
}
