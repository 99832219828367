import React, {
    // memo,
    forwardRef,
    // useEffect,
    // useRef,
    // useImperativeHandle
} from "react";
import classNames from "classnames";

const IFrameViewer = forwardRef(
    (
        {
            className,
            style,
            children,
            width = "100%",
            height = "auto",
            src = null,
            // lazyload,
            // isOverflow,
            // onVisible,
        },
        ref,
    ) => {
        // const validateClose =

        // if (ref) {
        //     useImperativeHandle(ref, () => ({
        //         closeValidate() {
        //             store.save();
        //             return store.closeValidate();
        //         },
        //     }));
        // }

        if (!src || src === "") {
            return null;
        }

        return (
            <div
                className={classNames("iframe-viewer", className)}
                style={{ ...style, width: width, height: height }}
            >
                <iframe
                    // seamless
                    // sandbox="allow-forms allow-scripts"
                    className="animate__animated animate__fadeIn"
                    style={{ width: width, height: height }}
                    src={src}
                    frameBorder="0"
                />
            </div>
        );
    },
);

export default IFrameViewer;
