import NProgress from "nprogress";
import { apiPost } from "../connection";
// import { showMessage } from "../app-base/displayUtils";

// get authorization for file upload
// data = baseModels.fileAuthModel
export async function getFileAuth(data, controller = null) {
    if (data.action !== "") {
        return await apiPost("files/auth", data, controller);
    } else {
        throw "Method is required.";
    }
}

// upload file to amazon s3 > file: blob
export function fileUploadS3(awsAuthData, file, cb) {
    const xhr = new XMLHttpRequest();
    // const message = showMessage("info", "Uploading file " + file.name);

    // progress
    xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
            let percentComplete = e.loaded / e.total;
            NProgress.set(percentComplete);
        }
    });
    // uploaded
    xhr.upload.addEventListener("load", () => {
        // console.log('completed', e);
        NProgress.done();
        cb(undefined, awsAuthData);
    });
    // error
    xhr.upload.addEventListener("error", (err) => {
        // console.log('error', e);
        NProgress.done();
        cb(err, undefined);
    });

    xhr.open("put", awsAuthData.url, true);
    // xhr.setRequestHeader('Content-Encoding', 'base64');
    xhr.setRequestHeader("Content-Type", awsAuthData.filemime);
    // xhr.setRequestHeader('Content-Type', 'binary/octet-stream');
    xhr.send(file);
}

// upload file to amazon s3 > file: blob
export function S3FileUploader(awsAuthData, onProgress, onComplete) {
    const xhr = new XMLHttpRequest();
    // const message = showMessage("info", "Uploading file " + file.name);

    // progress
    xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
            const percentComplete = e.loaded / e.total;
            if (onProgress) onProgress(awsAuthData, percentComplete);
        }
    });

    // uploaded
    xhr.upload.addEventListener("load", () => {
        if (onComplete) onComplete(undefined, awsAuthData);
    });

    // error
    xhr.upload.addEventListener("error", (err) => {
        // console.log('error', e);
        if (onComplete) onComplete(err, undefined);
    });

    xhr.open("put", awsAuthData.url, true);
    // xhr.setRequestHeader('Content-Encoding', 'base64');
    xhr.setRequestHeader("Content-Type", awsAuthData.filemime);
    // xhr.setRequestHeader('Content-Type', 'binary/octet-stream');

    return xhr;
}
//
// export function uploadProgress(e) {
//   if (e.lengthComputable) {
//     let percentComplete = e.loaded / e.total;
//     console.log('progress', percentComplete);
//     NProgress.set(percentComplete);
//   }
// }
//
// export function uploadCompleted(e) {
//   console.log('completed', e);
//   NProgress.done();
// }
