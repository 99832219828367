import { useReducer } from "react";
import {
    hasObject,
    isNullOrUndefined,
    findListItemIndex,
    // updateObject,
    // updateObjectKey,
    addToList, // add new line to a list
    updateList, // replace line in a list
    removeFromList, // remove line in a list
    mergeExistingOnly,
    // getLocalStorage,
    // setLocalStorage,
    // uniqueId
    // deleteLocalStorageKey
} from "../app-base/fn";
import { personName, getAvatarData } from "../utils";
import { defaultHours } from "../models/orgModels";

export function orgNavModel(usertype = "Member") {
    const nav = [
        {
            title: "ENCOUNTERS",
            type: "nav",
            access: "Member",
            url: "/encounters",
            icon: "assignment",
            items: [],
        },
        {
            title: "TASKS",
            type: "nav",
            icon: "event_available",
            access: "Member",
            url: "/tasks",
            items: [],
        },
        {
            title: "BILLING",
            type: "nav",
            access: "Admin",
            url: "/billing",
            icon: "local_atm",
            items: [],
        },
        {
            title: "DOCUMENTS",
            type: "nav",
            access: "Member",
            url: "/docs",
            icon: "folder",
            items: [],
        },
        {
            title: "VENDORS",
            type: "nav",
            access: "Member",
            url: "/vendors",
            icon: "contact_phone",
            items: [],
        },
        {
            title: "SETTINGS",
            type: "nav",
            access: "Admin",
            url: "/organization",
            icon: "location_city",
            items: [],
        },
    ];

    const isOrgAdmin = usertype === "Admin" || usertype === "Owner";

    if (isOrgAdmin) {
        return nav;
    } else {
        return nav.filter((nav) => {
            return nav.access !== "Admin";
        });
    }
}

export function orgStateModel() {
    return {
        orgUserType: "Member",
        defaultPage: "encounters", // default page for org
        recentList: { patients: [], encounters: [], payers: [] }, // recent searchBar.jsx search items
        nav: [],
        rootPaths: [], // [string]
        navIdx: 0,
        data: null, // orgShortModel(),
        payers: [],
        roles: [],
        areas: [],
        members: [],
        providers: [],
        procedures: [],
        defaultSrvLocationCode: null,
    };
}

export function orgState(initData) {
    const defaultPage = "encounters";
    const data = initData ? { ...orgStateModel(), ...initData } : orgStateModel();

    const changeState = (currentState, newState) => {
        if ((newState.hasOwnProperty("data"), newState.data.hasOwnProperty("type"))) {
            if (newState.data.type) {
                let defaultSrvLocationCode = "11";

                if (newState.data.type === "surgery-center") {
                    defaultSrvLocationCode = "24";
                } else {
                    defaultSrvLocationCode = "11";
                }

                return {
                    ...currentState,
                    ...newState,
                    defaultSrvLocationCode: defaultSrvLocationCode,
                };
            } else {
                return { ...currentState, ...newState };
            }
        } else {
            return { ...currentState, ...newState };
        }
    };

    const resetOrg = (currentState) => {
        return { ...currentState, ...orgStateModel() };
    };

    // private
    // const getOrgUserType = (orgid, orgs) => {
    //     let userType = "Associate";

    //     if (orgid) {
    //         const orgIdx = findListItemIndex(orgs, "orgid", orgid);

    //         if (orgIdx > -1) {
    //             const org = orgs[orgIdx];
    //             userType =
    //                 isNullOrUndefined(org.usertype) || org.usertype === ""
    //                     ? (userType = "Member")
    //                     : org.usertype;
    //         }
    //     }

    //     return userType;
    // };

    // private
    // get root paths > used in content.jsx to hide footer
    const getOrgRootPaths = (orgNav) => {
        if (orgNav && orgNav.length > 0) {
            let vals = ["/" + defaultPage];

            orgNav.map((nav) => {
                vals.push(nav.url);
            });

            return vals;
        } else {
            return [];
        }
    };

    // private format member
    const getFormattedMember = (member = null, orgHours = {}) => {
        if (hasObject(member)) {
            let workHours = defaultHours();

            workHours = !member.employee_hours
                ? !orgHours
                    ? defaultHours()
                    : orgHours
                : member.employee_hours;

            Object.keys(workHours).map((key) => {
                let day = workHours[key];

                if (day) {
                    // day.start = !day.start ? 0 : day.start;
                    // day.end = !day.end ? 0 : day.end;
                    // if (day.start > day.end) day.start = day.end = 0; // start is greater than end > set to no working
                    day.slice().sort((a, b) => a.start - b.start); // sort day hours by start time
                }
            });

            return {
                ...member,
                full_name: personName(member),
                avatar: getAvatarData("person", member),
                hours: workHours,
            };
        }

        return null;
    };

    // private
    const getFormatMemmbers = (members = []) => {
        if (!isNullOrUndefined(members) && members.length > 0) {
            let providers = [];
            let allMembers = members
                .map((member) => {
                    const newData = getFormattedMember(member);

                    if (newData) {
                        if (newData.physician) {
                            providers.push(newData);
                        }
                        return newData;
                    }
                })
                .sort((a, b) => {
                    let val = 0;
                    val = a.lastname === b.lastname ? 0 : a.lastname < b.lastname ? -1 : 1;
                    return val;
                });

            return { all: allMembers, providers: providers };
        }

        return { all: [], providers: [] };
    };

    // private
    const parseProcedures = (procs = []) => {
        let starredTests = [];
        let starredProcedures = [];
        let tests = [];
        let procedures = [];

        if (!isNullOrUndefined(procs) && procs.length > 0) {
            const sortBy = [
                {
                    prop: "starred",
                    direction: -1,
                },
                {
                    prop: "code",
                    direction: 1,
                },
            ];

            const sortFn = (a, b) => {
                let i = 0,
                    result = 0;
                while (i < sortBy.length && result === 0) {
                    result =
                        sortBy[i].direction *
                        (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString()
                            ? -1
                            : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString()
                            ? 1
                            : 0);
                    i++;
                }
                return result;
            };

            procs.map((proc) => {
                if (proc.is_test) {
                    tests.push(proc);
                    if (proc.starred) starredTests.push(proc);
                } else {
                    procedures.push(proc);
                    if (proc.starred) starredProcedures.push(proc);
                }
            });

            starredTests.sort(sortFn);
            starredProcedures.sort(sortFn);
            tests.sort(sortFn);
            procedures.sort(sortFn);
        }

        return {
            starredTests: starredTests,
            starredProcedures: starredProcedures,
            tests: tests,
            procedures: procedures,
        };
    };

    // private
    // bind org data
    const setOrgData = (currentState, { data, orgUserId = null, userType = "Associate" }) => {
        if (data) {
            const orgid = data.detail && data.detail.id ? data.detail.id : null;

            if (orgid) {
                const cleanState = resetOrg();
                // const userType = getOrgUserType(data.detail.id);
                const nav = orgNavModel(userType);
                const rootPaths = getOrgRootPaths(nav);
                const orgHours = data.detail && data.detail.hours ? data.detail.hours : {};
                const membersLists = getFormatMemmbers(data.members, orgHours);
                const orgProcs = parseProcedures(data.procedures);
                let defaultSrvLocationCode = null;

                if (data.detail.type) {
                    if (data.detail.type === "surgery-center") {
                        defaultSrvLocationCode = "24";
                    } else {
                        defaultSrvLocationCode = "11";
                    }
                }

                let ds = {
                    orgUserId: orgUserId,
                    orgUserType: userType,
                    defaultPage: defaultPage,
                    nav: nav,
                    rootPaths: rootPaths,
                    data: data.detail,
                    payers: isNullOrUndefined(data.payers) ? [] : data.payers,
                    roles: isNullOrUndefined(data.roles) ? [] : data.roles,
                    areas: isNullOrUndefined(data.areas) ? [] : data.areas,
                    members: membersLists.all,
                    providers: membersLists.providers,
                    starredTests: orgProcs.starredTests,
                    tests: orgProcs.tests,
                    starredProcedures: orgProcs.starredProcedures,
                    procedures: orgProcs.procedures,
                    defaultSrvLocationCode: defaultSrvLocationCode,
                };

                return { ...cleanState, ...ds };
            }
        }

        return currentState;
    };

    // add item to org recent items
    const orgRecentListAdd = (currentState, { view, item, itemKey }) => {
        if (
            item &&
            view &&
            currentState.recentList &&
            currentState.recentList.hasOwnProperty(view)
        ) {
            // recentList: { patients: [], encounters: [], payers: [] }, // recent searchBar.jsx search items
            let newObj = {};
            let list = currentState.recentList[view] ? currentState.recentList[view] : [];

            const idx = findListItemIndex(list, itemKey, item.id);

            if (idx > -1) list = removeFromList(list, idx);
            list = addToList(list, item, 0);
            if (list.length > 20) removeFromList(list, list.length - 1);
            newObj[view] = list;

            const newRecentList = { ...currentState.recentList, ...newObj };

            return { ...currentState, recentList: newRecentList };
        }

        return currentState;
    };

    // update item to org recent items
    const orgRecentListItemUpdate = (currentState, { view, item, itemKey }) => {
        if (
            item &&
            view &&
            currentState.recentList &&
            currentState.recentList.hasOwnProperty(view)
        ) {
            let list = currentState.recentList[view] ? currentState.recentList[view] : [];

            const idx = findListItemIndex(list, itemKey, item.id);

            if (idx > -1) {
                let newObj = {};

                list = updateList(list, mergeExistingOnly(list[idx], item), idx);
                newObj[view] = list;

                const newRecentList = { ...currentState.recentList, ...newObj };

                return { ...currentState, recentList: newRecentList };
            }

            return currentState;
        }

        return currentState;
    };

    // remove item to org recent items
    const orgRecentListRemove = (currentState, { view, item, itemKey }) => {
        if (
            item &&
            view &&
            currentState.recentList &&
            currentState.recentList.hasOwnProperty(view)
        ) {
            // recentList: { patients: [], encounters: [], payers: [] }, // recent searchBar.jsx search items

            let list = currentState.recentList[view] ? currentState.recentList[view] : [];

            const idx = findListItemIndex(list, itemKey, item.id);

            if (idx > -1) {
                let newObj = {};

                list = removeFromList(list, idx);
                newObj[view] = list;

                const newRecentList = { ...currentState.recentList, ...newObj };

                return { ...currentState, recentList: newRecentList };
            } else {
                return currentState;
            }
        }

        return currentState;
    };

    const updateListData = (currentState, { key, item, itemKey, addNotFound = true }) => {
        if (item && key && currentState.hasOwnProperty(key)) {
            let newObj = {};
            let list = currentState[key] && currentState[key] ? currentState[key] : [];

            const idx = findListItemIndex(list, itemKey, item.id);

            if (idx > -1) {
                list = updateList(list, mergeExistingOnly(list[idx], item), idx);
            } else {
                if (addNotFound) list = addToList(list, item);
            }

            newObj[key] = list;

            return { ...currentState, ...newObj };
        }

        return currentState;
    };

    const removeListData = (currentState, { key, item, itemKey }) => {
        if (item && key && currentState.hasOwnProperty(key)) {
            let list = currentState[key] && currentState[key] ? currentState[key] : [];

            const idx = findListItemIndex(list, itemKey, item.id);

            if (idx > -1) {
                let newObj = {};

                list = removeFromList(list, idx);
                newObj[key] = list;

                return { ...currentState, ...newObj };
            }

            return currentState;
        }

        return currentState;
    };

    const updateOrgData = (currentState, { key, item, itemKey, addNotFound = true }) => {
        if (item && key) {
            let newState = updateListData(currentState, {
                key: key,
                item: item,
                itemKey: itemKey,
                addNotFound: addNotFound,
            });

            return orgRecentListItemUpdate(newState, {
                view: key,
                item: item,
                itemKey: itemKey,
            });
        }

        return currentState;
    };

    const removeOrgData = (currentState, { key, item, itemKey }) => {
        if (item && key) {
            let newState = removeListData(currentState, { key: key, item: item, itemKey: itemKey });

            return orgRecentListRemove(newState, {
                view: key,
                item: item,
                itemKey: itemKey,
            });
        }

        return currentState;
    };

    // will always return full state
    const reducer = (currentState, action) => {
        switch (action.type) {
            case "changeState":
                return changeState(currentState, action.value);
            case "resetOrg":
                // reset org
                return resetOrg(currentState);
            case "setOrgData":
                //  set org data
                return setOrgData(currentState, action.value);
            case "orgRecentListAdd":
                return orgRecentListAdd(currentState, action.value);
            case "orgRecentListItemUpdate":
                return orgRecentListItemUpdate(currentState, action.value);
            case "updateOrgData":
                return updateOrgData(currentState, action.value);
            case "removeOrgData":
                return removeOrgData(currentState, action.value);
            default:
                return currentState;
            // throw new Error("Action not found");
        }
    };

    const [state, dispatch] = useReducer(reducer, data);

    return { data: state, actions: dispatch };
}

export default orgState;
